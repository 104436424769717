import { createContext, FC, ReactNode, useContext, useState } from 'react';

type BuildingEditState = {
  boundary: string | null;
  boundaryErrors: string[] | null;
};

type BuildingEditActions = {
  setBoundary: (newValue: BuildingEditState['boundary']) => void;
  setBoundaryErrors: (newValue: BuildingEditState['boundaryErrors']) => void;
};

const BuildingEditContext = createContext<[BuildingEditState | null, BuildingEditActions | null]>([
  null,
  null,
]);

const useBuildingEditContext = () => {
  const context = useContext(BuildingEditContext);

  return context;
};

const BuildingEditContextProvider: FC<{ children: ReactNode }> = (props) => {
  const { children } = props;

  const [boundary, setBoundary] = useState<BuildingEditState['boundary']>(null);
  const [boundaryErrors, setBoundaryErrors] = useState<BuildingEditState['boundaryErrors']>(null);

  const values = { boundary, boundaryErrors };
  const actions = { setBoundary, setBoundaryErrors };

  return (
    <BuildingEditContext.Provider value={[values, actions]}>
      {children}
    </BuildingEditContext.Provider>
  );
};

export { useBuildingEditContext, BuildingEditContextProvider };
