import buttonTheme from './buttonTheme';

const closeButtonStyles: { [key: string]: any } = {
  brand: {
    color: '#fff',
  },
};

const components = {
  CloseButton: {
    baseStyle: (props: { colorScheme: string }) => {
      const { colorScheme } = props;

      return closeButtonStyles[colorScheme];
    },
  },
  // TODO: We shouldn't override the baseStyle, it should be a variant named something like "permanent".
  // But I can't get a variant to work at the moment.
  Drawer: {
    parts: ['dialogContainer', 'dialog'],
    baseStyle: {
      dialog: {
        pointerEvents: 'auto',
      },
      dialogContainer: {
        pointerEvents: 'none',
      },
    },
  },
  Button: buttonTheme,
};

export default components;
