import { QueryObserverOptions, useQueries } from '@tanstack/react-query';
import centroid from '@turf/centroid';
import { feature, polygon } from '@turf/helpers';
import { Feature, Point } from '@turf/turf';
import type { Geometry } from 'geojson';
import isEqual from 'lodash/isEqual';
import { getColorFromScale } from '../helpers/ColorScales';
import { getBBox2d } from '../helpers/MapHelpers';
import booleanContains from '../helpers/booleanContains';
import { useLayerSettingsSelector } from '../hooks/useLayersSettings';
import { LayerKey } from '../reducers/layersSlice.types';
import dwAxiosClient from './dwAxiosClient';
import {
  AdditionalGridLayerProperties,
  AdditionalGridLayerResponse,
  GetAdditionalGridLayerOptions,
  GetAdditionalGridLayerParams,
  RawAdditionalGridLayer,
  RawAdditionalGridLayerResponse,
} from './gridAdditionalLayerClient.types';

const addAdditionalProperties = (
  gridProperties: RawAdditionalGridLayer,
  gridLocation: Feature<Point>,
  layerKey: LayerKey
) => {
  let stringifyLayerKey = 'gridSales';
  if (JSON.stringify(layerKey).includes('commecial')) {
    stringifyLayerKey = 'gridCommercial';
  }

  const rsrpMeanColor = getColorFromScale(stringifyLayerKey, 'rsrpMean', gridProperties.rsrpMean);
  const rsrp25Color = getColorFromScale(stringifyLayerKey, 'rsrp25', gridProperties.rsrp25);
  const rsrqMeanColor = getColorFromScale(stringifyLayerKey, 'rsrqMean', gridProperties.rsrqMean);
  const rsrq25Color = getColorFromScale(stringifyLayerKey, 'rsrq25', gridProperties.rsrq25);
  const qosCqiMeanColor = getColorFromScale(
    stringifyLayerKey,
    'qosCqiMean',
    gridProperties.rsrqMean
  );

  const locationLatLon = gridLocation.geometry.coordinates
    .slice()
    .reverse()
    .map((v) => v.toFixed(5))
    .join(', ');

  const properties: AdditionalGridLayerProperties = {
    ...gridProperties,
    locationLatLon: locationLatLon,
    rsrpMeanColor,
    rsrqMeanColor,
    rsrp25Color,
    rsrq25Color,
    qosCqiMeanColor,
  };

  return properties;
};

const getAdditionalGridLayerByBox = async (
  params: GetAdditionalGridLayerParams,
  options: GetAdditionalGridLayerOptions
) => {
  const { west, south, east, north, countryCode, providerName, layerKey } = params;
  const { signal } = options;

  if (countryCode === null || providerName === null) {
    return [] as Feature<Geometry, AdditionalGridLayerProperties>[];
  }

  let periodType = 'day';
  let analysisTier = 'sales';
  if (JSON.stringify(layerKey).toLowerCase().includes('commercial')) {
    periodType = 'month';
    analysisTier = 'commercial';
  }

  const { data, request } = await dwAxiosClient.get<RawAdditionalGridLayerResponse>(
    `/ResultGridCellCDM/GCP/Box/${west}/${south}/${east}/${north}`,

    {
      // TODO: remove this when the API is updated
      baseURL: 'https://api-2059.denseware.net/',
      params: { countryCode, providerName, periodType: periodType, analysisTier: analysisTier },
      signal,
      headers: {
        Accept: 'application/geo+json',
      },
    }
  );

  if (data && request.status === 200) {
    // transform data to GeoJSON
    const gridFeatures = data.features.reduce(
      (featuresAcc: Feature<Geometry, AdditionalGridLayerProperties>[], grid) => {
        if (grid === null) {
          return featuresAcc;
        }

        const gridGeometry = grid.geometry;

        // TODO: Work out what to do about GeometryCollections..
        if (gridGeometry && gridGeometry.type !== 'GeometryCollection') {
          // Check if the centroid is inside the box. Exclude the grid if it's not.
          const boxGeometry = polygon([
            [
              [west, south],
              [west, north],
              [east, north],
              [east, south],
              [west, south],
            ],
          ]);
          const gridCentroid = centroid(gridGeometry);
          const doesContain = booleanContains(boxGeometry, gridCentroid);
          if (!doesContain) {
            return featuresAcc;
          }

          const properties = addAdditionalProperties(grid.properties, gridCentroid, layerKey);

          const gridFeature = feature(gridGeometry, properties, {
            id: grid.id,
          });

          featuresAcc.push(gridFeature);
        }

        return featuresAcc;
      },
      []
    );

    return gridFeatures;
  }

  return [] as Feature<Geometry, AdditionalGridLayerProperties>[];
};

const useAdditionalGridLayerQueryClient = ({
  layerKey,
  providerName,
  countryCode,
  enabled,
}: {
  layerKey: LayerKey;
  providerName: string | null;
  countryCode: string | null;
  enabled: boolean;
}) => {
  const chunks = useLayerSettingsSelector(layerKey, 'visibleChunks', [], isEqual);

  const queries = chunks
    ? chunks.map((chunk) => {
        const chunk2d = chunk ? getBBox2d(chunk) : [];
        const [west, south, east, north] = chunk2d;

        const query: QueryObserverOptions<AdditionalGridLayerResponse | null> = {
          queryKey: [
            'gridAdditionalLayer' + layerKey,
            countryCode,
            providerName,
            chunk2d.join('|'),
          ],
          queryFn: ({ signal }: any) => {
            return getAdditionalGridLayerByBox(
              {
                west: west,
                south: south,
                east: east,
                north: north,
                countryCode,
                providerName,
                layerKey,
              },
              { signal }
            );
          },
          enabled,
          staleTime: 1000 * 60 * 60 * 5,
        };

        return query;
      })
    : [];

  return useQueries({ queries });
};

export { useAdditionalGridLayerQueryClient };
