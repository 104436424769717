export const mapStyles = [
  { name: 'Streets', url: 'mapbox://styles/mapbox/streets-v11', default: true },
  { name: 'Dark', url: 'mapbox://styles/mapbox/dark-v10' },
  { name: 'Greyscale', url: 'mapbox://styles/mapbox/light-v10' },
  { name: 'Outdoors', url: 'mapbox://styles/mapbox/outdoors-v11' },
  { name: 'Satellite', url: 'mapbox://styles/mapbox/satellite-streets-v11' },
  { name: 'Minimal', url: 'mapbox://styles/denseware-devs/clnlfmb8k003j01p929l8d3ue' },
];

export const defaultMapStyleUrl = () => {
  const defaultStyle = mapStyles.find((style) => style.default);

  if (defaultStyle) {
    return defaultStyle.url;
  }

  return '';
};
