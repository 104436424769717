import { useBuildingAdditionalLayerQueryClient } from './buildingAdditionalLayerClient';
import { useBuildings3dQueryClient } from './buildings3dClient';
import { useBuilding5gNsaQueryClient } from './buildings5GClientNsa';
import { useBuilding5gSaQueryClient } from './buildings5GClientSa';
import { useBuildingSingleLayerQueryClient } from './buildingsClient';
import useBuildingsNeutralHostQueryClient from './buildingsNeutralHostClient';
import useGenericClient from './genericClient';
import { useAdditionalGridLayerQueryClient } from './gridAdditionalLayerClient';
import { useGrids5gNsaQueryClient } from './grids5GClientNsa';
import { useGrids5gSaQueryClient } from './grids5GClientSa';
import { useGridsSingleLayerQueryClient } from './gridsClient';
import useGridsNeutralHostQueryClient from './gridsNeutralHostClient';
import { useNQTGaQueryClient } from './nqtGaClient';
import { useNQTHvQueryClient } from './nqtHvClient';
import { useNQTPassiveQueryClient } from './nqtPassiveClient';
import { useNQTsSingleLayerQueryClient } from './nqtResultsClient';
import { useOSSSingleLayerQueryClient } from './ossResultsClients';
import { useSpectrumClient } from './spectrumLicensingClient';

export type AllowedClient =
  | 'buildings'
  | 'buildings5gSa'
  | 'buildings5gNsa'
  | 'buildingsNh'
  | 'buildings3d'
  | 'buildingSales'
  | 'buildingCommercial'
  | 'grids'
  | 'gridSales'
  | 'gridCommercial'
  | 'grids5gNsa'
  | 'grids5gSa'
  | 'gridsNh'
  | 'nqt'
  | 'nqtHv'
  | 'nqtPassive'
  | 'nqtGa'
  | 'oss'
  | 'spectrumLicensing';

export const useDynamicClient = <T extends AllowedClient>(typeName: T) => {
  if (typeName === 'spectrumLicensing') {
    return useSpectrumClient;
  }

  if (typeName === 'buildingsNh') {
    return useBuildingsNeutralHostQueryClient;
  }

  if (typeName === 'buildings') {
    return useBuildingSingleLayerQueryClient;
  }

  if (typeName === 'buildingCommercial') {
    return useBuildingAdditionalLayerQueryClient;
  }

  if (typeName === 'buildingSales') {
    return useBuildingAdditionalLayerQueryClient;
  }

  if (typeName === 'buildings5gNsa') {
    return useBuilding5gNsaQueryClient;
  }

  if (typeName === 'buildings5gSa') {
    return useBuilding5gSaQueryClient;
  }

  if (typeName === 'buildings3d') {
    return useBuildings3dQueryClient;
  }

  if (typeName === 'grids5gNsa') {
    return useGrids5gNsaQueryClient;
  }

  if (typeName === 'grids5gSa') {
    return useGrids5gSaQueryClient;
  }

  if (typeName === 'gridsNh') {
    return useGridsNeutralHostQueryClient;
  }

  if (typeName === 'grids') {
    return useGridsSingleLayerQueryClient;
  }

  if (typeName === 'gridCommercial') {
    return useAdditionalGridLayerQueryClient;
  }

  if (typeName === 'gridSales') {
    return useAdditionalGridLayerQueryClient;
  }

  if (typeName === 'nqt') {
    return useNQTsSingleLayerQueryClient;
  }

  if (typeName === 'nqtHv') {
    return useNQTHvQueryClient;
  }

  if (typeName === 'nqtPassive') {
    return useNQTPassiveQueryClient;
  }

  if (typeName === 'nqtGa') {
    return useNQTGaQueryClient;
  }

  if (typeName === 'oss') {
    return useOSSSingleLayerQueryClient;
  }

  return useGenericClient;
};
