import buildingCommercialConfig from './layerConfig.buildingCommercial';
import buildingNeutralHostConfig from './layerConfig.buildingNeutral';
import buildingSalesConfig from './layerConfig.buildingSales';
import buildingConfig from './layerConfig.buildings';
import buildings3dConfig from './layerConfig.buildings3dConfig';
import building5gNsaConfig from './layerConfig.buildings5gNsa';
import building5gSaConfig from './layerConfig.buildings5gSa';
import gridCommercialConfig from './layerConfig.gridCommercial';
import gridSalesConfig from './layerConfig.gridSales';
import gridConfig from './layerConfig.grids';
import grid5gNsaConfig from './layerConfig.grids5gNsa';
import grid5gSaConfig from './layerConfig.grids5gSa';
import gridsNeutralHostConfig from './layerConfig.gridsNeutral';
import nqtConfig from './layerConfig.nqt';
import nqtGaConfig from './layerConfig.nqtGa';
import nqtHvConfig from './layerConfig.nqtHv';
import nqtPassiveConfig from './layerConfig.nqtPassive';
import ossConfig from './layerConfig.oss';
import spectrumLicensingConfig from './layerConfig.spectrumLicensing';
import { makeLayerId, makeLayerIdGrey, makeLayerIdTiles } from './layerIdGenerators';

const layerConfig = [
  nqtConfig,
  nqtGaConfig,
  nqtHvConfig,
  nqtPassiveConfig,
  ossConfig,
  buildings3dConfig,
  buildingConfig,
  building5gSaConfig,
  building5gNsaConfig,
  gridConfig,
  grid5gSaConfig,
  grid5gNsaConfig,
  spectrumLicensingConfig,
];

if (import.meta.env.VITE_APP_3POINT5_FEATURE) {
  layerConfig.push(
    buildingSalesConfig,
    gridSalesConfig,
    buildingCommercialConfig,
    gridCommercialConfig
  );
}

const layerOrderWithGreyAndTiles = layerConfig.flatMap((l) => [
  makeLayerId(l.layerId),
  makeLayerIdGrey(l.layerId),
  makeLayerIdTiles(l.layerId),
]);

const layerOrder = ['waterway-label', ...layerOrderWithGreyAndTiles];

const neutralHostLayerConfig = [buildingNeutralHostConfig, gridsNeutralHostConfig];

export { layerOrder, neutralHostLayerConfig };

export default layerConfig;
