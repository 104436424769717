import { extendTheme, Theme, withDefaultColorScheme } from '@chakra-ui/react';
import colors from '../../theme/colors.theme';
import components from '../../theme/components.theme';
import fonts from '../../theme/fonts.theme';

const themeExtension = { fonts, colors, components };

export const dwTheme = extendTheme(
  themeExtension,
  withDefaultColorScheme({ colorScheme: 'brand' })
);

export type DWThemeExtension = typeof themeExtension;
export type DWTheme = Theme & DWThemeExtension;
