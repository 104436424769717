import { EqualityFn } from 'react-redux';
import { LayerKey } from '../reducers/layersSlice.types';
import { useAppSelector } from './useAppSelector';

export const useLayerMetaSelector = <T>(
  layerKey: LayerKey,
  layerMetaPropertyName: string,
  defaultValue: any,
  equalityFn?: EqualityFn<any> | undefined
): T => {
  return useAppSelector((state) => {
    const allowedLayers: LayerKey[] = [
      'nqt',
      'nqtHv',
      'nqtPassive',
      'oss',
      'spectrumLicensing',
      20,
      21,
    ];

    if (allowedLayers.includes(layerKey)) {
      const layer: any = state.layersMeta[layerKey];

      if (layer && layerMetaPropertyName in layer) {
        const value = layer[layerMetaPropertyName];
        return value;
      }
    }

    return defaultValue;
  }, equalityFn);
};
