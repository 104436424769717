import tooltipText from '../config/tooltipTexts';
import twentififthPercentile from '../images/icons/first_percentile.png';
import { rsrpScale, rssnrScale } from './layerConfig.scales';
import { LayerConfig } from './layerConfig.types';

const buildings3dConfig: LayerConfig = {
  name: '3D Buildings',
  boundingBoxWkt: null,
  description: '3D Buildings',
  layerId: 'buildings3d',

  visibleFromUtc: '2000-01-01T00:00:00Z',
  visibleToUtc: '2099-01-01T00:00:00Z',
  zoomFrom: 0,
  zoomTo: 24,
  defaultSelectedMetric: 'rssnr25Pc',
  defaultLayerOn: false,
  layerParts: [
    {
      from: 14,
      to: 24,
      type: '3d',
      layerChunkLevel: 14,
      isInteractive: true,
    },
  ],
  metrics: [
    {
      name: 'Mean RSSNR',
      polygonMetricCode: 'rssnrMean',
      scale: rssnrScale,
      description: tooltipText.rssnr,
    },
    {
      name: 'RSSNR 25th Percentile',
      polygonMetricCode: 'rssnr25Pc',
      scale: rssnrScale,
      description: tooltipText.rssnr25,
      descriptionImageUrl: twentififthPercentile,
    },
    {
      name: 'Mean RSRP',
      polygonMetricCode: 'rsrpMean',
      scale: rsrpScale,
      description: tooltipText.rsrp,
    },
    {
      name: 'RSRP 25th Percentile',
      polygonMetricCode: 'rsrp25Pc',
      scale: rsrpScale,
      description: tooltipText.rsrp25,
      descriptionImageUrl: twentififthPercentile,
    },
  ],
};

export default buildings3dConfig;
