import { GIGABYTE, MEGABYTE, TERABYTE } from '../constants';
import { ColorScale } from './layerConfig.types';

const burgundy = '#3e0000';
const darkRed = '#800000';
const red = '#FF0000';
const orange = '#FF8000';
const yellow = '#ffff00';
const lightGreen = '#80ff80';
const green = '#4abe45';
const darkGreen = '#008000';

export const blueRedScale: ColorScale = {
  type: 'bin',
  scale: [
    { max: 0.01, color: '#cccccc' },
    { max: 2, color: '#0000ff' },
    { max: 5, color: '#5000f7' },
    { max: 10, color: '#7300ef' },
    { max: 15, color: '#8e00e4' },
    { max: 20, color: '#a500d9' },
    { max: 30, color: '#bb00cb' },
    { max: 50, color: '#cf00ba' },
    { max: 80, color: '#e200a6' },
    { color: '#ff0000' },
  ],
};

export const rssnrScale: ColorScale = {
  type: 'bin',
  scale: [
    { max: -6, color: burgundy },
    { max: -4, color: darkRed },
    { max: -2, color: red },
    { max: 0, color: orange },
    { max: 2, color: yellow },
    { max: 5, color: lightGreen },
    { max: 10, color: green },
    { color: darkGreen },
  ],
};

export const qosCqiMean: ColorScale = {
  type: 'bin',
  scale: [
    { max: -20, color: burgundy },
    { max: -10, color: darkRed },
    { max: -5, color: red },
    { max: 0, color: orange },
    { max: 5, color: yellow },
    { max: 10, color: lightGreen },
    { max: 20, color: green },
    { color: darkGreen },
  ],
};

export const rsrpScale: ColorScale = {
  type: 'bin',
  scale: [
    { max: -124, color: burgundy },
    { max: -120, color: darkRed },
    { max: -116, color: red },
    { max: -112, color: orange },
    { max: -108, color: yellow },
    { max: -104, color: lightGreen },
    { max: -100, color: green },
    { color: darkGreen },
  ],
};

export const rsrqScale: ColorScale = {
  type: 'bin',
  scale: [
    { max: -18, color: burgundy },
    { max: -15, color: red },
    { max: -12, color: orange },
    { max: -9, color: yellow },
    { max: -6, color: lightGreen },
    { max: -3, color: green },
    { color: darkGreen },
  ],
};

export const rsrpNHScale: ColorScale = {
  type: 'discrete',
  scale: [
    { color: '#fcfcfc', value: 0 },
    { color: '#2222cc', value: 1 },
    { color: '#d88118', value: 2 },
    { color: '#ff0000', value: 3 },
    { color: '#660000', value: 4 },
    { color: '#000000', value: 5 },
  ],
};

export const rssnrNHScale: ColorScale = {
  type: 'discrete',
  scale: [
    { color: '#fcfcfc', value: 0 },
    { color: '#2222cc', value: 1 },
    { color: '#d88118', value: 2 },
    { color: '#ff0000', value: 3 },
    { color: '#660000', value: 4 },
    { color: '#000000', value: 5 },
  ],
};

export const footfallDensityScale: ColorScale = {
  type: 'bin',
  scale: [
    { max: 0.001, color: '#ffffb2' },
    { max: 0.002, color: '#FFEBB2' },
    { max: 0.003, color: '#FFE687' },
    { max: 0.004, color: '#FECC5C' },
    { max: 0.005, color: '#FFAD4C' },
    { max: 0.006, color: '#FD8D3C' },
    { max: 0.007, color: '#F7642E' },
    { max: 0.008, color: '#F03B20' },
    { max: 0.009, color: '#D71D23' },
    { color: '#BD0026' },
  ],
};

export const thirdGenerationPercentScale: ColorScale = {
  type: 'bin',
  scale: [
    { max: 2, color: '#007000' },
    { max: 5, color: '#009900' },
    { max: 10, color: '#00ff00' },
    { max: 15, color: '#80ff80' },
    { max: 20, color: 'yellow' },
    { max: 25, color: '#FF8000' },
    { max: 30, color: '#FF0000' },
    { color: '#800000' },
  ],
};

export const meanTonnageScale: ColorScale = {
  type: 'bin',
  scale: [
    { max: 1, color: '#999999' },
    { maxLabel: '1 MB', max: MEGABYTE, color: '#ffff00' },
    { maxLabel: '10 MB', max: 10 * MEGABYTE, color: '#ffd000' },
    { maxLabel: '100 MB', max: 100 * MEGABYTE, color: '#ff9d00' },
    { maxLabel: '1 GB', max: GIGABYTE, color: '#ff5d30' },
    { maxLabel: '10 GB', max: 10 * GIGABYTE, color: '#e6005e' },
    { maxLabel: '100 GB', max: 100 * GIGABYTE, color: '#a70076' },
    { maxLabel: '1 TB', max: TERABYTE, color: '#660080' },
    { color: '#000080' },
  ],
};

export const measurementsScale: ColorScale = {
  type: 'bin',
  scale: [
    { max: 5, color: '#ffff00' },
    { max: 10, color: '#ffde00' },
    { max: 100, color: '#ffbc00' },
    { max: 500, color: '#ff9700' },
    { maxLabel: '1k', max: 1000, color: '#ff6c27' },
    { maxLabel: '2k', max: 2000, color: '#ff2447' },
    { maxLabel: '4k', max: 4000, color: '#d90065' },
    { maxLabel: '6k', max: 6000, color: '#ad0075' },
    { maxLabel: '10k', max: 10000, color: '#820080' },
    { maxLabel: '15k', max: 15000, color: '#510081' },
    { color: '#000080' },
  ],
};

export const fiveGPercentageScale: ColorScale = {
  type: 'bin',
  scale: [
    { max: 0.00001, color: '#cccccc' },
    { max: 2, color: '#0000ff' },
    { max: 5, color: '#5000f7' },
    { max: 10, color: '#7300ef' },
    { max: 15, color: '#8e00e4' },
    { max: 20, color: '#a500d9' },
    { max: 30, color: '#bb00cb' },
    { max: 50, color: '#cf00ba' },
    { max: 80, color: '#e200a6' },
    { color: '#ff0000' },
  ],
};

export const residentCountScale: ColorScale = {
  type: 'bin-specific',
  scale: [
    { max: 0, color: null, operator: 'eq' },
    { max: 5, color: '#ffff00', operator: 'lt' },
    { max: 10, color: '#ffde00', operator: 'lt' },
    { max: 25, color: '#ffbc00', operator: 'lt' },
    { max: 50, color: '#ff9711', operator: 'lt' },
    { max: 75, color: '#ff6b34', operator: 'lt' },
    { max: 100, color: '#fe2355', operator: 'lt' },
    { max: 150, color: '#d8006b', operator: 'lt' },
    { max: 300, color: '#ac0079', operator: 'lt' },
    { max: 500, color: '#800080', operator: 'lt' },
    { color: '#510081', operator: 'lt' },
  ],
};

export const beforeCapacityScale: ColorScale = {
  type: 'bin',
  scale: [
    { max: 5, color: '#ff8000' },
    { max: 10, color: 'yellow' },
    { max: 20, color: '#80ff80' },
    { max: 25, color: '#00ff00' },
    { color: '#008000' },
  ],
};

export const blueWhiteScale: ColorScale = {
  type: 'bin',
  scale: [
    { max: 1, color: 'white' },
    { max: 2, color: '#b7e0f6' },
    { max: 3, color: '#9bcbe3' },
    { max: 5, color: '#77afca' },
    { max: 7, color: '#5393b0' },
    { max: 10, color: '#377997' },
    { max: 20, color: '#2e6380' },
    { max: 40, color: '#244d68' },
    { color: '#1b3751' },
  ],
};

export const nqtScoreScale: ColorScale = {
  type: 'bin',
  scale: [
    { max: 1, color: '#3e0000' },
    { max: 2, color: '#ff0000' },
    { max: 3, color: '#ff8000' },
    { max: 4, color: 'yellow' },
    { max: 5, color: '#80ff80' },
    { color: 'green' },
  ],
};

export const throughputScale: ColorScale = {
  type: 'continuous',
  scale: {
    colors: ['#d73027', 'yellow', '#1a9850'],
    domain: [0, 5000, 10000],
    mode: 'lch',
  },
};

export const goodToBadContinuous: ColorScale = {
  type: 'continuous',
  scale: {
    colors: ['green', 'yellow', 'red'],
    domain: [0, 5000, 10000],
  },
};

export const zeroOrOneScale: ColorScale = {
  type: 'discrete',
  scale: [
    { value: 0, valueLabel: 'No', color: '#cccccc' },
    { value: 1, valueLabel: 'Yes', color: '#d252b9' },
  ],
};

const twelveColorScale = [
  '#a6cee3',
  '#1f78b4',
  '#b2df8a',
  '#33a02c',
  '#fb9a99',
  '#e31a1c',
  '#fdbf6f',
  '#ff7f00',
  '#cab2d6',
  '#6a3d9a',
  '#ffff99',
  '#b15928',
];

export const pciScale: ColorScale = {
  type: 'category',
  scale: Array(504)
    .fill(0)
    .map((_, i) => {
      return {
        color: twelveColorScale[i % twelveColorScale.length],
        value: i,
      };
    }),
};

export const dalBandScale: ColorScale = {
  type: 'category',
  scale: [
    { color: '#decbe4', value: 'D', valueLabel: '2.496 - 2.69 GHz (Band 41/n41)' },
    { color: '#fbb4ae', value: 'A', valueLabel: '3.45 - 3.55 GHz (Band n77)' },
    { color: '#b3cde3', value: 'B', valueLabel: '3.55 - 3.70 GHz (CBRS Band 48/n48)' },
    { color: '#ccebc5', value: 'C', valueLabel: '3.70 - 3.98 GHz (Band n77)' },
  ],
};
