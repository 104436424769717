import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  Grid,
  Heading,
  HStack,
  IconButton,
  SimpleGrid,
} from '@chakra-ui/react';
import { groupBy } from 'lodash';
import { FC, useEffect } from 'react';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { GiAnticlockwiseRotation } from 'react-icons/gi';
import { IoColorPalette } from 'react-icons/io5';
import assertUnreachable from '../../helpers/assertUnreachable';
import { FilterConfig } from '../../helpers/layerConfig.types';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useLayerSettingsSelector } from '../../hooks/useLayersSettings';
import {
  initializeFilterValues,
  setFilterValues,
  setIsLayerFiltersExpanded,
  setIsLayerMetricsExpanded,
  setShowAllFeatures,
} from '../../reducers/layersSlice';
import { LayerKey } from '../../reducers/layersSlice.types';
import LayerControlFilterDateRange from '../LayerControlFilterDateRange';
import LayerControlFilterDropdown from '../LayerControlFilterDropdown';
import LayerControlSingleFilterDropdown from '../LayerControlFilterDropdown/LayerControlSingleFilterDropdown';
import LayerControlFilterMultiText from '../LayerControlFilterMultiText';
import LayerControlFilterSlider from '../LayerControlFilterRangeSlider/LayerControlFilterRangeSlider';
import LayerControlFilterWrapper from './LayerControlFilterWrapper';

type LayerControlFiltersProps = {
  layerKey: LayerKey;
  filters: FilterConfig[];
};

const LayerControlFilters: FC<LayerControlFiltersProps> = (props) => {
  const { layerKey, filters } = props;

  const dispatch = useAppDispatch();

  const showAllFeatures = useLayerSettingsSelector(layerKey, 'showAllFeatures', true);
  const isFiltersExpanded = useLayerSettingsSelector(layerKey, 'isFiltersExpanded', false);

  const groupedFilters = groupBy(filters, 'category');

  useEffect(() => {
    dispatch(
      initializeFilterValues({
        layerKey,
        filterValues: filters.map((filter) => {
          return {
            filterMetricCode: filter.metricCode,
            filterValue: filter.type === 'rangeSlider' ? [null, null] : null,
          };
        }),
      })
    );
  }, [dispatch, layerKey, filters]);

  return (
    <Drawer
      id={`${layerKey}-controls-filters`}
      key={`${layerKey}-controls-filters`}
      isOpen={isFiltersExpanded}
      onClose={() => {}}
      placement="right"
      size="md"
      trapFocus={false}
    >
      <DrawerContent bg="blackAlpha.600" backdropFilter="blur(2px)">
        <DrawerCloseButton
          _hover={{ backgroundColor: 'brand.600' }}
          bgColor="brand.500"
          onClick={() => {
            dispatch(
              setIsLayerFiltersExpanded({
                layerKey,
                isFiltersExpanded: false,
              })
            );
          }}
        />
        <DrawerBody>
          <Box width="100%">
            <HStack>
              <IconButton
                aria-label={`Show metrics for ${layerKey}`}
                title={`Show metrics for ${layerKey}`}
                icon={<IoColorPalette size="1.4em" />}
                onClick={() => {
                  dispatch(
                    setIsLayerFiltersExpanded({
                      layerKey,
                      isFiltersExpanded: false,
                    })
                  );
                  dispatch(setIsLayerMetricsExpanded({ layerKey, isMetricsExpanded: true }));
                }}
              />
              <IconButton
                aria-label={`${
                  showAllFeatures ? 'Hide' : 'Show'
                } filtered features for ${layerKey}`}
                title={`${showAllFeatures ? 'Hide' : 'Show'} filtered features for ${layerKey}`}
                icon={
                  showAllFeatures ? <AiFillEyeInvisible size="1.2em" /> : <AiFillEye size="1.2em" />
                }
                onClick={() => {
                  dispatch(
                    setShowAllFeatures({
                      layerKey,
                      showAllFeatures: !showAllFeatures,
                    })
                  );
                }}
              />
              <IconButton
                aria-label={`Reset filters for ${layerKey}`}
                title={`Reset filters for ${layerKey}`}
                icon={<GiAnticlockwiseRotation size="1.2em" />}
                onClick={() => {
                  dispatch(
                    setFilterValues({
                      layerKey,
                      filterValues: filters.map((filter) => {
                        return {
                          filterMetricCode: filter.metricCode,
                          filterValue: null,
                        };
                      }),
                    })
                  );
                }}
              />
            </HStack>
            <Accordion allowToggle>
              {Object.entries(groupedFilters).map(([categoryName, categoryFilters]) => {
                if (categoryName === 'undefined') {
                  return categoryFilters.map((filter, index) => {
                    return getFilterComponent(filter, layerKey, index);
                  });
                }

                return (
                  <AccordionItem key={`filters-category-${categoryName}`} border="0">
                    <AccordionButton justifyContent={'flex-end'}>
                      <Heading size="sm" color="white">
                        <Box as="span" flex="1" textAlign="end">
                          {categoryName}
                        </Box>
                        <AccordionIcon />
                      </Heading>
                    </AccordionButton>
                    <AccordionPanel>
                      <SimpleGrid columns={2} columnGap="3" mt="2">
                        {categoryFilters.map((filter, index) => {
                          return getFilterComponent(filter, layerKey, index);
                        })}
                      </SimpleGrid>
                    </AccordionPanel>
                  </AccordionItem>
                );
              })}
            </Accordion>
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

const getFilterComponent = (filter: FilterConfig, layerKey: LayerKey, index: number) => {
  const filterType = filter.type;

  switch (filterType) {
    case 'date_range':
      return (
        <LayerControlFilterWrapper key={`filter-date_range-${layerKey}-${index}`} filter={filter}>
          <LayerControlFilterDateRange layerKey={layerKey} filterMetricCode={filter.metricCode} />
        </LayerControlFilterWrapper>
      );
    case 'single-dropdown':
      return (
        <Grid templateColumns={'repeat(2, 1fr)'} key={`filter-dropdown-${layerKey}-${index}`}>
          <LayerControlFilterWrapper filter={filter}>
            <LayerControlSingleFilterDropdown
              layerKey={layerKey}
              filterMetricCode={filter.metricCode}
              caseSensitive={filter.caseSensitive}
            />
          </LayerControlFilterWrapper>
        </Grid>
      );
    case 'dropdown':
      return (
        <Grid templateColumns={'repeat(2, 1fr)'} key={`filter-dropdown-${layerKey}-${index}`}>
          <LayerControlFilterWrapper filter={filter}>
            <LayerControlFilterDropdown
              layerKey={layerKey}
              filterMetricCode={filter.metricCode}
              caseSensitive={filter.caseSensitive}
            />
          </LayerControlFilterWrapper>
        </Grid>
      );
    case 'rangeSlider':
      return (
        <Grid templateColumns={'repeat(2, 1fr)'} key={`filter-rangeSlider-${layerKey}-${index}`}>
          <LayerControlFilterWrapper filter={filter}>
            <LayerControlFilterSlider
              layerKey={layerKey}
              intervals={filter.intervals}
              filterMetricCode={filter.metricCode}
            />
          </LayerControlFilterWrapper>
        </Grid>
      );

    case 'multiselect':
      return (
        <Grid templateColumns={'repeat(2, 1fr)'} key={`filter-rangeSlider-${layerKey}-${index}`}>
          <LayerControlFilterWrapper filter={filter}>
            <LayerControlFilterMultiText
              layerKey={layerKey}
              filterMetricCode={filter.metricCode}
              options={filter.options}
            />
          </LayerControlFilterWrapper>
        </Grid>
      );
  }

  return assertUnreachable(filterType);
};

export default LayerControlFilters;
