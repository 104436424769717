import { configureStore } from '@reduxjs/toolkit';
import clusterSlice from './clusterSlice';
import contextSettings from './contextSlice';
import drawersSlice from './drawersSlice';
import layersMeta from './layersMetaSlice';
import layersSettings from './layersSlice';
import mapSettings from './mapSettings';
import modalSlice from './modalSlice';
import modalTabSettings from './modalTabSlice';
import navigationSettings from './navigationSlice';
import personalizationSetting from './personalizationSlice';
import regionsSlice from './regionsSlice';
import searchTextSettings from './searchSlice';
import streetViewStateSettings from './streetViewSlice';

export const store = configureStore({
  reducer: {
    layersSettings,
    layersMeta,
    contextSettings,
    mapSettings,
    regionsSlice,
    drawersSlice,
    modalSlice,
    clusterSlice,
    navigationSettings,
    searchTextSettings,
    modalTabSettings,
    personalizationSetting,
    streetViewStateSettings,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
