import { Box, Checkbox, Flex, Table, TableContainer, Tag, Tbody, Text, Tr } from '@chakra-ui/react';
import { FC, Fragment, useState } from 'react';
import { RawOSSResultsProperties } from '../../services/ossResultsClient.types';
import AllNodesButton from './AllNodesButton';
import OpenLink from './OpenLink';

type NodesDataOptions = {
  selectedNodes: RawOSSResultsProperties[];
  countryCode: string | null;
  providerName: string | null;
};

const NodesData: FC<NodesDataOptions> = ({ selectedNodes, countryCode, providerName }) => {
  const inViewNodeNames = selectedNodes.map((node) => {
    return node.nodeName;
  });

  const [selectedNodesArray, setSelectedNodesArray] = useState<string[]>(inViewNodeNames);

  const checkedItems: 'checked' | 'unchecked' | 'indeterminate' =
    selectedNodesArray.length === 0
      ? 'unchecked'
      : selectedNodesArray.length === inViewNodeNames.length
      ? 'checked'
      : 'indeterminate';

  const selectAllCheckBoxClicked = () => {
    if (checkedItems === 'checked') {
      setSelectedNodesArray([]);
    } else if (checkedItems === 'unchecked') {
      setSelectedNodesArray(inViewNodeNames);
    } else {
      setSelectedNodesArray(inViewNodeNames);
    }
  };

  const singleNodeCheckboxClicked = (value: string) => {
    setSelectedNodesArray((previousState) => {
      const isSelected = previousState.includes(value);

      if (isSelected) {
        return previousState.filter((name) => {
          return value !== name;
        });
      }

      return [...previousState, value];
    });
  };

  return (
    <Fragment>
      <Flex mb={10}>
        <Flex alignItems={'center'} justifyContent={'flex-end'} width={'100%'}>
          <Checkbox
            isChecked={checkedItems === 'checked'}
            isIndeterminate={checkedItems === 'indeterminate'}
            onChange={selectAllCheckBoxClicked}
          />
          <Text pl={'10px'} mr={'2'}>
            Select All
          </Text>
        </Flex>
      </Flex>
      <TableContainer>
        <Table width={'100%'}>
          <Tbody>
            {selectedNodes.map((nodeData: any) => {
              return (
                <Tr
                  key={nodeData.nodeName}
                  display={'flex'}
                  justifyContent={'space-between'}
                  mb={'10px'}
                >
                  <Tag
                    background={'brand.600'}
                    borderRadius={'15px'}
                    color={'white'}
                    p={'2px 15px'}
                    lineHeight={0}
                  >
                    {nodeData.nodeName}
                  </Tag>
                  <Box display={'flex'}>
                    <Checkbox
                      mr={'10px'}
                      value={nodeData.nodeName}
                      isChecked={selectedNodesArray.includes(nodeData.nodeName)}
                      onChange={() => {
                        singleNodeCheckboxClicked(nodeData.nodeName);
                      }}
                    />
                    <OpenLink
                      name={'A'}
                      nodeName={nodeData.nodeName}
                      type={'analytics'}
                      countryCode={countryCode}
                      providerName={providerName}
                    />
                    <OpenLink
                      name={'M'}
                      nodeName={nodeData.nodeName}
                      type={'monitor'}
                      countryCode={countryCode}
                      providerName={providerName}
                    />
                  </Box>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
      <Box display={'flex'} gap={'10px'} justifyContent={'flex-end'}>
        <AllNodesButton
          selectedNodes={selectedNodesArray}
          name={'Analytics'}
          isDisabled={selectedNodesArray.length === 0}
          countryCode={countryCode}
          providerName={providerName}
        />
        <AllNodesButton
          isDisabled={selectedNodesArray.length === 0}
          selectedNodes={selectedNodesArray}
          name={'Monitor'}
          countryCode={countryCode}
          providerName={providerName}
        />
      </Box>
    </Fragment>
  );
};

export default NodesData;
