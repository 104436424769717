import { featureCollection } from '@turf/helpers';
import parse from 'date-fns/parse';
import _get from 'lodash/get';
import {
  CircleLayer,
  CircleLayout,
  CirclePaint,
  Expression as MapboxExpression,
  FillLayer,
  FillLayout,
  FillPaint,
  SymbolLayer,
} from 'mapbox-gl';
import { FC } from 'react';
import { Layer, Source } from 'react-map-gl';
import { Logger } from '../../helpers/Logger';
import { FilterConfig, LayerStyles } from '../../helpers/layerConfig.types';
import { makeLayerId, makeLayerIdGrey, makeSourceId } from '../../helpers/layerIdGenerators';
import { useAppSelector } from '../../hooks/useAppSelector';
import { useGetBeforeLayerId } from '../../hooks/useGetBeforeLayerId';
import { useIsNeutralHost } from '../../hooks/useIsNeutralHost';
import { useLayerSettingsSelector } from '../../hooks/useLayersSettings';
import { LayerKey, NeutralHostSettingsState } from '../../reducers/layersSlice.types';
import { NestedStringArray } from '../../types/helpers';

type TwoDPolygonDrawProps = {
  data: GeoJSON.Feature<GeoJSON.Geometry> | GeoJSON.FeatureCollection<GeoJSON.Geometry>;
  layerKey: LayerKey;
  layerFilters: FilterConfig[] | undefined;
  layerType: 'circle' | 'fill' | 'symbol' | 'line';
  minZoom: number;
  maxZoom: number;
  layerStyles?: LayerStyles;
  cluster?: boolean;
};

const TwoDPolygonDraw: FC<TwoDPolygonDrawProps> = (props) => {
  const { data, layerKey, layerType, layerFilters, minZoom, maxZoom, layerStyles, cluster } = props;

  const mapStyleUrl = useAppSelector((state) => {
    return state.mapSettings.mapStyleUrl;
  });

  const zoom = useAppSelector((state) => state.mapSettings.viewState.zoom);

  const borderOutlineColor = mapStyleUrl.includes('dark') ? '#FFFFFF' : '#000000';

  const sourceId = makeSourceId(layerKey);
  const layerId = makeLayerId(layerKey);
  const layerIdGrey = makeLayerIdGrey(layerKey);

  const selectedMetric = useLayerSettingsSelector(layerKey, 'selectedMetric', null);
  const metricName = `${selectedMetric}Color`;

  const showAllFeatures = useLayerSettingsSelector(layerKey, 'showAllFeatures', true);
  const filters = useLayerSettingsSelector(layerKey, 'filterValues', []);
  const nhSettings = useLayerSettingsSelector(
    layerKey,
    'neutralHostSettings',
    {} as NeutralHostSettingsState
  );

  const selectedFeatureId = useAppSelector((state) => state.mapSettings.selectedFeatureId);

  const isNeutralHost = useIsNeutralHost();

  const cellmapperKeys: Array<string | number> = [20, 21];
  const isCellmapper = cellmapperKeys.includes(layerKey);

  const badThreshold =
    selectedMetric && nhSettings[selectedMetric] ? nhSettings[selectedMetric] : 0;

  const dataToUse =
    isNeutralHost && selectedMetric && data.type === 'FeatureCollection'
      ? featureCollection(
          data.features.map((f) => {
            if (f.properties && f.properties[selectedMetric]) {
              const badScores = f.properties[selectedMetric].filter((v: any) => {
                if (v === null) {
                  return false;
                }

                if (badThreshold !== null && badThreshold !== undefined && v < badThreshold) {
                  return true;
                }

                return false;
              });

              const badCount = badScores.length;

              const badColor = ['#fcfcfc', '#2222cc', '#d88118', '#ff0000', '#660000', '#000000'][
                badCount
              ];

              const newFeature = {
                ...f,
                properties: {
                  ...f.properties,
                  [`${selectedMetric}Color`]: badColor,
                },
              };

              return newFeature;
            }

            return f;
          })
        )
      : isCellmapper && data.type === 'FeatureCollection' && layerFilters
      ? featureCollection(
          data.features.filter((f) => {
            const pass = Object.entries(filters).every((filter) => {
              if (f.properties) {
                const [metricCode, filterValues] = filter;
                const filterConfig = layerFilters.find((l) => l.metricCode === metricCode);
                if (filterConfig) {
                  switch (filterConfig.type) {
                    case 'dropdown': {
                      if (Array.isArray(filterValues) && filterValues.length > 0) {
                        const value = _get(f.properties, metricCode);

                        // if all are boolean, straight compare
                        if (filterValues.every((v) => typeof v === 'boolean')) {
                          return filterValues.includes(value);
                        } else if (filterConfig.caseSensitive) {
                          return filterValues.includes(value);
                        } else {
                          const valueAsString = String(value);
                          return filterValues.includes(valueAsString.toLowerCase());
                        }
                      }

                      return true;
                    }

                    default:
                      Logger.warn(
                        "WARNING. trying to filter based on a filter type we haven't thought about"
                      );
                      return true;
                  }
                }
              }

              return true;
            });

            return pass;
          })
        )
      : data;

  const buildingsFillPaint: FillPaint = {
    'fill-color': [
      'let',
      'fillColor',
      ['to-rgba', ['to-color', ['get', metricName], '#bbbbbb']],
      [
        'rgba',
        ['at', 0, ['var', 'fillColor']],
        ['at', 1, ['var', 'fillColor']],
        ['at', 2, ['var', 'fillColor']],
        [
          'case',
          ['==', ['get', 'buildingId'], selectedFeatureId],
          1,
          ['boolean', ['feature-state', 'hover'], false],
          0.7,
          0.5,
        ],
      ],
    ],
    'fill-outline-color': [
      'let',
      'fillOutlineColor',
      ['to-rgba', ['to-color', ['get', '#FFFFFF'], borderOutlineColor]],
      [
        'rgba',
        ['at', 0, ['var', 'fillOutlineColor']],
        ['at', 1, ['var', 'fillOutlineColor']],
        ['at', 2, ['var', 'fillOutlineColor']],
        [
          'case',
          ['==', ['get', 'buildingId'], selectedFeatureId],
          1,
          ['boolean', ['feature-state', 'hover'], false],
          0,
          0,
        ],
      ],
    ],
  };

  const gridsFillPaint: FillPaint = {
    'fill-color': [
      'let',
      'fillColor',
      ['to-rgba', ['to-color', ['get', metricName], '#bbbbbb']],
      [
        'rgba',
        ['at', 0, ['var', 'fillColor']],
        ['at', 1, ['var', 'fillColor']],
        ['at', 2, ['var', 'fillColor']],
        [
          'case',
          ['==', ['get', 'gridCellId'], selectedFeatureId],
          0.7,
          ['boolean', ['feature-state', 'hover'], false],
          0.5,
          0.3,
        ],
      ],
    ],
    'fill-outline-color': [
      'let',
      'fillOutlineColor',
      ['to-rgba', ['to-color', ['get', '#FFFFFF'], borderOutlineColor]],
      [
        'rgba',
        ['at', 0, ['var', 'fillOutlineColor']],
        ['at', 1, ['var', 'fillOutlineColor']],
        ['at', 2, ['var', 'fillOutlineColor']],
        [
          'case',
          ['==', ['get', 'gridCellId'], selectedFeatureId],
          1,
          ['boolean', ['feature-state', 'hover'], false],
          0,
          0,
        ],
      ],
    ],
  };

  const spectrumFillPaint: FillPaint = {
    'fill-color': [
      'let',
      'fillColor',
      ['to-rgba', ['to-color', ['get', metricName], '#bbbbbb']],
      [
        'rgba',
        ['at', 0, ['var', 'fillColor']],
        ['at', 1, ['var', 'fillColor']],
        ['at', 2, ['var', 'fillColor']],
        [
          'case',
          ['==', ['get', 'originalId'], selectedFeatureId],
          1,
          ['boolean', ['feature-state', 'hover'], false],
          0.7,
          0.2,
        ],
      ],
    ],
    'fill-outline-color': '#000000',
  };

  const buildingLayers: Array<string | number> = [
    'buildings',
    'buildingSales',
    'buildingCommercial',
    'buildingsNh',
    'buildings5gSa',
    'buildings5gNsa',
  ];
  const fillPaint: FillPaint = buildingLayers.includes(layerKey)
    ? buildingsFillPaint
    : layerKey === 'spectrumLicensing'
    ? spectrumFillPaint
    : gridsFillPaint;

  const HvPaint: CirclePaint = {
    'circle-radius': [
      'interpolate',
      ['linear'],
      ['zoom'],
      10,
      2,
      13,
      2,
      15,
      2,
      18,
      3,
      19,
      4,
      20,
      6,
    ],
    'circle-color': ['to-color', ['get', metricName], '#bbbbbb'],
    'circle-stroke-opacity': 1,
    'circle-pitch-alignment': 'map',
  };

  const hvLayout: CircleLayout = {
    'circle-sort-key': ['*', -1, ['get', selectedMetric]],
  };

  const defaultCirclePaint: CirclePaint = {
    'circle-radius': ['interpolate', ['linear'], ['zoom'], 18, 5, 19, 6, 22, 7],
    'circle-color': ['to-color', ['get', metricName], '#bbbbbb'],
    'circle-opacity': 0.4,
    'circle-stroke-color': ['to-color', ['get', metricName], '#bbbbbb'],
    'circle-stroke-width': ['interpolate', ['linear'], ['zoom'], 18, 2, 19, 3, 22, 3],
    'circle-stroke-opacity': 1,
    'circle-pitch-alignment': 'map',
  };

  const nqtDotLayers: Array<LayerKey> = ['nqtHv', 'nqtPassive'];
  const circlePaint: CirclePaint = nqtDotLayers.includes(layerKey) ? HvPaint : defaultCirclePaint;

  const circleLayout: CircleLayout = nqtDotLayers.includes(layerKey) ? hvLayout : {};

  const circleLayer: CircleLayer = {
    id: layerId,
    type: 'circle',
    paint: circlePaint,
    layout: circleLayout,
  };

  const fillLayout: FillLayout = { 'fill-sort-key': ['*', ['get', 'areaM2'], -1] };
  const spectrumLayout: FillLayout = {
    'fill-sort-key': ['case', ['==', ['get', 'originalId'], selectedFeatureId], 999999999, 0],
  };

  const fillLayer: FillLayer = {
    id: layerId,
    type: 'fill',
    paint: fillPaint,
    layout: layerKey === 'spectrumLicensing' ? spectrumLayout : fillLayout,
  };

  const symbolLayer: SymbolLayer = {
    id: layerId,
    type: 'symbol',
    layout: {
      'icon-image': [
        'case',
        ['==', ['get', 'nodeName'], selectedFeatureId],
        //Selected
        [
          'case',
          ['==', ['get', 'technologyType'], '4G'],
          'smallCell4gSelected',
          'smallCell5gSelected',
        ],
        //Normal
        ['case', ['==', ['get', 'technologyType'], '4G'], 'smallCell4g', 'smallCell5g'],
      ],
      'icon-allow-overlap': true,
      'icon-size': ['interpolate', ['linear'], ['zoom'], 10, 0.6, 14, 0.7, 20, 0.8],
      'icon-anchor': 'bottom',
    },
  };

  const circleStyles: CircleLayer = {
    // Note:leave it here for future reference
    // https://docs.mapbox.com/style-spec/reference/expressions/#step
    //   * Yellow, 20px circles when point count is less than 10
    //   * Peach, 30px circles when point count is between 10 and 25
    //   * Pink, 40px circles when point count is greater than or equal to 25
    id: layerId + '-cluster',
    type: 'circle',
    paint: {
      'circle-color': ['step', ['get', 'point_count'], '#FFFF99', 10, '#FFDAB9', 25, '#FFB6C1'],
      'circle-radius': ['step', ['get', 'point_count'], 20, 10, 30, 25, 40],
    },
  };

  const paintAndType =
    layerType === 'circle' ? circleLayer : layerType === 'symbol' ? symbolLayer : fillLayer;

  const circleLayerGrey: CircleLayer = {
    id: layerIdGrey,
    type: 'circle',
    paint: {
      ...circlePaint,
      'circle-stroke-color': '#bbbbbb',
      'circle-color': '#bbbbbb',
    },
    layout: {
      visibility: showAllFeatures ? 'visible' : 'none',
    },
  };

  const symbolLayerGrey: SymbolLayer = {
    id: layerIdGrey,
    type: 'symbol',
    layout: {
      'icon-image': [
        'case',
        ['==', ['get', 'technologyType'], '4G'],
        'smallCell4gGrey',
        'smallCell5gGrey',
      ],
      'icon-allow-overlap': true,
      'icon-size': ['interpolate', ['linear'], ['zoom'], 10, 0.6, 14, 0.7, 20, 0.8],
      'icon-anchor': 'bottom',
    },
  };

  const fillPaintGreyBuildings: FillPaint = {
    ...fillPaint,
    'fill-color': [
      'rgba',
      187,
      187,
      187,
      [
        'case',
        ['==', ['get', 'buildingId'], selectedFeatureId],
        1,
        ['boolean', ['feature-state', 'hover'], false],
        0.7,
        0.5,
      ],
    ],
  };

  const fillPaintGreyGrids: FillPaint = {
    ...fillPaint,
    'fill-color': [
      'rgba',
      187,
      187,
      187,
      [
        'case',
        ['==', ['get', 'gridCellId'], selectedFeatureId],
        0.7,
        ['boolean', ['feature-state', 'hover'], false],
        0.5,
        0.3,
      ],
    ],
  };

  const fillLayerGrey: FillLayer = {
    id: layerIdGrey,
    type: 'fill',
    paint:
      layerKey === 'buildings' || layerKey === 'buildingsNh'
        ? fillPaintGreyBuildings
        : fillPaintGreyGrids,
    layout: {
      ...fillLayout,
      visibility: showAllFeatures ? 'visible' : 'none',
    },
  };

  const paintAndTypeGrey =
    layerType === 'circle'
      ? circleLayerGrey
      : layerType === 'symbol'
      ? symbolLayerGrey
      : fillLayerGrey;

  const metricFilterExpressions = Object.entries(filters).reduce(
    (acc: MapboxExpression[], filter) => {
      const [metricCode, filterValues] = filter;

      const getExpression = metricCode.split('.').reduce<NestedStringArray>((acc, p) => {
        if (acc.length === 0) {
          acc = ['get', p];
        } else {
          acc = ['get', p, acc];
        }

        return acc;
      }, []);

      if (filterValues && layerFilters) {
        const filterConfig = layerFilters.find((l) => l.metricCode === metricCode);

        if (filterConfig) {
          if (filterConfig.type === 'single-dropdown') {
            if (typeof filterValues === 'string') {
              acc.push(['in', filterValues, getExpression]);
            }
          } else if (filterConfig.type === 'dropdown') {
            if (Array.isArray(filterValues)) {
              if (filterValues.length !== 0) {
                if (filterConfig.caseSensitive) {
                  acc.push(['in', getExpression, ['literal', filterValues]]);
                } else if (filterValues.every((v) => typeof v === 'boolean')) {
                  acc.push(['in', getExpression, ['literal', filterValues]]);
                } else {
                  acc.push([
                    'in',
                    ['downcase', ['to-string', getExpression]],
                    ['literal', filterValues],
                  ]);
                }

                return acc;
              }
            }
          } else if (filterConfig.type === 'multiselect') {
            if (Array.isArray(filterValues)) {
              if (filterValues.length !== 0) {
                acc.push(['in', ['to-string', getExpression], ['literal', filterValues]]);

                return acc;
              }
            }
          } else if (filterConfig.type === 'rangeSlider') {
            if (Array.isArray(filterValues)) {
              const [min, max] = filterValues;

              if (min !== null) {
                acc.push(['>=', getExpression, min]);
              }

              if (max !== null) {
                acc.push(['<', getExpression, max]);
              }

              return acc;
            }
          } else if (filterConfig.type === 'date_range') {
            if (Array.isArray(filterValues)) {
              const [from, to] = filterValues;

              const userDateRegExp = /^[0-9]{2}[/][0-9]{2}[/][0-9]{4}?$/;
              const userDateTimeRegExp = /^[0-9]{2}[/][0-9]{2}[/][0-9]{4} [0-9]{2}[:][0-9]{2}?$/;

              if (from !== null) {
                const fromDateValid = userDateRegExp.test(from);
                const fromDateTimeValid = userDateTimeRegExp.test(from);
                try {
                  if (fromDateValid) {
                    const fromDate = parse(from, 'dd/MM/yyyy', new Date());
                    acc.push(['>=', getExpression, fromDate.toISOString()]);
                  } else if (fromDateTimeValid) {
                    const fromDateTime = parse(from, 'dd/MM/yyyy HH:mm', new Date());
                    const year = fromDateTime.getFullYear();
                    const month =
                      (fromDateTime.getMonth() + 1).toString().length <= 1
                        ? `0${fromDateTime.getMonth() + 1}`
                        : fromDateTime.getMonth() + 1;
                    const date =
                      fromDateTime.getDate().toString().length <= 1
                        ? `0${fromDateTime.getDate()}`
                        : fromDateTime.getDate();
                    const hour =
                      fromDateTime.getHours().toString().length <= 1
                        ? `0${fromDateTime.getHours()}`
                        : fromDateTime.getHours();
                    const minute = fromDateTime.getMinutes();
                    const newDate = `${year}-${month}-${date}T${hour}:${minute}`;
                    acc.push(['>=', getExpression, newDate]);
                  } else {
                    Logger.warn('Incorrect from date format entered ');
                  }
                } catch (e) {
                  Logger.warn('Could not parse from date');
                }
              }

              if (to !== null) {
                const toDateValid = userDateRegExp.test(to);
                const toDateTimeValid = userDateTimeRegExp.test(to);
                try {
                  if (toDateValid) {
                    const toDate = parse(to, 'dd/MM/yyyy', new Date());
                    acc.push(['<', getExpression, toDate.toISOString()]);
                  } else if (toDateTimeValid) {
                    const toDateTime = parse(to, 'dd/MM/yyyy HH:mm', new Date());

                    const year = toDateTime.getFullYear();
                    const month =
                      (toDateTime.getMonth() + 1).toString().length <= 1
                        ? `0${toDateTime.getMonth() + 1}`
                        : toDateTime.getMonth() + 1;
                    const date =
                      toDateTime.getDate().toString().length <= 1
                        ? `0${toDateTime.getDate()}`
                        : toDateTime.getDate();
                    const hour =
                      toDateTime.getHours().toString().length <= 1
                        ? `0${toDateTime.getHours()}`
                        : toDateTime.getHours();
                    const minute = toDateTime.getMinutes();
                    const newToDate = `${year}-${month}-${date}T${hour}:${minute}`;
                    acc.push(['<', getExpression, newToDate]);
                  } else {
                    Logger.warn('Incorrect from date format entered ');
                  }
                } catch (e) {
                  Logger.warn('Could not parse from date');
                }
              }
            }
          } else {
            /**
             * This `as any` is OK. Because:
             * although Typescript won't allow a filter config to be created
             * that doesn't have `type` as an allowed string..
             * We might in the future have configs loaded via API which won't be type checked
             * good to have this warning in for us!
             */
            Logger.warn('No filter expression for filter type', (filterConfig as any).type);
          }
        }
      }

      return acc;
    },
    []
  );

  const filterExpression: MapboxExpression | undefined = [
    'oss',
    'spectrumLicensing',
    20,
    21,
  ].includes(layerKey)
    ? ['all', ...metricFilterExpressions]
    : selectedMetric
    ? ['all', ['!=', ['get', metricName], null], ...metricFilterExpressions]
    : undefined;

  const notFilterExpression: MapboxExpression | undefined = filterExpression
    ? ['!', filterExpression]
    : undefined;

  const beforeId = useGetBeforeLayerId(layerId);

  const allLayerStyles = layerStyles
    ? [
        ...layerStyles.main.map((style) => {
          return (
            <div key={`${layerId}-${style.type}-base`}>
              <Layer
                key={`${layerId}-${style.type}-cluster`}
                filter={['has', 'point_count']}
                minzoom={minZoom}
                source={sourceId}
                // id is in circleStyles
                {...circleStyles}
                // {...(filterExpression && { filter: filterExpression })}
              />
              <Layer
                key={`${layerId}-${style.type}-cluster-count`}
                id={`${layerId}-${style.type}-cluster-count`}
                filter={['has', 'point_count']}
                source={sourceId}
                type={'symbol'}
                minzoom={minZoom}
                layout={{
                  'text-field': ['get', 'point_count_abbreviated'],
                  'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                  'text-size': 12,
                }}
              />
              <Layer
                key={`${layerId}-${style.type}`}
                id={`${layerId}-${style.type}`}
                minzoom={minZoom}
                source={sourceId}
                {...style}
                {...(filterExpression && { filter: filterExpression })}
              />
            </div>
          );
        }),
        ...(layerStyles.grey
          ? layerStyles.grey.map((style) => {
              return (
                <Layer
                  key={`${layerId}-${style.type}-grey`}
                  id={`${layerId}-${style.type}-grey`}
                  beforeId={`${layerId}-${style.type}`}
                  // id={layerIdGrey}
                  source={sourceId}
                  {...style}
                  layout={{
                    ...style.layout,
                    visibility: showAllFeatures ? 'visible' : 'none',
                  }}
                  {...(notFilterExpression && { filter: notFilterExpression })}
                />
              );
            })
          : []),
      ]
    : null;

  return (
    <>
      {/* Mapbox doesn't convert string, or negative number, IDs: https://github.com/mapbox/mapbox-gl-js/issues/2716
      We need to generate ID so we can keep track of hover states */}
      <Source
        type="geojson"
        data={dataToUse}
        id={sourceId}
        generateId
        cluster={cluster}
        // This means clustering is disabled at zoom 17
        clusterMaxZoom={16}
        // Default value is 50, unsure of metrics(possibly meters)
        clusterRadius={Number(zoom) >= 15 ? 25 : 75}
      />
      {allLayerStyles ? (
        allLayerStyles
      ) : (
        <>
          {['nqtPassive', 'nqtHv', 'nqtGa'].includes(layerId) && selectedMetric === 'pci' ? (
            <Layer
              key={`${layerId}-text`}
              id={`${layerId}-text`}
              source={sourceId}
              type="symbol"
              maxzoom={maxZoom}
              minzoom={minZoom}
              layout={{
                'text-field': ['get', 'pci'],
                'text-size': ['interpolate', ['linear'], ['zoom'], 10, 5, 15, 10],
                'symbol-sort-key': ['*', -1, ['get', selectedMetric]],
                'text-variable-anchor': ['center', 'bottom', 'bottom-left', 'bottom-right'],
              }}
              paint={{
                'text-halo-blur': 4,
                'text-halo-color': 'white',
                'text-halo-width': 2,
                'text-translate': [
                  'interpolate',
                  ['linear'],
                  ['zoom'],
                  10,
                  ['literal', [0, 0]],
                  20,
                  ['literal', [0, 10]],
                ],
              }}
              {...(filterExpression && { filter: filterExpression })}
            />
          ) : null}
          <Layer
            key={layerId}
            beforeId={beforeId}
            // id={layerId}
            source={sourceId}
            {...paintAndType}
            {...(filterExpression && { filter: filterExpression })}
            maxzoom={maxZoom}
            minzoom={minZoom}
          />
          <Layer
            key={layerIdGrey}
            beforeId={layerId}
            // id={layerIdGrey}
            source={sourceId}
            {...paintAndTypeGrey}
            {...(notFilterExpression && { filter: notFilterExpression })}
            maxzoom={maxZoom}
            minzoom={minZoom}
          />
        </>
      )}
    </>
  );
};

export default TwoDPolygonDraw;
