import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type clustersState = {
  enableCluster: boolean;
  toggleCluster: boolean;
  clusterId: string | null;
  searchIdentifier: string | null;
};
const initialState: clustersState = {
  enableCluster: false,
  toggleCluster: false,
  clusterId: null,
  searchIdentifier: null,
};

export const clusterSlice = createSlice({
  name: 'clusterSlice',
  initialState,
  reducers: {
    setEnableCluster: (state, action: PayloadAction<clustersState['enableCluster']>) => {
      state.enableCluster = action.payload;
    },
    setToggleCluster: (state, action: PayloadAction<clustersState['toggleCluster']>) => {
      state.toggleCluster = action.payload;
    },
    setClusterId: (state, action: PayloadAction<clustersState['clusterId']>) => {
      state.clusterId = action.payload;
    },
    setSearchIdentifier: (state, action: PayloadAction<clustersState['searchIdentifier']>) => {
      state.searchIdentifier = action.payload;
    },
  },
});

const { actions, reducer } = clusterSlice;

export const { setEnableCluster, setToggleCluster, setClusterId, setSearchIdentifier } = actions;

export default reducer;
