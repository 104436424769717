import tooltipText from '../config/tooltipTexts';
import { GIGABYTE, MEGABYTE, TERABYTE } from '../constants';
import twentififthPercentile from '../images/icons/first_percentile.png';
import { meanTonnageScale, measurementsScale, rsrpScale, rssnrScale } from './layerConfig.scales';
import type {
  ColorScale,
  DetailConfig,
  FilterConfig,
  LayerConfig,
  MetricConfig,
} from './layerConfig.types';

const metricsList: {
  name: string;
  metricCode: string;
  tileMetricCode?: string;
  scale: ColorScale;
  tooltip: string;
  descriptionImageUrl?: string;
}[] = [
  { name: 'Mean RSSNR', metricCode: 'rssnr_mean', scale: rssnrScale, tooltip: tooltipText.rssnr },
  {
    name: 'RSSNR 25th Percentile',
    metricCode: 'rssnr_25',
    tileMetricCode: '5GNSA_SNR25',
    scale: rssnrScale,
    tooltip: tooltipText.rssnr25,
    descriptionImageUrl: twentififthPercentile,
  },
  { name: 'Mean RSRP', metricCode: 'rsrp_mean', scale: rsrpScale, tooltip: tooltipText.rsrp },
  {
    name: 'RSRP 25th Percentile',
    metricCode: 'rsrp_25',
    tileMetricCode: '5GNSA_POW25',
    scale: rsrpScale,
    tooltip: tooltipText.rsrp25,
    descriptionImageUrl: twentififthPercentile,
  },
  {
    name: 'SS Mean RSSNR',
    metricCode: 'ss_rssnr_mean',
    scale: rssnrScale,
    tooltip: tooltipText.rssnr,
  },
  {
    name: 'SS RSSNR 25th Percentile',
    metricCode: 'ss_rssnr_25',
    tileMetricCode: '5GNSA_SNR25_SS',
    scale: rssnrScale,
    tooltip: tooltipText.rssnr25,
    descriptionImageUrl: twentififthPercentile,
  },
  { name: 'SS Mean RSRP', metricCode: 'ss_rsrp_mean', scale: rsrpScale, tooltip: tooltipText.rsrp },
  {
    name: 'SS RSRP 25th Percentile',
    metricCode: 'ss_rsrp_25',
    tileMetricCode: '5GNSA_POW25_SS',
    scale: rsrpScale,
    tooltip: tooltipText.rsrp25,
    descriptionImageUrl: twentififthPercentile,
  },
  {
    name: 'CSI Mean RSSNR',
    metricCode: 'csi_rssnr_mean',
    scale: rssnrScale,
    tooltip: tooltipText.rssnr,
  },
  {
    name: 'CSI RSSNR 25th Percentile',
    metricCode: 'csi_rssnr_25',
    scale: rssnrScale,
    tooltip: tooltipText.rssnr25,
    descriptionImageUrl: twentififthPercentile,
  },
  {
    name: 'CSI Mean RSRP',
    metricCode: 'csi_rsrp_mean',
    scale: rsrpScale,
    tooltip: tooltipText.rsrp,
  },
  {
    name: 'CSI RSRP 25th Percentile',
    metricCode: 'csi_rsrp_25',
    scale: rsrpScale,
    tooltip: tooltipText.rsrp25,
    descriptionImageUrl: twentififthPercentile,
  },
  {
    name: 'Mean Tonnage',
    metricCode: 'tonnage',
    scale: meanTonnageScale,
    tooltip: tooltipText.meanTonnageDescription,
  },
  {
    name: 'Total Measurements',
    metricCode: 'measurements',
    scale: measurementsScale,
    tooltip: tooltipText.measurements,
  },
];

const rssnrRange = [
  { label: 'min', value: null },
  { value: -6 },
  { value: -4 },
  { value: -2 },
  { value: 0 },
  { value: 2 },
  { value: 5 },
  { value: 10 },
  { label: 'max', value: null },
];

const rsrpRange = [
  { label: 'min', value: null },
  { value: -124 },
  { value: -120 },
  { value: -116 },
  { value: -112 },
  { value: -108 },
  { value: -104 },
  { value: -100 },
  { label: 'max', value: null },
];
const tonnageRange = [
  { value: 0 },
  { label: '1MB', value: MEGABYTE },
  { label: '10MB', value: 10 * MEGABYTE },
  { label: '100MB', value: 100 * MEGABYTE },
  { label: '1GB', value: GIGABYTE },
  { label: '10GB', value: 10 * GIGABYTE },
  { label: '100GB', value: 100 * GIGABYTE },
  { label: '1TB', value: TERABYTE },
  { label: 'max', value: null },
];
const measurementsRange = [
  { value: 1 },
  { value: 5 },
  { value: 10 },
  { value: 100 },
  { value: 500 },
  { value: 1000 },
  { value: 2000 },
  { value: 4000 },
  { value: 6000 },
  { value: 10000 },
  { value: 15000 },
  { label: 'max', value: null },
];

const filtersList = [
  {
    name: 'Mean RSSNR',
    metricCode: 'rssnr_mean',
    interval: rssnrRange,
    tooltip: tooltipText.rssnr,
  },
  {
    name: 'RSSNR 25th Percentile',
    metricCode: 'rssnr_25',
    interval: rssnrRange,
    tooltip: tooltipText.rssnr25,
    descriptionImageUrl: twentififthPercentile,
  },
  { name: 'Mean RSRP', metricCode: 'rsrp_mean', interval: rsrpRange, tooltip: tooltipText.rsrp },
  {
    name: 'RSRP 25th Percentile',
    metricCode: 'rsrp_25',
    interval: rsrpRange,
    tooltip: tooltipText.rsrp25,
    descriptionImageUrl: twentififthPercentile,
  },
  {
    name: 'SS Mean RSSNR',
    metricCode: 'ss_rssnr_mean',
    interval: rssnrRange,
    tooltip: tooltipText.rssnr,
  },
  {
    name: 'SS RSSNR 25th Percentile',
    metricCode: 'ss_rssnr_25',
    interval: rssnrRange,
    tooltip: tooltipText.rssnr25,
    descriptionImageUrl: twentififthPercentile,
  },
  {
    name: 'SS Mean RSRP',
    metricCode: 'ss_rsrp_mean',
    interval: rsrpRange,
    tooltip: tooltipText.rsrp,
  },
  {
    name: 'SS RSRP 25th Percentile',
    metricCode: 'ss_rsrp_25',
    interval: rssnrRange,
    tooltip: tooltipText.rsrp25,
    descriptionImageUrl: twentififthPercentile,
  },
  {
    name: 'CSI Mean RSSNR',
    metricCode: 'csi_rssnr_mean',
    interval: rssnrRange,
    tooltip: tooltipText.rssnr,
  },
  {
    name: 'CSI RSSNR 25th Percentile',
    metricCode: 'csi_rssnr_25',
    interval: rssnrRange,
    tooltip: tooltipText.rssnr25,
    descriptionImageUrl: twentififthPercentile,
  },
  {
    name: 'CSI Mean RSRP',
    metricCode: 'csi_rsrp_mean',
    interval: rssnrRange,
    tooltip: tooltipText.rsrp,
  },
  {
    name: 'CSI RSRP 25th Percentile',
    metricCode: 'csi_rsrp_25',
    interval: rssnrRange,
    tooltip: tooltipText.rsrp25,
    descriptionImageUrl: twentififthPercentile,
  },
  {
    name: 'Mean Tonnage',
    metricCode: 'tonnage',
    interval: tonnageRange,
    tooltip: tooltipText.meanTonnageDescription,
  },
  {
    name: 'Total Measurements',
    metricCode: 'measurements',
    interval: measurementsRange,
    tooltip: tooltipText.measurements,
  },
];

const metrics = [
  ['All Bands', 'All_Bands'],
  ['Low <2.3GHz', 'Low_<23GHz'],
  ['Mid 2.3GHz - 4.2GHz', 'Mid_23GHz-42GHz'],
  ['High >4.2GHz', 'High_>42GHz'],
  ['Other', 'Other'],
].flatMap(([bandPretty, band]) => {
  const pretty = `${bandPretty}`;
  const notPretty = `Nsa_${band}`;

  const category = `${pretty}`;

  const categoryMetrics: MetricConfig[] = metricsList.map((metric) => {
    return {
      name: metric.name,
      // name: `${pretty} RSSNR 25th Percentile`,
      legendName: `${pretty} ${metric.name}`,
      ...(band === 'All_Bands' &&
        metric.tileMetricCode && { tileMetricCode: metric.tileMetricCode }),
      polygonMetricCode: `${notPretty}_${metric.metricCode}`,
      scale: metric.scale,
      description: metric.tooltip,
      ...(metric.descriptionImageUrl && { descriptionImageUrl: metric.descriptionImageUrl }),
      category,
    };
  });

  return categoryMetrics;
});

const filters = [
  ['All Bands', 'All_Bands'],
  ['Low <2.3GHz', 'Low_<23GHz'],
  ['Mid 2.3GHz - 4.2GHz', 'Mid_23GHz-42GHz'],
  ['High >4.2GHz', 'High_>42GHz'],
  ['Other', 'Other'],
].flatMap(([bandPretty, band]) => {
  const pretty = `${bandPretty}`;
  const notPretty = `Nsa_${band}`;

  const category = `${pretty}`;
  const categoryFilters = filtersList.map((m) => {
    const f: FilterConfig = {
      name: m.name,
      metricCode: `${notPretty}_${m.metricCode}`,
      type: 'rangeSlider',
      intervals: m.interval,
      description: m.tooltip,
      category,
      ...(m.descriptionImageUrl && { descriptionImageUrl: m.descriptionImageUrl }),
    };

    return f;
  });

  return categoryFilters;
});

const grid5gNsaConfig: LayerConfig = {
  name: 'Grids 5G NSA',
  boundingBoxWkt: null,
  description: 'Grids',
  layerId: 'grids5gNsa',
  visibleFromUtc: '2000-01-01T00:00:00Z',
  visibleToUtc: '2099-01-01T00:00:00Z',
  zoomFrom: 0,
  zoomTo: 24,
  defaultSelectedMetric: 'Nsa_All_Bands_rssnr_25',
  defaultLayerOn: false,
  layerParts: [
    {
      from: 0,
      to: 13,
      type: 'tile',
      tilePlotType: 'G-5G',
    },
    {
      from: 13,
      to: 24,
      type: 'geojson',
      layerType: 'fill',
      layerChunkLevel: 14,
      isInteractive: true,
    },
  ],
  metrics,
  filters,
  details: [
    ...[
      ['All Bands', 'All_Bands'],
      ['High >4.2GHz', 'High_>42GHz'],
      ['Mid 2.3GHz - 4.2GHz', 'Mid_23GHz-42GHz'],
      ['Low <2.3GHz', 'Low_<23GHz'],
      ['Other', 'Other'],
    ].map(([prettyBand, band]): DetailConfig => {
      return {
        sectionTitle: `Network Performance ${prettyBand}`,
        sectionKey: `network-performance-${band}`,
        navigationOptions: {
          navigationHeading: `Performance - ${prettyBand}`,
        },
        items: [
          {
            itemKey: 'network-activity-table',
            type: 'grid',
            rows: metricsList.map((metric) => {
              return {
                rowText: metric.name,
                rowKey: `nsa-${metric.metricCode}`,
                metricName: metric.metricCode,
                showColor: true,
                description: metric.tooltip,
                accessor: `Nsa_${band}_${metric.metricCode}`,
                ...(metric.metricCode !== 'tonnage' && { decimalPlaces: 2 }),
                ...(metric.metricCode === 'tonnage' && { format: 'bytes' }),
              };
            }),
          },
        ],
      };
    }),
  ],
};

export default grid5gNsaConfig;
