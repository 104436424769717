import { useEffect, useState } from 'react';

// kudos to https://levelup.gitconnected.com/react-localstorage-persisting-state-with-a-custom-hook-98f9a88ae7a9
export const usePersistedState = <T>(localStorageKey: string) => {
  const [value, setValue] = useState<T | null>(() => {
    const localStorageItem = localStorage.getItem(localStorageKey);
    if (localStorageItem === null) {
      return null;
    }

    try {
      const storedItem = JSON.parse(localStorageItem);
      return storedItem;
    } catch (err) {
      return null;
    }
  });

  useEffect(() => {
    localStorage.setItem(localStorageKey, JSON.stringify(value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  // Added by DW to make sure when the key changes - the new value is pulled out
  useEffect(() => {
    const localStorageItem = localStorage.getItem(localStorageKey);

    if (localStorageItem) {
      setValue(JSON.parse(localStorageItem));
    } else {
      setValue(null);
    }
  }, [localStorageKey]);

  // Expose the value and the updater function.
  const ret = [value, setValue] as [typeof value, typeof setValue];
  return ret;
};
