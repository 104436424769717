import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Select,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { kinks } from '@turf/turf';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMap } from 'react-map-gl';
import { GeoJSONPolygon, parse as parseWkt } from 'wellknown';
import { Logger } from '../../helpers/Logger';
import dwAxiosClient from '../../services/dwAxiosClient';
import CopyToClipboard from '../CopyToClipboard/CopyToClipboard';
import { useBuildingEditContext } from './BuildingEditContext';
import countries from './countries';

type BuildingFormState = {
  boundary: string | undefined;
  addr_housenumber: string;
  addr_housename: string;
  addr_street: string;
  addr_city: string;
  addr_state: string;
  addr_postcode: string;
  addr_country: string;
  height: number;
  floors: number;
  da_notes: string;
};

const BuildingEditForm: FC = () => {
  const toast = useToast();
  const [buildingEditContextValues] = useBuildingEditContext();

  const { 'building-edit-map': map } = useMap();

  const [heightUnits, setHeightUnits] = useState<'m' | 'ft'>('ft');
  const [submittingForm, setSubmittingForm] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm<BuildingFormState>({
    defaultValues: {
      boundary: buildingEditContextValues?.boundary
        ? buildingEditContextValues.boundary
        : undefined,
    },
  });

  useEffect(() => {
    const newValue =
      buildingEditContextValues && buildingEditContextValues.boundary
        ? buildingEditContextValues.boundary
        : undefined;

    setValue('boundary', newValue, {
      shouldValidate: true,
    });
  }, [setValue, buildingEditContextValues]);

  const onSubmit = async (formData: BuildingFormState) => {
    const heightInM = heightUnits === 'ft' ? formData.height / 3.28084 : formData.height;

    const tagPayload = {
      tags: {
        addr_housenumber: formData.addr_housenumber,
        addr_housename: formData.addr_housename,
        addr_street: formData.addr_street,
        addr_city: formData.addr_city,
        addr_state: formData.addr_state,
        addr_country: formData.addr_country,
        addr_postcode: formData.addr_postcode,
        height: heightInM,
        floors: formData.floors,
        da_notes: formData.da_notes,
      },
    };

    setSubmittingForm(true);

    try {
      const response = await dwAxiosClient.post('/Building/BuildingSubmit', undefined, {
        params: {
          boundaryWkt: formData.boundary,
          tagsArrayJson: JSON.stringify(tagPayload),
        },
      });

      setSubmittingForm(false);

      if (response.status === 200) {
        toast({
          id: 'building-submit-success',
          status: 'success',
          duration: 2000,
          description: 'Building submitted successfully',
        });

        reset();
        if (map) {
          map.getMap().fire('draw.clearAll');
        }
      } else {
        Logger.error('Error submitting building', response.status, response.data);
        toast({
          id: 'building-submit-error',
          status: 'error',
          duration: 3000,
          description: 'Something went wrong submitting this building. Please try again later.',
          isClosable: true,
        });
      }
    } catch (e) {
      setSubmittingForm(false);
      Logger.error('Error submitting building', e);
      toast({
        id: 'building-submit-error',
        status: 'error',
        duration: 3000,
        description: 'Something went wrong submitting this building. Please try again later.',
        isClosable: true,
      });
    }
  };

  return (
    <Flex
      as={'form'}
      onSubmit={handleSubmit(onSubmit)}
      borderRadius="md"
      borderColor="brand.300"
      borderStyle="solid"
      borderWidth="2px"
      p="4"
      flexDirection="column"
    >
      <Box flexGrow="1" mt="0" mb="auto">
        <FormControl isInvalid={!!errors.boundary} mb="3">
          <FormLabel>Building Boundary</FormLabel>
          <Flex alignItems="center">
            <Input
              readOnly
              {...register('boundary', {
                validate: {
                  isKinked: (wkt) => {
                    if (wkt) {
                      const geo = parseWkt(wkt);
                      if (geo) {
                        const numberOfKinks = kinks(geo as GeoJSONPolygon);
                        if (numberOfKinks.features.length > 0) {
                          return 'Shape cannot cross itself';
                        } else {
                          return true;
                        }
                      }
                      return 'Not Valid GeoJSON';
                    }
                    return 'Shape is required';
                  },
                },
              })}
              id="field-boundary"
            />
            <CopyToClipboard
              ml={2}
              copyText={
                buildingEditContextValues?.boundary ? buildingEditContextValues.boundary : ''
              }
              isDisabled={
                buildingEditContextValues === null || buildingEditContextValues.boundary === null
              }
            />
          </Flex>
          <FormErrorMessage>{errors.boundary?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.addr_housenumber} mb="3">
          <FormLabel>Building Number</FormLabel>
          <Input
            {...register('addr_housenumber', {
              maxLength: { value: 255, message: 'Maximum 255 characters' },
              pattern: {
                value: /^[A-Za-zÀ-ÖØ-öø-ÿ0-9&*()#.-\s]*'{0,1}[A-Za-zÀ-ÖØ-öø-ÿ0-9&*()#.-\s]*$/,
                message: 'Please use: letters, numbers, & * ( ) # . - and a single apostrophe',
              },
            })}
            id="field-addr_housenumber"
          />
          <FormErrorMessage>{errors.addr_housenumber?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.addr_housename} mb="3">
          <FormLabel>Building Name</FormLabel>
          <Input
            {...register('addr_housename', {
              maxLength: { value: 255, message: 'Maximum 255 characters' },
              pattern: {
                value: /^[A-Za-zÀ-ÖØ-öø-ÿ0-9&*()#.-\s]*'{0,1}[A-Za-zÀ-ÖØ-öø-ÿ0-9&*()#.-\s]*$/,
                message: 'Please use: letters, numbers, & * ( ) # . - and a single apostrophe',
              },
            })}
            id="field-addr_housename"
          />
          <FormErrorMessage>{errors.addr_housename?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.addr_street} mb="3">
          <FormLabel>Street</FormLabel>
          <Input
            {...register('addr_street', {
              maxLength: { value: 255, message: 'Maximum 255 characters' },
              pattern: {
                value: /^[A-Za-zÀ-ÖØ-öø-ÿ0-9&*()#.-\s]*'{0,1}[A-Za-zÀ-ÖØ-öø-ÿ0-9&*()#.-\s]*$/,
                message: 'Please use: letters, numbers, & * ( ) # . - and a single apostrophe',
              },
            })}
            id="field-addr_street"
          />
          <FormErrorMessage>{errors.addr_street?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.addr_city} mb="3">
          <FormLabel>City</FormLabel>
          <Input
            {...register('addr_city', {
              maxLength: { value: 255, message: 'Maximum 255 characters' },
              pattern: {
                value: /^[A-Za-zÀ-ÖØ-öø-ÿ0-9&*()#.-\s]*'{0,1}[A-Za-zÀ-ÖØ-öø-ÿ0-9&*()#.-\s]*$/,
                message: 'Please use: letters, numbers, & * ( ) # . - and a single apostrophe',
              },
            })}
            id="field-addr_city"
          />
          <FormErrorMessage>{errors.addr_city?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.addr_state} mb="3">
          <FormLabel>State</FormLabel>
          <Input
            {...register('addr_state', {
              maxLength: { value: 255, message: 'Maximum 255 characters' },
              pattern: {
                value: /^[A-Za-zÀ-ÖØ-öø-ÿ0-9&*()#.-\s]*'{0,1}[A-Za-zÀ-ÖØ-öø-ÿ0-9&*()#.-\s]*$/,
                message: 'Please use: letters, numbers, & * ( ) # . - and a single apostrophe',
              },
            })}
            id="field-addr_state"
          />
          <FormErrorMessage>{errors.addr_state?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.addr_postcode} mb="3">
          <FormLabel>Postal/Zip Code</FormLabel>
          <Input
            {...register('addr_postcode', {
              maxLength: { value: 255, message: 'Maximum 255 characters' },
              pattern: {
                value: /^[A-Za-zÀ-ÖØ-öø-ÿ0-9&*()#.-\s]*'{0,1}[A-Za-zÀ-ÖØ-öø-ÿ0-9&*()#.-\s]*$/,
                message: 'Please use: letters, numbers, & * ( ) # . - and a single apostrophe',
              },
            })}
            id="field-addr_postcode"
          />
          <FormErrorMessage>{errors.addr_postcode?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.addr_country} mb="3">
          <FormLabel>Country</FormLabel>
          <Select
            {...register('addr_country', {
              required: 'Please select a country',
            })}
            id="field-addr_country"
            placeholder="Select country"
          >
            {countries.map((country) => {
              return (
                <option key={country.value} value={country.value}>
                  {country.label}
                </option>
              );
            })}
          </Select>
          <FormErrorMessage>{errors.addr_country?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.height} mb="3">
          <FormLabel>Building Height ({heightUnits === 'm' ? 'm' : 'ft'})</FormLabel>
          <RadioGroup
            mb="2"
            value={heightUnits}
            onChange={(newValue) => {
              if (newValue === 'm' || newValue === 'ft') {
                setHeightUnits(newValue);
              } else {
                Logger.error('Setting height units to not allowed unit');
              }
            }}
          >
            <Stack direction="row">
              <Radio value="ft" id="form-radio-ft">
                ft
              </Radio>
              <Radio value="m" id="form-radio-m">
                m
              </Radio>
            </Stack>
          </RadioGroup>

          <Input
            {...register('height', {
              maxLength: { value: 255, message: 'Maximum 255 characters' },
              valueAsNumber: true,
            })}
            id="field-height"
            type="number"
          />
          <FormErrorMessage>{errors.height?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.floors} mb="3">
          <FormLabel>Floors</FormLabel>
          <Input
            {...register('floors', {
              valueAsNumber: true,
              maxLength: { value: 255, message: 'Maximum 255 characters' },
              min: { value: 1, message: 'Please enter a number greater than zero' },
            })}
            type="number"
            id="field-floors"
          />
          <FormErrorMessage>{errors.floors?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.da_notes} mb="3">
          <FormLabel>Notes</FormLabel>
          <Input
            {...register('da_notes', {
              maxLength: { value: 255, message: 'Maximum 255 characters' },
              pattern: { value: /^[^"]*$/, message: 'Please do not use double quotes' },
            })}
            id="field-da_notes"
          />
          <FormErrorMessage>{errors.da_notes?.message}</FormErrorMessage>
        </FormControl>
      </Box>
      <Flex flexGrow="0" mt="auto" mb="0">
        <Box ml="0" mr="auto"></Box>
        <Button type="submit" ml="auto" mr="0" isLoading={submittingForm} id="form-submit">
          Submit
        </Button>
      </Flex>
    </Flex>
  );
};

export default BuildingEditForm;
