import {
  Box,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
} from '@chakra-ui/react';
import { FC, useEffect } from 'react';
import { NeutralHostSettingsConfig } from '../../helpers/layerConfig.types';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useLayerSettingsSelector } from '../../hooks/useLayersSettings';
import { setNeutralHostSettingValue } from '../../reducers/layersSlice';
import { LayerKey, NeutralHostSettingsState } from '../../reducers/layersSlice.types';

type LayerControlSettingsSliderProps = {
  layerKey: LayerKey;
  setting: NeutralHostSettingsConfig;
};

const LayerControlSettingsSlider: FC<LayerControlSettingsSliderProps> = (props) => {
  const { layerKey, setting } = props;

  const dispatch = useAppDispatch();

  const nhSettings = useLayerSettingsSelector(
    layerKey,
    'neutralHostSettings',
    {} as NeutralHostSettingsState
  );

  const value = nhSettings[setting.metricCode];

  const position = setting.intervals.findIndex((i) => i.value === value);

  useEffect(() => {
    if (value === undefined) {
      dispatch(
        setNeutralHostSettingValue({
          layerKey,
          metricCode: setting.metricCode,
          value: setting.defaultValue,
        })
      );
    }
  }, [dispatch, layerKey, setting, value]);

  return (
    <Box>
      <Slider
        id={`settings-slider-${layerKey}-${setting.metricCode}`}
        size="lg"
        min={0}
        max={setting.intervals.length - 1}
        value={position}
        colorScheme="pink"
        step={1}
        onChange={(newPosition) => {
          const newValue = setting.intervals[newPosition].value;

          dispatch(
            setNeutralHostSettingValue({
              layerKey,
              metricCode: setting.metricCode,
              value: newValue,
            })
          );
        }}
      >
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <SliderThumb />
        {setting.intervals.map((interval, i) => {
          const showText = i === setting.intervals.length - 1 || i % 2 === 0;

          return (
            <SliderMark
              key={`slider-mark-${i}`}
              value={i}
              color="white"
              textShadow="0.4px 0.4px 0.6px hsl(0deg 0% 0% / 0.2),
      1px 1.1px 1.5px  hsl(0deg 0% 0% / 0.17),
  3.1px 3.4px 4.5px  hsl(0deg 0% 0% / 0.14),
  8.2px 8.9px 11.9px  hsl(0deg 0% 0% / 0.11)"
              fontSize="small"
              transform="translateX(-50%)"
            >
              <Box
                borderRight="1px solid white"
                height={showText ? '6px' : '4px'}
                transform="translateX(-50%)"
                mt="1px"
              />
              {showText ? <Box mt="2">{interval.label ?? interval.value}</Box> : null}
            </SliderMark>
          );
        })}
      </Slider>
    </Box>
  );
};

export default LayerControlSettingsSlider;
