import { useQuery } from '@tanstack/react-query';
import { ApiValuesType } from '../components/RecentlyModifiedBuildingsModal/RecentlyModifiedBuildingsModal';
import dwAxiosClient from './dwAxiosClient';

type RecentlyModifiedBuildingsResponse = {
  buildingMasterId: number;
  version: number;
  areaM2: number;
  pointWkt: string;
  startUtc: string | Date;
  endUtc: string | Date;
  isDeleted: boolean;
  countryCodeA2: string;
  stateAbbreviation: string;
  ingestId: string;
  tagCount: number;
};

const getviewRecentlyModifiedBuildingsClient =
  (fromDate: Date | null, values: ApiValuesType[] | null) => async () => {
    if (fromDate === null) {
      return null;
    }
    const day = fromDate.getDate();
    const month = String(fromDate.getMonth() + 1).padStart(2, '0');
    const year = fromDate.getFullYear();

    const singularVal = values ? '' : `&includeOldVersions=false`;

    const { data, status } = await dwAxiosClient.post<RecentlyModifiedBuildingsResponse[]>(
      `/Building/MasterSearch?optionalSinceUtc=${year}-${month}-${day}${singularVal}`,
      values,
      {
        headers: {
          'Content-Type': 'application/json-patch+json',
        },
      }
    );

    if (status === 200) {
      return data;
    }

    return null;
  };

const useViewRecentlyModifiedBuildingsClient = (
  fromDate: Date | null,
  values: ApiValuesType[] | null
) => {
  return useQuery(
    ['viewRecentlyModifiedBuildingsClient', fromDate, values],
    getviewRecentlyModifiedBuildingsClient(fromDate, values),
    {
      // refetchInterval: false,
      // Maybe remove stale
      staleTime: 60 * 60 * 60 * 1000,
    }
  );
};

export default useViewRecentlyModifiedBuildingsClient;
