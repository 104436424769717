import { useQuery } from '@tanstack/react-query';
import dwAxiosClient from './dwAxiosClient';

type AdditionalBuildingInformation = {
  buildingMasterId: number;
  version: number;
  areaM2: number;
  boundaryWkt: string;
  tags: {
    [key: string]: string | number;
  };
  startUtc: string;
  endUtc: string;
  isDeleted: boolean;
  regionId: number | null;
  countryCodeA2: string;
  stateAbbreviation: string;
  ingestId: string;
};

const getAdditionalBuildingDataById = (buildingId: number) => async () => {
  const { data, status } = await dwAxiosClient.get<AdditionalBuildingInformation>(
    `/Building/Master/${buildingId}`
  );

  if (status === 204) {
    return null;
  }

  if (status === 200) {
    return data;
  }

  return null;
};

const useAdditionalBuildingIdClient = ({ buildingId }: { buildingId: number }) => {
  return useQuery(['additionalBuildingId', buildingId], getAdditionalBuildingDataById(buildingId), {
    refetchInterval: false,
    staleTime: 10 * 60 * 60 * 60 * 1000, // 10 hours!
  });
};

export default useAdditionalBuildingIdClient;
