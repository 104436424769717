import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type SetCountryCodePayload = ContextSettingsState['selectedCountryCode'];
type SetProviderPayload = ContextSettingsState['selectedProviderName'];

type ContextSettingsState = {
  selectedCountryCode: string | null;
  selectedProviderName: string | null;
};

const initialState: ContextSettingsState = {
  selectedCountryCode: null,
  selectedProviderName: null,
};

const contextSettings = createSlice({
  name: 'contextSettings',
  initialState,
  reducers: {
    setSelectedCountryCode: (state, action: PayloadAction<SetCountryCodePayload>) => {
      state.selectedCountryCode = action.payload;
    },
    setSelectedProvider: (state, action: PayloadAction<SetProviderPayload>) => {
      state.selectedProviderName = action.payload;
    },
    setContext: (
      state,
      action: PayloadAction<{
        countryCode: SetCountryCodePayload;
        providerName: SetProviderPayload;
      }>
    ) => {
      state.selectedCountryCode = action.payload.countryCode;
      state.selectedProviderName = action.payload.providerName;
    },
  },
});

const { actions, reducer } = contextSettings;

export const { setSelectedCountryCode, setSelectedProvider, setContext } = actions;

export default reducer;
