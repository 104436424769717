import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Heading,
  Icon,
  Image,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  VStack,
} from '@chakra-ui/react';
import { groupBy as _groupBy } from 'lodash';
import { FC } from 'react';
import { MetricConfig } from '../../helpers/layerConfig.types';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import useHasActiveInteractiveParts from '../../hooks/useHasActiveInteractiveParts';
import { useLayerSettingsSelector } from '../../hooks/useLayersSettings';
import { setSelectedMetric, setSelectedTileMetric } from '../../reducers/layersSlice';
import { LayerKey } from '../../reducers/layersSlice.types';

type LayerControlMetricsProps = {
  layerKey: LayerKey;
  metrics: MetricConfig[];
};

const MetricsList: FC<{ subMetrics: MetricConfig[]; layerKey: LayerKey }> = (props) => {
  const { subMetrics, layerKey } = props;

  const dispatch = useAppDispatch();

  const selectedMetric = useLayerSettingsSelector(layerKey, 'selectedMetric', null);

  const hasInteractiveActiveParts = useHasActiveInteractiveParts(layerKey);

  return (
    <VStack align="flex-end">
      {subMetrics.map((metric) => {
        return (
          <Box key={`${metric.name}`} id={`METRIC-${metric.name}`}>
            {metric.description ? (
              <Popover trigger="hover" placement="bottom-start">
                <PopoverTrigger>
                  <Icon name="help" color="white" mr="2" />
                </PopoverTrigger>
                <PopoverContent width="md">
                  <PopoverBody whiteSpace="pre-line">
                    <Box fontSize="sm">{metric.description}</Box>
                    {metric.descriptionImageUrl ? (
                      <Image src={metric.descriptionImageUrl} mt="3" />
                    ) : null}
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            ) : null}
            <Button
              isActive={metric.polygonMetricCode === selectedMetric}
              key={metric.polygonMetricCode}
              size="sm"
              isDisabled={!hasInteractiveActiveParts && metric.tileMetricCode === undefined}
              onClick={() => {
                dispatch(setSelectedMetric({ layerKey, selectedMetric: metric.polygonMetricCode }));
                if (metric.tileMetricCode) {
                  dispatch(
                    setSelectedTileMetric({
                      layerKey,
                      selectedTileMetric: metric.tileMetricCode,
                    })
                  );
                } else {
                  dispatch(setSelectedTileMetric({ layerKey, selectedTileMetric: null }));
                }
              }}
            >
              {metric.name}
            </Button>
          </Box>
        );
      })}
    </VStack>
  );
};

const LayerControlMetrics: FC<LayerControlMetricsProps> = (props) => {
  const { layerKey, metrics } = props;
  const selectedMetric = useLayerSettingsSelector(layerKey, 'selectedMetric', null);

  const groupedMetrics = _groupBy(metrics, 'category');
  const groupedMetricsEntries = Object.entries(groupedMetrics);

  const defaultSelectedIndex = groupedMetricsEntries.findIndex(([_categoryName, subMetrics]) => {
    return subMetrics.find((m) => m.polygonMetricCode === selectedMetric);
  });

  return (
    <Accordion allowToggle defaultIndex={defaultSelectedIndex}>
      {groupedMetricsEntries.map(([categoryName, subMetrics]) => {
        if (categoryName === 'undefined') {
          return (
            <MetricsList
              key={`metrics-${layerKey}-${categoryName}`}
              layerKey={layerKey}
              subMetrics={subMetrics}
            />
          );
        }

        return (
          <AccordionItem key={`metrics-${layerKey}-${categoryName}`} border="0">
            <AccordionButton justifyContent={'flex-end'}>
              <Heading size="sm" color="white">
                <Box as="span" flex="1" textAlign="end">
                  {categoryName}
                </Box>
                <AccordionIcon />
              </Heading>
            </AccordionButton>
            <AccordionPanel>
              <MetricsList subMetrics={subMetrics} layerKey={layerKey} />
            </AccordionPanel>
          </AccordionItem>
        );
      })}
    </Accordion>
  );
};

export default LayerControlMetrics;
