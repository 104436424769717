import { pciScale, rsrpScale, rssnrScale } from './layerConfig.scales';
import { LayerConfig } from './layerConfig.types';

const nqtHvConfig: LayerConfig = {
  name: 'NQT High Volume',
  boundingBoxWkt: null,
  description: 'NQT High Volume results',
  layerId: 'nqtHv',
  visibleFromUtc: '2000-01-01T00:00:00Z',
  visibleToUtc: '2099-01-01T00:00:00Z',
  zoomFrom: 8,
  zoomTo: 24,
  defaultLayerOn: false,
  layerParts: [
    {
      from: 10,
      to: 24,
      type: 'geojson',
      layerType: 'circle',
      layerChunkLevel: 12,
      isInteractive: true,
    },
  ],
  defaultSelectedMetric: 'lte_rsrp',
  metrics: [
    { name: 'LTE RSRP', polygonMetricCode: 'lte_rsrp', scale: rsrpScale },
    { name: 'LTE RSSNR', polygonMetricCode: 'lte_sinr', scale: rssnrScale },
    { name: 'NR SS RSRP', polygonMetricCode: 'nr_ss_rsrp', scale: rsrpScale },
    { name: 'NR SS RSSNR', polygonMetricCode: 'nr_ss_sinr', scale: rssnrScale },
    { name: 'PCI', polygonMetricCode: 'pci', scale: pciScale, showScale: false },
  ],
  filters: [
    {
      name: 'Date Range (UTC)',
      type: 'date_range',
      metricCode: 'timestamp_utc',
    },
    {
      name: 'LTE RSRP',
      metricCode: 'lte_rsrp',
      type: 'rangeSlider',
      intervals: [
        { label: 'min', value: null },
        { value: -124 },
        { value: -120 },
        { value: -116 },
        { value: -112 },
        { value: -108 },
        { value: -104 },
        { value: -100 },
        { label: 'max', value: null },
      ],
    },
    {
      name: 'LTE RSSNR',
      metricCode: 'lte_sinr',
      type: 'rangeSlider',
      intervals: [
        { label: 'min', value: null },
        { value: -6 },
        { value: -4 },
        { value: -2 },
        { value: 0 },
        { value: 2 },
        { value: 5 },
        { value: 10 },
        { label: 'max', value: null },
      ],
    },
    {
      name: 'NR SS RSRP',
      metricCode: 'nr_ss_rsrp',
      type: 'rangeSlider',
      intervals: [
        { label: 'min', value: null },
        { value: -124 },
        { value: -120 },
        { value: -116 },
        { value: -112 },
        { value: -108 },
        { value: -104 },
        { value: -100 },
        { label: 'max', value: null },
      ],
    },
    {
      name: 'NR SS RSSNR',
      metricCode: 'nr_ss_sinr',
      type: 'rangeSlider',
      intervals: [
        { label: 'min', value: null },
        { value: -6 },
        { value: -4 },
        { value: -2 },
        { value: 0 },
        { value: 2 },
        { value: 5 },
        { value: 10 },
        { label: 'max', value: null },
      ],
    },
    {
      name: 'Connection Type',
      type: 'dropdown',
      metricCode: 'network_measurement_type',
      caseSensitive: true,
    },
    {
      name: 'PCI',
      type: 'multiselect',
      metricCode: 'pci',
      options: Array(504)
        .fill(0)
        .map((_, i) => {
          const v = i.toString();
          return { label: v, value: v };
        }),
    },
    {
      name: 'Collection ID',
      type: 'dropdown',
      metricCode: 'test_id',
      caseSensitive: false,
    },
  ],
  details: [
    {
      sectionTitle: 'Test Information',
      sectionKey: 'nqt-information',
      items: [
        {
          type: 'grid',
          itemKey: 'nqt-information-table',
          rows: [
            {
              rowText: 'Test ID',
              rowKey: 'test-id',
              metricName: 'test_id',
              showColor: false,
              format: 'text',
            },
            {
              rowText: 'Timestamp (UTC)',
              rowKey: 'started-utc',
              metricName: 'timestamp_utc',
              format: 'date',
              showColor: false,
            },
          ],
        },
      ],
    },
    {
      sectionTitle: 'Network Performance',
      sectionKey: 'nqt-result-details',
      items: [
        {
          type: 'grid',
          itemKey: 'nqt-details',
          rows: [
            {
              rowText: 'LTE RSRP',
              rowKey: 'rsrp-average',
              metricName: 'lte_rsrp',
              showColor: true,
            },
            {
              rowText: 'LTE RSSNR',
              rowKey: 'rssnr-average',
              metricName: 'lte_sinr',
              showColor: true,
            },
            {
              rowText: 'NR SS RSRP',
              rowKey: 'nr-rsrp-average',
              metricName: 'nr_ss_rsrp',
              showColor: true,
            },
            {
              rowText: 'NR SS RSSNR',
              rowKey: 'nr-rssnr-average',
              metricName: 'nr_ss_sinr',
              showColor: true,
            },
            {
              rowText: 'MCC',
              rowKey: 'mcc',
              metricName: 'mcc',
              showColor: false,
            },
            {
              rowText: 'MNC',
              rowKey: 'mnc',
              metricName: 'mnc',
              showColor: false,
            },
          ],
        },
      ],
    },
    {
      sectionTitle: 'Network Information',
      sectionKey: 'nqt-hv-network-information',
      items: [
        {
          type: 'grid',
          itemKey: 'nqt-hv-info-table',
          rows: [
            {
              rowText: 'Network Measurement Type',
              rowKey: 'connection-type',
              metricName: 'network_measurement_type',
              showColor: false,
            },
            {
              rowText: 'PCI',
              rowKey: 'nqt-hv-network-info-pci',
              metricName: 'pci',
              showColor: false,
            },
          ],
        },
      ],
    },
    {
      sectionTitle: 'Location',
      sectionKey: 'nqt-result-location',
      items: [
        {
          type: 'grid',
          itemKey: 'nqt-location-details',
          rows: [
            {
              rowText: 'Horizontal Accuracy',
              rowKey: 'hoz-acc',
              metricName: 'horiz_accuracy',
              showColor: false,
            },
          ],
        },
      ],
    },
  ],
};

export default nqtHvConfig;
