import { GeoJsonLayer } from '@deck.gl/layers/typed';
import { MapboxLayer } from '@deck.gl/mapbox/typed';
import { Feature, Polygon, Properties } from '@turf/turf';
import { FC, useCallback, useEffect, useRef } from 'react';
import { useMap } from 'react-map-gl';
import { FilterConfig } from '../../helpers/layerConfig.types';
import { makeLayerId } from '../../helpers/layerIdGenerators';
import { useGetBeforeLayerId } from '../../hooks/useGetBeforeLayerId';
import { useLayerSettingsSelector } from '../../hooks/useLayersSettings';
import { LayerKey } from '../../reducers/layersSlice.types';
import { Building3dProperties } from '../../services/buildings3dClient.types';

type ThreeDDrawProps = {
  layerKey: LayerKey;
  data: GeoJSON.Feature<GeoJSON.Geometry> | GeoJSON.FeatureCollection<GeoJSON.Geometry>;
  minZoom: number;
  maxZoom: number;
  layerFilters: FilterConfig[] | undefined;
};

const anyMapboxLayer = MapboxLayer as any;

const ThreeDDraw: FC<ThreeDDrawProps> = (props) => {
  const { data, layerKey } = props;

  const { 'dw-main-map': map } = useMap();

  const layerId = makeLayerId(layerKey);

  const selectedMetric = useLayerSettingsSelector(layerKey, 'selectedMetric', null);
  const metricName = `${selectedMetric}Color`;

  const beforeLayerId = useGetBeforeLayerId(layerKey);

  const deckLayer = useRef<any>();

  const getFillColor = useCallback(
    (feature: Feature<Polygon, Properties>) => {
      const metricColor = feature.properties?.[metricName];

      if (Array.isArray(metricColor) && metricColor.length > 0) {
        return metricColor;
      }

      return [187, 187, 187];
    },
    [metricName]
  );

  useEffect(() => {
    deckLayer.current = new anyMapboxLayer({
      id: layerId,
      type: GeoJsonLayer,
      data: {},
      extruded: true,
      wireframe: true,
      getFillColor,
      getElevation: (f: Feature<Polygon, Building3dProperties>) => {
        const height = f.properties ? f.properties.stratumHeightInMetres : 0;
        return height;
      },
      material: {
        ambient: 0.45,
        diffuse: 0.65,
        shininess: 20,
        specularColor: [60, 64, 70],
      },
    });

    if (map) {
      map.getMap().addLayer(deckLayer.current, beforeLayerId);
    }

    return () => {
      if (map) {
        map.getMap().removeLayer(layerId);
      }
    };

    // Yup we're disabling the check.
    // We only want to add the layer to the map if map or id changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, layerId]);

  useEffect(() => {
    deckLayer.current.setProps({
      data,
    });
  }, [data]);

  useEffect(() => {
    deckLayer.current.setProps({
      getFillColor,
      updateTriggers: {
        getFillColor: metricName,
      },
    });
  }, [metricName, getFillColor]);

  return null;
};

export default ThreeDDraw;
