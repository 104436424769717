import { ChakraProvider } from '@chakra-ui/react';
import '@fontsource/poppins';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import mapboxgl from 'mapbox-gl';
import { FC, ReactElement } from 'react';
import { MapProvider } from 'react-map-gl';
import { Provider as ReduxProvider } from 'react-redux';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import 'swiper/scss';
import { AuthProvider } from '../../helpers/AuthProvider';
import MainErrorPage from '../../pages/MainErrorPage';
import { store } from '../../reducers/store';
import { queryClient } from '../../services/queryClient';
import Home from '../Home/Home';
import MainErrorBoundary from '../MainErrorBoundary';
import './App.scss';
import { dwTheme } from './App.theme';

///hack hack hack mapbox canvas to webgl2
if ((mapboxgl as any).Map.prototype._setupPainter.toString().indexOf('webgl2') === -1) {
  var _setupPainter_old = (mapboxgl as any).Map.prototype._setupPainter;
  (mapboxgl as any).Map.prototype._setupPainter = function () {
    let getContext_old = this._canvas.getContext;
    this._canvas.getContext = function (name: any, attrib: any) {
      return (
        getContext_old.apply(this, ['webgl2', attrib]) ||
        getContext_old.apply(this, ['webgl', attrib]) ||
        getContext_old.apply(this, ['experimental-webgl', attrib])
      );
    };
    _setupPainter_old.apply(this);
    this._canvas.getContext = getContext_old;
  };
}

const Wrapper: FC<{ children: ReactElement }> = (props: { children: any }) => {
  return props.children;
};

const AppInner = () => {
  const currentUrl = new URL(window.location.href);
  const tokenFromUrl = currentUrl.searchParams.get('token');

  if (tokenFromUrl) {
    localStorage.setItem('dw_authToken', tokenFromUrl);
    currentUrl.searchParams.delete('token');
    window.location.replace(currentUrl.toString());
  }

  const router = createBrowserRouter([
    {
      path: '/',
      element: (
        <Wrapper>
          <AuthProvider />
        </Wrapper>
      ),
      handle: {
        crumbName: 'Home',
      },
      errorElement: <MainErrorPage />,
      children: [
        {
          index: true,
          element: <Home />,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
};

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ReduxProvider store={store}>
        <ChakraProvider theme={dwTheme}>
          <MainErrorBoundary>
            <MapProvider>
              <AppInner />
            </MapProvider>
          </MainErrorBoundary>
        </ChakraProvider>
      </ReduxProvider>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </QueryClientProvider>
  );
}

export default App;
