/**
 * Color scale functions
 *
 * @module
 */

import { scale as chromaScale } from 'chroma-js';
import { LayerKey } from '../reducers/layersSlice.types';
import { isValueValid } from './isValueValid';
import layerConfig from './layerConfig';
import { LayerConfig, MetricConfig } from './layerConfig.types';

/**
 * Just for {@link getColorFromScale}.
 * Finds a layer in a list, looking up by layerId
 */
const findLayer = (list: LayerConfig[], layerId: LayerKey) => {
  return list.find((item) => item.layerId === layerId);
};

/**
 * Gets the right color from a color scale, as a hex string.
 * The scales are defined in layer config, per metric.
 * Find the layer config by layerId, find the metric by metricName, then find the color for the value against that scale.
 */
export const getColorFromScale = (
  layerId: string,
  metricName: string,
  value: number | 'NaN' | null | string
): string | null => {
  const layer = findLayer(layerConfig, layerId);

  if (layer && layer.metrics) {
    const metric = layer.metrics.find(
      (item: MetricConfig) => item.polygonMetricCode === metricName
    );

    if (metric && typeof value === 'number') {
      const theScale = metric.scale;

      if (theScale.type === 'bin') {
        const scaleValue = theScale.scale.find((s: any) => {
          if (isValueValid(s.max)) {
            return value < s.max;
          }

          return false;
        });

        return scaleValue ? scaleValue.color : theScale.scale[theScale.scale.length - 1].color;
      }

      if (theScale.type === 'bin-specific') {
        const scaleValue = theScale.scale.find((s) => {
          if (s && isValueValid(s.max)) {
            if (s.operator === 'lt') {
              return value < s.max;
            } else if (s.operator === 'gt') {
              return value > s.max;
            } else if (s.operator === 'gte') {
              return value >= s.max;
            } else if (s.operator === 'lte') {
              return value <= s.max;
            } else if (s.operator === 'eq') {
              return value === s.max;
            }
          }

          return false;
        });

        return scaleValue ? scaleValue.color : theScale.scale[theScale.scale.length - 1].color;
      }

      if (theScale.type === 'discrete') {
        const scaleValue = theScale.scale.find((s) => {
          if (isValueValid(s.value)) {
            return value === s.value;
          }

          return false;
        });

        return scaleValue ? scaleValue.color : theScale.scale[theScale.scale.length - 1].color;
      }

      if (theScale.type === 'continuous') {
        const scaleFunction = chromaScale(theScale.scale.colors)
          .domain(theScale.scale.domain)
          .mode(theScale.scale.mode ?? 'rgb');

        const color = scaleFunction(value).hex();

        return color;
      }

      if (theScale.type === 'category') {
        const scaleValue = theScale.scale.find((s) => {
          if (isValueValid(s.value)) {
            return value === s.value;
          }

          return false;
        });

        return scaleValue?.color || 'grey';
      }

      return null;
    }

    if (metric && typeof value === 'string') {
      const theScale = metric.scale;

      if (theScale.type === 'category') {
        const scaleValue = theScale.scale.find((s) => {
          if (isValueValid(s.value)) {
            return value === s.value;
          }

          return false;
        });

        return scaleValue?.color || 'grey';
      }
    }
  }

  return null;
};
