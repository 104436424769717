import { FC } from 'react';
import Select, { Props } from 'react-select';

type Option = { value: boolean | string | number; label: string };

const ReactMultiSelect: FC<Props<Option, true>> = (props) => {
  return (
    <div>
      <Select<Option, true> isMulti {...props} />
    </div>
  );
};

export default ReactMultiSelect;
