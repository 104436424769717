import { Box, Button, Card, Flex, Wrap } from '@chakra-ui/react';
import { FC } from 'react';
import TagComponent from './Tag';

type SelectedTag = { key: string; value: string; comparison: string };

export type TagProps = {
  listName: string;
  deleteAll: () => void;
  selectedTags: SelectedTag[];
  deleteTagFunc: (key: string, value: string, comparison: string) => void;
};

const DisplayTags: FC<TagProps> = ({ listName, deleteAll, selectedTags, deleteTagFunc }) => {
  return selectedTags.length !== 0 ? (
    <Card m={'10px 0 10px 0'} p={'5px 5px 10px 5px '} border={'1px solid black'}>
      <Flex alignItems={'center'} justifyContent={'space-between'}>
        <Box pr={'10px'}>Selected {listName}</Box>
        <Button
          id={`${listName}-clear-all-button`}
          data-test-id={`clear-all-button`}
          fontSize={'10px'}
          width={'fit-content'}
          variant={'unstyled'}
          onClick={deleteAll}
          _hover={{
            cursor: 'pointer',
          }}
        >
          Clear all
        </Button>
      </Flex>

      <Wrap>
        {selectedTags.map((item, index) => {
          return (
            <Flex key={item.value + index}>
              <TagComponent
                key={item.key + index}
                index={index}
                label={item.key}
                value={item.value}
                comparison={item.comparison}
                delFunc={deleteTagFunc}
              />
            </Flex>
          );
        })}
      </Wrap>
    </Card>
  ) : (
    <></>
  );
};

export default DisplayTags;
