import { Box, Flex } from '@chakra-ui/react';
import { FC } from 'react';
import { CategoryScale as CategoryScaleType } from '../../helpers/layerConfig.types';

type CategoryScaleProps = {
  scale: CategoryScaleType['scale'];
  width: number;
};

const CategoryScale: FC<CategoryScaleProps> = (props) => {
  const { scale, width } = props;
  const colors = Array.from(new Set(scale.map((s) => s.color)));

  const numberOfSections = colors.length;

  return (
    <Flex justify="stretch" align="stretch" width={`${width}vw`}>
      {colors.map((c, i) => {
        const values = scale.filter((s) => s.color === c);

        return (
          <Flex
            key={`scale-section-${i}`}
            flexDirection="column"
            alignItems="center"
            justifyContent="start"
            width={`${width / numberOfSections}vw`}
          >
            <Box flexShrink="0" flexGrow="0" width="100%" height="10px" backgroundColor={c} />
            <Box fontSize="0.7vw" color="white">
              {values.map((v) => v.valueLabel ?? v.value).join(', ')}
            </Box>
          </Flex>
        );
      })}
    </Flex>
  );
};

export default CategoryScale;
