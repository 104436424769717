import { useQuery } from '@tanstack/react-query';
import dwAxiosClient from './dwAxiosClient';

type RegionsRequestOptions = {
  countryCode: string | null;
  providerName: string | null;
};

export type RegionTileSet = {
  name: string;
  metricCode: string;
  minZoom: number;
  maxZoom: number;
};

type RegionAnalysisResult = {
  name: 'Building' | 'Grid';
  plotType: 'B' | 'G';
  tileSets: RegionTileSet[];
};

export type Region = {
  boundaryWkt: string;
  countryCode: string;
  name: string;
  parentRegionId: number;
  path: string;
  regionId: number;
};

type RegionsResponse = ({
  analysisResults: RegionAnalysisResult[];
  notedRegions: Region[];
} & Region)[];

const getRegions = (options: RegionsRequestOptions) => async () => {
  const { countryCode, providerName } = options;

  const { data } = await dwAxiosClient.get<RegionsResponse>('/Region/GetRegions', {
    params: { countryCode, providerName },
  });

  return data;
};

const useRegionsQueryClient = (options: RegionsRequestOptions) => {
  const { countryCode, providerName } = options;

  return useQuery(['regions', providerName, countryCode], getRegions(options), {
    enabled: countryCode && providerName ? true : false,
    staleTime: 10 * 60 * 60 * 1000,
    retry: 2,
  });
};

export default useRegionsQueryClient;
