import { Box, Heading } from '@chakra-ui/react';
import { FC } from 'react';
import { useAppSelector } from '../../hooks/useAppSelector';
import ExportDataButton from './ExportDataButton';

type ExportDataRowProps = {
  name: string;
  layerKey: string | number;
  minZoom: number;
};

const ExportDataRow: FC<ExportDataRowProps> = (props) => {
  const { name, layerKey, minZoom } = props;

  const currentZoom = useAppSelector((state) => state.mapSettings.viewState.zoom);
  const isWithinZoom = minZoom <= currentZoom;

  return (
    <Box>
      <Heading color="white" size="md" mb="2" whiteSpace="nowrap">
        {name}
      </Heading>
      {isWithinZoom ? (
        <ExportDataButton name={name} layerKey={layerKey} />
      ) : (
        <Box color="white">Available from zoom {minZoom}</Box>
      )}
    </Box>
  );
};

export default ExportDataRow;
