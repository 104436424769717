import { Button, Spinner, useDisclosure, useToast } from '@chakra-ui/react';
import { useHash } from 'raviger';
import { FC, useCallback, useEffect } from 'react';
import { MdOutlineEdit } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../hooks/useAppSelector';
import { usePersistedState } from '../../hooks/usePersistedState';
import { setContext } from '../../reducers/contextSlice';
import useUpdateUrl from '../../services/UpdateURL';
import useContextQueryClient, { MccMnc } from '../../services/contextClient';
import { PERSIST_KEY_COUNTRY_CODE, PERSIST_KEY_PROVIDER_NAME } from './ContextSelection.constants';
import ContextSelectionButton from './ContextSelectionButton';
import ContextSelectionModal from './ContextSelectionModal';

type ContextSelectionProps = {};

const ContextSelection: FC<ContextSelectionProps> = () => {
  const dispatch = useDispatch();

  const updateUrl = useUpdateUrl();

  const hash = useHash();

  const toast = useToast();

  const loginErrorToast = useCallback(() => {
    const toastId = 'dw-toast-unauthorised-selection';
    if (!toast.isActive(toastId)) {
      return toast({
        id: toastId,
        position: 'top',
        title: 'You are not authorised to view the information and have been redirected',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  }, [toast]);

  const { isOpen, onOpen: onModalOpen, onClose } = useDisclosure();

  const [storedSelectedProviderName, setStoredSelectedProviderName] =
    usePersistedState<string>(PERSIST_KEY_PROVIDER_NAME);

  const [storedSelectedCountryCode, setStoredSelectedCountryCode] =
    usePersistedState<string>(PERSIST_KEY_COUNTRY_CODE);

  const selectedCountryCode = useAppSelector((state) => {
    return state.contextSettings.selectedCountryCode;
  });

  const validationSelectedProviderName = useAppSelector((state) => {
    return state.contextSettings.selectedProviderName;
  });

  const updateContext = useCallback(
    ({
      countryCode,
      providerName,
      mccMncs,
      mccs,
      mncs,
    }: {
      countryCode: string | null;
      providerName: string | null;
      mccMncs: MccMnc[] | null;
      mccs: string[] | null;
      mncs: string[] | null;
    }) => {
      dispatch(
        setContext({
          providerName,
          countryCode,
        })
      );

      setStoredSelectedCountryCode(countryCode);
      setStoredSelectedProviderName(providerName);
      if (countryCode && providerName) {
        updateUrl('countryCode', countryCode);
        updateUrl('providerName', providerName);
      }
    },
    [dispatch, setStoredSelectedCountryCode, setStoredSelectedProviderName, updateUrl]
  );

  const { data, error, isFetching, isSuccess, isError } = useContextQueryClient();

  const dataReady = !isFetching && isSuccess && data ? true : false;

  useEffect(() => {
    if (isSuccess && data && data.length > 0) {
      const hashObj = new URLSearchParams(hash);
      const hashCountry = hashObj.has('countryCode') ? hashObj.get('countryCode') : null;
      const hashProvider = hashObj.has('providerName') ? hashObj.get('providerName') : null;
      const hashCountryProvider = hashCountry || hashProvider;

      const firstContext = data[0];
      const firstProvider = firstContext.providers[0];
      const validateCountryLocally = data.find(
        (country) => country.countryCode === storedSelectedCountryCode
      );
      const validateProviderLocally = validateCountryLocally?.providers.find(
        (provider) => provider.name === storedSelectedProviderName
      );
      const foundCountryProviderLocally = validateCountryLocally && validateProviderLocally;
      const validateCountryUrl = data.find((country) => country.countryCode === hashCountry);
      const validateProviderUrl =
        hashProvider !== null
          ? validateCountryUrl?.providers.map((provider) => provider.brandName === hashProvider)
          : null;
      const foundCountryProviderUrl = validateCountryUrl && validateProviderUrl;
      // if the url does not have values and the values are not available locally then sets the default context
      if (hashCountryProvider) {
        // validate the country and provider
        if (!foundCountryProviderUrl) {
          updateContext({
            countryCode: firstContext.countryCode,
            providerName: firstProvider.name,
            mccMncs: firstProvider.mccMnc,
            mccs: firstProvider.mcc,
            mncs: firstProvider.mnc,
          });
          loginErrorToast();
        }
      } else if (foundCountryProviderLocally) {
        // set the value from local storage
        updateContext({
          countryCode: validateCountryLocally.countryCode,
          providerName: validateProviderLocally.name,
          mccMncs: validateProviderLocally.mccMnc,
          mccs: validateProviderLocally.mcc,
          mncs: validateProviderLocally.mnc,
        });
      } else {
        updateContext({
          countryCode: firstContext.countryCode,
          providerName: firstProvider.name,
          mccMncs: firstProvider.mccMnc,
          mccs: firstProvider.mcc,
          mncs: firstProvider.mnc,
        });
      }
    } else if (isSuccess && data && data.length === 0) {
      /** If no data then clear the context */
      updateContext({
        countryCode: null,
        providerName: null,
        mccMncs: null,
        mccs: null,
        mncs: null,
      });
    }
  }, [
    dispatch,
    data,
    updateContext,
    loginErrorToast,
    isSuccess,
    storedSelectedCountryCode,
    storedSelectedProviderName,
    hash,
  ]);

  useEffect(() => {
    if (isError) {
      updateContext({
        countryCode: null,
        providerName: null,
        mccMncs: null,
        mccs: null,
        mncs: null,
      });
    }
  }, [isError, updateContext]);

  return (
    <div>
      <Button
        id="context-button-open"
        alignItems="center"
        aria-label="Context Selection"
        borderRadius="full"
        onClick={onModalOpen}
        size="lg"
        rightIcon={<MdOutlineEdit size="1.2em" color="white" />}
        borderTopRadius={0}
        paddingBottom="1.5"
      >
        {isFetching ? (
          <Spinner />
        ) : (
          <ContextSelectionButton
            providerName={validationSelectedProviderName}
            countryCode={selectedCountryCode}
          />
        )}
        {/* It looks so weird to have the modal INSIDE the button. But chakra modal portals the modal element to end of the root element.
        So it doesn't matter where we put it! */}
        <ContextSelectionModal
          isOpen={isOpen}
          onClose={onClose}
          isFetching={isFetching}
          dataReady={dataReady}
          isSuccess={isSuccess}
          error={error}
          data={data}
          updateContext={updateContext}
        />
      </Button>
    </div>
  );
};

export default ContextSelection;
