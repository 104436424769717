import { Box, BoxProps, SlideFade } from '@chakra-ui/react';
import React, { useState } from 'react';
import { AiOutlineCheck, AiOutlineCopy } from 'react-icons/ai';
import { Logger } from '../../helpers/Logger';

export type CopyTextTypes = {
  copyText: string;
  isDisabled?: boolean;
  ml?: BoxProps['ml'];
};

const CopyToClipboard: React.FC<CopyTextTypes> = ({ copyText, ml, isDisabled = false }) => {
  const [copyTextState, setCopyTextState] = useState<boolean>(false);

  return (
    <Box
      ml={ml}
      p={'0'}
      onClick={async () => {
        if (!isDisabled) {
          try {
            await navigator.clipboard.writeText(copyText);
            setCopyTextState(true);

            setTimeout(() => {
              setCopyTextState(false);
            }, 2000);
          } catch (err: unknown) {
            if (typeof err === 'string') {
              Logger.error(err);
            } else if (err instanceof Error) {
              Logger.error(err.message);
            } else {
              Logger.error('An error occurred while copying text to clipboard');
            }
          }
        }
      }}
      _hover={{
        cursor: isDisabled ? 'not-allowed' : 'pointer',
      }}
    >
      {!copyTextState ? (
        <AiOutlineCopy opacity={isDisabled ? '0.3' : '1'} size={'15px'} />
      ) : (
        <SlideFade in={true}>
          <AiOutlineCheck size={'15px'} />
        </SlideFade>
      )}
    </Box>
  );
};

export default CopyToClipboard;
