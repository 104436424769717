import { Box, Button, Flex, Grid, Heading, Icon, Text, VStack } from '@chakra-ui/react';
import { AiOutlineClose } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../hooks/useAppSelector';
import { ThresholdEditFormState, ThresholdFormState } from '../../reducers/modalTabSlice';
import {
  UserProfileId,
  useDeleteThreshold,
  useGetThresholdLists,
} from '../../services/userProfileClient';
import EditThresholdsForm from './EditThresholdsForm';
import ThresholdsForm from './ThresholdsForm';

const Thresholds = () => {
  const showForm = useAppSelector((state) => state.modalTabSettings.isThresholdFormOpen);
  const showThresholdEditForm = useAppSelector(
    (state) => state.modalTabSettings.thresholdsEditForm.isThresholdEditFormOpen
  );
  const dispatch = useDispatch();

  const { data } = useGetThresholdLists();

  const deleteThresholdList = useDeleteThreshold();

  const onKPIDeleteClicked = (id: UserProfileId) => {
    deleteThresholdList.mutate(id);
  };

  return (
    <Box>
      <Heading size={'md'} fontWeight={600} mb={5} borderBottom={'1px solid lightgray'} pb={'10px'}>
        Thresholds
      </Heading>

      {showForm ? (
        <Box>
          <Flex justifyContent={'end'}>
            <Button
              id={'thresholds-back-button'}
              variant={'underLine'}
              onClick={() => dispatch(ThresholdFormState(false))}
            >
              Back
            </Button>
          </Flex>
          <ThresholdsForm />
        </Box>
      ) : showThresholdEditForm ? (
        <Box>
          <Flex justifyContent={'end'}>
            <Button
              id={'edit-thresholds-back-button'}
              variant={'underLine'}
              onClick={() => {
                dispatch(ThresholdFormState(false));
                dispatch(
                  ThresholdEditFormState({
                    isThresholdEditFormOpen: false,
                    thresholdEditId: null,
                  })
                );
              }}
            >
              Back
            </Button>
          </Flex>
          <EditThresholdsForm />
        </Box>
      ) : (
        <Box>
          <Flex mb={5}>
            <Button
              id={'create-thresholds-button'}
              variant={'unstyled'}
              onClick={() => dispatch(ThresholdFormState(true))}
            >
              Create Threshold
            </Button>
          </Flex>
          <Box mt={20} id={'existing-thresholds'}>
            <Heading size={'md'} mb={'20px'} fontWeight={'medium'}>
              Existing Thresholds
            </Heading>
            <VStack alignItems={'left'}>
              {data?.map((threshold) => {
                const thresholdData = JSON.parse(threshold.payload);
                const sectionName = threshold.sectionName;
                const name = thresholdData.listName;
                if (threshold.sectionName === 'Threshold') {
                  return (
                    <Grid
                      id={`threshold-${sectionName}-${name}`}
                      gridTemplateColumns={'1fr 1fr 40px'}
                      key={threshold.userProfileId}
                      background={'brand.500'}
                      color={'white'}
                      borderRadius={7}
                      alignContent={'center'}
                    >
                      <Box
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'start'}
                        padding={'0 15px'}
                      >
                        <Text pl={3}>{name}</Text>
                      </Box>
                      <Box display={'flex'} justifyContent={'end'} alignItems={'center'}>
                        <Button
                          id={`edit-button-${sectionName}-${name}`}
                          padding={'0 15px'}
                          onClick={() =>
                            dispatch(
                              ThresholdEditFormState({
                                isThresholdEditFormOpen: true,
                                thresholdEditId: threshold.userProfileId,
                              })
                            )
                          }
                          borderRadius={'0'}
                        >
                          Edit
                        </Button>
                      </Box>
                      <Button
                        id={`delete-item-button-${sectionName}-${name}`}
                        borderLeft={'1px solid white'}
                        borderLeftRadius={'none'}
                        p={0}
                        onClick={() => onKPIDeleteClicked(threshold.userProfileId)}
                      >
                        <Icon as={AiOutlineClose} color={'white'} />
                      </Button>
                    </Grid>
                  );
                }
                return null;
              })}
            </VStack>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Thresholds;
