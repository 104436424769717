import { Box, Button, Flex, Grid, Heading, Icon, Text, VStack } from '@chakra-ui/react';
import { AiOutlineClose } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../hooks/useAppSelector';
import { NodeCollectionEditFormFunc, NodesCollectionsForm } from '../../reducers/modalTabSlice';
import {
  UserProfileId,
  useDeleteThreshold,
  useGetThresholdLists,
} from '../../services/userProfileClient';
import EditNodeCollectionForm from './EditNodeCollectionForm';
import NodeCollectionForm from './NodeCollectionForm';

const NodeCollections = () => {
  const showNodeCollectionForm = useAppSelector(
    (state) => state.modalTabSettings.isNodeCollectionFormOpen
  );

  const showNodeCollectionEditForm = useAppSelector(
    (state) => state.modalTabSettings.nodeCollectionEditForm.isNodeCollectionEditFormOpen
  );

  const dispatch = useDispatch();

  const { data } = useGetThresholdLists();

  const deleteThresholdList = useDeleteThreshold();

  const onKPIDeleteClicked = (id: UserProfileId) => {
    deleteThresholdList.mutate(id);
  };

  return (
    <Box>
      <Heading size={'md'} fontWeight={600} mb={5} borderBottom={'1px solid lightgray'} pb={'10px'}>
        Node Collections
      </Heading>

      {showNodeCollectionForm ? (
        <Box>
          <Flex justifyContent={'end'}>
            <Button
              id={'node-collections-back-button'}
              variant={'underLine'}
              onClick={() => dispatch(NodesCollectionsForm(false))}
            >
              Back
            </Button>
          </Flex>
          <NodeCollectionForm />
        </Box>
      ) : showNodeCollectionEditForm ? (
        <Box>
          <Flex justifyContent={'end'}>
            <Button
              id={'edit-node-collections-back-button'}
              variant={'underLine'}
              onClick={() => {
                dispatch(NodesCollectionsForm(false));
                dispatch(
                  NodeCollectionEditFormFunc({
                    isNodeCollectionEditFormOpen: false,
                    nodeCollectionId: null,
                  })
                );
              }}
            >
              Back
            </Button>
          </Flex>
          <EditNodeCollectionForm />
        </Box>
      ) : (
        <Box>
          <Flex mb={5}>
            <Button
              id={'create-node-collection-button'}
              variant={'unstyled'}
              onClick={() => dispatch(NodesCollectionsForm(true))}
            >
              Create Node Collection
            </Button>
          </Flex>
          <Box mt={20} id={'existing-collection'}>
            <Heading size={'md'} mb={'20px'} fontWeight={'medium'}>
              Existing Collections
            </Heading>
            <VStack alignItems={'left'}>
              {data
                ?.sort((a, b) => {
                  const aPayload = JSON.parse(a.payload);
                  const bPayload = JSON.parse(b.payload);
                  return aPayload.listName.localeCompare(bPayload.listName);
                })
                .map((threshold) => {
                  const thresholdData = JSON.parse(threshold.payload);
                  const name = thresholdData.listName;
                  const sectionName = threshold.sectionName;
                  if (sectionName === 'NodeCollection') {
                    return (
                      <Grid
                        id={`node-collection-${sectionName}-${name}`}
                        gridTemplateColumns={'1fr 1fr 40px'}
                        key={threshold.userProfileId}
                        background={'brand.500'}
                        color={'white'}
                        borderRadius={7}
                        alignContent={'center'}
                      >
                        <Box
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'start'}
                          padding={'0 15px'}
                        >
                          <Text pl={3}>{name}</Text>
                        </Box>
                        <Box display={'flex'} justifyContent={'end'} alignItems={'center'}>
                          <Button
                            id={`edit-button-${sectionName}-${name}`}
                            padding={'0 15px'}
                            onClick={() =>
                              dispatch(
                                NodeCollectionEditFormFunc({
                                  isNodeCollectionEditFormOpen: true,
                                  nodeCollectionId: threshold.userProfileId,
                                })
                              )
                            }
                            borderRadius={'0'}
                          >
                            Edit
                          </Button>
                        </Box>
                        <Button
                          id={`delete-item-button-${sectionName}-${name}`}
                          borderLeft={'1px solid white'}
                          borderLeftRadius={'none'}
                          p={0}
                          onClick={() => onKPIDeleteClicked(threshold.userProfileId)}
                        >
                          <Icon as={AiOutlineClose} color={'white'} />
                        </Button>
                      </Grid>
                    );
                  }
                  return null;
                })}
            </VStack>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default NodeCollections;
