import { Box } from '@chakra-ui/react';
import { FC } from 'react';
import MapSearchInput from '../MapSearchListItem/MapSearchInput';
import MapSearchListContainer from '../MapSearchListItem/MapSearchListContainer';

const MapSearch: FC = () => {
  return (
    <Box flexGrow={0}>
      <MapSearchInput />
      <MapSearchListContainer />
    </Box>
  );
};

export default MapSearch;
