import { useQuery } from '@tanstack/react-query';
import dwAxiosClient from './dwAxiosClient';

type KeysForRecentlyModifiedResponse = {
  key: string;
  value: string;
};

const getKeysForRecentlyModifiedClient = () => async () => {
  const { data, status } = await dwAxiosClient.get<KeysForRecentlyModifiedResponse[]>(
    `/Building/MasterSearch/keys`
  );

  if (status === 204) {
    return null;
  }

  if (status === 200) {
    return data;
  }

  return null;
};

const useGetKeysForRecentlyModifiedClient = () => {
  return useQuery(['getKeysForRecentlyModifiedClient'], getKeysForRecentlyModifiedClient(), {
    refetchInterval: false,
    // Maybe remove stale
    // staleTime: 10 * 60 * 60 * 60 * 1000,
  });
};

export default useGetKeysForRecentlyModifiedClient;
