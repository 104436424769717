/**
 * Extracted function so if the method for identifying Neutral Host changes - we only change it in one place
 */
export const isNeutralHost = (providerName: unknown): boolean => {
  if (typeof providerName === 'string') {
    return providerName.startsWith('All-');
  }

  return false;
};
