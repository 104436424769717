import { useQuery } from '@tanstack/react-query';
import { Logger } from '../helpers/Logger';
import referenceData from './bandsInUse.json';
import dwAxiosClient from './dwAxiosClient';

type BandCountRequestOptions = {
  countryCode: string | null;
  providerName: string | null;
  binId: number | string;
  rssnrMetricRunId: number | string;
  resultComplexity: 1 | 2;
  type: string;
} | null;

type BandCountResponse = {
  metricV0101Id: number;
  binId: number;
  providerName: string;
  resultBandCounts: {
    connectionCategoryName: string;
    bandName: string;
    count: number;
  }[];
};

type ByBandData = {
  [bandName: string]: number;
};

type ByConnectionData = {
  [connectionName: string]: {
    [bandName: string]: number;
  };
};

type BandCountData = {
  byBand: ByBandData;
  byConnectionCategory: ByConnectionData;
  referenceAllBandsInUse: string[];
  referenceAllBandsInUseByConnectionCategory: {
    [connectionCategoryName: string]: string[];
  };
};

const transformBandData = (
  counts: BandCountResponse['resultBandCounts'],
  options: { countryCode: string | null; providerName: string | null }
): BandCountData | null => {
  if (!options.countryCode || !options.providerName) {
    return null;
  }

  const countsByBand = counts.reduce((acc: ByBandData, bandInfo) => {
    if (acc[bandInfo.bandName]) {
      acc[bandInfo.bandName] += bandInfo.count;
    } else {
      acc[bandInfo.bandName] = bandInfo.count;
    }

    return acc;
  }, {});

  const countsByConnectionCategory = counts.reduce((acc: ByConnectionData, bandInfo) => {
    const connectionCategoryName = bandInfo.connectionCategoryName;
    if (acc[connectionCategoryName]) {
      if (acc[connectionCategoryName][bandInfo.bandName]) {
        acc[connectionCategoryName][bandInfo.bandName] += bandInfo.count;
      } else {
        acc[connectionCategoryName][bandInfo.bandName] = bandInfo.count;
      }
    } else {
      acc[connectionCategoryName] = {
        [bandInfo.bandName]: bandInfo.count,
      };
    }
    return acc;
  }, {});

  const bandsInUseInBin = counts.map((c) => c.bandName.toString());
  const bandsInUseInBinByConnectionCategory = counts.reduce(
    (acc: { [categoryName: string]: string[] }, c) => {
      const connectionCategoryName = c.connectionCategoryName;

      if (acc[connectionCategoryName] && !acc[connectionCategoryName].includes(c.bandName)) {
        acc[connectionCategoryName].push(c.bandName.toString());
      } else {
        acc[connectionCategoryName] = [c.bandName.toString()];
      }

      return acc;
    },
    {}
  );

  const referenceAllBands = referenceData
    .filter((b) => {
      return b.MNO === options.providerName && b.Region === options.countryCode;
    })
    .sort((a, b) => {
      return a.Order - b.Order;
    })
    .map((r) => {
      if (r.Band === 'Other') {
        r.Band = 'Unknown';
      }
      return r;
    });

  const referenceAllUniqueBands = Array.from(
    new Set(referenceAllBands.map((b) => b.Band.toString()))
  );

  const referenceAllUniqueBandsByConnectionCategory = referenceAllBands.reduce(
    (acc: { [name: string]: string[] }, band) => {
      const connectionCategoryName = band.Tech;

      if (acc[connectionCategoryName] && !acc[connectionCategoryName].includes(band.Band)) {
        acc[connectionCategoryName].push(band.Band.toString());
      } else {
        acc[connectionCategoryName] = [band.Band.toString()];
      }

      return acc;
    },
    {}
  );

  const referenceAndInUseAllUniqueBands = Array.from(
    new Set([...referenceAllUniqueBands, ...bandsInUseInBin])
  );

  const referenceAllBandsInUseByConnectionCategory = Object.entries(
    referenceAllUniqueBandsByConnectionCategory
  ).reduce((acc: { [name: string]: string[] }, [connectionCategoryName, bands]) => {
    const bandsInUse = bandsInUseInBinByConnectionCategory[connectionCategoryName] ?? [];
    const uniqueBands = Array.from(new Set([...bands, ...bandsInUse]));

    acc[connectionCategoryName] = uniqueBands;

    return acc;
  }, {});

  const referenceAndInUseAllUniqueBandsByCategory = Object.entries(
    bandsInUseInBinByConnectionCategory
  ).reduce((acc, [inUseConnectionCategoryName, inUseBands]) => {
    const referenceBands = acc[inUseConnectionCategoryName];

    const uniqueBands = Array.from(
      new Set([...inUseBands, ...(referenceBands ? referenceBands : [])])
    );
    acc[inUseConnectionCategoryName] = uniqueBands;
    return acc;
  }, referenceAllBandsInUseByConnectionCategory);

  const bandData: BandCountData = {
    byBand: countsByBand,
    byConnectionCategory: countsByConnectionCategory,
    referenceAllBandsInUse: referenceAndInUseAllUniqueBands,
    referenceAllBandsInUseByConnectionCategory: referenceAndInUseAllUniqueBandsByCategory,
  };

  return bandData;
};

const gridKeys = ['grids', 'grids-grey'];
const buildingKeys = ['buildings', 'buildings-grey'];

const getBandCount = (options: BandCountRequestOptions) => async () => {
  if (options) {
    const { countryCode, providerName, binId, rssnrMetricRunId, resultComplexity, type } = options;

    const polyTypeForUrlFromLayerId: { [key: string]: string } = {
      grids: 'ResultGridCell',
      'grids-grey': 'ResultGridCell',
      buildings: 'ResultBuilding',
      'buildings-grey': 'ResultBuilding',
    };
    const idParamFromLayerId: { [key: string]: any } = {
      grids: { gridCellId: binId },
      'grids-grey': { gridCellId: binId },
      buildings: { buildingId: binId },
      'buildings-grey': { buildingId: binId },
    };

    if (polyTypeForUrlFromLayerId[type] === undefined) {
      return null;
    }

    try {
      const { data, status } = await dwAxiosClient.get<BandCountResponse>(
        `/${polyTypeForUrlFromLayerId[type]}/BandCount`,
        {
          params: {
            countryCode,
            providerName,
            rssnrMetricRunId,
            resultComplexity,
            ...(buildingKeys.includes(type) && idParamFromLayerId[type]),
            ...(gridKeys.includes(type) && idParamFromLayerId[type]),
          },
        }
      );

      if (data && status === 200) {
        const filteredCounts = data.resultBandCounts
          .filter((d) => {
            return !['WIFI', 'NONE-NO-SERVICE', 'OTHER', 'CALL-SERVICE-ONLY'].includes(
              d.connectionCategoryName
            );
          })
          .map((d) => {
            if (d.bandName === 'Other') {
              return { ...d, bandName: 'Unknown' };
            } else {
              return d;
            }
          });

        const newData = transformBandData(filteredCounts, { countryCode, providerName });

        return newData;
      }
    } catch (e) {
      Logger.error('Error fetching band count', e);
      return null;
    }
  }

  return null;
};

const useBandCountClient = (key: string | number, options: BandCountRequestOptions) => {
  return useQuery(['band-count', key, options?.providerName], getBandCount(options), {
    enabled: options && options.countryCode && options.providerName ? true : false,
    staleTime: 10 * 60 * 60 * 1000,
  });
};

export default useBandCountClient;
