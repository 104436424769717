export const PATHS = {
  HOME_PATH: '/',
  LOGIN_PATH: '/login',
};

export const MEGABYTE = 1_000_000;
export const GIGABYTE = 1_000_000_000;
export const TERABYTE = 1_000_000_000_000;

export const LOGOUT_TOAST_ID = 'dw-toast-logout';
