import { Box, BoxProps, GridItem, GridItemProps } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';

type PositionedContainerProps = {
  children: ReactNode;
  justifySelf?: GridItemProps['justifySelf'];
  alignSelf?: GridItemProps['alignSelf'];
  gridRow: GridItemProps['gridRow'];
  gridColumn: GridItemProps['gridColumn'];
  paddingTop?: GridItemProps['paddingTop'];
  paddingBottom?: GridItemProps['paddingBottom'];
  wrapperDisplay?: BoxProps['display'];
  innerBoxProps?: BoxProps;
};

const PositionedContainer: FC<PositionedContainerProps> = (props) => {
  const {
    children,
    justifySelf,
    alignSelf,
    gridColumn,
    gridRow,
    paddingTop,
    paddingBottom,
    wrapperDisplay,
    innerBoxProps,
  } = props;

  return (
    <GridItem
      padding={2}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      gridRow={gridRow}
      gridColumn={gridColumn}
      justifySelf={justifySelf}
      alignSelf={alignSelf}
    >
      <Box pointerEvents="all" display={wrapperDisplay} width="100%" {...innerBoxProps}>
        {children}
      </Box>
    </GridItem>
  );
};

export default PositionedContainer;
