import { FC, ReactElement } from 'react';
import PositionedContainer from './PositionedContainer';

type TopLeftProps = {
  children: ReactElement;
};

const TopLeft: FC<TopLeftProps> = (props) => {
  const { children } = props;
  return (
    <PositionedContainer
      children={children}
      gridColumn="1 / auto"
      gridRow="1 / 10"
      innerBoxProps={{ height: '100%' }}
    />
  );
};

export default TopLeft;
