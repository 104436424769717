import { FC } from 'react';
import useContextQueryClient from '../../services/contextClient';
import Flag from '../Flag';

type ContextSelectionButtonProps = {
  countryCode: string | null;
  providerName: string | null;
};

const ContextSelectionButton: FC<ContextSelectionButtonProps> = (props) => {
  const { countryCode, providerName } = props;

  const { data } = useContextQueryClient();

  const foundCountry = data?.find((c) => c.countryCode === countryCode);
  const foundProvider = foundCountry?.providers.find((p) => p.name === providerName);

  const providerBrand = foundProvider?.brandName;

  if (providerBrand) {
    const providerBrandSpan = <span style={{ lineHeight: 1 }}>{providerBrand}</span>;

    if (countryCode) {
      return (
        <>
          <Flag countryCode={countryCode} width="20px" />
          {providerBrandSpan}
        </>
      );
    }

    return providerBrandSpan;
  }

  return <>Choose a provider</>;
};

export default ContextSelectionButton;
