import { FC } from 'react';
import Select, { Props } from 'react-select';

export type Option = { value: boolean | string | number; label: string };

const ReactSelect: FC<Props<Option>> = (props) => {
  return (
    <div>
      <Select {...props} />
    </div>
  );
};

export default ReactSelect;
