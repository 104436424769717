import { Flex, Heading, Switch } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../hooks/useAppSelector';
import { setToggleCluster } from '../../reducers/clusterSlice';

const ClusterLayer = () => {
  const dispatch = useDispatch();
  const isClustesterEnable = useAppSelector((state) => state.clusterSlice.enableCluster);
  const toggleCluster = useAppSelector((state) => state.clusterSlice.toggleCluster);

  const handleToggleLayer = () => {
    dispatch(setToggleCluster(!toggleCluster));
  };
  return (
    <>
      {isClustesterEnable ? (
        <Flex right={'95px'} position={'absolute'} mt={'5px'}>
          <Heading
            id={`layer-switch-heading-highlight-cluster`}
            color="gray.700"
            size="sm"
            padding="1"
            textShadow="2px 2px 5px rgb(255 255 255 / 40%), 5px 5px 6px rgb(255 255 255 / 30%)"
            textAlign={'right'}
            onClick={() => handleToggleLayer()}
            cursor="pointer"
            whiteSpace="nowrap"
          >
            Highlight Cluster
          </Heading>
          <Switch
            id={`layer-switch-highlight-cluster`}
            size="sm"
            mt="5px"
            colorScheme="brand"
            isChecked={toggleCluster}
            invert="true"
            onChange={() => handleToggleLayer()}
          ></Switch>
        </Flex>
      ) : null}
    </>
  );
};

export default ClusterLayer;
