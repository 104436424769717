/**
 * There are number of checks required before we make use of a value.
 * Consolidated the checks into this function.
 */
export const isValueValid = <T>(value: T | null | 'NaN' | undefined): value is T => {
  if (value === null) {
    return false;
  }
  if (value === 'NaN') {
    return false;
  }
  if (value === undefined) {
    return false;
  }

  return true;
};
