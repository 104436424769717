import { Box, Center } from '@chakra-ui/react';
import { useIsFetching } from '@tanstack/react-query';
import { FC } from 'react';
import { useAppSelector } from '../../hooks/useAppSelector';
import { LayerKey } from '../../reducers/layersSlice.types';
import useLayersClient from '../../services/LayersClient';

const Pending: FC<{ layerId: LayerKey; layerName: string | number }> = (props) => {
  const { layerId, layerName } = props;

  const pendingChunks = useIsFetching([layerId]);

  if (pendingChunks) {
    return (
      <Center>
        Loading {pendingChunks} areas for {layerName}
      </Center>
    );
  }

  return null;
};

const Info: FC = () => {
  const zoom = useAppSelector((state) => state.mapSettings.viewState.zoom);
  const pitch = useAppSelector((state) => state.mapSettings.viewState.pitch);

  const layers = useLayersClient();
  const layerIds = layers.isSuccess ? layers.data.map((l) => [l.layerId, l.name]) : [];

  return (
    <Box bgColor="brand.500" borderTopRadius="md" color="white" height="auto" p={'10px'}>
      <Center>Zoom Level: {zoom.toFixed(2)}</Center>
      <Center>Pitch: {pitch.toFixed(2)}</Center>
      {layerIds.map(([id, name]) => {
        return <Pending key={id} layerId={id} layerName={name} />;
      })}
    </Box>
  );
};

export default Info;
