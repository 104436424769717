import { Box, Text } from '@chakra-ui/react';
import mapboxClient from '@mapbox/mapbox-sdk/lib/classes/mapi-client';
import mapboxStaticClient from '@mapbox/mapbox-sdk/services/static';
import centroid from '@turf/centroid';
import type { Geometry } from '@turf/turf';
import { LngLatLike } from 'mapbox-gl';

const baseClient = new mapboxClient({ accessToken: import.meta.env.VITE_APP_MAPBOX_TOKEN || '' });
const staticClient = mapboxStaticClient(baseClient);

type MapSearchPrevImageProps = {
  name: string;
  geometry: GeoJSON.Geometry;
};

const MapSearchPrevImage: React.FC<MapSearchPrevImageProps> = ({ geometry, name }) => {
  const isPoint = geometry.type === 'Point';

  const position = isPoint
    ? {
        zoom: 14,
        coordinates: centroid(geometry as Geometry).geometry.coordinates as LngLatLike,
      }
    : 'auto';

  const imageUrl = staticClient
    .getStaticImage({
      ownerId: 'mapbox',
      styleId: 'streets-v11',
      width: 500,
      height: 500,
      position,
      overlays: [
        {
          geoJson: {
            type: 'Feature',
            geometry,
            properties: {},
          },
        },
      ],
    })
    .url();

  return (
    <Box borderRadius={5} p={5}>
      <Text color={'white'} fontWeight={'bold'} fontSize={'13px'} pb={'5px'}>
        {name}
      </Text>
      <Box>
        <img src={imageUrl} alt={`Map Preview of ${name}`} />
      </Box>
    </Box>
  );
};

export default MapSearchPrevImage;
