import { useMap } from 'react-map-gl';
import layerConfig, { neutralHostLayerConfig } from '../helpers/layerConfig';
import { LayerKey } from '../reducers/layersSlice.types';
import { useAppSelector } from './useAppSelector';
import { useIsNeutralHost } from './useIsNeutralHost';

export const useActiveLayerPartConfig = (layerKey: LayerKey) => {
  const { 'dw-main-map': map } = useMap();
  const zoomLevel = useAppSelector((state) => state.mapSettings.viewState.zoom);
  const isNeutralHost = useIsNeutralHost();

  if (map) {
    const configForLayer = isNeutralHost
      ? neutralHostLayerConfig.find((c) => c.layerId === layerKey)
      : layerConfig.find((c) => c.layerId === layerKey);

    if (configForLayer) {
      const parts = configForLayer.layerParts.filter((config) => {
        const zoomIsHigherThanFrom = config.from <= zoomLevel;
        const zoomIsLowerThanTo = config.to > zoomLevel;

        return zoomIsHigherThanFrom && zoomIsLowerThanTo;
      });

      return parts;
    }
  }

  return null;
};
