import { useQuery } from '@tanstack/react-query';
import { useHash } from 'raviger';
import dwAxiosClient from './dwAxiosClient';

type Cluster = {
  regionId: null;
  neutralHostProviderId: null;
  clusterId: number;
  hexBins: number;
  area: number;
  measurements: number;
  population: number;
  hexBinsTenant1: number;
  hexBinsTenant2: number;
  hexBinsTenant3: number;
  hexBinsTenant4: number;
  meanTenancy: number;
  providerHexBins0: number;
  providerHexBins1: number;
  providerHexBins2: number;
  providerHexBins3: number;
  periodCode: string;
  boundary: string;
};

const getClusterById = (clusterId: string | null, searchIdentifier: string | null) => async () => {
  if (
    clusterId === '' ||
    searchIdentifier === '' ||
    clusterId === null ||
    searchIdentifier === null
  ) {
    return null;
  }

  const buildingIdAsNumber = parseInt(clusterId, 10);
  if (Number.isNaN(buildingIdAsNumber)) {
    return null;
  }

  const { data, status } = await dwAxiosClient.get<Cluster>(
    `/Cm/GetOutdoorClusterById/${searchIdentifier}/${clusterId}`,
    {
      baseURL: import.meta.env.VITE_APP_CM_API_URL,
    }
  );

  if (status === 204) {
    return null;
  }

  if (status === 200 && data.boundary) {
    return data;
  }

  return null;
};

const useClusterClient = () => {
  const hash = useHash();
  const hashObj = new URLSearchParams(hash);
  const searchIdentifier = hashObj.get('searchIdentifier');
  const clusterId = hashObj.get('clusterId');

  return useQuery(
    ['cluster', searchIdentifier, clusterId],
    getClusterById(clusterId, searchIdentifier),
    {
      staleTime: 10 * 60 * 60 * 60 * 1000, // 10 hours!
    }
  );
};

export default useClusterClient;
