import { Box, Flex } from '@chakra-ui/react';
import chroma from 'chroma-js';
import { FC, ReactNode } from 'react';
import type { ContinuousScale as ContinuousScaleType } from '../../helpers/layerConfig.types';

type ContinuousScaleProps = {
  scale: ContinuousScaleType['scale'];
  width: number;
};

const ContinuousScale: FC<ContinuousScaleProps> = (props) => {
  const { scale, width } = props;

  const nSections = 80;
  const sections: ReactNode[] = [];

  for (let i = 0; i < nSections; i++) {
    const colorScale = chroma.scale(scale.colors).domain([0, nSections]);

    if (scale.mode) {
      colorScale.mode(scale.mode);
    }

    const section = <Box width={`${width / nSections}vw`} height="8px" bg={colorScale(i).hex()} />;

    sections.push(section);
  }

  return (
    <Flex flexDirection="column" justify="stretch" align="stretch" width={`${width}vw`}>
      <Flex>{sections}</Flex>
      <Flex justify="space-between">
        {scale.domain.map((d, i) => {
          const isCenter = i === 0 || i === scale.domain.length - 1 ? false : true;

          return (
            <Box
              fontSize="0.7vw"
              color="white"
              transform={`translateX(${isCenter ? '50%' : 0})`}
              key={i}
            >
              {d.toLocaleString('en-GB', { useGrouping: true })}
            </Box>
          );
        })}
      </Flex>
    </Flex>
  );
};

export default ContinuousScale;
