import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Position } from '@turf/turf';

type StreetViewType = {
  streetViewFirstLoad: boolean;
  streetViewLayerOn: boolean;
  streetViewCoordinates: {
    lat: number;
    lng: number;
  } | null;
  streetViewHeading: number;
  streetViewBuildingLatLong: Position | null;
};

const initialState: StreetViewType = {
  streetViewFirstLoad: false,
  streetViewLayerOn: false,
  streetViewCoordinates: null,
  streetViewHeading: 0,
  streetViewBuildingLatLong: null,
};

const streetViewStateSettings = createSlice({
  name: 'streetViewStateSettings',
  initialState,
  reducers: {
    StreetViewInitialState: (
      state,
      action: PayloadAction<StreetViewType['streetViewFirstLoad']>
    ) => {
      state.streetViewFirstLoad = action.payload;
    },
    StreetViewState: (state, action: PayloadAction<StreetViewType['streetViewLayerOn']>) => {
      state.streetViewLayerOn = action.payload;
    },
    StreetViewPosition: (state, action: PayloadAction<StreetViewType['streetViewCoordinates']>) => {
      state.streetViewCoordinates = action.payload;
    },
    StreetViewFacingDirection: (
      state,
      action: PayloadAction<StreetViewType['streetViewHeading']>
    ) => {
      state.streetViewHeading = action.payload;
    },
    SelectedStreetViewBuildingData: (
      state,
      action: PayloadAction<StreetViewType['streetViewBuildingLatLong']>
    ) => {
      state.streetViewBuildingLatLong = action.payload;
    },
  },
});

const { actions, reducer } = streetViewStateSettings;

export const {
  StreetViewInitialState,
  StreetViewState,
  StreetViewPosition,
  StreetViewFacingDirection,
  SelectedStreetViewBuildingData,
} = actions;

export default reducer;
