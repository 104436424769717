import {
  Box,
  CloseButton,
  ListItem,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Tag,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import { FC } from 'react';
import { useMap } from 'react-map-gl';
import { goToGeometry } from '../../helpers/MapHelpers';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { setNavigationIsOpen } from '../../reducers/navigationSlice';
import { setSearchTextValue } from '../../reducers/searchSlice';
import MapSearchPrevImage from './MapSearchPrevImage';

export type SearchItem = {
  id: string;
  geometry: GeoJSON.Geometry;
  place_name: string;
  place_type: string[];
};

interface MapListItemProps {
  recentSearch: SearchItem[] | null;
  setRecentSearch: React.Dispatch<React.SetStateAction<SearchItem[] | null>>;
  showRemoveButton: boolean;
  item: SearchItem;
  addToRecentSearch: boolean;
}

const MapListItem: FC<MapListItemProps> = (props) => {
  const { recentSearch, setRecentSearch, showRemoveButton, item, addToRecentSearch } = props;
  const dispatch = useAppDispatch();

  const [isLargerThanHD] = useMediaQuery(['(min-width: 1290px)', '(display-mode: browser)']);

  const { 'dw-main-map': map } = useMap();
  const deleteItem = (id: string) => {
    if (recentSearch) {
      const deletedItems = recentSearch.filter((c) => c.id !== id);
      setRecentSearch(deletedItems);
    }
  };

  const addRecentSearchValues = (location: SearchItem) => {
    setRecentSearch((previous) => {
      if (previous) {
        const existingResult = previous.find((r) => r.id === location.id);

        if (existingResult) {
          return previous;
        } else {
          if (previous.length > 4) {
            previous.pop();
            return [location, ...previous];
          } else {
            previous.unshift();
            return [location, ...previous];
          }
        }
      } else {
        return [location];
      }
    });
  };

  return (
    <Box pt={1} pb={1} key={item.id}>
      <Popover trigger="hover" placement="right-start" id={'search-results'}>
        <PopoverTrigger>
          <Box display={'flex'}>
            <ListItem
              as="li"
              px="10px"
              color="white"
              position={'relative'}
              fontWeight={'bold'}
              width="100%"
              _hover={{
                background: 'var(--chakra-colors-brand-500)',
                cursor: 'pointer',
                transition: 'all 0.3s',
              }}
              onClick={() => {
                if (addToRecentSearch) {
                  addRecentSearchValues(item);
                }
                goToGeometry(item.geometry, map);
                dispatch(setNavigationIsOpen(false));
                dispatch(setSearchTextValue(''));
              }}
            >
              <Box
                display={'flex'}
                justifyContent={'space-between'}
                id={`list-item-${item.place_name.split(',')[0]}`}
              >
                <Text fontSize={'12px'}>{item.place_name.split(',')[0]} </Text>
                <Tag borderRadius={'2px'} fontSize={'10px'} minH={'1px'} maxH={'15px'}>
                  {item.place_type}
                </Tag>
              </Box>
              <Text fontSize={'10px'}>
                {item.place_name.split(',')[2]}
                {item.place_name.split(',')[3]}
              </Text>
            </ListItem>
            {showRemoveButton ? <CloseButton onClick={() => deleteItem(item.id)} /> : null}
          </Box>
        </PopoverTrigger>
        <PopoverContent border={'none'} bg="blackAlpha.600" width={'500px'} left={'20px'}>
          {isLargerThanHD ? (
            <MapSearchPrevImage geometry={item.geometry} name={item.place_name} />
          ) : null}
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export default MapListItem;
