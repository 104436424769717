import { useToast } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'raviger';
import { useCallback } from 'react';
import { LOGOUT_TOAST_ID, PATHS } from '../constants';
import { removeToken } from '../helpers/AuthProvider';

type useLogOutOptions = {
  redirectUrl?: string | false;
  triggerToast?: boolean;
};

const useLogOut = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const toast = useToast();

  return useCallback(
    (options: useLogOutOptions = { triggerToast: true, redirectUrl: PATHS.HOME_PATH }) => {
      const { triggerToast = true } = options;
      // const currentUrl = new URL(window.location.href);
      // const paramsSize = Array.from(currentUrl.searchParams.keys()).length;

      removeToken();
      if (options.redirectUrl) {
        navigate(options.redirectUrl);
      } else {
        window.open(import.meta.env.VITE_APP_LOGIN_PATH, '_self');
        // WIP: Not sure if needed
        // navigate(PATHS.LOGIN_PATH, {
        //   ...(paramsSize > 0 && {
        //     query: Object.fromEntries(currentUrl.searchParams.entries()),
        //   }),
        // });
      }

      queryClient.removeQueries();

      if (triggerToast && !toast.isActive(LOGOUT_TOAST_ID)) {
        toast({
          id: LOGOUT_TOAST_ID,
          title: 'Session expired',
          status: 'warning',
          duration: 7000,
          isClosable: true,
        });
      }
    },
    [navigate, queryClient, toast]
  );
};

export default useLogOut;
