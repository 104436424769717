import { Box } from '@chakra-ui/react';
import { FC } from 'react';
import { neutralHostLayerConfig } from '../../helpers/layerConfig';
import { useActiveLayerPartConfig } from '../../hooks/useActiveLayerPartsConfig';
import { useLayerSettingsSelector } from '../../hooks/useLayersSettings';
import { LayerKey, NeutralHostSettingsState } from '../../reducers/layersSlice.types';

type ThresholdDisplayProps = { layerId: LayerKey };

const ThresholdDisplay: FC<ThresholdDisplayProps> = (props) => {
  const { layerId } = props;

  const selectedMetric = useLayerSettingsSelector(layerId, 'selectedMetric', null);

  const nhSettings = useLayerSettingsSelector(
    layerId,
    'neutralHostSettings',
    {} as NeutralHostSettingsState
  );

  const layerParts = useActiveLayerPartConfig(layerId);

  const configForLayer = neutralHostLayerConfig.find((c) => {
    return c.layerId === layerId;
  });

  const defaultSetting =
    configForLayer && configForLayer.neutralHostSettings
      ? configForLayer.neutralHostSettings.find((s) => {
          return s.metricCode === selectedMetric;
        })
      : undefined;

  const thresholdFromStore =
    selectedMetric && nhSettings[selectedMetric] ? nhSettings[selectedMetric] : undefined;

  const thresholdForInteractive = thresholdFromStore ?? defaultSetting?.defaultValue;

  const interactiveLayerParts = layerParts ? layerParts.filter((part) => part.isInteractive) : [];

  const thresholdToUse =
    interactiveLayerParts.length > 0 ? thresholdForInteractive : defaultSetting?.defaultValue;

  if (thresholdToUse !== null && thresholdToUse !== undefined) {
    return <Box as="div">Threshold: &lt; {thresholdToUse}</Box>;
  }

  return null;
};

export default ThresholdDisplay;
