import { FC, ReactElement } from 'react';
import PositionedContainer from './PositionedContainer';

type TopRightProps = { children: ReactElement };

const TopRight: FC<TopRightProps> = (props) => {
  const { children } = props;
  return (
    <PositionedContainer children={children} gridColumn="-4/-1" gridRow="1/12" justifySelf="end" />
  );
};

export default TopRight;
