import { Feature } from '@turf/turf';
import { get } from 'lodash';
import { FC, useEffect } from 'react';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { initializeLayerMeta, setLayerMeta } from '../../reducers/layersMetaSlice';
import { LayerKey } from '../../reducers/layersSlice.types';

type TwoDPolygonMetaProps = {
  layerKey: LayerKey;
  features: Feature[];
};

const cellMapperProps = [
  'jsonContent.verified',
  'jsonContent.provider_name',
  'jsonContent.system',
  'jsonContent.subsystem',
  'jsonContent.generation',
  'jsonContent.band',
  'jsonContent.sid',
  'jsonContent.pci',
];

const propertyNameMap: { [layerKey: LayerKey]: string[] } = {
  nqt: ['floor', 'connectionType', 'deviceId', 'insideBuilding'],
  nqtHv: ['network_measurement_type', 'test_id'],
  nqtPassive: ['network_measurement_type', 'test_id'],
  oss: ['technologyType'],
  spectrumLicensing: ['band', 'licenceNames'],
  20: cellMapperProps,
  21: cellMapperProps,
};

const TwoDPolygonMeta: FC<TwoDPolygonMetaProps> = (props) => {
  const { features, layerKey } = props;

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(initializeLayerMeta({ layerKey }));
  }, [dispatch, layerKey]);

  const propertyNames = propertyNameMap[layerKey] ?? [];

  const list = features.reduce((acc: { [propertyName: string]: unknown[] }, f) => {
    propertyNames.forEach((propName) => {
      const value: string = get(f.properties, propName);

      if (Array.isArray(value)) {
        if (propName in acc) {
          acc[propName] = [...acc[propName], ...value];
        } else {
          acc[propName] = [...value];
        }
      } else {
        if (f.properties && value !== undefined) {
          if (propName in acc) {
            acc[propName].push(value);
          } else {
            acc[propName] = [value];
          }
        }
      }
    });

    return acc;
  }, {});

  useEffect(() => {
    Object.entries(list).forEach((entry) => {
      const [propName, values] = entry;
      dispatch(setLayerMeta({ layerKey, name: propName, value: values }));
    });
  }, [dispatch, list, layerKey]);

  return null;
};

export default TwoDPolygonMeta;
