import { LayerKey } from '../reducers/layersSlice.types';
import { useActiveLayerPartConfig } from './useActiveLayerPartsConfig';

const useHasActiveInteractiveParts = (layerId: LayerKey) => {
  const activeParts = useActiveLayerPartConfig(layerId);

  const hasInteractiveActiveParts = activeParts
    ? activeParts.some((part) => part.isInteractive)
    : false;

  return hasInteractiveActiveParts;
};

export default useHasActiveInteractiveParts;
