import { SmallCloseIcon } from '@chakra-ui/icons';
import { Box, Tag } from '@chakra-ui/react';
import { FC } from 'react';

type TagOptions = {
  label: string;
  value: string;
  comparison: string;
  index: number;
  delFunc: (label: string, value: string, comparison: string) => void;
  onClickFunc?: () => void;
};

const TagComponent: FC<TagOptions> = ({
  label,
  value,
  comparison,
  index,
  delFunc,
  onClickFunc,
}) => {
  return (
    <Box display={'flex'} mr={'10px'}>
      <Tag
        id={`${label}-tag`}
        data-test-id={`${index}-tag`}
        background={'primary'}
        borderLeftRadius={'15px'}
        borderRightRadius={'0px'}
        color={'white'}
        p={'5px 10px'}
        _hover={{
          ...(onClickFunc && {
            cursor: 'pointer',
            textDecoration: 'underline',
          }),
          background: 'primaryDarkFifteenPercent',
          transition: 'all 0.3s',
        }}
        onClick={onClickFunc}
      >
        {`${label}, ${comparison}, ${value}`}
      </Tag>
      <Box
        borderLeft={'1px solid #99AEBB'}
        borderRightRadius={'15px'}
        background={'primary'}
        display={'flex'}
        justifyItems={'center'}
        alignItems={'center'}
        pr={'5px'}
        _hover={{ cursor: 'pointer', background: 'errorRed' }}
      >
        <SmallCloseIcon
          id={`${label}-remove-button`}
          fontSize={'1.5em'}
          color={'white'}
          ml={'1px'}
          onClick={() => {
            delFunc(label, value, comparison);
          }}
        />
      </Box>
    </Box>
  );
};

export default TagComponent;
