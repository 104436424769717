import { QueryObserverOptions, useQueries } from '@tanstack/react-query';
import booleanContains from '@turf/boolean-contains';
import centroid from '@turf/centroid';
import { polygon } from '@turf/helpers';
import { Feature, Id, Point, Polygon, LineString } from '@turf/turf';
import isEqual from 'lodash/isEqual';
import { Logger } from '../helpers/Logger';
import { getBBox2d } from '../helpers/MapHelpers';
import { useLayerSettingsSelector } from '../hooks/useLayersSettings';
import { LayerKey } from '../reducers/layersSlice.types';
import dwAxiosClient from './dwAxiosClient';

type GenericPolygonRaw = Feature<
  Polygon,
  {
    hoverContent: string;
    name: string;
    popupContent: string;
    style: {
      color: string;
      fillColor: string;
      fillOpacity: number;
      opacity: number;
      weight: number;
    };
  }
>;
type GenericPointRaw = Feature<
  Point,
  {
    hoverContent: string;
    name: string;
    popupContent: string;
    style: {
      awesomeColor: string;
      awesomeIcon: string;
      awesomeIconColor: string;
      pointType: string;
    };
  }
>;
export type GenericPolygon = Feature<
  Polygon,
  {
    originalId: Id | undefined;
    hoverContent: string;
    name: string;
    popupContent: string;
    style: {
      color: string;
      fillColor: string;
      fillOpacity: number;
      opacity: number;
      weight: number;
    };
  }
>;
type GenericPoint = Feature<
  Point,
  {
    originalId: Id | undefined;
    hoverContent: string;
    name: string;
    popupContent: string;
    style: {
      awesomeColor: string;
      awesomeIcon: string;
      awesomeIconColor: string;
      pointType: string;
    };
  }
>;

type GenericLineRaw = Feature<
  LineString,
  {
    hoverContent: string;
    name: string;
    popupContent: string;
    style: {
      color: string;
      opacity: number;
      weight: number;
    };
  }
>;

type GenericLineString = Feature<
  LineString,
  {
    originalId: Id | undefined;
    hoverContent: string;
    name: string;
    popupContent: string;
    style: {
      weight: number;
      color: string;
      opacity: number;
    };
  }
>;

type GenericResultResponseRaw = {
  type: 'FeatureCollection';
  features: (GenericPolygonRaw | GenericPointRaw | GenericLineRaw)[];
};

export type GenericResultResponse = (GenericPolygon | GenericPoint | GenericLineString)[];

type GetGenericGeoParams = {
  west: number;
  south: number;
  east: number;
  north: number;
  countryCode: string | null;
  providerName: string | null;
  layerId: LayerKey;
};

export const fetchGenericGeo = async (
  params: GetGenericGeoParams,
  options: { signal: AbortSignal | undefined }
): Promise<GenericResultResponse> => {
  const { west, south, east, north, countryCode, providerName, layerId } = params;

  const { signal } = options;

  if (countryCode === null || providerName === null) {
    return [] as GenericResultResponse;
  }

  const response = await dwAxiosClient.get<GenericResultResponseRaw>(
    `/Layers/GetLayerFeatures/${layerId}/${west}/${south}/${east}/${north}`,
    {
      params: { countryCode, providerName },
      signal,
    }
  );

  if (response.status === 200) {
    const features = response.data.features.reduce((acc: GenericResultResponse, f) => {
      if (f === null) {
        return acc;
      }

      const boxGeometry = polygon([
        [
          [west, south],
          [west, north],
          [east, north],
          [east, south],
          [west, south],
        ],
      ]);

      if (f.geometry.type === 'Point') {
        const pointCentroid = centroid(f.geometry);
        const doesContain = booleanContains(boxGeometry, pointCentroid);
        const point = {
          ...f,
          properties: {
            ...f.properties,
            originalId: f.id,
          },
        } as GenericPoint;

        if (doesContain) {
          acc.push(point);
        }
      } else if (f.geometry.type === 'Polygon') {
        const polygonCentroid = centroid(f.geometry);
        const doesContain = booleanContains(boxGeometry, polygonCentroid);
        const poly = {
          ...f,
          properties: {
            ...f.properties,
            originalId: f.id,
          },
        } as GenericPolygon;

        if (doesContain) {
          acc.push(poly);
        }
      } else if (f.geometry.type === 'LineString') {
        const lineCentroid = centroid(f.geometry);
        const doesContain = booleanContains(boxGeometry, lineCentroid);
        const lineStr = {
          ...f,
          properties: {
            ...f.properties,
            originalId: f.id,
          },
        } as GenericPolygon;

        if (doesContain) {
          acc.push(lineStr);
        }
      } else {
        // Todo: added the code below for futureproofing, if new types are created/added then easier to debug
        // eslint-disable-next-line no-console
        Logger.error('genericsClient is the point of failure, unadded type added');
      }

      return acc;
    }, []);

    return features;
  }

  return [] as GenericResultResponse;
};

const useGenericClient = ({
  layerKey,
  providerName,
  countryCode,
  startUtc,
  endUtc,
  enabled,
  layerId,
}: {
  layerKey: LayerKey;
  providerName: string | null;
  countryCode: string | null;
  startUtc: Date;
  endUtc: Date;
  enabled: boolean;
  layerId: LayerKey;
}) => {
  const chunks = useLayerSettingsSelector(layerKey, 'visibleChunks', [], isEqual);

  const queries = chunks
    ? chunks.map((chunk) => {
        const newChunk = getBBox2d(chunk);
        const [west, south, east, north] = newChunk;

        const query: QueryObserverOptions<GenericResultResponse | null> = {
          queryKey: [layerId, countryCode, providerName, newChunk.join('|')],
          queryFn: (queryFnOptions) => {
            const { signal } = queryFnOptions;

            return fetchGenericGeo(
              {
                west,
                south,
                east,
                north,
                countryCode,
                providerName,
                layerId,
              },
              { signal }
            );
          },
          enabled,
        };

        return query;
      })
    : [];

  return useQueries({ queries });
};

export default useGenericClient;
