import tooltipText from '../config/tooltipTexts';
import twentififthPercentile from '../images/icons/first_percentile.png';
import { rsrpScale, rsrqScale } from './layerConfig.scales';
import type { LayerConfig } from './layerConfig.types';

const gridCommercialConfig: LayerConfig = {
  name: 'Grid-Commercial',
  boundingBoxWkt: null,
  description: 'Grids',
  layerId: 'gridCommercial',
  visibleFromUtc: '2000-01-01T00:00:00Z',
  visibleToUtc: '2099-01-01T00:00:00Z',
  zoomFrom: 0,
  zoomTo: 24,
  tilePlotType: 'G',
  defaultLayerOn: false,
  layerParts: [
    { from: 0, to: 13, type: 'tile', tilePlotType: 'G' },
    {
      from: 13,
      to: 24,
      type: 'geojson',
      layerType: 'fill',
      layerChunkLevel: 14,
      isInteractive: true,
    },
  ],
  defaultSelectedMetric: 'rsrpMean',
  metrics: [
    {
      name: 'Mean RSRP',
      polygonMetricCode: 'rsrpMean',
      scale: rsrpScale,
      description: tooltipText.rsrp,
    },
    {
      name: 'RSRP 25th Percentile',
      polygonMetricCode: 'rsrp25',
      scale: rsrpScale,
      description: tooltipText.rsrp25,
      descriptionImageUrl: twentififthPercentile,
    },
    {
      name: 'Mean RSRQ',
      polygonMetricCode: 'rsrqMean',
      scale: rsrqScale,
    },
    {
      name: 'RSRQ 25th Percentile',
      polygonMetricCode: 'rsrq25',
      scale: rsrqScale,
    },
  ],
  filters: [
    {
      name: 'Mean RSRP',
      metricCode: 'rsrpMean',
      type: 'rangeSlider',
      intervals: [
        { label: 'min', value: null },
        { value: -124 },
        { value: -120 },
        { value: -116 },
        { value: -112 },
        { value: -108 },
        { value: -104 },
        { value: -100 },
        { label: 'max', value: null },
      ],
      description: tooltipText.rsrp,
    },
    {
      name: 'RSRP 25th Percentile',
      metricCode: 'rsrp25',
      type: 'rangeSlider',
      intervals: [
        { label: 'min', value: null },
        { value: -124 },
        { value: -120 },
        { value: -116 },
        { value: -112 },
        { value: -108 },
        { value: -104 },
        { value: 0 },
        { label: 'max', value: null },
      ],
      description: tooltipText.rssnr25,
      descriptionImageUrl: twentififthPercentile,
    },
    {
      name: 'RSRQ Mean',
      metricCode: 'rsrqMean',
      type: 'rangeSlider',
      intervals: [
        { label: 'min', value: null },
        { value: -18 },
        { value: -15 },
        { value: -12 },
        { value: -9 },
        { value: -6 },
        { value: -3 },
        { label: 'max', value: null },
      ],
    },
    {
      name: 'RSRQ 25th Percentile',
      metricCode: 'qosCqiMean',
      type: 'rangeSlider',
      intervals: [
        { label: 'min', value: null },
        { value: -18 },
        { value: -15 },
        { value: -12 },
        { value: -9 },
        { value: -6 },
        { value: -3 },
        { label: 'max', value: null },
      ],
      // description: tooltipText.rsrp25,
      // descriptionImageUrl: twentififthPercentile,
    },
  ],
  details: [
    {
      sectionTitle: 'Network Performance',
      sectionKey: 'network-performance',
      navigationOptions: {
        navigationHeading: 'Network Performance',
      },
      items: [
        {
          type: 'grid',
          itemKey: 'building-information-table',
          rows: [
            {
              rowText: 'Mean RSRP',
              rowKey: 'mean-rsrp',
              metricName: 'rsrpMean',
              showColor: true,
              decimalPlaces: 2,
            },
            {
              rowText: 'RSRP 25%',
              rowKey: 'rssnr-25',
              metricName: 'rsrp25',
              showColor: true,
              decimalPlaces: 0,
            },
            {
              rowText: 'Mean RSRQ',
              rowKey: 'mean-rsrq',
              metricName: 'rsrqMean',
              showColor: true,
              decimalPlaces: 2,
            },
            {
              rowText: 'RSRQ 25%',
              rowKey: 'rsrq-25',
              metricName: 'rsrq25',
              showColor: true,
              decimalPlaces: 2,
            },
            {
              rowText: 'QOS Cqi Mean',
              rowKey: 'qos-CqiMean',
              metricName: 'qosCqiMean',
              showColor: true,
            },
          ],
        },
      ],
    },

    {
      sectionTitle: 'Grid Information',
      sectionKey: 'grid-information',
      navigationOptions: {
        navigationHeading: 'Reference Information',
      },
      items: [
        {
          type: 'grid',
          itemKey: 'grid-information-table',
          rows: [
            {
              rowText: 'Grid Cell ID',
              rowKey: 'gridCellId',
              metricName: 'gridCellId',
              showColor: false,
              format: 'text',
              enableCopy: true,
            },
            {
              rowText: 'Grid Cell Lat / Lon',
              rowKey: 'gridLatLon',
              metricName: 'locationLatLon',
              showColor: false,
              format: 'text',
              enableCopy: true,
            },
            {
              rowText: 'Area (m<sup>2</sup>)',
              rowKey: 'area',
              metricName: 'areaM2',
              showColor: false,
            },
            {
              rowText: 'Resident Count',
              rowKey: 'resident-count',
              metricName: 'residentCount',
              showColor: false,
              decimalPlaces: 2,
            },
            {
              rowText: 'Provider',
              rowKey: 'provider',
              metricName: 'provider',
              showColor: false,
            },
            {
              rowText: 'Analysis From',
              rowKey: 'fromUtc',
              metricName: 'fromUtc',
              showColor: false,
              format: 'date',
            },
            {
              rowText: 'Analysis To',
              rowKey: 'toUtc',
              metricName: 'toUtc',
              showColor: false,
              format: 'date',
            },
          ],
        },
      ],
    },
  ],
};

export default gridCommercialConfig;
