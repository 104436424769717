import { Grid } from '@chakra-ui/react';
import bbox from '@turf/bbox';
import bboxPolygon from '@turf/bbox-polygon';
import booleanIntersects from '@turf/boolean-intersects';
import { lineString } from '@turf/helpers';
import { useHash } from 'raviger';
import { FC, useEffect, useRef, useState } from 'react';
import { LayerConfig } from '../../helpers/layerConfig.types';
import { useAppSelector } from '../../hooks/useAppSelector';
import { LayerKey } from '../../reducers/layersSlice.types';
import useLayersClient from '../../services/LayersClient';
import useUpdateURL from '../../services/UpdateURL';
import LayerControl from '../LayerControl';

type LayersControlsProps = {};

const LayersControls: FC<LayersControlsProps> = () => {
  const prevConfig = useRef<LayerConfig[] | null | undefined>();
  const layersClient = useLayersClient();

  const { data: layerConfig } = layersClient;

  const [config, setConfig] = useState<LayerConfig[] | null | undefined>(null);

  const viewport = useAppSelector((state) => state.mapSettings.viewState.viewport);

  const hash = useHash();

  const updateURL = useUpdateURL();

  const selectedCountryCode = useAppSelector((state) => state.contextSettings.selectedCountryCode);

  useEffect(() => {
    const hashObj = new URLSearchParams(hash);
    const currentViewVal = lineString(viewport);
    const currentViewBbox = bbox(currentViewVal);
    const currentViewPolygon = bboxPolygon(currentViewBbox);

    if (layerConfig !== undefined) {
      const refinedLayers: LayerConfig[] | null | undefined = [];
      prevConfig.current = layerConfig;
      layerConfig.map((individualLayer) => {
        if (individualLayer.boundingBox === null || individualLayer.boundingBox === undefined) {
          refinedLayers.push(individualLayer);
        } else {
          let polygonVal = individualLayer.boundingBox;
          let currentBboxVal = bbox(polygonVal);
          let currentPolygonVal = bboxPolygon(currentBboxVal);
          let doesItOverlap = booleanIntersects(currentPolygonVal, currentViewPolygon);
          if (doesItOverlap) {
            refinedLayers.push(individualLayer);
          }
        }
        return refinedLayers;
      });
      // This checks and enables layers
      if (hashObj.has('layers')) {
        const enabledLayers = hashObj.get('layers');
        const enabledLayersObj = enabledLayers ? JSON.parse(enabledLayers) : null;
        // DW-3780 Might need to enable
        // const enabledLayersObj = enabledLayers ? JSON.parse(decodeURIComponent(enabledLayers)) : null;
        if (enabledLayers !== null && enabledLayersObj.length > 0) {
          const objLowercased = enabledLayersObj.map((name: string | number) => {
            if (typeof name === 'string') {
              return name.toLowerCase();
            }

            return name;
          });
          refinedLayers.forEach((layer) => {
            const layerName =
              typeof layer.layerId === 'string' ? layer.layerId.toLowerCase() : layer.layerId;
            layer.defaultLayerOn = objLowercased.includes(layerName);
          });
        }
      } else {
        updateURL('layers', '["grids"]');
      }
      const unlockedCountries = ['GB', 'US'];

      let furtherRefined = refinedLayers;
      if (import.meta.env.VITE_APP_3POINT5_FEATURE) {
        if (selectedCountryCode && !unlockedCountries.includes(selectedCountryCode)) {
          const allowedLayers: Array<LayerKey> = [
            'buildingSales',
            'buildingCommercial',
            'gridSales',
            'gridCommercial',
          ];

          furtherRefined = refinedLayers.filter((l) => !allowedLayers.includes(l.layerId));
        }
      }
      setConfig(furtherRefined);
    }
  }, [layerConfig, viewport, hash, selectedCountryCode, updateURL]);

  return (
    <Grid
      gridTemplateColumns="repeat(3, auto)"
      gridRowGap="2"
      gridColumnGap="2"
      alignItems="center"
    >
      {config
        ? config.map((config, i) => {
            return <LayerControl key={config.layerId} config={config} />;
          })
        : null}
    </Grid>
  );
};

export default LayersControls;
