import {
  goodToBadContinuous,
  nqtScoreScale,
  rsrpScale,
  rssnrScale,
  throughputScale,
} from './layerConfig.scales';
import { LayerConfig } from './layerConfig.types';

const nqtConfig: LayerConfig = {
  name: 'NQT',
  boundingBoxWkt: null,
  description: 'NQT',
  layerId: 'nqt',
  visibleFromUtc: '2000-01-01T00:00:00Z',
  visibleToUtc: '2099-01-01T00:00:00Z',
  zoomFrom: 8,
  zoomTo: 24,
  defaultLayerOn: false,
  layerParts: [
    {
      from: 10,
      to: 24,
      type: 'geojson',
      layerType: 'circle',
      layerChunkLevel: 12,
      isInteractive: true,
    },
  ],
  defaultSelectedMetric: 'overallScore',
  metrics: [
    { name: 'Overall Score', polygonMetricCode: 'overallScore', scale: nqtScoreScale },
    { name: 'Image Upload Throughput Score', polygonMetricCode: 'ultpScore', scale: nqtScoreScale },
    {
      name: 'Video Download Throughput Score',
      polygonMetricCode: 'dltpScore',
      scale: nqtScoreScale,
    },
    { name: 'Voice Score', polygonMetricCode: 'voiceScore', scale: nqtScoreScale },
    { name: 'Web Score', polygonMetricCode: 'webScore', scale: nqtScoreScale },
    { name: 'RSRP Score', polygonMetricCode: 'rsrpScore', scale: nqtScoreScale },
    { name: 'RSSNR Score', polygonMetricCode: 'sinrScore', scale: nqtScoreScale },
    { name: 'RSRP Average', polygonMetricCode: 'rsrpAverage', scale: rsrpScale },
    { name: 'RSSNR Average', polygonMetricCode: 'sinrAverage', scale: rssnrScale },
    { name: 'Image Upload Average kb/s', polygonMetricCode: 'ultpAvgKbps', scale: throughputScale },
    {
      name: 'Video Download Average kb/s',
      polygonMetricCode: 'dltpAvgKbps',
      scale: throughputScale,
    },
    {
      name: 'Web Page Download (ms)',
      polygonMetricCode: 'webPageDownloadMs',
      scale: goodToBadContinuous,
    },
  ],
  filters: [
    {
      name: 'Date Range (UTC)',
      type: 'date_range',
      metricCode: 'startedUtc',
    },
    {
      name: 'Overall Score',
      metricCode: 'overallScore',
      type: 'rangeSlider',
      intervals: [
        { value: 0 },
        { value: 1 },
        { value: 2 },
        { value: 3 },
        { value: 4 },
        { value: 5 },
        { value: null, label: 'max' },
      ],
    },
    {
      name: 'Image Upload Throughput Score',
      metricCode: 'ultpScore',
      type: 'rangeSlider',
      intervals: [
        { value: 0 },
        { value: 1 },
        { value: 2 },
        { value: 3 },
        { value: 4 },
        { value: 5 },
        { value: null, label: 'max' },
      ],
    },
    {
      name: 'Video Download Throughput Score',
      metricCode: 'dltpScore',
      type: 'rangeSlider',
      intervals: [
        { value: 0 },
        { value: 1 },
        { value: 2 },
        { value: 3 },
        { value: 4 },
        { value: 5 },
        { value: null, label: 'max' },
      ],
    },
    {
      name: 'Voice Score',
      metricCode: 'voiceScore',
      type: 'rangeSlider',
      intervals: [
        { value: 0 },
        { value: 1 },
        { value: 2 },
        { value: 3 },
        { value: 4 },
        { value: 5 },
        { value: null, label: 'max' },
      ],
    },
    {
      name: 'Web Score',
      metricCode: 'webScore',
      type: 'rangeSlider',
      intervals: [
        { value: 0 },
        { value: 1 },
        { value: 2 },
        { value: 3 },
        { value: 4 },
        { value: 5 },
        { value: null, label: 'max' },
      ],
    },
    {
      name: 'RSRP Score',
      metricCode: 'rsrpScore',
      type: 'rangeSlider',
      intervals: [
        { value: 0 },
        { value: 1 },
        { value: 2 },
        { value: 3 },
        { value: 4 },
        { value: 5 },
        { value: null, label: 'max' },
      ],
    },
    {
      name: 'RSSNR Score',
      metricCode: 'sinrScore',
      type: 'rangeSlider',
      intervals: [
        { value: 0 },
        { value: 1 },
        { value: 2 },
        { value: 3 },
        { value: 4 },
        { value: 5 },
        { value: null, label: 'max' },
      ],
    },
    {
      name: 'RSRP Average',
      metricCode: 'rsrpAverage',
      type: 'rangeSlider',
      intervals: [
        { label: 'min', value: null },
        { value: -124 },
        { value: -120 },
        { value: -116 },
        { value: -112 },
        { value: -108 },
        { value: -104 },
        { value: -100 },
        { label: 'max', value: null },
      ],
    },
    {
      name: 'RSSNR Average',
      metricCode: 'sinrAverage',
      type: 'rangeSlider',
      intervals: [
        { label: 'min', value: null },
        { value: -6 },
        { value: -4 },
        { value: -2 },
        { value: 0 },
        { value: 2 },
        { value: 5 },
        { value: 10 },
        { label: 'max', value: null },
      ],
    },
    {
      name: 'Image Upload Throughput Average (kb/s)',
      metricCode: 'ultpAvgKbps',
      type: 'rangeSlider',
      intervals: [
        { value: 0 },
        { value: 1000 },
        { value: 2000 },
        { value: 3000 },
        { value: 4000 },
        { value: 5000 },
        { value: 6000 },
        { value: 7000 },
        { value: 8000 },
        { value: 9000 },
        { value: 10000 },
      ],
    },
    {
      name: 'Video Download Throughput Average (kb/s)',
      metricCode: 'dltpAvgKbps',
      type: 'rangeSlider',
      intervals: [
        { value: 0 },
        { value: 1000 },
        { value: 2000 },
        { value: 3000 },
        { value: 4000 },
        { value: 5000 },
        { value: 6000 },
        { value: 7000 },
        { value: 8000 },
        { value: 9000 },
        { value: 10000 },
      ],
    },
    {
      name: 'Web Page Download Time (ms)',
      metricCode: 'webPageDownloadMs',
      type: 'rangeSlider',
      intervals: [
        { value: 0 },
        { value: 500 },
        { value: 1000 },
        { value: 1500 },
        { value: 2000 },
        { value: 2500 },
        { value: 3000 },
        { value: 3500 },
        { value: 4000 },
        { value: 4500 },
        { value: 5000 },
        { value: 5500 },
        { value: 6000 },
        { value: 6500 },
        { value: 7000 },
        { value: 7500 },
        { value: 8000 },
        { value: 8500 },
        { value: 9000 },
        { value: 9500 },
        { value: 10000 },
      ],
    },
    {
      name: 'Floor',
      type: 'dropdown',
      metricCode: 'floor',
    },
    {
      name: 'Connection Type',
      type: 'dropdown',
      metricCode: 'connectionType',
      caseSensitive: true,
    },
    {
      name: 'Device ID',
      type: 'dropdown',
      metricCode: 'deviceId',
      caseSensitive: true,
    },
    {
      name: 'Inside building?',
      type: 'dropdown',
      metricCode: 'insideBuilding',
    },
  ],
  details: [
    {
      sectionTitle: 'Information',
      sectionKey: 'nqt-information',
      items: [
        {
          type: 'grid',
          itemKey: 'nqt-information-table',
          rows: [
            {
              rowText: 'Test ID',
              rowKey: 'test-id',
              metricName: 'testId',
              showColor: false,
            },
            {
              rowText: 'Test Started (UTC)',
              rowKey: 'started-utc',
              metricName: 'startedUtc',
              format: 'date',
              showColor: false,
            },
            {
              rowText: 'Device ID',
              rowKey: 'device-id',
              metricName: 'deviceId',
              showColor: false,
            },
            {
              rowText: 'Floor',
              rowKey: 'floor',
              metricName: 'floor',
              showColor: false,
            },
            {
              rowText: 'Inside building?',
              rowKey: 'inside-building',
              metricName: 'insideBuilding',
              showColor: false,
            },
            {
              rowText: 'Connection Type',
              rowKey: 'connection-type',
              metricName: 'connectionType',
              showColor: false,
            },
            {
              rowText: 'Notes',
              rowKey: 'notes',
              metricName: 'notes',
              showColor: false,
            },
          ],
        },
      ],
    },
    {
      sectionTitle: 'NQT Scores',
      sectionKey: 'nqt-scores',
      items: [
        {
          itemKey: 'nqt-scores-table',
          type: 'grid',
          rows: [
            {
              rowText: 'Overall Score',
              rowKey: 'overall-score',
              metricName: 'overallScore',
              showColor: true,
            },
            {
              rowText: 'Image Upload Throughput Score',
              rowKey: 'image-upload-throughput-score',
              metricName: 'ultpScore',
              showColor: true,
            },
            {
              rowText: 'Video Download Throughput Score',
              rowKey: 'video-download-throughput-score',
              metricName: 'dltpScore',
              showColor: true,
            },
            {
              rowText: 'Voice Score',
              rowKey: 'voice-score',
              metricName: 'voiceScore',
              showColor: true,
            },
            {
              rowText: 'Web Score',
              rowKey: 'web-score',
              metricName: 'webScore',
              showColor: true,
            },
            {
              rowText: 'RSRP Score',
              rowKey: 'rsrp-score',
              metricName: 'rsrpScore',
              showColor: true,
            },
            {
              rowText: 'RSSNR Score',
              rowKey: 'rssnr-score',
              metricName: 'sinrScore',
              showColor: true,
            },
          ],
        },
      ],
    },
    {
      sectionTitle: 'NQT Result Details',
      sectionKey: 'nqt-result-details',
      items: [
        {
          type: 'grid',
          itemKey: 'nqt-details',
          rows: [
            {
              rowText: 'Image Upload Throughput Average (kb/s)',
              rowKey: 'nqt-details-ultpAvgKbps',
              metricName: 'ultpAvgKbps',
              showColor: false,
            },
            {
              rowText: 'Video Download Throughput Average (kb/s)',
              rowKey: 'nqt-details-dltpAvgKbps',
              metricName: 'dltpAvgKbps',
              showColor: false,
            },
            {
              rowText: 'RSRP Average',
              rowKey: 'rsrp-average',
              metricName: 'rsrpAverage',
              showColor: false,
            },
            {
              rowText: 'RSSNR Average',
              rowKey: 'rssnr-average',
              metricName: 'sinrAverage',
              showColor: false,
            },
            {
              rowText: 'Web Page Download (ms)',
              rowKey: 'webpage-download-ms',
              metricName: 'webPageDownloadMs',
              showColor: false,
            },
          ],
        },
      ],
    },
  ],
};

export default nqtConfig;
