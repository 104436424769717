import {
  Box,
  Button,
  Center,
  CircularProgress,
  Divider,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Tag,
  VStack,
} from '@chakra-ui/react';
import { FC, Fragment } from 'react';
import { ContextResponse, MccMnc } from '../../services/contextClient';
import Flag from '../Flag';

type ContextSelectionModalProps = {
  isOpen: ModalProps['isOpen'];
  onClose: ModalProps['onClose'];
  isFetching: boolean;
  dataReady: boolean;
  isSuccess: boolean;
  error: Error | null;
  data: ContextResponse | undefined;
  updateContext: (context: {
    countryCode: string | null;
    providerName: string | null;
    mccMncs: MccMnc[] | null;
    mccs: string[] | null;
    mncs: string[] | null;
  }) => void;
};

const ContextSelectionModal: FC<ContextSelectionModalProps> = (props) => {
  const { isOpen, onClose, data, isFetching, dataReady, updateContext } = props;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Choose provider</ModalHeader>
        <ModalBody>
          <Center>{isFetching ? <CircularProgress isIndeterminate /> : null}</Center>
          {dataReady && data ? (
            <>
              <Center>
                <Flex width="80%" flexWrap="wrap" justifyContent="center">
                  {data.map((context) => {
                    return (
                      <Fragment key={context.countryCode}>
                        <Box flexBasis="30%" mb="10">
                          <Box maxWidth="80%" margin="0 auto">
                            <Heading size="md" mb="2">
                              <Flex align="center">
                                <Flag countryCode={context.countryCode} width="30px" />
                                {context.name}
                              </Flex>
                            </Heading>
                            <Divider mb="3" />
                            <VStack align="start">
                              {context.providers.map((provider) => {
                                return (
                                  <Flex
                                    justifyContent="space-between"
                                    width="100%"
                                    key={provider.providerId}
                                  >
                                    <Button
                                      id={`context-button-${context.countryCode}-${provider.name}`}
                                      color="grey.800"
                                      justifyContent="start"
                                      variant="link"
                                      onClick={() => {
                                        updateContext({
                                          countryCode: context.countryCode,
                                          providerName: provider.name,
                                          mccMncs: provider.mccMnc,
                                          mccs: provider.mcc,
                                          mncs: provider.mnc,
                                        });

                                        onClose();
                                      }}
                                    >
                                      {provider.brandName}
                                    </Button>
                                    {provider.isMvno ? (
                                      <Tag size="sm" variant="outline">
                                        MVNO
                                      </Tag>
                                    ) : null}
                                  </Flex>
                                );
                              })}
                            </VStack>
                          </Box>
                        </Box>
                      </Fragment>
                    );
                  })}
                </Flex>
              </Center>

              <ModalFooter>
                <Button id="context-button-close" variant="ghost" onClick={onClose}>
                  Cancel
                </Button>
              </ModalFooter>
            </>
          ) : null}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ContextSelectionModal;
