import { Box, Flex, Heading, Icon, IconButton, Switch } from '@chakra-ui/react';
import { useHash } from 'raviger';
import { FC, useEffect } from 'react';
import { IoColorPalette } from 'react-icons/io5';
import { VscFilter } from 'react-icons/vsc';
import { LayerConfig } from '../../helpers/layerConfig.types';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import useHasActiveInteractiveParts from '../../hooks/useHasActiveInteractiveParts';
import { useLayerSettingsSelector } from '../../hooks/useLayersSettings';
import {
  initializeLayerSettings,
  setIsLayerOn,
  toggleLayerFiltersExpanded,
  toggleLayerMetricsExpanded,
} from '../../reducers/layersSlice';
import { toggleSpectrumQueryMode } from '../../reducers/mapSettings';
import useUpdateURL from '../../services/UpdateURL';
import LayerControlFilters from '../LayerControlFilters';
import LayerControlMetricsDrawer from '../LayerControlMetrics';

type LayerControlProps = {
  config: LayerConfig;
};

const LayerControl: FC<LayerControlProps> = (props) => {
  const { config } = props;

  const dispatch = useAppDispatch();

  const isLayerOn = useLayerSettingsSelector(config.layerId, 'isLayerOn', undefined);
  const isSpectrumQueryMode = useAppSelector((state) => state.mapSettings.isSpectrumQueryMode);

  const hasInteractiveActiveParts = useHasActiveInteractiveParts(config.layerId);

  useEffect(() => {
    if (isLayerOn === undefined) {
      let defaultSelectedTileMetric = null;

      if (config.defaultSelectedMetric) {
        const tileMetric = config.metrics
          ? config.metrics.find((metric) => {
              return metric.polygonMetricCode === config.defaultSelectedMetric;
            })
          : null;

        defaultSelectedTileMetric = tileMetric ? tileMetric.tileMetricCode : null;
      }

      dispatch(
        initializeLayerSettings({
          layerKey: config.layerId,
          isLayerOn: config.defaultLayerOn,
          selectedMetric: config.defaultSelectedMetric ?? null,
          selectedTileMetric: defaultSelectedTileMetric ?? null,
        })
      );
    }
  }, [
    dispatch,
    config.layerId,
    config.defaultSelectedMetric,
    config.defaultLayerOn,
    config.metrics,
    isLayerOn,
  ]);

  const hash = useHash();
  const hashObj = new URLSearchParams(hash);

  const updateURL = useUpdateURL();

  const updateURLVal = () => {
    let updateValue: string[] = [];
    if (hashObj.has('layers')) {
      const enabledLayers = hashObj.get('layers');
      const enabledLayersObj = enabledLayers ? JSON.parse(enabledLayers) : null;
      // check if the layers are empty
      if (enabledLayersObj !== null && enabledLayersObj.length > 0) {
        if (!isLayerOn) {
          enabledLayersObj.push(config.layerId);
          updateValue = enabledLayersObj;
        } else {
          const removeFromURL = enabledLayersObj.filter((letter: string) => {
            return letter !== config.layerId;
          });
          updateValue = removeFromURL;
        }
      } else {
        updateValue.push(config.layerId.toString());
      }
    } else {
      // need to look into when this will happen
      updateValue.push(config.layerId.toString());
    }
    updateURL('layers', JSON.stringify(updateValue));
  };

  const handleToggleLayer = () => {
    dispatch(setIsLayerOn({ layerKey: config.layerId, isLayerOn: !isLayerOn }));
    updateURLVal();
  };

  return (
    <>
      <Box alignSelf="stretch" gridColumnStart={1}>
        <Flex justify="end" align="center">
          {config.layerId === 'spectrumLicensing' && isLayerOn ? (
            <IconButton
              id={`spectrumLicensing-query-features-toggle`}
              size="sm"
              aria-label={`Toggle Spectrum Licensing Query Mode`}
              title={`Toggle Spectrum Licensing Query Mode`}
              isActive={isSpectrumQueryMode}
              icon={
                <Icon viewBox="0 0 100 101" width="1.3em" height="1.3em">
                  <path
                    d="M38.3947 71.5954C38.3244 71.5251 38.2724 71.4387 38.2432 71.3437C38.2139 71.2487 38.2084 71.1479 38.227 71.0503C38.2456 70.9526 38.2879 70.861 38.35 70.7834C38.4122 70.7058 38.4924 70.6446 38.5837 70.6052L54.7214 67.0668C55.5002 66.7146 56.1553 66.1366 56.6016 65.4076C57.0479 64.6786 57.265 63.8324 57.2246 62.9786C57.1842 62.1248 56.8882 61.3028 56.3751 60.6193C55.8619 59.9357 55.1552 59.4221 54.3467 59.1449L5.56924 43.2098C4.82315 42.9663 4.02419 42.9341 3.26092 43.1168C2.49765 43.2995 1.79987 43.6899 1.24491 44.2449C0.68995 44.7999 0.29948 45.4977 0.116776 46.2609C-0.0659293 47.0242 -0.0337346 47.8231 0.209791 48.5692L16.1449 97.3467C16.4078 98.169 16.9171 98.8906 17.6038 99.4138C18.2905 99.937 19.1214 100.236 19.984 100.272C20.0541 100.272 20.1242 100.272 20.1942 100.272C21.0183 100.271 21.8243 100.03 22.5125 99.5766C23.2008 99.1234 23.7411 98.4784 24.0668 97.7214L27.496 80.7837C27.5355 80.6924 27.5967 80.6122 27.6743 80.5501C27.7519 80.4879 27.8435 80.4457 27.9411 80.427C28.0388 80.4084 28.1395 80.4139 28.2345 80.4432C28.3296 80.4724 28.416 80.5245 28.4863 80.5948L46.1201 99.0285C46.5162 99.4247 46.9865 99.7389 47.504 99.9533C48.0216 100.168 48.5763 100.278 49.1365 100.278C49.6967 100.278 50.2514 100.168 50.769 99.9533C51.2866 99.7389 51.7568 99.4247 52.1529 99.0285L56.0194 95.1621C56.4155 94.766 56.7298 94.2957 56.9442 93.7781C57.1586 93.2606 57.2689 92.7059 57.2689 92.1456C57.2689 91.5854 57.1586 91.0307 56.9442 90.5132C56.7298 89.9956 56.4155 89.5254 56.0194 89.1292L38.3947 71.5954Z"
                    fill="currentColor"
                  />
                  <path
                    d="M76 67.15C79.3136 67.15 82 69.8028 82 73.075C82 76.3472 79.3136 79 76 79C72.6864 79 70 76.3472 70 73.075C70 69.8028 72.6864 67.15 76 67.15ZM76 0C89.2548 0 100 10.6108 100 23.7C100 32.2502 96.9896 36.6983 89.3036 43.1462C81.5944 49.6136 80 52.5228 80 59.25H72C72 49.4777 75.148 44.6555 84.124 37.1257C90.1916 32.0353 92 29.3633 92 23.7C92 14.9739 84.8364 7.9 76 7.9C67.1634 7.9 60 14.9739 60 23.7V27.65H52V23.7C52 10.6108 62.7452 0 76 0Z"
                    fill="currentColor"
                  />
                </Icon>
              }
              onClick={() => {
                dispatch(toggleSpectrumQueryMode());
              }}
            />
          ) : null}
          <Heading
            id={`layer-switch-heading-${config.layerId}`}
            color="gray.700"
            size="sm"
            padding="1"
            textShadow="2px 2px 5px rgb(255 255 255 / 40%), 5px 5px 6px rgb(255 255 255 / 30%)"
            textAlign={'right'}
            onClick={() => handleToggleLayer()}
            cursor="pointer"
            whiteSpace="nowrap"
          >
            {config.name}
          </Heading>
          <Switch
            id={`layer-switch-${config.layerId}`}
            size="sm"
            mr="2"
            colorScheme="brand"
            isChecked={isLayerOn}
            invert="true"
            onChange={() => handleToggleLayer()}
          ></Switch>
        </Flex>
      </Box>
      <Box alignSelf="start">
        {config.filters && config.filters.length > 0 ? (
          <IconButton
            id={`${config.name}-filter-trigger-button`}
            alignSelf="start"
            gridColumnStart={2}
            size="sm"
            aria-label={`Expand ${config.name} Filters`}
            title={`Expand ${config.name} Filters`}
            icon={<VscFilter size="1.2em" />}
            onClick={() => {
              dispatch(
                toggleLayerFiltersExpanded({
                  layerKey: config.layerId,
                })
              );
            }}
            disabled={!hasInteractiveActiveParts}
          />
        ) : null}
      </Box>
      <Box alignSelf="start">
        {config.metrics && config.metrics.length > 1 ? (
          <IconButton
            id={`${config.name}-filter-color-trigger-button`}
            gridColumnStart={3}
            size="sm"
            aria-label={`Expand ${config.name} Controls`}
            title={`Expand ${config.name} Controls`}
            icon={<IoColorPalette size="1.4em" />}
            onClick={() => {
              dispatch(toggleLayerMetricsExpanded({ layerKey: config.layerId }));
            }}
          />
        ) : null}
      </Box>
      {config.filters && config.filters.length > 0 ? (
        <LayerControlFilters layerKey={config.layerId} filters={config.filters} />
      ) : null}
      {config.metrics && config.metrics.length > 0 ? (
        <LayerControlMetricsDrawer
          layerKey={config.layerId}
          metrics={config.metrics}
          neutralHostSettings={config.neutralHostSettings}
          displayFilter={config.filters && config.filters.length ? true : false}
        />
      ) : null}
    </>
  );
};

export default LayerControl;
