import { QueryObserverOptions, useQueries } from '@tanstack/react-query';
import centroid from '@turf/centroid';
import { feature, polygon } from '@turf/helpers';
import { Feature, Point, Properties } from '@turf/turf';
import type { Geometry } from 'geojson';
import isEqual from 'lodash/isEqual';
import { getColorFromScale } from '../helpers/ColorScales';
import { getBBox2d } from '../helpers/MapHelpers';
import booleanContains from '../helpers/booleanContains';
//import { isValueValid } from '../helpers/isValueValid';
import { useLayerSettingsSelector } from '../hooks/useLayersSettings';
import { LayerKey } from '../reducers/layersSlice.types';
import {
  BuildingLayerProperties,
  BuildingLayerResponse,
  GetBuildingLayerOptions,
  GetBuildingLayerParams,
  RawBuildingLayer,
  RawBuildingLayerResponse,
} from './buildingAdditionalLayerClient.types';
import dwAxiosClient from './dwAxiosClient';

const addAdditionalProperties = (
  buildingSalesProperties: RawBuildingLayer,
  buildingLocation: Feature<Point, Properties>,
  layerKey: LayerKey
) => {
  let stringifyLayerKey = 'gridSales';
  if (JSON.stringify(layerKey).includes('commecial')) {
    stringifyLayerKey = 'gridCommercial';
  }
  const rsrpMeanColor = getColorFromScale(
    stringifyLayerKey,
    'rsrpMean',
    buildingSalesProperties.rsrpMean
  );
  const rsrp25Color = getColorFromScale(
    stringifyLayerKey,
    'rsrp25',
    buildingSalesProperties.rsrp25
  );
  const rsrqMeanColor = getColorFromScale(
    stringifyLayerKey,
    'rsrqMean',
    buildingSalesProperties.rsrqMean
  );

  const rsrq25Color = getColorFromScale(
    stringifyLayerKey,
    'rsrq25',
    buildingSalesProperties.rsrqMean
  );

  const qosCqiMeanColor = getColorFromScale(
    stringifyLayerKey,
    'qosCqiMean',
    buildingSalesProperties.rsrqMean
  );

  const centroidLonLat = buildingLocation.geometry.coordinates
    .slice()
    .reverse()
    .map((v) => v.toFixed(5))
    .join(', ');

  const properties: BuildingLayerProperties = {
    ...buildingSalesProperties,
    locationLatLon: centroidLonLat,
    rsrpMeanColor,
    rsrp25Color,
    rsrqMeanColor,
    rsrq25Color,
    qosCqiMeanColor,
  };

  return properties;
};

const processGeoJson = (
  data: RawBuildingLayerResponse,
  { west, south, north, east }: { west: number; south: number; north: number; east: number },
  layerKey: LayerKey
) => {
  const buildingFeatures = data.features.reduce(
    (featuresAcc: Feature<Geometry, BuildingLayerProperties>[], building) => {
      if (building === null) {
        return featuresAcc;
      }

      const buildingGeometry = building.geometry;

      // TODO: Work out what to do about GeometryCollections..
      if (buildingGeometry && buildingGeometry.type !== 'GeometryCollection') {
        // Check if the centroid is inside the box. Exclude the building if it's not.
        const boxGeometry = polygon([
          [
            [west, south],
            [west, north],
            [east, north],
            [east, south],
            [west, south],
          ],
        ]);

        const buildingCentroid = centroid(buildingGeometry);
        const doesContain = booleanContains(boxGeometry, buildingCentroid);
        if (!doesContain) {
          return featuresAcc;
        }

        const properties = addAdditionalProperties(building.properties, buildingCentroid, layerKey);

        const buildingFeature = feature(buildingGeometry, properties, {
          id: building.id,
        });

        featuresAcc.push(buildingFeature);
      }

      return featuresAcc;
    },
    []
  );

  return buildingFeatures;
};

const getBuildingsByBox = async (
  params: GetBuildingLayerParams,
  options: GetBuildingLayerOptions
) => {
  const { west, south, east, north, countryCode, providerName, layerKey } = params;

  const { signal } = options;

  if (countryCode === null || providerName === null) {
    return [] as Feature<Geometry, BuildingLayerProperties>[];
  }
  let periodType = 'day';
  let analysisTier = 'sales';
  if (JSON.stringify(layerKey).toLowerCase().includes('commercial')) {
    periodType = 'month';
    analysisTier = 'commercial';
  }

  const { data, request } = await dwAxiosClient.get<RawBuildingLayerResponse>(
    `ResultBuildingCDM/GCP/Box/${west}/${south}/${east}/${north}`,
    {
      // TODO: remove this when the API is updated
      baseURL: 'https://api-2059.denseware.net/',
      params: { countryCode, providerName, periodType: periodType, analysisTier: analysisTier },
      signal,
      headers: {
        Accept: 'application/geo+json',
      },
    }
  );

  if (data && request.status === 200) {
    return processGeoJson(data, { west, south, east, north }, layerKey);
  }

  return [] as Feature<Geometry, BuildingLayerProperties>[];
};

const useBuildingAdditionalLayerQueryClient = ({
  layerKey,
  providerName,
  countryCode,
  enabled,
}: {
  layerKey: LayerKey;
  providerName: string | null;
  countryCode: string | null;
  enabled: boolean;
}) => {
  const chunks = useLayerSettingsSelector(layerKey, 'visibleChunks', [], isEqual);

  const queries = chunks
    ? chunks.map((chunk) => {
        const chunk2d = chunk ? getBBox2d(chunk) : [];
        const [west, south, east, north] = chunk2d;

        const query: QueryObserverOptions<BuildingLayerResponse | null> = {
          queryKey: [
            'buildingAdditionalLayer' + layerKey,
            countryCode,
            providerName,
            chunk2d.join('|'),
          ],
          queryFn: ({ signal }: any) => {
            return getBuildingsByBox(
              {
                west: west,
                south: south,
                east: east,
                north: north,
                countryCode,
                providerName,
                layerKey,
              },
              { signal }
            );
          },
          staleTime: 1000 * 60 * 60 * 5,
          enabled,
        };

        return query;
      })
    : [];

  return useQueries({ queries });
};

export { useBuildingAdditionalLayerQueryClient };
