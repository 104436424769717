import tooltipText from '../config/tooltipTexts';
import twentififthPercentile from '../images/icons/first_percentile.png';
import { rsrpNHScale, rssnrNHScale } from './layerConfig.scales';
import { LayerConfig } from './layerConfig.types';

const gridsNeutralHostConfig: LayerConfig = {
  name: 'Grids Neutral Host',
  boundingBoxWkt: null,
  description: 'Grids',
  // layerId: 'grids',
  layerId: 'gridsNh',
  visibleFromUtc: '2000-01-01T00:00:00Z',
  visibleToUtc: '2099-01-01T00:00:00Z',
  zoomFrom: 0,
  zoomTo: 24,
  defaultSelectedMetric: 'rssnr25Percentile',
  defaultLayerOn: true,
  layerParts: [
    {
      from: 0,
      to: 14,
      type: 'tile',
      tilePlotType: 'GNH',
    },
    {
      from: 14,
      to: 24,
      type: 'geojson',
      layerType: 'fill',
      layerChunkLevel: 15,
      isInteractive: true,
    },
  ],
  metrics: [
    {
      name: 'RSSNR 25th Percentile',
      tileMetricCode: 'NHSNR',
      polygonMetricCode: 'rssnr25Percentile',
      scale: rssnrNHScale,
      description: tooltipText.rssnr25,
      descriptionImageUrl: twentififthPercentile,
    },
    {
      name: 'RSRP 25th Percentile',
      tileMetricCode: 'NHRSP',
      polygonMetricCode: 'rsrp25Percentile',
      scale: rsrpNHScale,
      description: tooltipText.rsrp25,
      descriptionImageUrl: twentififthPercentile,
    },
  ],
  neutralHostSettings: [
    {
      type: 'threshold',
      name: 'RSSNR 25th Percentile Threshold',
      metricCode: 'rssnr25Percentile',
      intervals: [
        { value: -6 },
        { value: -5 },
        { value: -4 },
        { value: -3 },
        { value: -2 },
        { value: -1 },
        { value: 0 },
        { value: 1 },
        { value: 2 },
        { value: 3 },
        { value: 4 },
        { value: 5 },
      ],
      defaultValue: 0,
    },
    {
      type: 'threshold',
      name: 'RSRP 25th Percentile Threshold',
      metricCode: 'rsrp25Percentile',
      intervals: [
        { value: -124 },
        { value: -120 },
        { value: -116 },
        { value: -112 },
        { value: -108 },
        { value: -104 },
        { value: -100 },
      ],
      defaultValue: -108,
    },
  ],
  details: [
    {
      sectionTitle: 'Grid Information',
      sectionKey: 'building-information',
      items: [
        {
          type: 'grid',
          itemKey: 'grid-information-table',
          rows: [
            {
              rowText: 'Grid Cell ID',
              rowKey: 'gridCellId',
              metricName: 'gridCellId',
              showColor: false,
              format: 'text',
              enableCopy: true,
            },
            {
              rowText: 'Grid Cell Lat / Lon',
              rowKey: 'gridLatLon',
              metricName: 'centroidLonLat',
              showColor: false,
            },
            {
              rowText: 'Area (m<sup>2</sup>)',
              rowKey: 'area',
              metricName: 'areaM2',
              showColor: false,
            },
          ],
        },
      ],
    },
    {
      sectionTitle: 'Network Performance',
      sectionKey: 'network-performance',
      items: [
        {
          type: 'table',
          itemKey: 'network-performance-table',
          header: {
            headerLabel: 'Providers',
            metricName: 'providers',
          },
          rows: [
            {
              rowText: 'RSRP 25th Percentile',
              rowKey: 'rsrp25Percentile',
              metricName: 'rsrp25Percentile',
              description: tooltipText.rssnr25,
              descriptionImageUrl: twentififthPercentile,
              format: 'thousands',
              decimalPlaces: 0,
            },
            {
              rowText: 'RSSNR 25th Percentile',
              rowKey: 'rssnr25Percentile',
              metricName: 'rssnr25Percentile',
              description: tooltipText.rsrp25,
              descriptionImageUrl: twentififthPercentile,
              format: 'thousands',
              decimalPlaces: 1,
            },
          ],
        },
      ],
    },
  ],
};

export default gridsNeutralHostConfig;
