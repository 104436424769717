import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type ModalsState = {
  isExportDrawerOpen: boolean;
  isNodesModalOpen: boolean;
  isAddressAnalysisModalOpen: boolean;
  isBuildingEditOpen: boolean;
  isRecentlyUpdatedBuildingModalOpen: boolean;
};

const initialState: ModalsState = {
  isExportDrawerOpen: false,
  isNodesModalOpen: false,
  isAddressAnalysisModalOpen: false,
  isBuildingEditOpen: false,
  isRecentlyUpdatedBuildingModalOpen: false,
};

export const modalSlice = createSlice({
  name: 'modalSlice',
  initialState,
  reducers: {
    setExportDrawerOpen: (state, action: PayloadAction<ModalsState['isExportDrawerOpen']>) => {
      state.isExportDrawerOpen = action.payload;
    },
    setNodesModalOpen: (state, action: PayloadAction<ModalsState['isNodesModalOpen']>) => {
      state.isNodesModalOpen = action.payload;
    },
    setAddressAnalysisModalOpen: (
      state,
      action: PayloadAction<ModalsState['isAddressAnalysisModalOpen']>
    ) => {
      state.isAddressAnalysisModalOpen = action.payload;
    },
    setBuildingEditOpen: (state, action: PayloadAction<ModalsState['isBuildingEditOpen']>) => {
      state.isBuildingEditOpen = action.payload;
    },
    setRecentlyUpdatedBuildingModalOpen: (
      state,
      action: PayloadAction<ModalsState['isRecentlyUpdatedBuildingModalOpen']>
    ) => {
      state.isRecentlyUpdatedBuildingModalOpen = action.payload;
    },
  },
});

const { actions, reducer } = modalSlice;

export const {
  setExportDrawerOpen,
  setNodesModalOpen,
  setAddressAnalysisModalOpen,
  setBuildingEditOpen,
  setRecentlyUpdatedBuildingModalOpen,
} = actions;

export default reducer;
