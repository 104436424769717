import { FC, ReactElement } from 'react';
import PositionedContainer from './PositionedContainer';

type BottomCenterProps = {
  children: ReactElement;
};

const BottomCenter: FC<BottomCenterProps> = (props) => {
  const { children } = props;
  return (
    <PositionedContainer
      paddingTop={0}
      gridColumn="4 / span 6"
      gridRow="1 / 3"
      children={children}
    />
  );
};

export default BottomCenter;
