import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ViewState } from 'react-map-gl';
import { defaultMapStyleUrl } from '../config/mapStyles';

type MapSettingsState = {
  mapStyleUrl: string;
  viewState: ViewState & { viewport: number[][] };
  selectedFeatureId: number | string | null;
  queriedFeatureIds: Array<number | string> | null;
  markerLocation: Array<number> | null;
  isSpectrumQueryMode: boolean;
};

const initialState: MapSettingsState = {
  mapStyleUrl: defaultMapStyleUrl(),
  viewState: {
    bearing: 0,
    latitude: 0,
    longitude: 0,
    pitch: 0,
    zoom: 8,
    padding: { top: 0, bottom: 0, left: 0, right: 0 },
    viewport: [
      [0, 0],
      [0, 0],
    ],
  },
  selectedFeatureId: null,
  queriedFeatureIds: null,
  markerLocation: null,
  isSpectrumQueryMode: false,
};

const mapSettings = createSlice({
  name: 'mapSettings',
  initialState,
  reducers: {
    setMapStyleUrl: (state, action: PayloadAction<string>) => {
      state.mapStyleUrl = action.payload;
    },
    setMapViewState: (state, action: PayloadAction<MapSettingsState['viewState']>) => {
      state.viewState = action.payload;
    },
    setSelectedFeatureId: (state, action: PayloadAction<MapSettingsState['selectedFeatureId']>) => {
      state.selectedFeatureId = action.payload;
    },
    clearSelectedFeatureId: (state) => {
      state.selectedFeatureId = null;
    },
    setQueriedFeatureIds: (state, action: PayloadAction<MapSettingsState['queriedFeatureIds']>) => {
      state.queriedFeatureIds = action.payload;
    },
    clearQueriedFeatureIds: (state) => {
      state.queriedFeatureIds = null;
    },
    setMarkerLocation: (state, action: PayloadAction<MapSettingsState['markerLocation']>) => {
      state.markerLocation = action.payload;
    },
    clearMarkerLocation: (state) => {
      state.markerLocation = null;
    },
    toggleSpectrumQueryMode: (state) => {
      state.isSpectrumQueryMode = !state.isSpectrumQueryMode;
    },
    clearSpectrumQueryMode: (state) => {
      state.isSpectrumQueryMode = false;
    },
  },
});

const { actions, reducer } = mapSettings;

export const {
  setMapStyleUrl,
  setMapViewState,
  setSelectedFeatureId,
  clearSelectedFeatureId,
  setQueriedFeatureIds,
  clearQueriedFeatureIds,
  setMarkerLocation,
  clearMarkerLocation,
  toggleSpectrumQueryMode,
  clearSpectrumQueryMode,
} = actions;

export default reducer;
