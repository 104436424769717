import { useMap } from 'react-map-gl';
import { Logger } from '../helpers/Logger';
import { isLayerAddedToMap } from '../helpers/MapHelpers';
import { makeLayerId, makeLayerIdGrey, makeLayerIdTiles } from '../helpers/layerIdGenerators';
import useLayersClient from '../services/LayersClient';

export const useGetBeforeLayerId = (layerId: string | number): string | undefined => {
  const { 'dw-main-map': map } = useMap();

  const layersClient = useLayersClient();

  const { data: layerConfig } = layersClient;

  const layerOrderWithGreyAndTiles = layerConfig
    ? layerConfig.flatMap((l) => [
        makeLayerId(l.layerId),
        makeLayerIdGrey(l.layerId),
        makeLayerIdTiles(l.layerId),
      ])
    : [];

  const layerOrder = ['waterway-label', ...layerOrderWithGreyAndTiles];

  if (map) {
    const onLayersInOrder = layerOrder.filter((l) => {
      const layerOn = isLayerAddedToMap(l, map);
      const isSame = l === layerId;

      return layerOn || isSame;
    });

    const layerPosition = onLayersInOrder.findIndex((l) => l === layerId);

    if (layerPosition > 0) {
      const beforeLayerIndex = layerPosition - 1;

      if (beforeLayerIndex >= 0) {
        const beforeLayerId = onLayersInOrder[beforeLayerIndex];

        const isBeforeLayerAdded = isLayerAddedToMap(beforeLayerId, map);

        if (isBeforeLayerAdded) {
          return beforeLayerId.toString();
        }
      }
    }
  } else {
    Logger.warn('No map parameter provided');
  }

  return undefined;
};
