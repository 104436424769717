import {
  Box,
  Divider,
  Flex,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Radio,
  RadioGroup,
  VStack,
} from '@chakra-ui/react';
import MapboxDraw, { DrawCreateEvent, DrawUpdateEvent } from '@mapbox/mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { QueryObserverOptions, useQueries } from '@tanstack/react-query';
import { featureCollection, kinks, LineString, Point, Polygon } from '@turf/turf';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { BsDashLg, BsLayersHalf, BsPlusLg } from 'react-icons/bs';
import { FaRegTrashCan } from 'react-icons/fa6';
import { TbShapeOff } from 'react-icons/tb';
import { Layer, Map, MapRef, Source, useMap } from 'react-map-gl';
import { GeoJSONPolygon, stringify as wktStringify } from 'wellknown';
import { getBBox2d } from '../../helpers/MapHelpers';
import { useAppSelector } from '../../hooks/useAppSelector';
import useChunkArea from '../../hooks/useChunkArea';
import { fetchGenericGeo, GenericResultResponse } from '../../services/genericClient';
import MapOverlayButton from '../MapOverlayButton';
import { useBuildingEditContext } from './BuildingEditContext';

const isAllowedShape = (
  shape: GeoJSON.Feature
): shape is GeoJSON.Feature<
  GeoJSON.LineString | GeoJSON.MultiLineString | GeoJSON.Polygon | GeoJSON.MultiPolygon
> => {
  return ['LineString', 'MultiLineString', 'Polygon', 'MultiPolygon'].includes(shape.geometry.type);
};

const useDrawMode = (map: MapRef | undefined) => {
  const buildingEditContextActions = useBuildingEditContext()[1];

  const { current: draw } = useRef(
    new MapboxDraw({
      defaultMode: 'draw_polygon',
      userProperties: true,
      controls: {
        point: false,
        combine_features: false,
        line_string: false,
        polygon: false,
        trash: false,
        uncombine_features: false,
      },
      styles: [
        // ACTIVE (being drawn)
        // line stroke
        {
          id: 'gl-draw-line',
          type: 'line',
          filter: ['all', ['==', '$type', 'LineString'], ['!=', 'mode', 'static']],
          layout: {
            'line-cap': 'round',
            'line-join': 'round',
          },
          paint: {
            'line-color': '#ffae35',
            'line-dasharray': [1, 3],
            'line-width': 3,
          },
        },
        // polygon fill
        {
          id: 'gl-draw-polygon-fill',
          type: 'fill',
          filter: ['all', ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
          paint: {
            'fill-color': '#ffae35',
            'fill-outline-color': '#ffae35',
            'fill-opacity': 0.3,
          },
        },
        {
          id: 'gl-draw-polygon-fill-active',
          type: 'fill',
          filter: [
            'all',
            ['==', 'active', 'true'],
            ['==', '$type', 'Polygon'],
            ['==', 'mode', 'simple_select'],
          ],
          paint: {
            'fill-color': '#0000ff',
            'fill-outline-color': '#ffae35',
            'fill-opacity': 0.3,
          },
        },
        {
          id: 'gl-draw-polygon-fill-active',
          type: 'fill',
          filter: [
            'all',
            ['==', 'active', 'true'],
            ['==', '$type', 'Polygon'],
            ['==', 'mode', 'direct_select'],
          ],
          paint: {
            'fill-color': '#00ff00',
            'fill-outline-color': '#ffae35',
            'fill-opacity': 0.3,
          },
        },
        // polygon mid points halos
        {
          id: 'gl-draw-polygon-midpoint-halos',
          type: 'circle',
          filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'midpoint']],
          paint: {
            'circle-radius': 5,
            'circle-color': '#FFF',
          },
        },
        // polygon mid points
        {
          id: 'gl-draw-polygon-midpoint',
          type: 'circle',
          filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'midpoint']],
          paint: {
            'circle-radius': 4,
            'circle-color': '#ffae35',
          },
        },
        // polygon outline stroke
        // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
        {
          id: 'gl-draw-polygon-stroke-active',
          type: 'line',
          filter: ['all', ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
          layout: {
            'line-cap': 'round',
            'line-join': 'round',
          },
          paint: {
            'line-color': '#ffae35',
            'line-dasharray': [1, 3],
            'line-width': 3,
          },
        },
        // vertex point halos
        {
          id: 'gl-draw-polygon-and-line-vertex-halo-active',
          type: 'circle',
          filter: [
            'all',
            ['==', 'meta', 'vertex'],
            ['==', '$type', 'Point'],
            ['!=', 'mode', 'static'],
          ],
          paint: {
            'circle-radius': 7,
            'circle-color': '#FFF',
          },
        },
        // vertex points
        {
          id: 'gl-draw-polygon-and-line-vertex-active',
          type: 'circle',
          filter: [
            'all',
            ['==', 'meta', 'vertex'],
            ['==', '$type', 'Point'],
            ['!=', 'mode', 'static'],
          ],
          paint: {
            'circle-radius': 5,
            'circle-color': '#ffae35',
          },
        },
        // Active Vertexes
        {
          id: 'gl-draw-polygon-and-line-vertex-selected',
          type: 'circle',
          filter: [
            'all',
            ['==', 'meta', 'vertex'],
            ['==', '$type', 'Point'],
            ['==', 'active', 'true'],
          ],
          paint: {
            'circle-radius': 5,
            'circle-color': '#0000ff',
          },
        },
        // Style for invalid shape
        {
          id: 'gl-draw-line-invalid',
          type: 'line',
          filter: ['all', ['==', '$type', 'LineString'], ['has', 'user_invalid']],
          layout: {
            'line-cap': 'round',
            'line-join': 'round',
          },
          paint: {
            'line-color': '#ff0000',
            'line-dasharray': [1, 3],
            'line-width': 3,
          },
        },
        {
          id: 'gl-draw-polygon-fill-invalid',
          type: 'fill',
          filter: ['all', ['==', '$type', 'Polygon'], ['has', 'user_invalid']],
          paint: {
            'fill-color': '#ff0000',
            'fill-outline-color': '#ff0000',
            'fill-opacity': 0.3,
          },
        },
        {
          id: 'gl-draw-polygon-stroke-active-invalid',
          type: 'line',
          filter: ['all', ['==', '$type', 'Polygon'], ['has', 'user_invalid']],
          layout: {
            'line-cap': 'round',
            'line-join': 'round',
          },
          paint: {
            'line-color': '#ff0000',
            'line-dasharray': [1, 3],
            'line-width': 3,
          },
        },
      ],
    })
  );

  const onFeatureChange = useCallback(
    (feature: GeoJSON.Feature) => {
      if (isAllowedShape(feature)) {
        const kinksCollection = kinks(feature);
        const numberOfKinks = kinksCollection.features.length;
        const isKinked = numberOfKinks > 0;

        // If it has kinks mark it invalid
        if (isKinked && feature.id) {
          draw.setFeatureProperty(feature.id.toString(), 'invalid', true);
        }

        // If it has kinks set the boundary error
        if (buildingEditContextActions && isKinked) {
          buildingEditContextActions.setBoundaryErrors(['Building shape cannot cross itself']);
        }

        // If it's not kinked then clear invalid
        if (!isKinked && feature.id) {
          draw.setFeatureProperty(feature.id.toString(), 'invalid', undefined);
        }
        if (!isKinked && feature.id && buildingEditContextActions) {
          buildingEditContextActions.setBoundaryErrors(null);
        }

        if (buildingEditContextActions) {
          const wkt = wktStringify(feature.geometry as GeoJSONPolygon);
          buildingEditContextActions.setBoundary(wkt);
        }
      }
    },
    [draw, buildingEditContextActions]
  );

  const onCreate = useCallback(
    (e: DrawCreateEvent) => {
      const feature = e.features[0];
      onFeatureChange(feature);
    },
    [onFeatureChange]
  );

  const onUpdate = useCallback(
    (e: DrawUpdateEvent) => {
      const feature = e.features[0];
      onFeatureChange(feature);
    },
    [onFeatureChange]
  );

  const onDelete = useCallback(() => {
    draw.changeMode('draw_polygon');
    if (buildingEditContextActions) {
      buildingEditContextActions.setBoundary(null);
    }
  }, [draw, buildingEditContextActions]);

  const onClearAll = useCallback(() => {
    draw.changeMode('draw_polygon');
    draw.deleteAll();
  }, [draw]);

  const onModechange = useCallback(() => {
    const allFeatures = draw.getAll();

    if (allFeatures.features.length === 0) {
      draw.changeMode('draw_polygon');
    }
  }, [draw]);

  useEffect(() => {
    if (map) {
      map.on('draw.create', onCreate);
      map.on('draw.update', onUpdate);
      map.on('draw.delete', onDelete);
      map.on('draw.modechange', onModechange);
      // clearAll is a custom event. Made up by us
      map.on('draw.clearAll', onClearAll);
    }

    return () => {
      if (map) {
        map.off('draw.create', onCreate);
        map.off('draw.update', onUpdate);
        map.off('draw.delete', onDelete);
        map.off('draw.modechange', onModechange);
        map.off('draw.clearAll', onClearAll);
      }
    };
  }, [map, onCreate, onUpdate, onDelete, onClearAll, onModechange]);

  useEffect(() => {
    if (map) {
      map.addControl(draw);
    }

    return () => {
      map?.removeControl(draw);
    };
  }, [map, draw]);

  return draw;
};

const BuildingEditMap: FC = () => {
  const { 'building-edit-map': map } = useMap();
  const viewState = useAppSelector((state) => state.mapSettings.viewState);
  const providerName = useAppSelector((s) => s.contextSettings.selectedProviderName);
  const countryCode = useAppSelector((s) => s.contextSettings.selectedCountryCode);

  const [mapStyleUrl, setMapStyleUrl] = useState('mapbox://styles/mapbox/satellite-streets-v11');

  const [viewport, setViewport] = useState<number[][] | null>(
    map ? map.getBounds().toArray() : null
  );
  useEffect(() => {
    if (map) {
      setViewport(map.getBounds().toArray());
    }
  }, [map]);

  const isMoving = map ? map.isMoving() : true;

  const { extents } = useChunkArea(
    isMoving ? null : viewport,
    15,
    14 <= (map ? map.getZoom() : 20)
  );

  const buildingOutlineResponses = useQueries({
    queries: extents
      ? extents.map((chunk) => {
          const newChunk = getBBox2d(chunk);
          const [west, south, east, north] = newChunk;

          const query: QueryObserverOptions<GenericResultResponse | null> = {
            queryKey: ['-1', countryCode, providerName, newChunk.join('|')],
            queryFn: (queryFnOptions) => {
              const { signal } = queryFnOptions;

              return fetchGenericGeo(
                {
                  west,
                  south,
                  east,
                  north,
                  countryCode,
                  providerName,
                  layerId: '-1',
                },
                { signal }
              );
            },
          };

          return query;
        })
      : [],
  });

  const features = buildingOutlineResponses.reduce((acc: GenericResultResponse, r) => {
    if (r.isSuccess && r.data) {
      acc = [...acc, ...r.data];
    }

    return acc;
  }, []);

  const data = featureCollection<Polygon | Point | LineString>(features);

  const draw = useDrawMode(map);

  return (
    <Box position="relative">
      <Map
        id="building-edit-map"
        reuseMaps
        onMoveEnd={(e) => {
          setViewport(e.target.getBounds().toArray());
        }}
        customAttribution="denseWare | &copy; Dense Air"
        initialViewState={viewState}
        mapStyle={mapStyleUrl}
        mapboxAccessToken={import.meta.env.VITE_APP_MAPBOX_TOKEN}
      >
        <Source id="buildings-outline" type="geojson" data={data} />
        <Layer
          source="buildings-outline"
          beforeId="waterway-label"
          type="fill"
          paint={{
            'fill-color': ['rgb', 100, 100, 100],
            'fill-opacity': 0.9,
            'fill-outline-color': ['rgb', 200, 200, 200],
          }}
        />
      </Map>
      <VStack position="absolute" top="10px" left="10px">
        <Popover trigger="hover" placement="top-start" id={'map-layer-select'}>
          <PopoverTrigger>
            {/* Needs to be wrapped in a box to handle the forwardRef */}
            <Box>
              <MapOverlayButton>
                <BsLayersHalf color="white" size="1.4em" />
              </MapOverlayButton>
            </Box>
          </PopoverTrigger>
          <PopoverContent zIndex={100}>
            <Box padding={2}>
              <RadioGroup
                value={mapStyleUrl}
                onChange={(e) => {
                  setMapStyleUrl(e);
                }}
              >
                <VStack>
                  {[
                    { name: 'Streets', url: 'mapbox://styles/mapbox/streets-v11' },
                    { name: 'Satellite', url: 'mapbox://styles/mapbox/satellite-streets-v11' },
                  ].map((style, i) => {
                    return (
                      <Radio
                        id={`${style.name}-radio-button`}
                        key={`tilebutton${i}`}
                        value={style.url}
                      >
                        {style.name}
                      </Radio>
                    );
                  })}
                </VStack>
              </RadioGroup>
              <Divider />
            </Box>
          </PopoverContent>
        </Popover>
        <Flex id={'building-edit-map-zoom-controls'}>
          <VStack alignItems="stretch" spacing={0}>
            <MapOverlayButton
              id={'building-edit-map-zoom-in-button'}
              borderBottomRadius={0}
              onClick={() => {
                const zoomLevel = map?.getZoom();

                if (zoomLevel) {
                  map?.zoomTo(Math.round(zoomLevel) + 1);
                } else {
                  map?.zoomIn({});
                }
              }}
            >
              <BsPlusLg color="white" />
            </MapOverlayButton>
            <MapOverlayButton
              id={`building-edit-map-zoom-out-button`}
              borderTopRadius={0}
              onClick={() => {
                map?.zoomOut();
              }}
            >
              <BsDashLg color="white" />
            </MapOverlayButton>
          </VStack>
        </Flex>
        <MapOverlayButton
          id={'building-edit-map-trash-button'}
          onClick={() => {
            draw.trash();
          }}
        >
          <FaRegTrashCan color="white" size="1.2em" />
        </MapOverlayButton>
        <MapOverlayButton
          id={'building-edit-map-clear-button'}
          onClick={() => {
            draw.deleteAll().changeMode('draw_polygon');
          }}
        >
          <TbShapeOff color="white" size="1.2em" />
        </MapOverlayButton>
      </VStack>
    </Box>
  );
};

export default BuildingEditMap;
