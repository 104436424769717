import { FC, useMemo } from 'react';
import { makeSourceId } from '../../helpers/layerIdGenerators';
import useLayersClient from '../../services/LayersClient';
import MapLayer from '../MapLayer';
import useLayerClickHandler from './MapLayers.hooks';

type MapLayersProps = {};

const MapLayers: FC<MapLayersProps> = () => {
  const layersClient = useLayersClient();

  const { data: layerConfig } = layersClient;

  const dwLayerSources = useMemo(
    () => (layerConfig ? layerConfig.map((config) => makeSourceId(config.layerId)) : []),
    [layerConfig]
  );

  useLayerClickHandler(dwLayerSources);

  if (layerConfig) {
    return (
      <>
        {layerConfig.map((config) => {
          return (
            <MapLayer
              key={config.layerId}
              layerKey={config.layerId}
              layerParts={config.layerParts}
              layerFilters={config.filters}
            />
          );
        })}
      </>
    );
  }

  return null;
};

export default MapLayers;
