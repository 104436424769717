import camera_orange from '../images/icons/camera_orange.png';
import cell_green from '../images/icons/cell_green.png';
import cell_grey from '../images/icons/cell_grey.png';
import cell_red from '../images/icons/cell_red.png';
import comment_blue from '../images/icons/comment_blue.png';
import marker from '../images/icons/marker.png';
import rss_blue from '../images/icons/rss_blue.png';
import rss_orange from '../images/icons/rss_orange.png';
import rss_purple from '../images/icons/rss_purple.png';
import rss_red from '../images/icons/rss_red.png';
import smallCell4g from '../images/icons/small_cell_4g.png';
import smallCell4gGrey from '../images/icons/small_cell_4g_grey.png';
import smallCell4gSelected from '../images/icons/small_cell_4g_selected.png';
import smallCell5g from '../images/icons/small_cell_5g.png';
import smallCell5gGrey from '../images/icons/small_cell_5g_grey.png';
import smallCell5gSelected from '../images/icons/small_cell_5g_selected.png';
import trafficLightDarkPurple from '../images/icons/traffic-light_darkpurple.png';
import trash_cadetblue from '../images/icons/trash_cadetblue.png';
import trash_darkgreen from '../images/icons/trash_darkgreen.png';
import trash_green from '../images/icons/trash_green.png';
import trash_orange from '../images/icons/trash_orange.png';
import trash_red from '../images/icons/trash_red.png';
import wifiBlue from '../images/icons/wifi_blue.png';
import wifiDaBrand from '../images/icons/wifi_da_brand.png';
import wifiGray from '../images/icons/wifi_gray.png';

const images: { [imageName: string]: string | undefined } = {
  smallCellBlueIcon: wifiBlue,
  smallCellGrayIcon: wifiGray,
  smallCellBrandIcon: wifiDaBrand,
  smallCell4g,
  smallCell5g,
  smallCell4gSelected,
  smallCell5gSelected,
  smallCell4gGrey,
  smallCell5gGrey,
  camera_orange,
  comment_blue,
  rss_blue,
  rss_orange,
  rss_purple,
  rss_red,
  trash_red,
  trash_orange,
  trash_green,
  trash_cadetblue,
  trash_darkgreen,
  cell_green,
  cell_red,
  cell_grey,
  'traffic-light_darkpurple': trafficLightDarkPurple,
  marker,
};

export default images;
