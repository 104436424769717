import { QueryObserverOptions, useQueries } from '@tanstack/react-query';
import { feature } from '@turf/helpers';
import { Feature, Point } from '@turf/turf';
import isEqual from 'lodash/isEqual';
import { getColorFromScale } from '../helpers/ColorScales';
import { getBBox2d } from '../helpers/MapHelpers';
import { useLayerSettingsSelector } from '../hooks/useLayersSettings';
import { LayerKey } from '../reducers/layersSlice.types';
import dwAxiosClient from './dwAxiosClient';
import {
  GetNQTPassiveParams,
  GetNQTResultsOptions,
  NQTPassiveProperties,
  NQTPassiveResponse,
  RawNQTPassiveResponse,
} from './nqtPassiveClient.types';

const getNQTPassiveResultsByBox = async (
  params: GetNQTPassiveParams,
  options: GetNQTResultsOptions
) => {
  const { bbox, countryCode, providerName, startUtc, endUtc } = params;

  const { signal } = options;

  if (bbox === null || countryCode === null || providerName === null) {
    return [] as Feature<Point, NQTPassiveProperties>[];
  }

  const response = await dwAxiosClient.get<RawNQTPassiveResponse>(
    `/NqtPointsCDM/Collection/Details/Viewport/Pass`,
    {
      baseURL: import.meta.env.VITE_APP_NQT_API_URL,
      params: { countryCode, providerName, bbox: bbox.join(','), startUtc, endUtc },
      signal,
      responseType: 'json',
      headers: { Accept: 'application/geo+json' },
    }
  );

  if (response.status === 200 && response.data) {
    const geoJsonData = response.data.data;

    const featuresWithColors = geoJsonData.features.map((f) => {
      const lte_sinr = f.properties.lte_sinr === 2147483647 ? null : f.properties.lte_sinr;
      const lte_rsrp = f.properties.lte_rsrp === 2147483647 ? null : f.properties.lte_rsrp;

      const nr_ss_sinr = f.properties.nr_ss_sinr === 2147483647 ? null : f.properties.nr_ss_sinr;
      const nr_ss_rsrp = f.properties.nr_ss_rsrp === 2147483647 ? null : f.properties.nr_ss_rsrp;

      const newProperties: NQTPassiveProperties = {
        ...f.properties,
        ...(f.id && { originalId: f.id }),
        lte_sinr,
        lte_rsrp,
        nr_ss_sinr,
        nr_ss_rsrp,
        lte_sinrColor: getColorFromScale('nqtPassive', 'lte_sinr', lte_sinr),
        lte_rsrpColor: getColorFromScale('nqtPassive', 'lte_rsrp', lte_rsrp),
        nr_ss_sinrColor: getColorFromScale('nqtPassive', 'nr_ss_sinr', nr_ss_sinr),
        nr_ss_rsrpColor: getColorFromScale('nqtPassive', 'nr_ss_rsrp', nr_ss_rsrp),
        pciColor: getColorFromScale('nqtPassive', 'pci', f.properties.pci),
      };

      const featureWithColor = feature(f.geometry, newProperties, { id: f.id });

      return featureWithColor;
    });

    return featuresWithColors;
  }

  return [] as Feature<Point, NQTPassiveProperties>[];
};

const useNQTPassiveQueryClient = ({
  layerKey,
  providerName,
  countryCode,
  startUtc,
  endUtc,
  enabled,
}: {
  layerKey: LayerKey;
  providerName: string | null;
  countryCode: string | null;
  startUtc: Date;
  endUtc: Date;
  enabled: boolean;
}) => {
  const chunks = useLayerSettingsSelector(layerKey, 'visibleChunks', [], isEqual);

  const queries = chunks
    ? chunks.map((chunk) => {
        const chunk2d = chunk ? getBBox2d(chunk) : null;
        const queryKey = [
          'nqtPassive',
          countryCode,
          providerName,
          chunk2d ? chunk2d.join('|') : null,
        ];

        const query: QueryObserverOptions<NQTPassiveResponse | null> = {
          queryKey,
          queryFn: ({ signal }) => {
            return getNQTPassiveResultsByBox(
              {
                bbox: chunk2d,
                countryCode,
                providerName,
                startUtc,
                endUtc,
              },
              { signal }
            );
          },

          enabled: enabled && !!chunk2d,
          staleTime: 1000 * 60 * 60 * 5,
          retry: 3,
        };

        return query;
      })
    : [];

  return useQueries({ queries });
};

export { useNQTPassiveQueryClient };
