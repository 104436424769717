import { MapMouseEvent } from 'mapbox-gl';
import { useEffect, useRef } from 'react';
import { useMap } from 'react-map-gl';
import { getFeatureId } from '../../helpers/getFeatureId';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { closeOtherDrawersAndOpen } from '../../reducers/drawersSlice';
import {
  clearMarkerLocation,
  setMarkerLocation,
  setQueriedFeatureIds,
  setSelectedFeatureId,
} from '../../reducers/mapSettings';

const useLayerClickHandler = (dwLayerSources: string[]) => {
  const { 'dw-main-map': map } = useMap();
  const dispatch = useAppDispatch();

  const hoveredIdRef = useRef<number | string | undefined>(undefined);
  const hoveredSourceRef = useRef<string | undefined>(undefined);
  const hoveredSourceLayerRef = useRef<string | undefined>(undefined);
  const openDrawer = useAppSelector((state) => state.drawersSlice.openDrawer);

  const isSpectrumQueryMode = useAppSelector((state) => state.mapSettings.isSpectrumQueryMode);

  useEffect(() => {
    if (map) {
      const onClick = (e: MapMouseEvent): void => {
        if (isSpectrumQueryMode) {
          dispatch(setSelectedFeatureId(null));
          const features = map.queryRenderedFeatures(e.point).filter((feature) => {
            return dwLayerSources.includes(feature.source);
          });

          const featureIds = features
            .map((f) => getFeatureId(f, f.layer.id))
            .filter((id): id is string | number => id !== null);

          dispatch(setMarkerLocation(e.lngLat.toArray()));
          dispatch(setQueriedFeatureIds(featureIds));
          dispatch(closeOtherDrawersAndOpen({ drawerKey: `spectrumLicensing` }));
        } else {
          const features = map.queryRenderedFeatures(e.point).filter((feature) => {
            return dwLayerSources.includes(feature.source);
          });

          if (features && features.length > 0) {
            const firstFeature = features[0];
            const layerKey = firstFeature.layer.id;

            if (layerKey.includes('cluster')) {
              const clusterId = firstFeature.properties?.cluster_id;
              const sourceObj = map.getSource(firstFeature.source);
              if (sourceObj.type === 'geojson') {
                sourceObj.getClusterExpansionZoom(clusterId, (err: any, zoom: number) => {
                  if (err) return;

                  if (firstFeature.geometry.type === 'Point') {
                    map.easeTo({
                      center: [
                        firstFeature.geometry.coordinates[0],
                        firstFeature.geometry.coordinates[1],
                      ],
                      zoom: zoom,
                    });
                  }
                });
              }
            } else {
              const selectedFeatureId = getFeatureId(firstFeature, layerKey);

              dispatch(setSelectedFeatureId(selectedFeatureId));
              dispatch(clearMarkerLocation());

              const isOpenDrawerHistoric = openDrawer?.toString().includes('historic');
              if (openDrawer) {
                if (isOpenDrawerHistoric) {
                  dispatch(closeOtherDrawersAndOpen({ drawerKey: `${layerKey}-historic-drawer` }));
                } else {
                  dispatch(closeOtherDrawersAndOpen({ drawerKey: layerKey }));
                }
              } else {
                dispatch(closeOtherDrawersAndOpen({ drawerKey: layerKey }));
              }
            }
          }
        }
      };

      map.on('click', onClick);

      return () => {
        map.off('click', onClick);
      };
    }
  }, [dispatch, map, dwLayerSources, isSpectrumQueryMode, openDrawer]);

  useEffect(() => {
    if (map) {
      const onMouseMove = (e: MapMouseEvent) => {
        const ourFeatures = map.queryRenderedFeatures(e.point).filter((feature) => {
          return dwLayerSources.includes(feature.source);
        });

        if (ourFeatures && ourFeatures.length > 0) {
          // Hovered over something of ours.
          if (isSpectrumQueryMode) {
            map.getCanvas().style.cursor = 'help';
          } else {
            map.getCanvas().style.cursor = 'pointer';
          }

          const topFeature = ourFeatures[0];
          const topFeatureId = topFeature.id;
          const topFeatureSource = topFeature.source;
          const topFeatureSourceLayer = topFeature.sourceLayer;

          if (topFeatureId !== hoveredIdRef.current) {
            // Hovered on something different
            if (hoveredIdRef.current && hoveredSourceRef.current) {
              // There was a previous. Set that to hover: false
              map.setFeatureState(
                {
                  source: hoveredSourceRef.current,
                  sourceLayer: hoveredSourceLayerRef.current,
                  id: hoveredIdRef.current,
                },
                { hover: false }
              );
            }

            if (topFeatureId) {
              // Our feature has an ID.
              // Set that to the previously hovered.
              hoveredIdRef.current = topFeatureId;
              hoveredSourceLayerRef.current = topFeatureSourceLayer;
              hoveredSourceRef.current = topFeatureSource;

              // and set it hover: true
              map.setFeatureState(
                {
                  source: topFeatureSource,
                  sourceLayer: topFeatureSourceLayer,
                  id: topFeatureId,
                },
                { hover: true }
              );
            }
          }
        } else {
          /**
           * We didn't hover on anything of ours.
           * Set cursor back, set prvious to hover:false and clear the previously hovered.
           */

          if (isSpectrumQueryMode) {
            map.getCanvas().style.cursor = 'help';
          } else {
            map.getCanvas().style.cursor = '';
          }

          if (hoveredSourceRef.current && hoveredIdRef.current) {
            map.setFeatureState(
              {
                source: hoveredSourceRef.current,
                sourceLayer: hoveredSourceLayerRef.current,
                id: hoveredIdRef.current,
              },
              { hover: false }
            );
          }

          hoveredIdRef.current = undefined;
          hoveredSourceLayerRef.current = undefined;
          hoveredSourceRef.current = undefined;
        }
      };

      map.on('mousemove', onMouseMove);

      return () => {
        map.off('mousemove', onMouseMove);
      };
    }
  }, [map, dwLayerSources, isSpectrumQueryMode]);

  useEffect(() => {
    if (map) {
      if (isSpectrumQueryMode) {
        map.getCanvas().style.cursor = 'help';
      } else {
        map.getCanvas().style.cursor = '';
      }
    }
  }, [map, isSpectrumQueryMode]);
};

export default useLayerClickHandler;
