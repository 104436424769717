import {
  Box,
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  VStack,
} from '@chakra-ui/react';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../hooks/useAppSelector';
import { PersonalizationModalState, ThresholdTab } from '../../reducers/modalTabSlice';
import NodeCollections from '../PersonalizationModalOptions/NodeCollections';
import Thresholds from '../PersonalizationModalOptions/Thresholds';

type ModalOptions = {
  currentState: boolean;
};

const Personalization: FC<ModalOptions> = ({ currentState }) => {
  const toggleThreshold = useAppSelector((state) => state.modalTabSettings.tabState);
  const dispatch = useDispatch();

  return (
    <Modal
      isOpen={currentState}
      size={'6xl'}
      onClose={() => dispatch(PersonalizationModalState(false))}
    >
      <ModalOverlay />
      <ModalContent p={'34px 20px 35px 20px'} background={'lightGrey'}>
        <ModalCloseButton
          id={'user-profile-modal-close-button'}
          top={0}
          right={0}
          padding={0}
          color={'black'}
        />
        <ModalHeader background={'white'} borderRadius={7} mb={5}>
          <Heading size={'md'}>Personalization</Heading>
        </ModalHeader>
        <ModalBody
          display={'grid'}
          gridTemplateColumns={'1fr 4fr'}
          gap={5}
          background={'white'}
          borderRadius={7}
          p={5}
        >
          <VStack alignItems={'left'} height={'600px'}>
            <Button
              id={'thresholds-tab-open-button'}
              onClick={() => {
                dispatch(ThresholdTab('Threshold'));
              }}
            >
              Thresholds
            </Button>
            <Button
              id={'node-collections-tab-open-button'}
              onClick={() => {
                dispatch(ThresholdTab('NodeCollection'));
              }}
            >
              Node Collections
            </Button>
          </VStack>
          <Box>
            {toggleThreshold === 'Threshold' ? (
              <Thresholds />
            ) : toggleThreshold === 'NodeCollection' ? (
              <NodeCollections />
            ) : null}
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default Personalization;
