import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  InitializeLayerMetaPayload,
  LayersMetaState,
  SetMetaNqtPayload,
} from './layersMetaSlice.types';

const initialState: LayersMetaState = {};

const layersMeta = createSlice({
  name: 'layersMeta',
  initialState,
  reducers: {
    initializeLayerMeta: (state, action: PayloadAction<InitializeLayerMetaPayload>) => {
      const layer = state[action.payload.layerKey];

      if (layer === undefined) {
        state[action.payload.layerKey] = {};
      }
    },
    setLayerMeta: (state, action: PayloadAction<SetMetaNqtPayload>) => {
      const layer = state[action.payload.layerKey];

      if (layer) {
        layer[action.payload.name] = action.payload.value;
      }
    },
  },
});

const { actions, reducer } = layersMeta;

export const { initializeLayerMeta, setLayerMeta } = actions;

export default reducer;
