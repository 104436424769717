import { Box, Flex, Text } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import { FC } from 'react';
import { MetricConfig } from '../../helpers/layerConfig.types';
import useHasActiveInteractiveParts from '../../hooks/useHasActiveInteractiveParts';
import { useIsNeutralHost } from '../../hooks/useIsNeutralHost';
import { useLayerSettingsSelector } from '../../hooks/useLayersSettings';
import FilterLegend from '../FilterLegend';
import BinScale from './BinScale';
import CategoryScale from './CategoryScale';
import ContinuousScale from './ContinuousScale';
import DiscreteScale from './DiscreteScale';
import ThresholdDisplay from './ThresholdDisplay';

type ScaleLegendProps = {
  layerId: string | number;
  layerName: string;
  metrics: MetricConfig[];
  first: boolean;
  last: boolean;
};

const ScaleLegend: FC<ScaleLegendProps> = (props) => {
  const { layerId, layerName, metrics, first, last } = props;

  const selectedMetric = useLayerSettingsSelector(layerId, 'selectedMetric', null);
  const isNeutralHost = useIsNeutralHost();

  const metric = metrics.find((m) => m.polygonMetricCode === selectedMetric);
  const showScaleFromConfig = metric?.showScale ?? true;

  const hasInteractiveActiveParts = useHasActiveInteractiveParts(layerId);

  const hasTiles = metric?.tileMetricCode !== undefined;

  const showScale = showScaleFromConfig && (hasInteractiveActiveParts || hasTiles);

  return (
    <AnimatePresence>
      {metric && showScale && (
        <motion.div
          initial={{ height: 0, opacity: 0 }}
          animate={{ height: 'auto', opacity: 1 }}
          exit={{ height: 0, opacity: 0 }}
          transition={{ type: 'tween', duration: 0.25 }}
        >
          <Flex
            flexDirection="column"
            align="start"
            bg="brand.500"
            p="2"
            {...(first && { borderTopRadius: 'md' })}
            {...(last && !first && { borderBottomRadius: 'md' })}
            {...(last && first && { borderBottomRadius: 'md' })}
          >
            <Box color="white" fontSize="0.75vw" mb="1">
              {layerName}
              <Text as="span" fontSize="0.65vw" ml="4">
                {metric.legendName ? metric.legendName : metric.name}
              </Text>
              {isNeutralHost ? <ThresholdDisplay layerId={layerId} /> : null}
            </Box>
            {hasInteractiveActiveParts ? <FilterLegend layerId={layerId} /> : null}
            {metric.scale.type === 'bin' || metric.scale.type === 'bin-specific' ? (
              <BinScale scale={metric.scale.scale} width={22} />
            ) : null}
            {metric.scale.type === 'continuous' ? (
              <ContinuousScale scale={metric.scale.scale} width={22} />
            ) : null}
            {metric.scale.type === 'discrete' ? (
              <DiscreteScale scale={metric.scale.scale} width={22} />
            ) : null}
            {metric.scale.type === 'category' ? (
              <CategoryScale scale={metric.scale.scale} width={22} />
            ) : null}
          </Flex>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ScaleLegend;
