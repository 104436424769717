import { QueryObserverOptions, useQueries } from '@tanstack/react-query';
import centroid from '@turf/centroid';
import { feature, polygon } from '@turf/helpers';
import { Feature, Point, Properties } from '@turf/turf';
import type { Geometry } from 'geojson';
import { omit as _omit } from 'lodash';
import isEqual from 'lodash/isEqual';
import { getColorFromScale } from '../helpers/ColorScales';
import { getBBox2d } from '../helpers/MapHelpers';
import booleanContains from '../helpers/booleanContains';
import { useLayerSettingsSelector } from '../hooks/useLayersSettings';
import { LayerKey } from '../reducers/layersSlice.types';
import {
  Building5gProperties,
  Buildings5gResponse,
  GetBuilding5gOptions,
  GetBuildings5gParams,
  RawBuilding5g,
  RawBuildings5gResponse,
} from './buildings5GClientNsa.types';
import dwAxiosClient from './dwAxiosClient';

const addAdditionalProperties = (
  buildingProperties: RawBuilding5g,
  buildingLocation: Feature<Point, Properties>,
  featureId: string | number | undefined
) => {
  const flatNsaStats = buildingProperties.measuresNsaJson.reduce(
    (acc: Record<string, number | null>, byBand) => {
      const freqCategory = byBand.frequency_Category;
      const metrics = _omit(byBand, 'frequency_Category');

      Object.entries(metrics).forEach(([metricName, metricValue]) => {
        const key = `Nsa_${freqCategory}_${metricName}`;
        acc[key] = metricValue;
      });

      return acc;
    },
    {}
  );

  const metricList = [
    'csi_rsrp_25',
    'csi_rsrp_mean',
    'csi_rssnr_25',
    'csi_rssnr_mean',
    'measurements',
    'rsrp_25',
    'rsrp_mean',
    'rssnr_25',
    'rssnr_mean',
    'ss_rsrp_25',
    'ss_rsrp_mean',
    'ss_rssnr_25',
    'ss_rssnr_mean',
    'tonnage',
  ];
  const categories = [
    ['All_Bands', 'All_Bands'],
    ['Low_<2.3GHz', 'Low_<23GHz'],
    ['Mid_2.3GHz-4.2GHz', 'Mid_23GHz-42GHz'],
    ['High_>4.2GHz', 'High_>42GHz'],
    ['Other', 'Other'],
  ];

  const nsaProps = categories.reduce(
    (acc: Record<string, null | number | string>, [cat, newCat]) => {
      metricList.forEach((metric) => {
        const accessKeyName = `Nsa_${cat}_${metric}`;
        const value = flatNsaStats[accessKeyName] ?? null;

        const newKeyName = `Nsa_${newCat}_${metric}`;

        acc[newKeyName] = value;
        acc[`${newKeyName}Color`] = getColorFromScale('buildings5gNsa', newKeyName, value);
      });

      return acc;
    },
    {}
  );

  const centroidLonLat = buildingLocation.geometry.coordinates
    .slice()
    .reverse()
    .map((v) => v.toFixed(5))
    .join(', ');

  const properties: Building5gProperties = {
    ...buildingProperties,
    ...(featureId && { originalId: featureId }),
    locationLatLon: centroidLonLat,
    ...nsaProps,
  };

  return properties;
};

const processGeoJson = (
  data: RawBuildings5gResponse,
  { west, south, north, east }: { west: number; south: number; north: number; east: number }
) => {
  const buildingFeatures = data.features.reduce(
    (featuresAcc: Feature<Geometry, Building5gProperties>[], building) => {
      if (building === null) {
        return featuresAcc;
      }

      const buildingGeometry = building.geometry;

      // TODO: Work out what to do about GeometryCollections..
      if (buildingGeometry && buildingGeometry.type !== 'GeometryCollection') {
        // Check if the centroid is inside the box. Exclude the building if it's not.
        const boxGeometry = polygon([
          [
            [west, south],
            [west, north],
            [east, north],
            [east, south],
            [west, south],
          ],
        ]);

        const buildingCentroid = centroid(buildingGeometry);
        const doesContain = booleanContains(boxGeometry, buildingCentroid);
        if (!doesContain) {
          return featuresAcc;
        }

        const properties = addAdditionalProperties(
          building.properties,
          buildingCentroid,
          building.id
        );

        const buildingFeature = feature(buildingGeometry, properties, {
          id: building.id,
        });

        featuresAcc.push(buildingFeature);
      }

      return featuresAcc;
    },
    []
  );

  return buildingFeatures;
};

const getBuildings5GByBox = async (params: GetBuildings5gParams, options: GetBuilding5gOptions) => {
  const { west, south, east, north, countryCode, providerName } = params;

  const { signal } = options;

  if (countryCode === null || providerName === null) {
    return [] as Feature<Geometry, Building5gProperties>[];
  }

  const { data, request } = await dwAxiosClient.get<RawBuildings5gResponse>(
    `/ResultBuilding5G/GCP/Box/${west}/${south}/${east}/${north}`,
    {
      // baseURL: 'https://api-0013.denseware.net',
      params: { countryCode, providerName },
      signal,
      headers: {
        Accept: 'application/geo+json',
      },
    }
  );

  if (data && request.status === 200) {
    return processGeoJson(data, { west, south, east, north });
  }

  return [] as Feature<Geometry, Building5gProperties>[];
};

const useBuilding5gNsaQueryClient = ({
  layerKey,
  providerName,
  countryCode,
  enabled,
}: {
  layerKey: LayerKey;
  providerName: string | null;
  countryCode: string | null;
  enabled: boolean;
}) => {
  const chunks = useLayerSettingsSelector(layerKey, 'visibleChunks', [], isEqual);

  const queries = chunks
    ? chunks.map((chunk) => {
        const chunk2d = chunk ? getBBox2d(chunk) : [];
        const [west, south, east, north] = chunk2d;

        const query: QueryObserverOptions<Buildings5gResponse | null> = {
          queryKey: ['buildings5gNsa', countryCode, providerName, chunk2d.join('|')],
          queryFn: ({ signal }: any) => {
            return getBuildings5GByBox(
              {
                west: west,
                south: south,
                east: east,
                north: north,
                countryCode,
                providerName,
              },
              { signal }
            );
          },
          staleTime: 1000 * 60 * 60 * 5,
          enabled,
        };

        return query;
      })
    : [];

  return useQueries({ queries });
};

export { useBuilding5gNsaQueryClient };
