import { IconButton, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { FC } from 'react';
import { BiDotsVertical } from 'react-icons/bi';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import {
  setAddressAnalysisModalOpen,
  setBuildingEditOpen,
  setExportDrawerOpen,
  setNodesModalOpen,
  setRecentlyUpdatedBuildingModalOpen,
} from '../../reducers/modalSlice';
import IsUserPermissioned from '../IsUserPermissioned';

const MapAdditionalMenu: FC = () => {
  const dispatch = useAppDispatch();

  return (
    <Menu placement="right-end" id="additional-menu" data-test-id="additional-menu">
      <MenuButton as={IconButton} icon={<BiDotsVertical />}></MenuButton>
      <MenuList>
        <IsUserPermissioned
          app="dw-geo"
          action="exportBuildingData"
          resourceType="system"
          resource="*"
        >
          <MenuItem
            onClick={() => {
              dispatch(setExportDrawerOpen(true));
            }}
            id="additional-menu-export"
            data-test-id="additional-menu-export"
          >
            Export
          </MenuItem>
        </IsUserPermissioned>
        <IsUserPermissioned app="dw-geo" action="menuViewNode" resourceType="system" resource="*">
          <MenuItem
            onClick={() => {
              dispatch(setNodesModalOpen(true));
            }}
            id="additional-menu-nodes-actions"
            data-test-id="additional-menu-nodes-actions"
          >
            Nodes Actions
          </MenuItem>
        </IsUserPermissioned>
        <MenuItem
          onClick={() => {
            dispatch(setRecentlyUpdatedBuildingModalOpen(true));
          }}
          id="additional-menu-recently-updated-buildings"
          data-test-id="additional-menu-recently-updated-buildings"
        >
          Recently updated Buildings
        </MenuItem>
        {import.meta.env.VITE_APP_3POINT5_FEATURE ? (
          <MenuItem
            onClick={() => {
              dispatch(setAddressAnalysisModalOpen(true));
            }}
            id="additional-menu-address-list-analysis"
            data-test-id="additional-menu-address-list-analysis"
          >
            Address List Analysis
          </MenuItem>
        ) : null}
        <IsUserPermissioned
          app="dw-geo"
          action="createBuildingData"
          resourceType="system"
          resource="*"
        >
          <MenuItem
            onClick={() => {
              dispatch(setBuildingEditOpen(true));
            }}
            id="additional-menu-add-building"
            data-test-id="additional-menu-add-building"
          >
            Add Building
          </MenuItem>
        </IsUserPermissioned>
      </MenuList>
    </Menu>
  );
};

export default MapAdditionalMenu;
