import { dalBandScale } from './layerConfig.scales';
import { LayerConfig } from './layerConfig.types';

const spectrumLicensingConfig: LayerConfig = {
  name: 'Spectrum Licensing',
  boundingBoxWkt: null,
  description: 'Spectrum Licensing',
  layerId: 'spectrumLicensing',
  visibleFromUtc: '2000-01-01T00:00:00Z',
  visibleToUtc: '2099-01-01T00:00:00Z',
  zoomFrom: 0,
  zoomTo: 24,
  defaultLayerOn: false,
  defaultSelectedMetric: 'dalBand',
  layerParts: [
    {
      from: 8,
      to: 24,
      type: 'geojson',
      layerType: 'fill',
      layerChunkLevel: 9,
      isInteractive: true,
    },
  ],
  metrics: [
    {
      name: 'DAL Band',
      polygonMetricCode: 'dalBand',
      scale: dalBandScale,
    },
  ],
  filters: [
    {
      name: 'Band',
      type: 'dropdown',
      metricCode: 'band',
    },
    { name: 'Provider', type: 'single-dropdown', metricCode: 'licenceNames' },
  ],
  details: [
    {
      sectionKey: 'details',
      sectionTitle: 'License Details',
      items: [
        {
          type: 'grid',
          itemKey: 'license-details',
          rows: [
            {
              rowText: 'DAL Band',
              rowKey: 'dal-band',
              metricName: 'dalBand',
              showColor: false,
            },
            {
              rowText: 'Alias License Name',
              rowKey: 'aliasLicName',
              metricName: 'aliasLicName',
              showColor: false,
            },
            { rowText: 'Area Code', rowKey: 'areaCode', metricName: 'areaCode', showColor: false },
            { rowText: 'Band', rowKey: 'band', metricName: 'band', showColor: false },
            {
              rowText: 'Country Code',
              rowKey: 'countryCode',
              metricName: 'countryCode',
              showColor: false,
            },
            {
              rowText: 'License Area Type',
              rowKey: 'licAreaType',
              metricName: 'licAreaType',
              showColor: false,
            },
            {
              rowText: 'License Auction',
              rowKey: 'licAuction',
              metricName: 'licAuction',
              showColor: false,
            },
            {
              rowText: 'Reg Spectrum Id',
              rowKey: 'regSpectrumId',
              metricName: 'regSpectrumId',
              showColor: false,
            },
          ],
        },
      ],
    },
  ],
};

export default spectrumLicensingConfig;
