import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Grid,
  Heading,
  Icon,
  Input,
  Text,
  useToast,
} from '@chakra-ui/react';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AiOutlineClose } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { z } from 'zod';
import { ThresholdFormState } from '../../reducers/modalTabSlice';
import { useGetMeasuresListClient } from '../../services/measureClient';
import {
  checkListOfKpisIsValid,
  useCreateThresholdList,
  useGetThresholdLists,
} from '../../services/userProfileClient';

export type KPIValue = {
  name: string;
  id: string;
  value: number;
  color: string;
};

export type InProgressKpi = {
  name: string | null;
  id: string | null;
  value: number | null;
  color: string | null;
};

export type ThresholdListValues = {
  listName: string;
  values: KPIValue[];
};

export type CreateThreshold = {
  sectionName: string;
  listData: ThresholdListValues;
};

const ThresholdsForm: FC = () => {
  const { register, handleSubmit } = useForm<KPIValue>();
  const dispatch = useDispatch();
  const toast = useToast();
  const createThresholdList = useCreateThresholdList();
  const existingList = useGetThresholdLists();
  const [listName, setListName] = useState<string>('');
  const [kpiId, setKpiId] = useState<string | null>(null);
  const [thresholdValue, setThresholdValue] = useState<string>('');
  const [thresholdColor, setThresholdColor] = useState<{
    label: string | null;
    value: string | null;
  }>({ label: '', value: '' });

  const isValueANumber =
    thresholdValue === '' ? { success: false } : z.coerce.number().safeParse(thresholdValue);
  const [addedKPIList, setAddedKPIList] = useState<InProgressKpi[]>([]);
  const addedData: InProgressKpi[] = [...addedKPIList];
  const isValidList = listName !== '' && addedKPIList.length > 0 && listName.trim().length !== 0;

  const existingThresholdsListNames: string[] = [];

  existingList.data?.map((item) => {
    const thresholdData = JSON.parse(item.payload);
    const name = thresholdData.listName;
    if (item.sectionName === 'Threshold') {
      existingThresholdsListNames.push(name);
    }
    return null;
  });
  const { data, status } = useGetMeasuresListClient();

  let titleOptions: { label: string; value: string }[] = [];

  const KPIName = kpiId && data ? data.data.data[kpiId].title : null;

  if (status === 'success') {
    const titles = Object.entries(data.data.data).map((item) => {
      const option = {
        label: item[1].title,
        value: item[0],
      };
      return option;
    });

    titleOptions = titles.sort((a, b) => {
      return a.label.localeCompare(b.label);
    });
  }

  let colorOptions: { label: string; value: string }[] = [];

  colorOptions = [
    { label: 'Red', value: '#700202' },
    { label: 'Green', value: '#255325' },
    { label: 'Yellow', value: '#dab600' },
  ];

  const onSubmit = (data: InProgressKpi) => {
    const addedKpi = {
      name: KPIName,
      id: kpiId,
      value: data.value,
      color: thresholdColor.value,
    };

    addedData.push(addedKpi);
    setAddedKPIList(addedData);
    setThresholdValue('');
    setKpiId('');
    setThresholdColor({ label: null, value: null });
  };

  const onDeleteKPI = (index: number | null) => {
    setAddedKPIList(
      addedKPIList.filter((ele, i) => {
        return i !== index;
      })
    );
  };

  const isFormValid = checkListOfKpisIsValid(addedKPIList);

  const onThresholdSave = () => {
    if (existingThresholdsListNames?.includes(listName)) {
      toast({
        title: 'List name is already in use.',
        description: 'Please change your list name',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });
      return null;
    }

    if (isFormValid) {
      const data: CreateThreshold = {
        sectionName: 'Threshold',
        listData: { listName: listName, values: addedKPIList },
      };

      createThresholdList.mutate(data, {
        onSuccess: () => {
          toast({
            title: 'Your list is saved',
            description: '',
            status: 'success',
            duration: 3000,
            isClosable: true,
            position: 'top',
          });
          dispatch(ThresholdFormState(false));
        },
      });
    }

    return null;
  };

  return (
    <>
      <Grid gridTemplateColumns={'1fr 1fr'} gridGap={10} minH={'450px'}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mb={'20px'}>
            <Input
              id={'threshold-list-name-input-field'}
              placeholder="Threshold List Name"
              {...register('name')}
              onChange={(e) => {
                const inputValue = e.target.value;
                setListName(inputValue.trim());
              }}
            />
          </Box>
          <Box mb={'20px'}>
            <Select
              id={'thresholds-select-dropdown'}
              options={titleOptions}
              onChange={(newValue) => {
                if (newValue !== null) {
                  if (newValue.value) {
                    setKpiId(newValue.value);
                  }
                } else {
                  setKpiId(null);
                }
              }}
              isClearable
              value={kpiId ? { label: KPIName, value: kpiId } : null}
            />
          </Box>
          <Box mb={'20px'}>
            <Input
              id={'add-threshold-value-input-field'}
              placeholder="Add Threshold Value"
              {...register('value')}
              value={thresholdValue}
              onChange={(e) => setThresholdValue(e.target.value)}
            />
          </Box>
          <Box>
            <Select
              id={'color-options-select-dropdown'}
              options={colorOptions}
              placeholder={'Threshold line color'}
              onChange={(color) => {
                if (color !== null) {
                  setThresholdColor({
                    label: color.label,
                    value: color.value,
                  });
                } else {
                  setThresholdColor({
                    label: null,
                    value: null,
                  });
                }
              }}
              isClearable
              value={
                thresholdColor.label
                  ? { label: thresholdColor.label, value: thresholdColor.value }
                  : null
              }
            />
          </Box>
          <Flex>
            <Button
              id={'add-threshold-value-button'}
              type="submit"
              isDisabled={isValueANumber.success === false}
              mt={5}
            >
              Add Threshold
            </Button>
          </Flex>
        </form>

        {listName !== '' || addedKPIList.length > 0 ? (
          <Box>
            <Card p={2} maxH={'250px'}>
              <CardHeader>
                <Heading size={'sm'} textTransform={'uppercase'}>
                  {listName}
                </Heading>
              </CardHeader>
              <CardBody overflow={'hidden'} overflowY={'scroll'}>
                {addedKPIList.map((value, index) => {
                  return (
                    <Grid
                      key={index}
                      gridTemplateColumns={'25px 300px 1fr 1fr'}
                      background={'brand.500'}
                      color={'white'}
                      borderRadius={7}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                      mb={1.5}
                    >
                      <Box
                        ml={'10px'}
                        mr={'10px'}
                        width={'20px'}
                        height={'20px'}
                        borderRadius={'5px'}
                        background={value.color ? value.color : 'primary'}
                      ></Box>
                      <Text pl={3}>{value.name}</Text>
                      <Text key={index} pr={'5px'}>
                        {value.value}
                      </Text>
                      <Button
                        borderLeft={'1px solid white'}
                        borderLeftRadius={'none'}
                        p={0}
                        onClick={() => onDeleteKPI(index)}
                      >
                        <Icon as={AiOutlineClose} color={'white'} />
                      </Button>
                    </Grid>
                  );
                })}
              </CardBody>
            </Card>
          </Box>
        ) : null}
      </Grid>
      <Flex justifyContent={'end'} gap={'20px'}>
        <Button
          id={'cancel-button'}
          variant={'unstyled'}
          onClick={() => dispatch(ThresholdFormState(false))}
        >
          Cancel
        </Button>
        <Button
          id={'save-new-threshold-button'}
          onClick={onThresholdSave}
          isDisabled={!isValidList}
        >
          Save
        </Button>
      </Flex>
    </>
  );
};

export default ThresholdsForm;
