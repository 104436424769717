import { Box, Flex } from '@chakra-ui/react';
import type { FC } from 'react';
import { useEffect } from 'react';
import { MapProvider } from 'react-map-gl';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import {
  setClusterId,
  setEnableCluster,
  setSearchIdentifier,
  setToggleCluster,
} from '../../reducers/clusterSlice';
import { setContext } from '../../reducers/contextSlice';
import MapComponent from '../Map';
import MapUiOverlay from '../MapUiOverlay';

type HomeProps = {};

const Home: FC<HomeProps> = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const hash = window.location.hash.slice(1);
    const hashObj = new URLSearchParams(hash);
    if (hashObj.has('countryCode') && hashObj.has('providerName')) {
      dispatch(
        setContext({
          countryCode: hashObj.get('countryCode'),
          providerName: hashObj.get('providerName'),
        })
      );
    }
    if (hashObj.has('clusterId') && hashObj.has('searchIdentifier')) {
      const urlClusterId = hashObj.get('clusterId');
      const urlSearchIdentifier = hashObj.get('searchIdentifier');
      if (urlClusterId !== '' && urlSearchIdentifier !== '') {
        dispatch(setEnableCluster(true));
        dispatch(setToggleCluster(true));
        dispatch(setClusterId(urlClusterId));
        dispatch(setSearchIdentifier(urlSearchIdentifier));
      }
    }
  }, [dispatch]);

  return (
    <Flex height="full">
      <Box flex="1" position="relative">
        <MapProvider>
          <MapComponent />
          <MapUiOverlay />
        </MapProvider>
      </Box>
    </Flex>
  );
};

export default Home;
