const tooltipText = {
  rssnr: `Displays Mean RSSNR.

  Mean RSSNR (Reference Signal Signal to Noise Ratio) is the average Signal-to-Noise ratio for LTE UE measurements across all frequency bands, measured in dB.`,
  rssnr25: `The 25th Percentile is a statistical measure indicating the value below which 25% of measurements are recorded.
  This means that for a given value, 25% of UE measurements within this location have an Signal-to-Noice Ratio (RSSNR) which is worse than this value (in dB) as indicated in the example distribution curve below.
  This value identifies location where a portion of the building has poor RSSNR (>25%), but other areas of the building have a good RSSNR.`,
  rsrp: `Displays Mean RSRP.
  
  Mean RSRP (Reference Signal Received Power) is the average power of the LTE reference signal received at the UE across all frequency bands, measured in dBm.`,
  rsrp25: `The 25th Percentile is a statistical measure indicating the value below which 25% of measurements are recorded.
  This means that for a given value, 25% of UE measurements within this location receive an LTE Reference Signal which is worse than this value (in dBm) as indicated in the example distribution curve below.
  This value identifies location where a portion of the building has poor RSRP (>25%), but other areas of the building have a good RSRP.`,
  meanTonnageDescription: `Displays monthly mean Tonnage.
  
  Calculated as the daily average number of bytes transmitted and received over the analysis period, multiplied by 30 days. 
    
  Value is based on measurements received from anonymised crowd-sourced data
  
  For convenience bytes are aggregated to Mega/Giga/Terabytes.
  
  NOTE: mean tonnage does not represent the entirety of data transmitted or received in an area, but the total that has been observed in the crowd-sourced data.`,
  measurements: `Total measurements is a count of all user device connections over the analysis period. 
    
  Value is based on measurements received from anonymised crowd-sourced data.`,
  footfall: `Displays Footfall Density.
  
  Footfall density represents an average number of unique users per day, per square meter`,
  twoGThreeG: `Displays Percentage of 2G/3G Connections.

  2G/3G percentages show what proportion of users are connecting to the network using 2G or 3G technologies.
  A smaller percentage means that most users are connecting using 4G. Wi-Fi is excluded from these calculations.`,
  fiveGSa: `Displays 5G SA measurements as a percentage of total measurements`,
  fiveGNsa: `Displays 5G NSA measurements as a percentage of total measurements`,
  existingCapacity: `Displays the Existing Network Capacity. [Beta Release]

  The existing capacity is the measured and calculated throughput experienced within a building, measured in Mbit/s.`,
  wiredRatio: `Displays the predicted Wired Ratio Multiplier. [Beta Release]

  The wired ratio multiplier is a range of download performance improvements that analysts predict can be achieved by using Dense Air Small Cells with wired backhaul technology, and providing LTE service using Dense Air’s dedicated spectrum.
  It is a multiplier of "Existing Capacity".`,
  relayedRatio: `Displays the predicted Relay Ratio Multiplier. [Beta Release]

  The relay ratio multiplier is a range of download performance improvements that analysts predict can be achieved by using Dense Air Small Cells with UE Relay backhaul technology (i.e. connecting via the MNO Macro network), and providing LTE service using Dense Air’s dedicated spectrum.
  It is a multiplier of "Existing Capacity".`,
  residentCount: `Resident Count for grid cells is a modelled, aggregated count of people that are likely to reside within the grid cell 

  It can be used as an indicator of local residential populations.
  
  Population data is derived from https://worldpop.org data sources.
  
  For further information on WorldPop data sources visit https://www.worldpop.org/datacatalog/`,
};

export default tooltipText;
