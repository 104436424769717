import { useNavigate } from 'raviger';
import { useCallback } from 'react';

function useUpdateURL() {
  const navigate = useNavigate();

  return useCallback(
    (valKey: string | undefined, val: string | undefined) => {
      const stringWithHashOff = window.location.hash.slice(1);
      const searchParams = new URLSearchParams(stringWithHashOff);
      let valueKey = valKey || '';
      let value = val || '';
      let hash = '#';
      if (searchParams.has(valueKey)) {
        searchParams.set(valueKey, value);
        hash = hash + searchParams.toString();
      } else {
        searchParams.append(valueKey, value);
        hash = hash + searchParams.toString();
      }
      navigate(hash);
    },
    [navigate]
  );
}

export default useUpdateURL;
