import { QueryObserverOptions, useQueries } from '@tanstack/react-query';
import { feature, Feature, Polygon } from '@turf/turf';
import { isEqual } from 'lodash';
import { getColorFromScale } from '../helpers/ColorScales';
import { getBBox2d } from '../helpers/MapHelpers';
import { useLayerSettingsSelector } from '../hooks/useLayersSettings';
import { LayerKey } from '../reducers/layersSlice.types';
import dwAxiosClient from './dwAxiosClient';
import {
  GetSpectrumOptions,
  GetSpectrumParams,
  RawSpectrumResponse,
  SpectrumProperties,
  SpectrumResponse,
} from './spectrumLicensingClient.types';

const getSpectrumByBox = async (
  params: GetSpectrumParams,
  options: GetSpectrumOptions
): Promise<Feature<Polygon, SpectrumProperties>[]> => {
  const { west, south, east, north, countryCode, providerName, band, dalBand } = params;

  const { signal } = options;

  if (countryCode === null || providerName === null) {
    return [] as Feature<Polygon, SpectrumProperties>[];
  }

  const { data, request } = await dwAxiosClient.get<RawSpectrumResponse>(
    `/Spectrum/BoxNew/${west}/${south}/${east}/${north}`,
    {
      params: { countryCode, providerName, dalBand, band },
      signal,
      headers: { Accept: 'application/geo+json' },
    }
  );

  if (data && request.status === 200) {
    return data.features.reduce((featuresAcc: Feature<Polygon, SpectrumProperties>[], f) => {
      if (f === null) {
        return featuresAcc;
      }
      const fGeometry = f.geometry;

      const newProperties: SpectrumProperties = {
        ...f.properties,
        ...(f.id && { originalId: f.id }),
        dalBandColor: getColorFromScale('spectrumLicensing', 'dalBand', f.properties.dalBand),
        licenceNames: f.properties.licenceOwnershipJson.map((l) =>
          l.license_name.toLocaleLowerCase()
        ),
      };

      const spectrumFeature = feature(fGeometry, newProperties, { id: f.id });

      featuresAcc.push(spectrumFeature);

      return featuresAcc;
    }, []);
  }

  return [] as Feature<Polygon, SpectrumProperties>[];
};

const useSpectrumClient = ({
  layerKey,
  providerName,
  countryCode,
  enabled,
}: {
  layerKey: LayerKey;
  providerName: string | null;
  countryCode: string | null;
  enabled: boolean;
}) => {
  const chunks = useLayerSettingsSelector(layerKey, 'visibleChunks', [], isEqual);

  const queries = chunks
    ? chunks.map((chunk) => {
        const chunk2d = chunk ? getBBox2d(chunk) : [];
        const [west, south, east, north] = chunk2d;

        const query: QueryObserverOptions<SpectrumResponse | null> = {
          queryKey: ['spectrumLicensing', countryCode, providerName, chunk2d.join('|')],
          queryFn: ({ signal }: any) => {
            return Promise.all(
              [
                ['A', 'n77'],
                ['B', 'n48'],
                ['C', 'n77'],
                ['D', 'n41'],
              ].map(([dalBand, band]) => {
                return getSpectrumByBox(
                  {
                    west: west,
                    south: south,
                    east: east,
                    north: north,
                    countryCode,
                    providerName,
                    dalBand,
                    band,
                  },
                  { signal }
                );
              })
            ).then((allResponses) => allResponses.flatMap((a) => a));
          },
          staleTime: 1000 * 60 * 60 * 5,
          enabled,
        };

        return query;
      })
    : [];

  return useQueries({ queries });
};

export { useSpectrumClient };
