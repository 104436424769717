import { QueryObserverOptions, useQueries } from '@tanstack/react-query';
import { feature } from '@turf/helpers';
import { Feature, Point } from '@turf/turf';
import isEqual from 'lodash/isEqual';
import { getColorFromScale } from '../helpers/ColorScales';
import { getBBox2d } from '../helpers/MapHelpers';
import { useLayerSettingsSelector } from '../hooks/useLayersSettings';
import { LayerKey } from '../reducers/layersSlice.types';
import dwAxiosClient from './dwAxiosClient';
import {
  GetNQTGaParams,
  GetNQTResultsOptions,
  NQTGaProperties,
  NQTGaResponse,
  RawNQTGaResponse,
} from './nqtGaClient.types';

const getNQTGaResultsByBox = async (params: GetNQTGaParams, options: GetNQTResultsOptions) => {
  const { bbox, countryCode, providerName, startUtc, endUtc } = params;

  const { signal } = options;

  if (bbox === null || countryCode === null || providerName === null) {
    return [] as Feature<Point, NQTGaProperties>[];
  }

  const response = await dwAxiosClient.get<RawNQTGaResponse>(
    `/NqtPointsCDM/Collection/Details/Viewport/GA`,
    {
      baseURL: import.meta.env.VITE_APP_NQT_API_URL,
      params: { countryCode, providerName, bbox: bbox.join(','), startUtc, endUtc },
      signal,
      responseType: 'json',
      headers: { Accept: 'application/geo+json' },
    }
  );

  if (response.status === 200 && response.data) {
    const geoJsonData = response.data.data;

    const featuresWithColors = geoJsonData.features.map((f) => {
      const newProperties: NQTGaProperties = {
        ...f.properties,
        ...(f.id && { originalId: f.id }),
        avg_rsrpColor: getColorFromScale('nqtGa', 'avg_rsrp', f.properties.avg_rsrp),
        avg_sinrColor: getColorFromScale('nqtGa', 'avg_sinr', f.properties.avg_sinr),
        avg_ss_sinrColor: getColorFromScale('nqtGa', 'avg_ss_sinr', f.properties.avg_ss_sinr),
        avg_ss_rsrpColor: getColorFromScale('nqtGa', 'avg_ss_rsrp', f.properties.avg_ss_rsrp),
        pciColor: getColorFromScale('nqtGa', 'pci', f.properties.pci),
      };

      const featureWithColor = feature(f.geometry, newProperties, { id: f.id });

      return featureWithColor;
    });

    return featuresWithColors;
  }

  return [] as Feature<Point, NQTGaProperties>[];
};

const useNQTGaQueryClient = ({
  layerKey,
  providerName,
  countryCode,
  startUtc,
  endUtc,
  enabled,
}: {
  layerKey: LayerKey;
  providerName: string | null;
  countryCode: string | null;
  startUtc: Date;
  endUtc: Date;
  enabled: boolean;
}) => {
  const chunks = useLayerSettingsSelector(layerKey, 'visibleChunks', [], isEqual);

  const queries = chunks
    ? chunks.map((chunk) => {
        const chunk2d = chunk ? getBBox2d(chunk) : null;
        const queryKey = ['nqtGa', countryCode, providerName, chunk2d ? chunk2d.join('|') : null];

        const query: QueryObserverOptions<NQTGaResponse | null> = {
          queryKey,
          queryFn: ({ signal }) => {
            return getNQTGaResultsByBox(
              {
                bbox: chunk2d,
                countryCode,
                providerName,
                startUtc,
                endUtc,
              },
              { signal }
            );
          },

          enabled: enabled && !!chunk2d,
          staleTime: 1000 * 60 * 60 * 5,
          refetchInterval: 1000 * 30,
        };

        return query;
      })
    : [];

  return useQueries({ queries });
};

export { useNQTGaQueryClient };
