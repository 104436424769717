import { Box } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';
import assertUnreachable from '../../helpers/assertUnreachable';
import { FilterConfig } from '../../helpers/layerConfig.types';
import { formatValue } from '../../helpers/utils';
import { useLayerSettingsSelector } from '../../hooks/useLayersSettings';
import { LayerKey } from '../../reducers/layersSlice.types';
import useLayersClient from '../../services/LayersClient';

type FilterLegendProps = { layerId: LayerKey };

const format = (
  value: unknown,
  filterType: FilterConfig['type'],
  filterFormat?: FilterConfig['format']
): ReactNode => {
  switch (filterType) {
    case 'date_range':
      const [firstDate, secondDate] = value as [string, string];

      if (firstDate && secondDate) {
        return `From ${firstDate} to ${secondDate}`;
      } else if (firstDate) {
        return `From ${firstDate}`;
      } else if (secondDate) {
        return `To ${secondDate}`;
      } else {
        return null;
      }

    case 'single-dropdown': {
      if (typeof value === 'string' || typeof value === 'number') {
        return value;
      }

      return null;
    }

    case 'multiselect':
    case 'dropdown':
      const values = value as string[];
      return values.join(', ');

    case 'rangeSlider':
      const [firstValue, secondValue] = value as [number | null, number | null];

      const firstFormatted = firstValue ? formatValue(firstValue, filterFormat) : null;
      const secondFormatted = secondValue ? formatValue(secondValue, filterFormat) : null;

      if (firstFormatted && secondFormatted) {
        return (
          <span>
            {firstFormatted} &le; <span style={{ fontStyle: 'italic' }}>value</span> &lt;{' '}
            {secondFormatted}
          </span>
        );
      } else if (firstFormatted) {
        return (
          <span>
            {firstFormatted} &le; <span style={{ fontStyle: 'italic' }}>value</span>
          </span>
        );
      } else if (secondFormatted) {
        return (
          <span>
            <span style={{ fontStyle: 'italic' }}>value</span> &lt; {secondFormatted}
          </span>
        );
      }

      return null;
  }

  return assertUnreachable(filterType);
};

const FilterLegend: FC<FilterLegendProps> = (props) => {
  const { layerId } = props;

  const filterSettings = useLayerSettingsSelector(layerId, 'filterValues', null);
  const { isSuccess, data: config } = useLayersClient();

  if (isSuccess && config && filterSettings) {
    const layerConfig = config.find((l) => l.layerId === layerId);
    const filtersForLayer = layerConfig?.filters ?? null;

    return (
      <Box color="white" fontSize="0.65vw" mb="2">
        {filtersForLayer
          ? filtersForLayer
              .reduce((acc: [string, ReactNode][], filter) => {
                const filterValue = filterSettings[filter.metricCode];

                if (filterValue) {
                  const value = format(filterValue, filter.type, filter.format);

                  if (value) {
                    acc.push([filter.name, value]);
                  }
                }

                return acc;
              }, [])
              .map((item) => {
                const [name, value] = item;

                return (
                  <Box key={`${layerId}-${name}`} id={`legend-filter-list-${layerId}-${name}`}>
                    {name}: {value}
                  </Box>
                );
              })
          : null}
      </Box>
    );
  }

  return null;
};

export default FilterLegend;
