import { FC, useEffect } from 'react';
import { useMap } from 'react-map-gl';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import useChunkArea from '../../hooks/useChunkArea';
import { setVisibleChunks } from '../../reducers/layersSlice';
import { LayerKey } from '../../reducers/layersSlice.types';

type TwoDPolygonControllerProps = {
  layerKey: LayerKey;
  minZoom: number;
  layerChunkLevel: number;
};

const TwoDPolygonController: FC<TwoDPolygonControllerProps> = (props) => {
  const { layerChunkLevel, minZoom, layerKey } = props;

  const dispatch = useAppDispatch();

  const { 'dw-main-map': map } = useMap();
  const isMoving = map ? map.isMoving() : true;
  const viewport = useAppSelector((state) => state.mapSettings.viewState.viewport);

  const { extents } = useChunkArea(
    isMoving ? null : viewport,
    layerChunkLevel,
    minZoom <= (map ? map.getZoom() : 20)
  );

  useEffect(() => {
    if (extents) {
      dispatch(setVisibleChunks({ layerKey, visibleChunks: extents }));
    }
  }, [dispatch, layerKey, extents]);

  return null;
};

export default TwoDPolygonController;
