import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Checkbox,
  Flex,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { isEqual, uniq, uniqBy } from 'lodash';
import { FC, useMemo, useState } from 'react';
import { getFeatureId } from '../../helpers/getFeatureId';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { DrawerKey } from '../../reducers/drawersSlice';
import { LayerKey } from '../../reducers/layersSlice.types';
import { clearMarkerLocation, setSelectedFeatureId } from '../../reducers/mapSettings';
import { AllowedClient, useDynamicClient } from '../../services/dynamicClient';
import { SpectrumProperties } from '../../services/spectrumLicensingClient.types';

type SpectrumQueriedDetailsProps = {
  drawerKey: DrawerKey;
  layerId: LayerKey;
};

const SpectrumQueriedDetails: FC<SpectrumQueriedDetailsProps> = (props) => {
  const { drawerKey, layerId } = props;

  const dispatch = useAppDispatch();
  const client = useDynamicClient(drawerKey as AllowedClient);

  const queriedFeatureIds = useAppSelector((state) => state.mapSettings.queriedFeatureIds, isEqual);

  const selectedCountryCode = useAppSelector((state) => state.contextSettings.selectedCountryCode);
  const selectedProviderName = useAppSelector(
    (state) => state.contextSettings.selectedProviderName
  );

  const responses = client({
    layerKey: layerId,
    providerName: selectedProviderName,
    countryCode: selectedCountryCode,
    plmns: null,
    startUtc: new Date('2021-01-01'),
    endUtc: new Date('2025-01-01'),
    enabled: false,
    layerId: layerId,
  });

  const responseDataFeatures: GeoJSON.Feature<GeoJSON.Geometry, SpectrumProperties>[] =
    responses.flatMap((r: any) => {
      if (r.isSuccess && r.data) {
        return r.data;
      }

      return [];
    });

  const allSpectrumFeatures = useMemo<
    GeoJSON.Feature<GeoJSON.Geometry, SpectrumProperties>[] | null | undefined
  >(() => {
    if (queriedFeatureIds && queriedFeatureIds.length > 0 && responseDataFeatures.length > 0) {
      const features = responseDataFeatures.filter((f: GeoJSON.Feature) => {
        const fId = getFeatureId(f, layerId);
        if (fId) {
          return queriedFeatureIds.includes(fId);
        }

        return false;
      });

      if (features && features.length > 0) {
        return features;
      } else {
        return undefined;
      }
    }
  }, [layerId, queriedFeatureIds, responseDataFeatures]);

  const spectrumFeatures = useMemo<GeoJSON.Feature<GeoJSON.Geometry, SpectrumProperties>[]>(() => {
    return uniqBy(allSpectrumFeatures, (f) => f.properties.regSpectrumID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queriedFeatureIds]);

  const licences = spectrumFeatures
    ? spectrumFeatures
        .flatMap((f) => {
          return f.properties.licenceOwnershipJson.map((o) => {
            const { licenceOwnershipJson, ...extraProperties } = f.properties;
            return {
              ...o,
              ...extraProperties,
              ...(f.properties.originalId && { originalId: f.properties.originalId }),
            };
          });
        })
        .sort((a, b) => {
          return a.license_name.localeCompare(b.license_name);
        })
    : [];

  const allOwners = uniq(licences.map((l) => l.license_name));
  const [checkedOwners, setCheckedOwners] = useState<(string | number)[]>(
    allOwners.filter(
      (o) => !['DISH', 'HITN', 'SHEKINAH', 'OTHER', 'SCHOOL', 'UNIVERSITY'].includes(o)
    )
  );

  const filteredLicenses = licences
    .filter((l) => checkedOwners.includes(l.license_name))
    .sort((a, b) => {
      if (a.channel_start_mhz === b.channel_start_mhz) {
        return a.channel_end_mhz - b.channel_end_mhz;
      }
      return a.channel_start_mhz - b.channel_start_mhz;
    });

  return (
    <>
      <Heading size="md" color={'white'} mb="1.6em">
        Spectrum Licenses
      </Heading>
      <Box mb="2rem">
        <Flex mb="1" alignItems="center">
          <Heading color="white" size="sm" mr="3">
            Owners
          </Heading>
          <ButtonGroup>
            <Button
              size="sm"
              onClick={() => {
                setCheckedOwners(licences.map((l) => l.license_name));
              }}
            >
              Select all
            </Button>
            <Button
              size="sm"
              onClick={() => {
                setCheckedOwners([]);
              }}
            >
              Deselect all
            </Button>
          </ButtonGroup>
        </Flex>
        {allOwners.map((owner) => {
          return (
            <Checkbox
              key={`checkbox-${owner}`}
              value={owner}
              isChecked={checkedOwners.includes(owner)}
              onChange={(e) => {
                if (e.target.checked) {
                  setCheckedOwners((s) => [...s, owner]);
                } else {
                  setCheckedOwners((s) => s.filter((o) => o !== owner));
                }
              }}
              mr="5"
            >
              <Box color="white" as="span">
                {owner}
              </Box>
              <Menu>
                <MenuButton
                  as={IconButton}
                  icon={<ChevronDownIcon />}
                  size="xs"
                  ml={1}
                ></MenuButton>
                <MenuList>
                  <MenuItem
                    onClick={() => {
                      setCheckedOwners([owner]);
                    }}
                  >
                    Select only {owner}
                  </MenuItem>
                </MenuList>
              </Menu>
            </Checkbox>
          );
        })}
      </Box>
      <Flex overflowX={'auto'}>
        {filteredLicenses.map((l) => {
          return (
            <Box
              key={`${l.license_name}-${l.regSpectrumID}-${l.channel_start_mhz}-${l.channel_end_mhz}`}
            >
              <Card bg="brand.500" color="white" m={'5px'}>
                <CardBody>
                  <Box display={'flex'} width={'300px'}>
                    <Box>
                      <Heading size="sm" mb="0.6em">
                        {l.license_name}
                      </Heading>
                      <Box fontSize="sm" display={'flex'}>
                        Area Code:&nbsp;<Text fontWeight={'semibold'}> {l.areaCode}</Text>
                      </Box>
                      <Box fontSize="sm" display={'flex'}>
                        Band:&nbsp;<Text fontWeight={'semibold'}>{l.band}</Text>
                      </Box>
                      <Box fontSize="sm" display={'flex'}>
                        Start&nbsp;-&nbsp;End:&nbsp;
                        <Text fontWeight={'semibold'}>
                          {`${l.channel_start_mhz} - ${l.channel_end_mhz}`}
                        </Text>
                      </Box>
                      <Box fontSize="sm" display={'flex'}>
                        Width:&nbsp;
                        <Text fontWeight={'semibold'}>{l.channel_width_mhz}</Text>
                      </Box>
                    </Box>
                    <Button
                      variant="secondary"
                      id={`view-${l.band}`}
                      fontSize={12}
                      onClick={() => {
                        if (l.originalId) {
                          dispatch(setSelectedFeatureId(l.originalId));
                        }
                        dispatch(clearMarkerLocation());
                      }}
                      position="absolute"
                      right="1"
                      bottom="1"
                    >
                      View
                    </Button>
                  </Box>
                </CardBody>
              </Card>
            </Box>
          );
        })}
      </Flex>
    </>
  );
  // return <div>stuff {features ? features.map((f) => f.properties.licAreaCode) : null}</div>;
};

export default SpectrumQueriedDetails;
