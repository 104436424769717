/**
 * Typescript helper function to flag code that should be unreachable.
 * It should cause a TS compiler error, but will at least throw a JS error if it gets through the compiler.
 * @example
 * ```ts
 * type FilterType = 'date' | 'dropdown' | 'text';
 *
 * function () {
 *  switch (filterType) {
 *   case 'date':
 *     // Do some things
 *     return true;
 *
 *   case 'dropdown':
 *     // Do some things
 *     return true;
 *  }
 *
 *  // If we forget to assign a `case` for 'text', then it will fall through to `assertUnreachable`
 *  // Nothing is assignable to never so we'll get a compiler error.
 *  assertUnreachable(filterType);
 * }
 * ```
 *
 * @module
 */
function assertUnreachable(x: never): never {
  throw new Error("Didn't expect to get here");
}

export default assertUnreachable;
