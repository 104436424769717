import { EqualityFn, NoInfer } from 'react-redux';
import { LayerKey, LayerState } from '../reducers/layersSlice.types';
import { useAppSelector } from './useAppSelector';

export const useLayerSettingsSelector = <
  TLayerKey extends LayerKey,
  TLayerProperty extends keyof LayerState,
  TDefaultValue
>(
  layerKey: TLayerKey,
  layerProperty: TLayerProperty,
  defaultValue: TDefaultValue,
  equalityFn?: EqualityFn<NoInfer<TDefaultValue | LayerState[TLayerProperty]>> | undefined
) => {
  return useAppSelector((state) => {
    const layer = state.layersSettings[layerKey];

    if (layer) {
      const value = layer[layerProperty];
      return value;
    }

    return defaultValue;
  }, equalityFn);
};
