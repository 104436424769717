import { QueryObserverOptions, useQueries, useQuery } from '@tanstack/react-query';
import centroid from '@turf/centroid';
import { feature, polygon } from '@turf/helpers';
import { BBox, Feature, Point } from '@turf/turf';
import type { Geometry } from 'geojson';
import isEqual from 'lodash/isEqual';
import { getColorFromScale } from '../helpers/ColorScales';
import { getBBox2d } from '../helpers/MapHelpers';
import booleanContains from '../helpers/booleanContains';
import { isValueValid } from '../helpers/isValueValid';
import { useLayerSettingsSelector } from '../hooks/useLayersSettings';
import { LayerKey } from '../reducers/layersSlice.types';
import dwAxiosClient from './dwAxiosClient';
import {
  GetGridsOptions,
  GetGridsParams,
  GridProperties,
  GridsResponse,
  RawGrid,
  RawGridsResponse,
} from './gridsClient.types';

const addAdditionalProperties = (gridProperties: RawGrid, gridLocation: Feature<Point>) => {
  const monthlyAverageTonnage =
    isValueValid(gridProperties.receivedBytesPerDay) &&
    isValueValid(gridProperties.transmittedBytesPerDay)
      ? gridProperties.receivedBytesPerDay * 30 + gridProperties.transmittedBytesPerDay * 30
      : 0;
  const monthlyAveragePoints = isValueValid(gridProperties.footfallPerDay)
    ? gridProperties.footfallPerDay * 30
    : 0;
  const threeGPercentage =
    isValueValid(gridProperties.count3G) && isValueValid(gridProperties.points)
      ? gridProperties.count3G === 0
        ? 0
        : (gridProperties.count3G / gridProperties.points) * 100
      : 0;
  const fourGPercentage =
    isValueValid(gridProperties.count4G) && isValueValid(gridProperties.points)
      ? gridProperties.count4G === 0
        ? 0
        : (gridProperties.count4G / gridProperties.points) * 100
      : 0;
  const fiveGSaPercentage =
    isValueValid(gridProperties.count5G) && isValueValid(gridProperties.points)
      ? gridProperties.count5G === 0
        ? 0
        : (gridProperties.count5G / gridProperties.points) * 100
      : 0;
  const fiveGNsaPercentage =
    isValueValid(gridProperties.count5GNsa) && isValueValid(gridProperties.points)
      ? gridProperties.count5GNsa === 0
        ? 0
        : (gridProperties.count5GNsa / gridProperties.points) * 100
      : 0;
  const callServiceOnlyPercentage =
    isValueValid(gridProperties.countCallServiceOnly) && isValueValid(gridProperties.points)
      ? gridProperties.countCallServiceOnly === 0
        ? 0
        : (gridProperties.countCallServiceOnly / gridProperties.points) * 100
      : 0;
  const noServicePercentage =
    isValueValid(gridProperties.countNoService) && isValueValid(gridProperties.points)
      ? gridProperties.countNoService === 0
        ? 0
        : (gridProperties.countNoService / gridProperties.points) * 100
      : 0;
  const otherPercentage =
    isValueValid(gridProperties.countOther) && isValueValid(gridProperties.points)
      ? gridProperties.countOther === 0
        ? 0
        : (gridProperties.countOther / gridProperties.points) * 100
      : 0;
  const wifiPercentage =
    isValueValid(gridProperties.countWifi) && isValueValid(gridProperties.points)
      ? gridProperties.countWifi === 0
        ? 0
        : (gridProperties.countWifi / gridProperties.points) * 100
      : 0;

  const rssnrMeanColor = getColorFromScale('grids', 'rssnrMean', gridProperties.rssnrMean);
  const rssnr25PcColor = getColorFromScale('grids', 'rssnr25Pc', gridProperties.rssnr25Pc);
  const rsrpMeanColor = getColorFromScale('grids', 'rsrpMean', gridProperties.rsrpMean);
  const rsrp25PcColor = getColorFromScale('grids', 'rsrp25Pc', gridProperties.rsrp25Pc);
  const pointsColor = getColorFromScale('grids', 'points', gridProperties.points);
  const monthlyAverageTonnageColor = getColorFromScale(
    'grids',
    'monthlyAverageTonnage',
    monthlyAverageTonnage
  );
  const monthlyAveragePointsColor = getColorFromScale('grids', 'points', monthlyAveragePoints);
  const footfallDensityColor = getColorFromScale(
    'grids',
    'footfallDensity',
    gridProperties.footfallDensity
  );
  const thirdGenerationPercentColor = getColorFromScale(
    'grids',
    'thirdGenerationPercent',
    gridProperties.thirdGenerationPercent
  );
  const fiveGSaPercentageColor = getColorFromScale('grids', 'fiveGSaPercentage', fiveGSaPercentage);
  const fiveGNsaPercentageColor = getColorFromScale(
    'grids',
    'fiveGNsaPercentage',
    fiveGNsaPercentage
  );

  const residentCountColor = getColorFromScale(
    'grids',
    'residentCount',
    gridProperties.residentCount
  );

  const locationLatLon = gridLocation.geometry.coordinates
    .slice()
    .reverse()
    .map((v) => v.toFixed(5))
    .join(', ');

  const properties: GridProperties = {
    ...gridProperties,
    locationLatLon,
    monthlyAverageTonnage,
    monthlyAveragePoints,
    threeGPercentage,
    fourGPercentage,
    fiveGSaPercentage,
    fiveGNsaPercentage,
    callServiceOnlyPercentage,
    noServicePercentage,
    otherPercentage,
    wifiPercentage,
    rssnrMeanColor,
    rsrpMeanColor,
    rssnr25PcColor,
    rsrp25PcColor,
    pointsColor,
    monthlyAverageTonnageColor,
    monthlyAveragePointsColor,
    footfallDensityColor,
    thirdGenerationPercentColor,
    fiveGSaPercentageColor,
    fiveGNsaPercentageColor,
    residentCountColor,
  };

  return properties;
};

const getGridsByBox = async (params: GetGridsParams, options: GetGridsOptions) => {
  const { west, south, east, north, countryCode, providerName } = params;
  const { signal } = options;

  if (countryCode === null || providerName === null) {
    return [] as Feature<Geometry, GridProperties>[];
  }

  const { data, request } = await dwAxiosClient.get<RawGridsResponse>(
    `/ResultGridCell/Box/${west}/${south}/${east}/${north}`,
    {
      params: { countryCode, providerName },
      signal,
      headers: { Accept: 'application/geo+json' },
    }
  );

  if (data && request.status === 200) {
    // transform data to GeoJSON
    const gridFeatures = data.features.reduce(
      (featuresAcc: Feature<Geometry, GridProperties>[], grid) => {
        if (grid === null) {
          return featuresAcc;
        }

        const gridGeometry = grid.geometry;

        // TODO: Work out what to do about GeometryCollections..
        if (gridGeometry && gridGeometry.type !== 'GeometryCollection') {
          // Check if the centroid is inside the box. Exclude the grid if it's not.
          const boxGeometry = polygon([
            [
              [west, south],
              [west, north],
              [east, north],
              [east, south],
              [west, south],
            ],
          ]);
          const gridCentroid = centroid(gridGeometry);
          const doesContain = booleanContains(boxGeometry, gridCentroid);
          if (!doesContain) {
            return featuresAcc;
          }

          const properties = addAdditionalProperties(grid.properties, gridCentroid);

          const gridFeature = feature(gridGeometry, properties, {
            id: grid.id,
          });

          featuresAcc.push(gridFeature);
        }

        return featuresAcc;
      },
      []
    );

    return gridFeatures;
  }

  return [] as Feature<Geometry, GridProperties>[];
};

const useGridQueryClient = ({
  chunk,
  providerName,
  countryCode,
  enabled,
}: {
  chunk: BBox | null;
  providerName: string | null;
  countryCode: string | null;
  enabled: boolean;
}) => {
  const chunk2d = chunk ? getBBox2d(chunk) : [];
  const [west, south, east, north] = chunk2d;

  return useQuery<GridsResponse | null>(
    ['grids', countryCode, providerName, chunk2d.join('|')],
    ({ signal }) => {
      return getGridsByBox(
        {
          west: west,
          south: south,
          east: east,
          north: north,
          countryCode,
          providerName,
        },
        { signal }
      );
    },
    {
      enabled,
      staleTime: 1000 * 60 * 60 * 5,
    }
  );
};

const useGridsSingleLayerQueryClient = ({
  layerKey,
  providerName,
  countryCode,
  enabled,
}: {
  layerKey: LayerKey;
  providerName: string | null;
  countryCode: string | null;
  enabled: boolean;
}) => {
  const chunks = useLayerSettingsSelector(layerKey, 'visibleChunks', [], isEqual);

  const queries = chunks
    ? chunks.map((chunk) => {
        const chunk2d = chunk ? getBBox2d(chunk) : [];
        const [west, south, east, north] = chunk2d;

        const query: QueryObserverOptions<GridsResponse | null> = {
          queryKey: ['grids', countryCode, providerName, chunk2d.join('|')],
          queryFn: ({ signal }: any) => {
            return getGridsByBox(
              {
                west: west,
                south: south,
                east: east,
                north: north,
                countryCode,
                providerName,
              },
              { signal }
            );
          },
          enabled,
          staleTime: 1000 * 60 * 60 * 5,
        };

        return query;
      })
    : [];

  return useQueries({ queries });
};

export { useGridQueryClient, useGridsSingleLayerQueryClient };
