import {
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Tooltip,
} from '@chakra-ui/react';
import { FC } from 'react';
import { BsBarChartFill } from 'react-icons/bs';
import { MdMonitorHeart } from 'react-icons/md';
import { Link } from 'react-router-dom';
import useDashQueryClient from '../../services/dashboardClient';

type OpenLinkProps = {
  name: string;
  nodeName: string;
  type: string;
  countryCode: string | null;
  providerName: string | null;
};

const OpenLink: FC<OpenLinkProps> = ({ name, nodeName, type, countryCode, providerName }) => {
  let link = '';
  const dashboardResponse = useDashQueryClient();
  const dashboardsList =
    dashboardResponse.status === 'success' ? dashboardResponse.data.data.data : [];

  const refetchDashboards = () => {
    dashboardResponse.refetch();
  };

  if (type === 'analytics') {
    return dashboardsList.length > 0 ? (
      <>
        <Menu>
          <Button as={MenuButton} p={0}>
            <Icon as={BsBarChartFill} boxSize={5} />
          </Button>
          <MenuList>
            {dashboardsList.map((dashBoard, index) => {
              return (
                <Link
                  key={`${dashBoard}-${index}`}
                  to={`${import.meta.env.VITE_APP_ANALYTICS_URL}dashboards/${
                    dashBoard.id
                  }?selectedNodes=[{"label":"${nodeName}","value":"${nodeName}"}]&countryCode=${countryCode}&providerName=${
                    providerName ? encodeURIComponent(providerName) : ''
                  }&nodeToggle=per-node`}
                >
                  <MenuItem>{dashBoard.title}</MenuItem>
                </Link>
              );
            })}
          </MenuList>
        </Menu>
      </>
    ) : dashboardResponse.status === 'error' ? (
      <Tooltip label="Error getting dashboards" bg="red.600">
        <Button onClick={refetchDashboards}>Retry</Button>
      </Tooltip>
    ) : (
      <Spinner size="sm" color="black" />
    );
  } else {
    link = `${import.meta.env.VITE_APP_MONITOR_URL}events?nodes=["${nodeName}"]`;
  }

  return (
    <Link to={`${link}`}>
      <Button width={30} ml={5}>
        <Icon as={MdMonitorHeart} boxSize={6} />
      </Button>
    </Link>
  );
};

export default OpenLink;
