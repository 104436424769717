import { Flex, VStack } from '@chakra-ui/react';
import { FC } from 'react';
import { BsDashLg, BsPlusLg } from 'react-icons/bs';
import { useMap } from 'react-map-gl';
import MapOverlayButton from '../MapOverlayButton';

type MapZoomControlProps = {};

const MapZoomControl: FC<MapZoomControlProps> = () => {
  const { 'dw-main-map': map } = useMap();

  return (
    <Flex id={'map-zoom-controls'}>
      <VStack alignItems="stretch" spacing={0}>
        <MapOverlayButton
          id={'map-zoom-in-button'}
          borderBottomRadius={0}
          onClick={() => {
            const zoomLevel = map?.getZoom();

            if (zoomLevel) {
              map?.zoomTo(Math.round(zoomLevel) + 1);
            } else {
              map?.zoomIn({});
            }
          }}
        >
          <BsPlusLg color="white" />
        </MapOverlayButton>
        <MapOverlayButton
          id={`map-zoom-out-button`}
          borderTopRadius={0}
          onClick={() => {
            map?.zoomOut();
          }}
        >
          <BsDashLg color="white" />
        </MapOverlayButton>
      </VStack>
    </Flex>
  );
};

export default MapZoomControl;
