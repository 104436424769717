import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import useDashQueryClient from '../../services/dashboardClient';

type Nodes = {
  label: string;
  value: string;
};

type NodeButtonOptions = {
  isDisabled: boolean;
  selectedNodes: string[];
  name: string;
  countryCode?: string | null;
  providerName?: string | null;
};

const AllNodesButton: FC<NodeButtonOptions> = ({
  isDisabled,
  selectedNodes,
  name,
  countryCode,
  providerName,
}) => {
  const dashboardResponse = useDashQueryClient();

  const dashboardsList =
    dashboardResponse.status === 'success' ? dashboardResponse.data.data.data : [];

  const nodeData: Nodes[] = selectedNodes.map((node) => {
    const data = {
      label: node,
      value: node,
    };
    return data;
  });

  let link = '';
  if (name === 'Analytics') {
    return (
      <Menu>
        <Button as={MenuButton} isDisabled={isDisabled}>
          View {selectedNodes.length} in Analytics
        </Button>
        <MenuList>
          {dashboardsList.map((dashBoard, index) => {
            return (
              <Link
                key={`${dashBoard}-${index}`}
                to={`${import.meta.env.VITE_APP_ANALYTICS_URL}dashboards/${
                  dashBoard.id
                }/?selectedNodes=${encodeURIComponent(
                  JSON.stringify(nodeData)
                )}&countryCode=${countryCode}&providerName=${
                  providerName ? encodeURIComponent(providerName) : ''
                }`}
              >
                <MenuItem>{dashBoard.title}</MenuItem>
              </Link>
            );
          })}
        </MenuList>
      </Menu>
    );
  } else {
    link = `${import.meta.env.VITE_APP_MONITOR_URL}events?nodes=${encodeURIComponent(
      JSON.stringify(selectedNodes)
    )}`;
    return (
      <Link to={`${link}`}>
        <Button isDisabled={isDisabled}>{`View ${selectedNodes.length} nodes in ${name}`}</Button>
      </Link>
    );
  }
};

export default AllNodesButton;
