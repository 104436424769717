import { QueryObserverOptions, useQueries, useQuery } from '@tanstack/react-query';
import centroid from '@turf/centroid';
import { feature, polygon } from '@turf/helpers';
import { BBox, Feature, Point, Properties } from '@turf/turf';
import type { Geometry } from 'geojson';
import isEqual from 'lodash/isEqual';
import { getColorFromScale } from '../helpers/ColorScales';
import { getBBox2d } from '../helpers/MapHelpers';
import booleanContains from '../helpers/booleanContains';
import { isValueValid } from '../helpers/isValueValid';
import { useLayerSettingsSelector } from '../hooks/useLayersSettings';
import { LayerKey } from '../reducers/layersSlice.types';
import {
  BuildingProperties,
  BuildingsResponse,
  GetBuildingOptions,
  GetBuildingsParams,
  RawBuilding,
  RawBuildingsResponse,
} from './buildingsClient.types';
import dwAxiosClient from './dwAxiosClient';

const addAdditionalProperties = (
  buildingProperties: RawBuilding,
  buildingLocation: Feature<Point, Properties>
) => {
  const monthlyAverageTonnage =
    isValueValid(buildingProperties.receivedBytesPerDay) &&
    isValueValid(buildingProperties.transmittedBytesPerDay)
      ? buildingProperties.receivedBytesPerDay * 30 + buildingProperties.transmittedBytesPerDay * 30
      : 0;
  const monthlyAveragePoints = isValueValid(buildingProperties.footfallPerDay)
    ? buildingProperties.footfallPerDay * 30
    : 0;
  const threeGPercentage =
    isValueValid(buildingProperties.count3G) && isValueValid(buildingProperties.points)
      ? buildingProperties.count3G === 0
        ? 0
        : (buildingProperties.count3G / buildingProperties.points) * 100
      : 0;
  const fourGPercentage =
    isValueValid(buildingProperties.count4G) && isValueValid(buildingProperties.points)
      ? buildingProperties.count4G === 0
        ? 0
        : (buildingProperties.count4G / buildingProperties.points) * 100
      : 0;
  const fiveGSaPercentage =
    isValueValid(buildingProperties.count5G) && isValueValid(buildingProperties.points)
      ? buildingProperties.count5G === 0
        ? 0
        : (buildingProperties.count5G / buildingProperties.points) * 100
      : 0;
  const fiveGNsaPercentage =
    isValueValid(buildingProperties.count5GNsa) && isValueValid(buildingProperties.points)
      ? buildingProperties.count5GNsa === 0
        ? 0
        : (buildingProperties.count5GNsa / buildingProperties.points) * 100
      : 0;
  const callServiceOnlyPercentage =
    isValueValid(buildingProperties.countCallServiceOnly) && isValueValid(buildingProperties.points)
      ? buildingProperties.countCallServiceOnly === 0
        ? 0
        : (buildingProperties.countCallServiceOnly / buildingProperties.points) * 100
      : 0;
  const noServicePercentage =
    isValueValid(buildingProperties.countNoService) && isValueValid(buildingProperties.points)
      ? buildingProperties.countNoService === 0
        ? 0
        : (buildingProperties.countNoService / buildingProperties.points) * 100
      : 0;
  const otherPercentage =
    isValueValid(buildingProperties.countOther) && isValueValid(buildingProperties.points)
      ? buildingProperties.countOther === 0
        ? 0
        : (buildingProperties.countOther / buildingProperties.points) * 100
      : 0;
  const wifiPercentage =
    isValueValid(buildingProperties.countWifi) && isValueValid(buildingProperties.points)
      ? buildingProperties.countWifi === 0
        ? 0
        : (buildingProperties.countWifi / buildingProperties.points) * 100
      : 0;

  const candidateForDenseAirSolution =
    isValueValid(buildingProperties.rssnr25Pc) &&
    isValueValid(buildingProperties.rsrp25Pc) &&
    isValueValid(buildingProperties.footfallDensity) &&
    buildingProperties.rssnr25Pc < 5 &&
    buildingProperties.rsrp25Pc < -100 &&
    buildingProperties.footfallDensity > 0.007
      ? 1
      : 0;

  const rssnrMeanColor = getColorFromScale('buildings', 'rssnrMean', buildingProperties.rssnrMean);
  const rssnr25PcColor = getColorFromScale('buildings', 'rssnr25Pc', buildingProperties.rssnr25Pc);
  const rsrpMeanColor = getColorFromScale('buildings', 'rsrpMean', buildingProperties.rsrpMean);
  const rsrp25PcColor = getColorFromScale('buildings', 'rsrp25Pc', buildingProperties.rsrp25Pc);
  const pointsColor = getColorFromScale('buildings', 'points', buildingProperties.points);
  const monthlyAverageTonnageColor = getColorFromScale(
    'buildings',
    'monthlyAverageTonnage',
    monthlyAverageTonnage
  );
  const monthlyAveragePointsColor = getColorFromScale('buildings', 'points', monthlyAveragePoints);
  const footfallDensityColor = getColorFromScale(
    'buildings',
    'footfallDensity',
    buildingProperties.footfallDensity
  );
  const thirdGenerationPercentColor = getColorFromScale(
    'buildings',
    'thirdGenerationPercent',
    buildingProperties.thirdGenerationPercent
  );
  const fiveGSaPercentageColor = getColorFromScale(
    'buildings',
    'fiveGSaPercentage',
    fiveGSaPercentage
  );
  const fiveGNsaPercentageColor = getColorFromScale(
    'buildings',
    'fiveGNsaPercentage',
    fiveGNsaPercentage
  );

  const beforeCapacityColor = getColorFromScale(
    'buildings',
    'beforeCapacity',
    buildingProperties.beforeCapacity
  );
  const wiredRatioColor = getColorFromScale(
    'buildings',
    'wiredRatio',
    buildingProperties.wiredRatio
  );
  const relayedRatioColor = getColorFromScale(
    'buildings',
    'relayedRatio',
    buildingProperties.relayedRatio
  );

  const candidateForDenseAirSolutionColor = getColorFromScale(
    'buildings',
    'candidateForDenseAirSolution',
    candidateForDenseAirSolution
  );

  const centroidLonLat = buildingLocation.geometry.coordinates
    .slice()
    .reverse()
    .map((v) => v.toFixed(5))
    .join(', ');

  const properties: BuildingProperties = {
    ...buildingProperties,
    locationLatLon: centroidLonLat,
    monthlyAverageTonnage,
    monthlyAveragePoints,
    threeGPercentage,
    fourGPercentage,
    fiveGSaPercentage,
    fiveGNsaPercentage,
    callServiceOnlyPercentage,
    noServicePercentage,
    otherPercentage,
    wifiPercentage,
    rssnrMeanColor,
    rsrpMeanColor,
    rssnr25PcColor,
    rsrp25PcColor,
    pointsColor,
    monthlyAverageTonnageColor,
    monthlyAveragePointsColor,
    footfallDensityColor,
    thirdGenerationPercentColor,
    fiveGSaPercentageColor,
    fiveGNsaPercentageColor,
    beforeCapacityColor,
    wiredRatioColor,
    relayedRatioColor,
    candidateForDenseAirSolution,
    candidateForDenseAirSolutionColor,
  };

  return properties;
};

const processGeoJson = (
  data: RawBuildingsResponse,
  { west, south, north, east }: { west: number; south: number; north: number; east: number }
) => {
  const buildingFeatures = data.features.reduce(
    (featuresAcc: Feature<Geometry, BuildingProperties>[], building) => {
      if (building === null) {
        return featuresAcc;
      }

      const buildingGeometry = building.geometry;

      // TODO: Work out what to do about GeometryCollections..
      if (buildingGeometry && buildingGeometry.type !== 'GeometryCollection') {
        // Check if the centroid is inside the box. Exclude the building if it's not.
        const boxGeometry = polygon([
          [
            [west, south],
            [west, north],
            [east, north],
            [east, south],
            [west, south],
          ],
        ]);

        const buildingCentroid = centroid(buildingGeometry);
        const doesContain = booleanContains(boxGeometry, buildingCentroid);
        if (!doesContain) {
          return featuresAcc;
        }

        const properties = addAdditionalProperties(building.properties, buildingCentroid);

        const buildingFeature = feature(buildingGeometry, properties, {
          id: building.id,
        });

        featuresAcc.push(buildingFeature);
      }

      return featuresAcc;
    },
    []
  );

  return buildingFeatures;
};

const getBuildingsByBox = async (params: GetBuildingsParams, options: GetBuildingOptions) => {
  const { west, south, east, north, countryCode, providerName } = params;

  const { signal } = options;

  if (countryCode === null || providerName === null) {
    return [] as Feature<Geometry, BuildingProperties>[];
  }

  const { data, request } = await dwAxiosClient.get<RawBuildingsResponse>(
    `/ResultBuilding/Box/${west}/${south}/${east}/${north}`,
    {
      params: { countryCode, providerName },
      signal,
      headers: {
        Accept: 'application/geo+json',
      },
    }
  );

  if (data && request.status === 200) {
    return processGeoJson(data, { west, south, east, north });
  }

  return [] as Feature<Geometry, BuildingProperties>[];
};

const useBuildingQueryClient = ({
  chunk,
  providerName,
  countryCode,
  enabled,
}: {
  chunk: BBox | null;
  providerName: string | null;
  countryCode: string | null;
  enabled: boolean;
}) => {
  const chunk2d = chunk ? getBBox2d(chunk) : [];
  const [west, south, east, north] = chunk2d;

  return useQuery<BuildingsResponse | null>(
    ['buildings', countryCode, providerName, chunk2d.join('|')],
    ({ signal }) => {
      return getBuildingsByBox(
        {
          west: west,
          south: south,
          east: east,
          north: north,
          countryCode,
          providerName,
        },
        { signal }
      );
    },
    {
      enabled,
      staleTime: 1000 * 60 * 60 * 5,
    }
  );
};

const useBuildingSingleLayerQueryClient = ({
  layerKey,
  providerName,
  countryCode,
  enabled,
}: {
  layerKey: LayerKey;
  providerName: string | null;
  countryCode: string | null;
  enabled: boolean;
}) => {
  const chunks = useLayerSettingsSelector(layerKey, 'visibleChunks', [], isEqual);

  const queries = chunks
    ? chunks.map((chunk) => {
        const chunk2d = chunk ? getBBox2d(chunk) : [];
        const [west, south, east, north] = chunk2d;

        const query: QueryObserverOptions<BuildingsResponse | null> = {
          queryKey: ['buildings', countryCode, providerName, chunk2d.join('|')],
          queryFn: ({ signal }: any) => {
            return getBuildingsByBox(
              {
                west: west,
                south: south,
                east: east,
                north: north,
                countryCode,
                providerName,
              },
              { signal }
            );
          },
          staleTime: 1000 * 60 * 60 * 5,
          enabled,
        };

        return query;
      })
    : [];

  return useQueries({ queries });
};

export { useBuildingQueryClient, useBuildingSingleLayerQueryClient };
