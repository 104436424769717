import { FC } from 'react';
import { Marker } from 'react-map-gl';
import { useAppSelector } from '../../hooks/useAppSelector';

const MapClickedMarker: FC = () => {
  const lngLat = useAppSelector((state) => state.mapSettings.markerLocation);

  if (lngLat === null) {
    return null;
  }
  return <Marker longitude={lngLat[0]} latitude={lngLat[1]} />;
};

export default MapClickedMarker;
