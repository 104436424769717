import {
  Box,
  Icon,
  Image,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import { FC, ReactNode } from 'react';
import { FilterConfig } from '../../helpers/layerConfig.types';

const LayerControlFilterWrapper: FC<{ children: ReactNode; filter: FilterConfig }> = ({
  children,
  filter,
}) => {
  return (
    <Box mt={'10px'} mb={'8px'} key={filter.metricCode} gridColumnStart={1} gridColumnEnd={3}>
      <Box>
        <Box
          as="span"
          mb="0"
          color="white"
          textShadow="0.4px 0.4px 0.6px hsl(0deg 0% 0% / 0.2),
                    1px 1.1px 1.5px  hsl(0deg 0% 0% / 0.17),
                    3.1px 3.4px 4.5px  hsl(0deg 0% 0% / 0.14),
                    8.2px 8.9px 11.9px  hsl(0deg 0% 0% / 0.11)"
        >
          {filter.name}
        </Box>
        {filter.description ? (
          <Popover trigger="hover" placement="bottom-start">
            <PopoverTrigger>
              <Icon name="help" color="white" ml="2" />
            </PopoverTrigger>
            <PopoverContent width="md">
              <PopoverBody whiteSpace="pre-line">
                <Box as="span" fontSize="sm">
                  {filter.description}
                </Box>
                {filter.descriptionImageUrl ? (
                  <Image src={filter.descriptionImageUrl} mt="3" />
                ) : null}
              </PopoverBody>
            </PopoverContent>
          </Popover>
        ) : null}
      </Box>
      {children}
    </Box>
  );
};

export default LayerControlFilterWrapper;
