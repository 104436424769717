import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  IconButton,
} from '@chakra-ui/react';
import { FC } from 'react';
import { VscFilter } from 'react-icons/vsc';
import { MetricConfig, NeutralHostSettingsConfig } from '../../helpers/layerConfig.types';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useLayerSettingsSelector } from '../../hooks/useLayersSettings';
import { setIsLayerFiltersExpanded, setIsLayerMetricsExpanded } from '../../reducers/layersSlice';
import { LayerKey } from '../../reducers/layersSlice.types';
import LayerControlMetrics from './LayerControlMetrics';
import LayerControlSettings from './LayerControlSettings';

type LayerControlMetricsDrawerProps = {
  layerKey: LayerKey;
  metrics: MetricConfig[];
  neutralHostSettings?: NeutralHostSettingsConfig[];
  displayFilter?: boolean;
};

const LayerControlMetricsDrawer: FC<LayerControlMetricsDrawerProps> = (props) => {
  const { layerKey, metrics, neutralHostSettings, displayFilter } = props;

  const dispatch = useAppDispatch();

  const isMetricsExpanded = useLayerSettingsSelector(layerKey, 'isMetricsExpanded', false);

  return (
    <Drawer
      isOpen={isMetricsExpanded}
      onClose={() => {}}
      placement="right"
      closeOnEsc
      trapFocus={false}
      size="sm"
    >
      <DrawerContent bg="blackAlpha.600" backdropFilter="blur(2px)">
        <DrawerCloseButton
          _hover={{ backgroundColor: 'brand.600' }}
          bgColor="brand.500"
          // size="lg"
          onClick={() => {
            dispatch(setIsLayerMetricsExpanded({ layerKey, isMetricsExpanded: false }));
          }}
        />
        <DrawerHeader>
          {displayFilter === false ? null : (
            <IconButton
              size="sm"
              aria-label={`Go to Filters`}
              title={`Go to Filters`}
              icon={<VscFilter size="1.2em" />}
              onClick={() => {
                dispatch(setIsLayerMetricsExpanded({ layerKey, isMetricsExpanded: false }));
                dispatch(
                  setIsLayerFiltersExpanded({
                    layerKey,
                    isFiltersExpanded: true,
                  })
                );
              }}
            />
          )}
        </DrawerHeader>
        <DrawerBody>
          <Box>
            <LayerControlMetrics layerKey={layerKey} metrics={metrics} />
            {neutralHostSettings ? (
              <LayerControlSettings layerKey={layerKey} neutralHostSettings={neutralHostSettings} />
            ) : null}
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default LayerControlMetricsDrawer;
