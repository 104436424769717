import { FC } from 'react';
import { Layer, Source } from 'react-map-gl';
import { makeLayerIdTiles } from '../../helpers/layerIdGenerators';
import { useAppSelector } from '../../hooks/useAppSelector';
import { useGetBeforeLayerId } from '../../hooks/useGetBeforeLayerId';
import { useLayerSettingsSelector } from '../../hooks/useLayersSettings';
import { LayerKey } from '../../reducers/layersSlice.types';

type TwoDTilesLayerProps = {
  layerKey: LayerKey;
  plotType: string;
  minZoom: number;
  maxZoom: number;
};

const TwoDTilesLayer: FC<TwoDTilesLayerProps> = (props) => {
  const { layerKey, plotType, maxZoom, minZoom } = props;

  const selectedCountryCode = useAppSelector((state) => state.contextSettings.selectedCountryCode);
  const selectedProviderName = useAppSelector(
    (state) => state.contextSettings.selectedProviderName
  );

  const selectedTileMetric = useLayerSettingsSelector(layerKey, 'selectedTileMetric', null);

  const layerId = makeLayerIdTiles(layerKey);
  const beforeId = useGetBeforeLayerId(layerId);

  if (selectedCountryCode && selectedProviderName && selectedTileMetric) {
    return (
      <>
        <Source
          key={`${layerKey}-${selectedCountryCode}/${selectedProviderName}/${selectedTileMetric}`}
          type="raster"
          tiles={[
            `${
              import.meta.env.VITE_APP_API_URL
            }/Tile/${selectedCountryCode}/${selectedProviderName}/${plotType}/${selectedTileMetric}/{z}/{x}/{y}`,
          ]}
          tileSize={512}
        >
          <Layer
            id={layerId}
            type="raster"
            beforeId={beforeId}
            maxzoom={maxZoom}
            minzoom={minZoom}
          />
        </Source>
      </>
    );
  }

  return null;
};

export default TwoDTilesLayer;
