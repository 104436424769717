import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type SearchSettingsState = {
  searchText: string;
};

const initialState: SearchSettingsState = {
  searchText: '',
};

const searchTextSettings = createSlice({
  name: 'searchTextSettings',
  initialState,
  reducers: {
    setSearchTextValue: (state, action: PayloadAction<SearchSettingsState['searchText']>) => {
      state.searchText = action.payload;
    },
  },
});

const { actions, reducer } = searchTextSettings;

export const { setSearchTextValue } = actions;

export default reducer;
