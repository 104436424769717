import { FC } from 'react';
import { ImCompass2 } from 'react-icons/im';
import { useMap } from 'react-map-gl';
import { useAppSelector } from '../../hooks/useAppSelector';
import MapOverlayButton from '../MapOverlayButton';

type MapResetNorthControlProps = {};

const MapResetNorthControl: FC<MapResetNorthControlProps> = () => {
  const bearing = useAppSelector((state) => state.mapSettings.viewState.bearing);
  const { 'dw-main-map': map } = useMap();

  return (
    <MapOverlayButton
      id={'map-reset-north-control-button'}
      disabled={bearing === 0}
      onClick={() => {
        map?.resetNorth();
      }}
    >
      <ImCompass2 size="1.5em" style={{ transform: 'rotate(135deg)' }} />
    </MapOverlayButton>
  );
};

export default MapResetNorthControl;
