import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  Flex,
  Heading,
} from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import { FC } from 'react';
import { LayerPartGeoJSONConfig } from '../../helpers/layerConfig.types';
import { useAppSelector } from '../../hooks/useAppSelector';
import useLayersClient from '../../services/LayersClient';
import ExportDataRow from './ExportDataRow';

type ExportDataDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
};

const ExportDataDrawer: FC<ExportDataDrawerProps> = (props) => {
  const { isOpen, onClose } = props;

  const layersClient = useLayersClient();

  const { data: layerConfig } = layersClient;

  const allLayersSettings = useAppSelector((state) => {
    return state.layersSettings;
  });

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      placement="bottom"
      closeOnEsc
      size="sm"
      trapFocus={false}
    >
      <DrawerContent bg="blackAlpha.600" backdropFilter="blur(2px)">
        <DrawerCloseButton
          id="export-data-button-close"
          _hover={{ backgroundColor: 'brand.600' }}
          bgColor="brand.500"
          onClick={onClose}
        />
        <DrawerHeader color="white">
          <Heading size="lg">Export</Heading>
        </DrawerHeader>
        <DrawerBody>
          <Flex align="flex-start" wrap="wrap">
            <AnimatePresence>
              {layerConfig
                ? layerConfig.flatMap((config) => {
                    return config.layerParts
                      .filter((layerPart): layerPart is LayerPartGeoJSONConfig => {
                        return layerPart.type === 'geojson';
                      })
                      .filter(() => {
                        const layer = allLayersSettings[config.layerId];
                        const isLayerOn = layer ? layer.isLayerOn : false;

                        return isLayerOn;
                      })
                      .map((layerPart) => {
                        return (
                          <motion.div
                            key={`export-row-${config.layerId}`}
                            initial={{ width: 0, marginRight: 0, opacity: 0 }}
                            animate={{ width: 'auto', marginRight: '1em', opacity: 1 }}
                            exit={{ width: 0, marginRight: 0, opacity: 0 }}
                            style={{ overflow: 'hidden' }}
                            transition={{ type: 'tween', duration: 0.25 }}
                          >
                            <ExportDataRow
                              name={config.name}
                              layerKey={config.layerId}
                              minZoom={layerPart.from}
                            />
                          </motion.div>
                        );
                      });
                  })
                : null}
            </AnimatePresence>
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default ExportDataDrawer;
