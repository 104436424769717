import { QueryObserverOptions, useQueries } from '@tanstack/react-query';
import { feature } from '@turf/helpers';
import { Feature, Point } from '@turf/turf';
import isEqual from 'lodash/isEqual';
import { getColorFromScale } from '../helpers/ColorScales';
import { getBBox2d } from '../helpers/MapHelpers';
import { useLayerSettingsSelector } from '../hooks/useLayersSettings';
import { LayerKey } from '../reducers/layersSlice.types';
import dwAxiosClient from './dwAxiosClient';
import {
  GetNQTResultsOptions,
  GetNQTResultsParams,
  NQTResultProperties,
  NQTResultsResponse,
  RawNQTResultsResponse,
} from './nqtResultsClient.types';

const getNQTResultsByBox = async (params: GetNQTResultsParams, options: GetNQTResultsOptions) => {
  const { bbox, countryCode, providerName, startUtc, endUtc } = params;

  const { signal } = options;

  if (bbox === null || countryCode === null || providerName === null) {
    return [] as Feature<Point, NQTResultProperties>[];
  }

  const response = await dwAxiosClient.get<RawNQTResultsResponse>(`/NqtPointsCDM/results`, {
    baseURL: import.meta.env.VITE_APP_NQT_API_URL,
    params: { countryCode, providerName, bbox: bbox.join(','), startUtc, endUtc },
    signal,
    responseType: 'json',
    headers: { Accept: 'application/geo+json' },
  });

  if (response.status === 200 && response.data) {
    const geoJsonData = response.data.data;

    const featuresWithColors = geoJsonData.features.map((f) => {
      const newProperties: NQTResultProperties = {
        ...f.properties,
        dltpAvgKbpsColor: getColorFromScale('nqt', 'dltpAvgKbps', f.properties.dltpAvgKbps),
        dltpScoreColor: getColorFromScale('nqt', 'dltpScore', f.properties.dltpScore),
        overallScoreColor: getColorFromScale('nqt', 'overallScore', f.properties.overallScore),
        rsrpAverageColor: getColorFromScale('nqt', 'rsrpAverage', f.properties.rsrpAverage),
        rsrpScoreColor: getColorFromScale('nqt', 'rsrpScore', f.properties.rsrpScore),
        sinrAverageColor: getColorFromScale('nqt', 'sinrAverage', f.properties.sinrAverage),
        sinrScoreColor: getColorFromScale('nqt', 'sinrScore', f.properties.sinrScore),
        ultpAvgKbpsColor: getColorFromScale('nqt', 'ultpAvgKbps', f.properties.ultpAvgKbps),
        ultpScoreColor: getColorFromScale('nqt', 'ultpScore', f.properties.ultpScore),
        voiceScoreColor: getColorFromScale('nqt', 'voiceScore', f.properties.voiceScore),
        webPageDownloadMsColor: getColorFromScale(
          'nqt',
          'webPageDownloadMs',
          f.properties.webPageDownloadMs
        ),
        webScoreColor: getColorFromScale('nqt', 'webScore', f.properties.webScore),
      };
      const featureWithColor = feature(f.geometry, newProperties);

      return featureWithColor;
    });

    return featuresWithColors;
  }

  return [] as Feature<Point, NQTResultProperties>[];
};

const useNQTsSingleLayerQueryClient = ({
  layerKey,
  providerName,
  countryCode,
  startUtc,
  endUtc,
  enabled,
}: {
  layerKey: LayerKey;
  providerName: string | null;
  countryCode: string | null;
  startUtc: Date;
  endUtc: Date;
  enabled: boolean;
}) => {
  const chunks = useLayerSettingsSelector(layerKey, 'visibleChunks', [], isEqual);

  const queries = chunks
    ? chunks.map((chunk) => {
        const chunk2d = chunk ? getBBox2d(chunk) : null;
        const queryKey = ['nqt', countryCode, providerName, chunk2d ? chunk2d.join('|') : null];

        const query: QueryObserverOptions<NQTResultsResponse | null> = {
          queryKey,
          queryFn: ({ signal }) => {
            return getNQTResultsByBox(
              {
                bbox: chunk2d,
                countryCode,
                providerName,
                startUtc,
                endUtc,
              },
              { signal }
            );
          },

          enabled: enabled && !!chunk2d,
          staleTime: 1000 * 60 * 60 * 5,
          refetchInterval: 1000 * 30,
        };

        return query;
      })
    : [];

  return useQueries({ queries });
};

export { useNQTsSingleLayerQueryClient };
