import { useQuery } from '@tanstack/react-query';
import bboxPolygon from '@turf/bbox-polygon';
import type { BBox } from '@turf/turf';
import axios from 'axios';

const accessToken = import.meta.env.VITE_APP_MAPBOX_TOKEN;

export interface MapboxSearchFeature {
  type: 'Feature';
  place_name: string;
  id: string;
  relevance: number;
  place_type: (
    | 'country'
    | 'region'
    | 'postcode'
    | 'district'
    | 'place'
    | 'locality'
    | 'neighborhood'
    | 'address'
    | 'poi'
  )[];
  center: [number, number];
  bbox?: BBox;
  geometry: GeoJSON.Geometry;
}

type MapboxGeocodingResponse = {
  type: 'FeatureCollection';
  features: MapboxSearchFeature[];
  query: string[];
  attribution: string;
};

const getLocations =
  (searchParam: string, country: string | null) =>
  async (): Promise<MapboxSearchFeature[] | null> => {
    if (searchParam === '' || country === null) {
      return null;
    }

    const { data } = await axios.get<MapboxGeocodingResponse>(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${searchParam}.json?country=${country}&access_token=${accessToken}`
    );

    const newData = data.features.map((f) => {
      const newGeometry = f.bbox ? bboxPolygon(f.bbox).geometry : f.geometry;

      return {
        ...f,
        geometry: newGeometry,
      };
    });

    return newData;
  };

const useMapboxGeocodingClient = ({
  searchParam,
  country,
}: {
  searchParam: string;
  country: string | null;
}) => {
  return useQuery(['locations', searchParam, country], getLocations(searchParam, country), {
    staleTime: 10 * 60 * 60 * 60 * 1000, // 10 hours!
  });
};

export default useMapboxGeocodingClient;
