import isEqual from 'lodash/isEqual';
import { FC } from 'react';
import { MultiValue, SingleValue } from 'react-select';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { useLayerMetaSelector } from '../../hooks/useLayersMeta';
import { setFilterValue } from '../../reducers/layersSlice';
import { LayerKey } from '../../reducers/layersSlice.types';
import ReactSelect, { Option } from '../ReactSelect';

type LayerControlFilterDropdownProps = {
  layerKey: LayerKey;
  filterMetricCode: string;
  caseSensitive: boolean | undefined;
};

const isMulti = (
  selected: MultiValue<Option> | SingleValue<Option>
): selected is MultiValue<Option> => {
  return Array.isArray(selected);
};

const LayerControlFilterDropdown: FC<LayerControlFilterDropdownProps> = (props) => {
  const { layerKey, filterMetricCode, caseSensitive } = props;
  const dispatch = useAppDispatch();

  const metaList = useLayerMetaSelector(layerKey, filterMetricCode, [], isEqual);

  const options = Array.isArray(metaList)
    ? metaList
        .reduce((acc: (string | number | boolean)[], item) => {
          if (typeof item === 'string' || typeof item === 'number') {
            const normalizedItem = caseSensitive ? item.toString() : item.toString().toLowerCase();

            if (!acc.includes(normalizedItem)) {
              acc.push(normalizedItem);
            }
          }

          if (typeof item === 'boolean') {
            if (!acc.includes(item)) {
              acc.push(item);
            }
          }

          return acc;
        }, [])
        .sort((a, b) => {
          const stringA = a.toString();
          const stringB = b.toString();
          return stringA.localeCompare(stringB);
        })
    : [];

  const filterValue = useAppSelector((state) => {
    const layer = state.layersSettings[layerKey];

    if (layer) {
      return layer.filterValues[filterMetricCode];
    }
  });

  const value: Option[] = Array.isArray(filterValue)
    ? filterValue.map((v) => {
        const label = typeof v === 'boolean' ? (v ? 'Yes' : 'No') : v.toString();
        return { value: v, label };
      })
    : typeof filterValue === 'string' ||
      typeof filterValue === 'number' ||
      typeof filterValue === 'boolean'
    ? [{ value: filterValue, label: filterValue.toString() }]
    : [];

  const optionsForSelect = [
    ...options.map((o) => {
      const label = typeof o === 'boolean' ? (o ? 'Yes' : 'No') : o.toString();
      return { value: o, label };
    }),
  ];

  return (
    <ReactSelect
      closeMenuOnSelect={false}
      value={value}
      onChange={(selected) => {
        if (isMulti(selected)) {
          dispatch(
            setFilterValue({
              layerKey,
              filterMetricCode,
              filterValue: selected.map((s) => s.value),
            })
          );
        }
      }}
      options={optionsForSelect}
      isMulti
    />
  );
};

export default LayerControlFilterDropdown;
