import { Grid } from '@chakra-ui/react';
import { FC } from 'react';
import { BuildingEditContextProvider } from './BuildingEditContext';
import BuildingEditForm from './BuildingEditForm';
import BuildingEditMap from './BuildingEditMap';

const BuildingEditContent: FC = () => {
  return (
    <BuildingEditContextProvider>
      <Grid
        width="100%"
        height="100%"
        flexGrow={1}
        templateColumns={{ md: '1fr', lg: '9fr 3fr' }}
        gridColumnGap="5"
      >
        <BuildingEditMap />
        <BuildingEditForm />
      </Grid>
    </BuildingEditContextProvider>
  );
};

export default BuildingEditContent;
