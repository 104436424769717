import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Position } from '@turf/turf';

export type DrawerKey = string | number;

export type DrawerFeatureProperties = {
  centroidLonLat?: Position;
  rssnrMetricRunId?: number;
  buildingId?: number;
  buildingName?: string;
  gridCellId?: number;
  regionId?: number;
  nodeName?: string;
  [name: string]: null | undefined | string | number | Array<string | number>;
};

type DrawersSettingsState = {
  openDrawer: DrawerKey | null;
  drawers: Record<
    DrawerKey,
    | {
        featureProperties: DrawerFeatureProperties | null;
      }
    | undefined
  >;
};

type DrawerKeyPayload = {
  drawerKey: DrawerKey;
};

type InitializeDrawerPayload = {
  featureProperties: DrawerFeatureProperties | null;
} & DrawerKeyPayload;

type SetIsDrawerOpenPayload = DrawerKeyPayload;

type CloseOtherDrawersAndOpenPayload = DrawerKeyPayload;

type SetDrawerFeaturePayload = {
  featureProperties: DrawerFeatureProperties;
} & DrawerKeyPayload;

type ClearDrawerFeaturePayload = {} & DrawerKeyPayload;

const initialState: DrawersSettingsState = {
  openDrawer: null,
  drawers: {},
};

const drawersSlice = createSlice({
  name: 'drawersSettings',
  initialState,
  reducers: {
    initializeDrawer: (state, action: PayloadAction<InitializeDrawerPayload>) => {
      state.drawers[action.payload.drawerKey] = {
        featureProperties: action.payload.featureProperties,
      };
    },
    setIsDrawerOpen: (state, action: PayloadAction<SetIsDrawerOpenPayload>) => {
      state.openDrawer = action.payload.drawerKey;
    },
    setDrawerFeatureProperties: (state, action: PayloadAction<SetDrawerFeaturePayload>) => {
      const drawer = state.drawers[action.payload.drawerKey];

      if (drawer) {
        drawer.featureProperties = action.payload.featureProperties;
      }
    },
    clearDrawerFeatureProperties: (state, action: PayloadAction<ClearDrawerFeaturePayload>) => {
      const drawer = state.drawers[action.payload.drawerKey];

      if (drawer) {
        drawer.featureProperties = null;
      }
    },
    closeOtherDrawersAndOpen: (state, action: PayloadAction<CloseOtherDrawersAndOpenPayload>) => {
      state.openDrawer = action.payload.drawerKey;
    },
    closeDrawer: (state) => {
      state.openDrawer = null;
    },
  },
});

const { actions, reducer } = drawersSlice;

export const { initializeDrawer, setIsDrawerOpen, closeOtherDrawersAndOpen, closeDrawer } = actions;

export default reducer;
