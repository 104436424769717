import { Button } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import booleanIntersects from '@turf/boolean-intersects';
import { featureCollection, polygon } from '@turf/helpers';
import { Feature, Geometry } from '@turf/turf';
import saveAs from 'file-saver';
import { FC } from 'react';
import { getBBox2d } from '../../helpers/MapHelpers';
import { useAppSelector } from '../../hooks/useAppSelector';
import { useLayerSettingsSelector } from '../../hooks/useLayersSettings';
import { usePLMNQueryClient } from '../../services/contextClient';

type ExportDataButtonProps = {
  name: string;
  layerKey: string | number;
};

const ExportDataButton: FC<ExportDataButtonProps> = (props) => {
  const { layerKey, name } = props;

  const queryClient = useQueryClient();

  const viewport = useAppSelector((state) => state.mapSettings.viewState.viewport);

  const south = viewport[0][0];
  const west = viewport[0][1];
  const north = viewport[1][0];
  const east = viewport[1][1];
  const viewportGeometry = polygon([
    [
      [south, west],
      [north, west],
      [north, east],
      [south, east],
      [south, west],
    ],
  ]);

  const extents = useLayerSettingsSelector(layerKey, 'visibleChunks', []);

  const chunks2d = extents.map((chunk) => {
    return getBBox2d(chunk);
  });

  const countryCode = useAppSelector((state) => state.contextSettings.selectedCountryCode);
  const providerName = useAppSelector((state) => state.contextSettings.selectedProviderName);

  const plmnQuery = usePLMNQueryClient({ countryCode, providerName });

  const plmns = plmnQuery.isSuccess ? plmnQuery.data : [];

  return (
    <Button
      id={`export-data-download-${layerKey}`}
      size="sm"
      onClick={() => {
        const queryCacheKey =
          layerKey === 'oss'
            ? [layerKey, countryCode, providerName, plmns]
            : [layerKey, countryCode, providerName];

        const boundaryPosition = layerKey === 'oss' ? 4 : 3;

        const queriesFromCache = queryClient.getQueriesData<(Feature<Geometry> | undefined)[]>({
          queryKey: queryCacheKey,
          predicate: (query) => {
            return chunks2d.some((chunk) => chunk.join('|') === query.queryKey[boundaryPosition]);
          },
        });

        if (queriesFromCache.length > 0) {
          const data = queriesFromCache
            .flatMap((i) => i[1])
            .filter((f): f is Feature<Geometry> => {
              if (f) {
                const doesIntersect = booleanIntersects(viewportGeometry, f);
                return doesIntersect;
              }

              return false;
            });

          const collection = featureCollection(data);
          const collectionString = JSON.stringify(collection);
          const blob = new Blob([collectionString], { type: 'application/json' });
          const date = new Date().toISOString();

          saveAs(
            blob,
            `${name}-${date}-${west}_${east}_${south}_${north}-${providerName}-${countryCode}.json`
          );
        }
      }}
    >
      Download
    </Button>
  );
};

export default ExportDataButton;
