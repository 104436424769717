//This file is to do the API CALL to the OSS layer
import { QueryObserverOptions, useQueries } from '@tanstack/react-query';
import { BBox, point, polygon } from '@turf/turf';
import isEqual from 'lodash/isEqual';
import booleanContains from '../helpers/booleanContains';
import { useLayerSettingsSelector } from '../hooks/useLayersSettings';
import { LayerKey } from '../reducers/layersSlice.types';
import { GetNodesResponse } from './da-sdk/types';
import daAxiosClient from './daAxiosClient';
import {
  GetOSSResultsOptions,
  GetOSSResultsParams,
  OSSResultResponse,
} from './ossResultsClient.types';

const ossResultsClients = async (
  params: GetOSSResultsParams,
  options: GetOSSResultsOptions
): Promise<OSSResultResponse> => {
  const { plmns, chunk } = params;

  const { signal } = options;

  const request = await daAxiosClient.nodes.getNodes({
    params: { plmn: plmns, technologyType: ['4G', '5G'] },
    axiosOptions: { signal },
  });

  const [west, south, east, north] = chunk;
  const bboxPolygon = polygon([
    [
      [west, south],
      [west, north],
      [east, north],
      [east, south],
      [west, south],
    ],
  ]);

  const dataMap = new Map();

  if (request.status === 200) {
    Object.entries(request.data.data).forEach(([k, v]) => {
      if (
        v.location.longitude &&
        v.location.latitude &&
        booleanContains(bboxPolygon, point([v.location.longitude, v.location.latitude]))
      )
        dataMap.set(k, v);
    });
  }

  const data: GetNodesResponse['data'] = Object.fromEntries(dataMap);

  const features: OSSResultResponse = Object.values(data).reduce((acc: OSSResultResponse, node) => {
    if (node.location.latitude && node.location.longitude) {
      const nodeAsPoint = point([node.location.longitude, node.location.latitude], node);

      acc.push(nodeAsPoint);
    }

    return acc;
  }, []);

  return features;
};

const useOSSSingleLayerQueryClient = ({
  layerKey,
  providerName,
  countryCode,
  plmns,
}: {
  layerKey: LayerKey;
  providerName: string | null;
  countryCode: string | null;
  plmns: string[] | null;
}) => {
  const chunks = useLayerSettingsSelector(layerKey, 'visibleChunks', [] as BBox[], isEqual);

  const queries = chunks
    ? chunks.reduce((acc: QueryObserverOptions<OSSResultResponse | null>[], chunk) => {
        if (plmns === null) {
          return acc;
        }

        const query: QueryObserverOptions<OSSResultResponse | null> = {
          queryKey: ['oss', countryCode, providerName, plmns, chunk.join('|')],
          queryFn: ({ signal }: any) => {
            return ossResultsClients({ plmns, chunk }, { signal });
          },
          enabled: plmns.length > 0,
          staleTime: 1000 * 60 * 5,
        };

        acc.push(query);

        return acc;
      }, [])
    : [];

  return useQueries({ queries });
};
export { useOSSSingleLayerQueryClient };
