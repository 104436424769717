import {
  Box,
  Divider,
  Flex,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Radio,
  RadioGroup,
  VStack,
} from '@chakra-ui/react';
import { FC } from 'react';
import { BsLayersHalf } from 'react-icons/bs';
import { mapStyles } from '../../config/mapStyles';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { setMapStyleUrl } from '../../reducers/mapSettings';
import MapOverlayButton from '../MapOverlayButton';

type MapLayerSelectProps = {};

const MapLayerSelect: FC<MapLayerSelectProps> = (props) => {
  const dispatch = useAppDispatch();

  const mapStyleUrl = useAppSelector((state) => {
    return state.mapSettings.mapStyleUrl;
  });

  return (
    <>
      <Popover trigger="hover" placement="top-start" id={'map-layer-select'}>
        <PopoverTrigger>
          <Flex tabIndex={1}>
            <MapOverlayButton>
              <BsLayersHalf color="white" size="1.4em" />
            </MapOverlayButton>
          </Flex>
        </PopoverTrigger>
        <PopoverContent>
          <Box padding={2}>
            <RadioGroup
              value={mapStyleUrl}
              onChange={(e) => {
                dispatch(setMapStyleUrl(e));
              }}
            >
              <VStack>
                {mapStyles.map((style, i) => {
                  return (
                    <Radio
                      id={`${style.name}-radio-button`}
                      key={`tilebutton${i}`}
                      value={style.url}
                    >
                      {style.name}
                    </Radio>
                  );
                })}
              </VStack>
            </RadioGroup>
            <Divider />
          </Box>
        </PopoverContent>
      </Popover>
    </>
  );
};

export default MapLayerSelect;
