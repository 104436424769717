export const connectionBackupColors = [
  '#8dd3c7',
  '#ffffb3',
  '#bebada',
  '#fb8072',
  '#80b1d3',
  '#fdb462',
  '#b3de69',
  '#fccde5',
  '#d9d9d9',
  '#bc80bd',
  '#ccebc5',
  '#ffed6f',
];

export const connectionColors: { [bandName: string]: string } = {
  '700 MHz APT (Band 28)': '#a50026',
  '700 MHz C (Band 13)': '#a50029',
  '800 Japan': '#d73027',
  '800 MHz (Band 20)': '#d73027',
  '850 MHz CLR (Band 5)': '#f46d43',
  '850 MHz Extended CLR (Band 26)': '#f46d89',
  '850': '#f46d43',
  '900 MHz (Band 8)': '#fdae61',
  '900 GSM': '#fdae61',
  '1700 MHz AWS-1 (Band 4)': '#fee08b',
  '1700 MHz Extended AWS (Band 66)': '#006587',
  '1800+ MHz (Band 3)': '#ffffbf',
  '1800 DCS': '#ffffbf',
  '1900 MHz (Band 2)': '#d9ef8b',
  '2100': '#a6d96a',
  '2100 MHz (Band 1)': '#a6d96a',
  'TD 2300 MHz (Band 40)': '#66bd63',
  'TD 2500 MHz (Band 41)': '#1a9850',
  '2600 MHz (Band 7)': '#006837',
  '35000 MHz (Band 7)': '#3288bd',
  Other: '#aaaaaa',
  Unknown: '#aaaaaa',
};

export const getBandOrder = (bandName: string): number => {
  switch (bandName) {
    case '700':
      return 10;
    case '700 MHz APT (Band 28)':
      return 11;
    case '800':
      return 30;
    case '800 MHz (Band 20)':
      return 31;
    case '850':
      return 40;
    case '850 MHz CLR (Band 5)':
      return 41;
    case '900 GSM':
      return 50;
    case '900 MHz (Band 8)':
      return 51;
    case '900':
      return 52;
    case '1800':
      return 60;
    case '1800+ MHz (Band 3)':
      return 61;
    case '2100':
      return 70;
    case '2100 MHz (Band 1)':
      return 71;
    case '2300':
      return 80;
    case 'TD 2300 MHz (Band 40)':
      return 81;
    case '2500':
      return 90;
    case 'TD 2500 MHz (Band 41)':
      return 91;
    case '2600':
      return 100;
    case '2600 MHz (Band 7)':
      return 101;
    case '35000':
      return 200;
    case '35000 MHz (Band 7)':
      return 201;
    case 'Unknown':
      return 1000;
    case 'Other':
      return 1001;
    default:
      return 900;
  }
};

export const getConnectionTypeOrder = (connectionName: string): number => {
  switch (connectionName) {
    case '5G':
      return 10;
    case '5G SA':
    case '5G-SA':
      return 11;
    case '5G NSA':
    case '5G-NSA':
      return 12;
    case '4G':
      return 20;
    case '3G':
      return 30;
    case 'CALL-SERVICE-ONLY':
      return 40;

    default:
      return 100;
  }
};
