import { FC } from 'react';
import { useMap } from 'react-map-gl';
import { useAppSelector } from '../../hooks/useAppSelector';
import MapOverlayButton from '../MapOverlayButton';

type MapResetPitchControlProps = {};

const MapResetPitchControl: FC<MapResetPitchControlProps> = () => {
  const pitch = useAppSelector((state) => state.mapSettings.viewState.pitch);
  const { 'dw-main-map': map } = useMap();

  return (
    <MapOverlayButton
      id={'map-reset-pitch-control-button'}
      onClick={() => {
        const pitchToSet = pitch === 0 ? 60 : 0;
        map?.easeTo({ pitch: pitchToSet, duration: 800 });
      }}
    >
      {pitch === 0 ? '3D' : '2D'}
    </MapOverlayButton>
  );
};

export default MapResetPitchControl;
