import { FC, Fragment } from 'react';
import { FilterConfig, LayerPart } from '../../helpers/layerConfig.types';
import { useLayerSettingsSelector } from '../../hooks/useLayersSettings';
import { LayerKey } from '../../reducers/layersSlice.types';
import ThreeDLayer from '../ThreeDLayer';
import TwoDPolygonController from '../TwoDLayer/TwoDPolygonController';
import TwoDPolygonLayer from '../TwoDLayer/TwoDPolygonLayer';
import TwoDTilesLayer from '../TwoDLayer/TwoDTilesLayer';

type TwoDLayerProps = {
  layerKey: LayerKey;
  layerParts: LayerPart[];
  layerFilters: FilterConfig[] | undefined;
};

const TwoDLayer: FC<TwoDLayerProps> = (props) => {
  const { layerKey, layerParts, layerFilters } = props;

  const isLayerOn = useLayerSettingsSelector(layerKey, 'isLayerOn', false);

  if (isLayerOn) {
    return (
      <>
        {layerParts.map((layerPart, i) => {
          if (layerPart.type === 'tile') {
            return (
              <TwoDTilesLayer
                key={`${layerKey}-${layerPart.type}-${i}`}
                layerKey={layerKey}
                plotType={layerPart.tilePlotType}
                minZoom={layerPart.from}
                maxZoom={layerPart.to}
              />
            );
          }

          if (layerPart.type === 'geojson') {
            return (
              <Fragment key={`${layerKey}-${layerPart.type}-${i}`}>
                <TwoDPolygonController
                  layerKey={layerKey}
                  layerChunkLevel={layerPart.layerChunkLevel}
                  minZoom={layerPart.from}
                />
                <TwoDPolygonLayer
                  layerKey={layerKey}
                  layerType={layerPart.layerType}
                  minZoom={layerPart.from}
                  maxZoom={layerPart.to}
                  layerFilters={layerFilters}
                  layerStyles={layerPart.layerStyles}
                  cluster={layerPart.cluster ?? false}
                />
              </Fragment>
            );
          }

          if (layerPart.type === '3d') {
            return (
              <Fragment key={`${layerKey}-${layerPart.type}-${i}`}>
                <TwoDPolygonController
                  layerKey={layerKey}
                  layerChunkLevel={layerPart.layerChunkLevel}
                  minZoom={layerPart.from}
                />
                <ThreeDLayer
                  layerKey={layerKey}
                  minZoom={layerPart.from}
                  maxZoom={layerPart.to}
                  layerFilters={layerFilters}
                />
              </Fragment>
            );
          }

          return null;
        })}
      </>
    );
  }

  return null;
};

export default TwoDLayer;
