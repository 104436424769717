import { DownloadIcon } from '@chakra-ui/icons';
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import saveAs from 'file-saver';
import { FC, useState } from 'react';
import { useAppSelector } from '../../hooks/useAppSelector';
import { useProvidersFromCurrentCountry } from '../../services/contextClient';
import daAxiosClient from '../../services/daAxiosClient';

type MnoAnalysisModalProps = {
  isOpen: boolean;
  onClose: () => void;
  featureId: string | number;
};

const MnoAnalysisModal: FC<MnoAnalysisModalProps> = (props) => {
  const { isOpen, onClose, featureId } = props;

  const [filenamePrefix, setFilenamePrefix] = useState('');

  const currentCountry = useAppSelector((s) => s.contextSettings.selectedCountryCode);
  const providers = useProvidersFromCurrentCountry();

  const { data, isSuccess, isLoading, isError } = useQuery({
    queryKey: ['mno-matrix', providers, featureId],
    queryFn: () => {
      if (currentCountry && providers && providers.length > 0) {
        const providerNames = providers
          .filter((p) => !p.name.startsWith('All'))
          .filter((p) => !p.name.startsWith('Dense'))
          .filter((p) => !p.isMvno)
          .map((p) => p.name);

        return daAxiosClient.analysis.getMnoMatrixBuildingImage(
          featureId,
          providerNames,
          currentCountry
        );
      }

      return null;
    },
    enabled: isOpen,
    staleTime: Infinity,
  });

  const blobAsUrl = isSuccess && data ? URL.createObjectURL(data.data) : null;

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      colorScheme="brand"
      size="6xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>MNO Comparative Analysis</ModalHeader>
        <ModalCloseButton id="mno-analysis-button-close" colorScheme="black" />
        <ModalBody>
          <Heading size="sm" mb="4">
            For Building ID: {featureId}
          </Heading>
          {isSuccess && blobAsUrl ? <Image src={blobAsUrl} /> : null}

          {isError ? <Button>Retry</Button> : null}
          {isLoading ? <Skeleton height="40vh" /> : null}
        </ModalBody>
        <ModalFooter>
          <FormControl as={Flex} flexGrow="0" width="auto" mr="4" alignItems="center">
            <FormLabel textAlign="end">Filename prefix:</FormLabel>

            <InputGroup>
              <Input
                value={filenamePrefix}
                onChange={(e) => {
                  setFilenamePrefix(e.target.value);
                }}
              />
            </InputGroup>
          </FormControl>
          <Button
            id="mno-analysis-button-download-image"
            size="sm"
            flexShrink="0"
            flexGrow="0"
            rightIcon={<DownloadIcon />}
            isLoading={isLoading}
            onClick={() => {
              if (isSuccess && data) {
                const dateString = format(new Date(), 'yyyyMMdd-HHmm');
                saveAs(
                  data.data,
                  `${
                    filenamePrefix ? `${filenamePrefix}_` : ''
                  }${dateString}_${featureId}_mno_matrix.png`
                );
              }
            }}
          >
            Download image
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default MnoAnalysisModal;
