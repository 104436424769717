import { Box, Flex } from '@chakra-ui/react';
import { FC } from 'react';
import type { BinScale as BinScaleType } from '../../helpers/layerConfig.types';

type BinScaleProps = {
  scale: BinScaleType['scale'];
  width: number;
};

const BinScale: FC<BinScaleProps> = (props) => {
  const { scale, width } = props;
  const numberOfSections = scale.length;

  return (
    <Flex justify="stretch" align="stretch" width={`${width}vw`}>
      {scale.map((c, i) => {
        return (
          <Flex
            key={`scale-section-${i}`}
            flexDirection="column"
            alignItems="end"
            justifyContent="start"
            width={`${width / numberOfSections}vw`}
          >
            <Box
              flexShrink="0"
              flexGrow="0"
              width="100%"
              height="10px"
              backgroundColor={c.color ?? 'transparent'}
            />
            <Box fontSize="0.7vw" color="white" transform="translateX(50%)">
              {c.maxLabel ?? c.max}
            </Box>
          </Flex>
        );
      })}
    </Flex>
  );
};

export default BinScale;
