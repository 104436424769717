import { Icon } from '@chakra-ui/react';
import { isAfter } from 'date-fns';
import { decodeJwt } from 'jose';
import { useEffect } from 'react';
import { AiOutlineUser } from 'react-icons/ai';
import { Outlet } from 'react-router-dom';
import { Logger } from './Logger';
import Result, { Either } from './Result';

export type jwtToken = {
  FirstName: string;
  LastName: string;
};

export type TokenPayload = {
  FirstName: string;
  LastName: string;
  Organization: string;
  PhoneNumber: string;
  ProviderId: string[];
  ViewDataValues: string;
  ViewRegionTag: string[];
  amr: string[];
  auth_time: number;
  client_id: string;
  email: string;
  exp: number;
  iat: number;
  idp: string;
  iss: string;
  jti: string;
  nbf: number;
  role: string[] | string;
  scope: string[];
  sub: string;
};

/**
 * @remarks the auth token is stored locally
 */

const AUTH_TOKEN_KEY = 'dw_authToken';

/**
 * Get the status of the token in storage.
 * @returns string
 */
export const tokenStatus = (): 'no-token' | 'expired' | 'valid' => {
  const tokenResult = getToken();

  if (Result.isSuccess(tokenResult)) {
    const expiryResult = getAuthProperty('exp');

    if (Result.isSuccess(expiryResult)) {
      const expiryDate = new Date(expiryResult.value * 1000);
      const isValid = isAfter(expiryDate, new Date());

      if (isValid) {
        return 'valid';
      } else {
        return 'expired';
      }
    }
  }

  return 'no-token';
};

export const removeToken = () => {
  localStorage.removeItem(AUTH_TOKEN_KEY);
};

/**
 * Get the token. Wrapped in Result.
 * @see {@link Result}
 */
export const getToken = (): Either<string> => {
  try {
    const tokenString = localStorage.getItem(AUTH_TOKEN_KEY);
    if (!tokenString) {
      return Result.failure('No token found');
    }

    return Result.success(tokenString);
  } catch (e: any) {
    Logger.error(e);
    return Result.failure('Error getting token');
  }
};

/**
 * Get a property from the auth token.
 * Generics should handle providing the type of the resulting value.
 * TS should warn if you pass a property name that doesn't exist on the {@link TokenPayload}
 *
 * @example
 * ```
 * type TokenPayload = {
 *  role: string[];
 * }
 * const authEmailResult = Auth.getAuthProperty('role');
 * // Type of authEmailResult should be string[]
 * ```
 *
 */
export const getAuthProperty = <K extends keyof TokenPayload>(
  propertyName: K
): Either<TokenPayload[K]> => {
  const tokenResult = getToken();

  if (Result.isSuccess(tokenResult)) {
    const token = tokenResult.value;
    const decodedToken = decodeJwt(token) as TokenPayload;
    const value = decodedToken[propertyName];

    if (value) {
      return Result.success(value);
    }

    Logger.error(`No value found in Auth Token for property ${propertyName}`);
    return Result.failure(`Property ${propertyName} not found`);
  }

  return tokenResult;
};

export const getInitials = () => {
  const token = localStorage.getItem('dw_authToken');
  if (token) {
    const jwt = decodeJwt(token) as jwtToken;
    const firstInitial = jwt.FirstName.charAt(0).toLocaleUpperCase();
    const lastInitial = jwt.LastName.charAt(0).toLocaleUpperCase();
    return `${firstInitial}${lastInitial}`;
  }
  return <Icon as={AiOutlineUser} />;
};

export const checkApplicationAccess = (role: string): boolean => {
  const tokenResult = getToken();

  if (tokenResult) {
    const rolesEither = getAuthProperty('role');

    if (Result.isSuccess(rolesEither)) {
      const rolesArray = rolesEither.value;

      if (rolesArray.includes(role)) {
        return true;
      }
    }
  }
  return false;
};

export const checkHasAnyRole = (roles: string[]): boolean => {
  const tokenResult = getToken();

  if (tokenResult) {
    const rolesEither = getAuthProperty('role');

    if (Result.isSuccess(rolesEither)) {
      const rolesArray = rolesEither.value;

      if (roles.some((role) => rolesArray.includes(role))) {
        return true;
      }
    }
  }

  return false;
};

export const isInternalUser = () => {
  const tokenResult = getToken();

  if (Result.isSuccess(tokenResult)) {
    const decodedToken = decodeJwt(tokenResult.value) as TokenPayload;

    const role = decodedToken.role;

    if (Array.isArray(role)) {
      return role.some((r) => r.includes('DenseAir'));
    } else {
      return role.includes('DenseAir');
    }
  } else {
    return false;
  }
};

export const AuthProvider = () => {
  const tokenResult = getToken();
  const URL = import.meta.env.VITE_APP_LOGIN_PATH;

  useEffect(() => {
    if (Result.isFailure(tokenResult)) {
      const location = window.location.href;
      // need to encode component so things like AT%26T become AT%2526T
      // otherwise we risk decoding AT%26T to AT&T on the other side and messing up params
      const encodedLocation = encodeURIComponent(location);
      const navigationUrl = `${URL}?redirectUrl=${encodedLocation}`;
      window.location.replace(navigationUrl);
    }
  }, [tokenResult, URL]);

  if (Result.isSuccess(tokenResult)) {
    return <Outlet />;
  }

  return null;
};
