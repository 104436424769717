import axios from 'axios';
import { getToken, removeToken, tokenStatus } from '../helpers/AuthProvider';
import { Logger } from '../helpers/Logger';
import Result from '../helpers/Result';
import { queryClient } from './queryClient';

const dwAxiosClient = axios.create({
  baseURL: import.meta.env.VITE_APP_API_URL,
});

dwAxiosClient.interceptors.request.use(
  (config) => {
    const currentTokenStatus = tokenStatus();

    if (currentTokenStatus === 'expired' || currentTokenStatus === 'no-token') {
      removeToken();
      queryClient.cancelQueries();
      const URL = import.meta.env.VITE_APP_LOGIN_PATH;
      const location = window.location.href;
      const encodedLocation = encodeURIComponent(location);
      const navigationUrl = `${URL}?redirectUrl=${encodedLocation}`;
      window.location.replace(navigationUrl);
    }

    const token = getToken();

    if (Result.isSuccess(token)) {
      config.headers.set('Authorization', `Bearer ${token.value}`);
    }

    if (Result.isFailure(token)) {
      Logger.error('Error setting token on request');
    }

    return config;
  },
  (error) => {
    Logger.error('DW Axios Client error:', error);
    return Promise.reject(error);
  },
  { synchronous: true }
);

export default dwAxiosClient;
