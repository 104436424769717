import { FC } from 'react';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { setExportDrawerOpen } from '../../reducers/modalSlice';
import ExportDataDrawer from './ExportDataDrawer';

type ExportDataProps = {};

const ExportData: FC<ExportDataProps> = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector((state) => state.modalSlice.isExportDrawerOpen);

  return (
    <ExportDataDrawer
      isOpen={isOpen}
      onClose={() => {
        dispatch(setExportDrawerOpen(false));
      }}
    />
  );
};

export default ExportData;
