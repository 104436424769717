import { Box, BoxProps } from '@chakra-ui/react';
import { hasFlag } from 'country-flag-icons';
import { AU, BE, GB, IE, NZ, PT, US } from 'country-flag-icons/react/3x2';
import { FC, ReactNode } from 'react';

type LazyFlagProps = {
  countryCode: string;
  width?: BoxProps['width'];
};

const lookup: { [countryCode: string]: ReactNode } = {
  AU: <AU title="Australia" />,
  BE: <BE title="Belgium" />,
  GB: <GB title="United Kingdom" />,
  IE: <IE title="Ireland" />,
  NZ: <NZ title="New Zealand" />,
  PT: <PT title="Portugal" />,
  US: <US title="United States" />,
};

const Flag: FC<LazyFlagProps> = (props) => {
  const { countryCode, width } = props;
  const FlagComponent = lookup[countryCode];

  if (hasFlag(countryCode) && FlagComponent) {
    return (
      <Box width={width} mr="2">
        {FlagComponent}
      </Box>
    );
  }

  return null;
};

export default Flag;
