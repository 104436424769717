import { Button } from '@chakra-ui/react';
import { useEffect } from 'react';
import { IoIosMan } from 'react-icons/io';
import { useMap } from 'react-map-gl';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../hooks/useAppSelector';
import {
  StreetViewInitialState,
  StreetViewPosition,
  StreetViewState,
} from '../../reducers/streetViewSlice';

const MapStreetViewButton = () => {
  const dispatch = useDispatch();
  const { 'dw-main-map': map } = useMap();
  const center = map?.getCenter();
  const isMapDraggableMarkerVisible = useAppSelector(
    (state) => state.streetViewStateSettings.streetViewLayerOn
  );
  const isStreetViewOpen = useAppSelector((s) => s.streetViewStateSettings.streetViewLayerOn);
  const viewport = useAppSelector((state) => state.mapSettings.viewState.viewport);

  useEffect(() => {
    if (map && center) {
      dispatch(StreetViewPosition({ lat: center.lat, lng: center.lng }));
    }
  }, [map, dispatch, center, viewport]);

  return (
    <Button
      id={'street-view-nav-button'}
      isActive={isStreetViewOpen}
      p={1}
      onClick={() => {
        dispatch(StreetViewState(!isMapDraggableMarkerVisible));
        dispatch(StreetViewInitialState(true));
      }}
    >
      <IoIosMan size={'25px'} />
    </Button>
  );
};

export default MapStreetViewButton;
