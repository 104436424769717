import { Input, InputGroup, InputRightElement, Spinner } from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import useDebounce from '../../hooks/useDebounce';
import { setSearchTextValue } from '../../reducers/searchSlice';
import useBuildingIdClient from '../../services/buildingIdClient';
import useMapboxGeocodingClient from '../../services/mapboxGeocodingClient';

const MapSearchInput: FC = () => {
  const dispatch = useAppDispatch();

  const country = useAppSelector((state) => {
    return state.contextSettings.selectedCountryCode;
  });

  const [inputValue, setInputValue] = useState('');

  const debouncedValue = useDebounce(inputValue, 500);

  useEffect(() => {
    dispatch(setSearchTextValue(debouncedValue));
  }, [dispatch, debouncedValue]);

  const { status: mapboxStatus } = useMapboxGeocodingClient({
    searchParam: debouncedValue,
    country,
  });

  const { status: dwStatus } = useBuildingIdClient({
    buildingId: debouncedValue,
  });

  return (
    <InputGroup>
      <Input
        id={'map-search-input'}
        placeholder={'Location...'}
        _placeholder={{ color: 'white' }}
        color="white"
        fontWeight="600"
        value={inputValue}
        onChange={(e) => {
          setInputValue(e.target.value);
        }}
        mb={3}
        flexShrink={1}
      />
      <InputRightElement>
        {mapboxStatus === 'loading' || dwStatus === 'loading' ? <Spinner color="white" /> : null}
      </InputRightElement>
    </InputGroup>
  );
};

export default MapSearchInput;
