import { QueryObserverOptions, useQueries } from '@tanstack/react-query';
import isEqual from 'lodash/isEqual';
import { getBBox2d } from '../helpers/MapHelpers';
import { useLayerSettingsSelector } from '../hooks/useLayersSettings';
import { LayerKey } from '../reducers/layersSlice.types';
import { getNeutralHostByBox, NeutralHostResponse } from './buildingsNeutralHostClient';

const useGridsNeutralHostQueryClient = ({
  layerKey,
  providerName,
  countryCode,
  enabled,
}: {
  layerKey: LayerKey;
  providerName: string | null;
  countryCode: string | null;
  enabled: boolean;
}) => {
  const chunks = useLayerSettingsSelector(layerKey, 'visibleChunks', [], isEqual);

  const queries = chunks
    ? chunks.map((chunk) => {
        const chunk2d = chunk ? getBBox2d(chunk) : [];
        const [west, south, east, north] = chunk2d;

        const query: QueryObserverOptions<NeutralHostResponse | null> = {
          queryKey: [layerKey, countryCode, providerName, chunk2d.join('|')],
          queryFn: ({ signal }: any) => {
            return getNeutralHostByBox(
              {
                west,
                south,
                east,
                north,
                countryCode,
                providerName,
                plotType: 'GNH',
              },
              { signal }
            );
          },
          staleTime: 1000 * 60 * 60 * 5,
          enabled,
        };

        return query;
      })
    : [];

  return useQueries({ queries });
};

export default useGridsNeutralHostQueryClient;
