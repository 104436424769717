import { Box, Flex, Grid, Text } from '@chakra-ui/react';
import { ArcElement, Chart as ChartJs, Legend, Tooltip } from 'chart.js';
import { FC } from 'react';
import { Pie } from 'react-chartjs-2';

type DetailsDrawerPieChartProps = {
  properties: { [propName: string]: string | number | (string | number)[] | null | undefined };
  config: { label: string; metricName: string; color: string }[];
  hidePieIfZero?: boolean;
  tableId: string;
};

ChartJs.register(ArcElement, Legend, Tooltip);

const DetailsDrawerPieChart: FC<DetailsDrawerPieChartProps> = (props) => {
  const { config, properties, hidePieIfZero = false, tableId } = props;
  const data = config.reduce((acc: { label: string; value: number; color: string }[], series) => {
    const value = properties[series.metricName];

    // TODO: parseInt?
    if (typeof value === 'number') {
      acc.push({ label: series.label, value, color: series.color });
    }

    if (value === undefined) {
      acc.push({ label: series.label, value: 0, color: series.color });
    }

    return acc;
  }, []);

  const totalCount = data.reduce((acc, curr) => {
    return acc + curr.value;
  }, 0);

  return (
    <Flex alignItems="center" mt="2">
      {hidePieIfZero && totalCount === 0 ? null : (
        <div style={{ maxWidth: '13vw', display: 'flex', justifyContent: 'center' }}>
          <Pie
            options={{
              responsive: false,
              plugins: {
                legend: { display: false },
              },
              radius: '100%',
            }}
            data={{
              labels: data.map((d) => d.label),
              datasets: [
                {
                  data: data.map((d) => d.value),
                  backgroundColor: data.map((d) => d.color),
                  borderWidth: 0,
                },
              ],
            }}
          />
        </div>
      )}
      <Box id={tableId} pl={'20px'}>
        <Grid templateColumns={'repeat(2, 1fr)'}>
          <Text
            textAlign={'center'}
            fontWeight={'bold'}
            color={'white'}
            borderBottom={'2px solid white'}
            mb={'10px'}
          >
            Total
          </Text>
          <Text
            textAlign={'right'}
            fontWeight={'bold'}
            color={'white'}
            borderBottom={'2px solid white'}
            mb={'10px'}
          >
            {totalCount}
          </Text>
        </Grid>
        <Box>
          <Grid
            gridTemplateRows={'repeat(7, 1fr)'}
            gridAutoFlow={'column'}
            gridGap={'5px'}
            gridColumnGap={'10px'}
          >
            {data.map((d) => {
              return (
                <Grid
                  templateColumns={'1fr 2fr 1fr'}
                  key={d.label}
                  borderBottom={'1px solid white'}
                >
                  <Box textAlign="center" padding={0}>
                    <span
                      style={{
                        width: '3em',
                        height: '0.9em',
                        display: 'inline-block',
                        backgroundColor: d.color,
                      }}
                    />
                  </Box>
                  <Box color="white" fontSize="xs" pl={'10px'} textAlign={'left'} id={d.label}>
                    {d.label}
                  </Box>
                  <Box color="white" fontSize="xs" pl={'10px'} textAlign={'right'}>
                    {d.value}
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </Flex>
  );
};

export default DetailsDrawerPieChart;
