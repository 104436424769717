import { useQuery } from '@tanstack/react-query';
import { Feature } from '@turf/turf';
import dwAxiosClient from './dwAxiosClient';

type Building = Feature<
  GeoJSON.Geometry,
  {
    hoverContent: string;
    name: string;
    popupContent: string;
    style: unknown;
  }
> & { id: string };

const getBuildingById = (buildingId: string) => async () => {
  if (buildingId === '') {
    return null;
  }

  const buildingIdAsNumber = parseInt(buildingId, 10);
  if (Number.isNaN(buildingIdAsNumber)) {
    return null;
  }

  const { data, status } = await dwAxiosClient.get<Building>(`/Building/${buildingId}`);

  if (status === 204) {
    return null;
  }

  if (status === 200) {
    return data;
  }

  return null;
};

const useBuildingIdClient = ({ buildingId }: { buildingId: string }) => {
  return useQuery(['buildingId', buildingId], getBuildingById(buildingId), {
    staleTime: 10 * 60 * 60 * 60 * 1000, // 10 hours!
  });
};

export default useBuildingIdClient;
