import { FC, ReactNode } from 'react';
import PositionedContainer from './PositionedContainer';

type BottomLeftProps = { children: ReactNode };

const BottomLeft: FC<BottomLeftProps> = (props) => {
  const { children } = props;

  return (
    <PositionedContainer
      children={children}
      gridColumn="1/4"
      gridRow="10/13"
      alignSelf="end"
      wrapperDisplay="flex"
    />
  );
};

export default BottomLeft;
