import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  Grid,
  GridItem,
  Heading,
  Input,
  Select,
} from '@chakra-ui/react';
import { feature, featureCollection } from '@turf/turf';
import { sub } from 'date-fns';
import { FC, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './RecentlyModifiedBuildingsModal.css';
import { Layer, Source } from 'react-map-gl';
import ReactSelect from 'react-select';
import { parse } from 'wellknown';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { setRecentlyUpdatedBuildingModalOpen } from '../../reducers/modalSlice';
import useGetKeysForRecentlyModifiedClient from '../../services/keysForRecentlyModifiedClient';
import useViewRecentlyModifiedBuildingsClient from '../../services/viewRecentlyModifiedBuildingsClient';
import DisplayTags from '../DisplayTags/DisplayTags';

type KeyValue = { key: string; label: string };

type UserInput = { key: string; value: string; comparison: string };

export type ApiValuesType = {
  keySearchTerm: string;
  // TODO: Validate
  keySearchType: 'EQUALS';
  valueSearchTerm: string;
  valueSearchType: string;
};

const RecentlyModifiedBuildingsModal: FC = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector((state) => state.modalSlice.isRecentlyUpdatedBuildingModalOpen);
  const [placeholderForAPIStartDate, setPlaceholderForAPIStartDate] = useState<Date | null>(null);
  const [startDate, setStartDate] = useState(sub(new Date(), { days: 7 }));
  const [selectedKey, setSelectedKey] = useState<KeyValue | null>(null);
  const [comparisonType, setComparisonType] = useState('EQUALS');
  const [inputValue, setInputValue] = useState('');
  const [allUserInputs, setAllUserInputs] = useState<UserInput[]>([]);
  const [allObjectValues, setAllObjectValues] = useState<ApiValuesType[]>([]);

  const options = useGetKeysForRecentlyModifiedClient().data?.map((option) => ({
    label: option.key,
    key: option.key,
  }));

  const convertObject = (obj: UserInput) => {
    return {
      keySearchTerm: obj.key,
      // Assuming 'EQUALS' will remain constant
      keySearchType: 'EQUALS',
      valueSearchTerm: obj.value,
      valueSearchType: obj.comparison,
    };
  };

  const handleAdd = () => {
    if (selectedKey && comparisonType && inputValue) {
      const newValue = {
        key: selectedKey.key,
        comparison: comparisonType,
        value: inputValue,
      };
      setAllUserInputs([...allUserInputs, newValue]);
      setSelectedKey(null);
      setComparisonType('EQUALS');
      setInputValue('');
    }
  };

  const handleDeleteTag = (key: string, value: string, comparison: string) => {
    setAllUserInputs(
      allUserInputs.filter(
        (input) => input.key !== key || input.value !== value || input.comparison !== comparison
      )
    );
  };

  const getRecentlyModifiedBuildings = useViewRecentlyModifiedBuildingsClient(
    placeholderForAPIStartDate,
    allObjectValues
  );

  const callTheApi = () => {
    const values = allUserInputs.map((value) => convertObject(value));
    setPlaceholderForAPIStartDate(startDate);
    setAllObjectValues(values as ApiValuesType[]);
  };

  const results = getRecentlyModifiedBuildings.data;

  const marker =
    !results || results === undefined ? null : (
      <>
        <Source
          id="building-search"
          type="geojson"
          data={featureCollection(
            results.reduce((acc: GeoJSON.Feature[], result) => {
              const geom = parse(result.pointWkt);

              if (geom) {
                acc.push(feature(geom, { ...result }));
              }

              return acc;
            }, [])
          )}
        >
          <Layer
            type="symbol"
            layout={{
              'icon-image': 'marker',
              'icon-allow-overlap': true,
              'icon-size': ['interpolate', ['linear'], ['zoom'], 10, 0.6, 14, 0.7, 20, 0.8],
              'icon-anchor': 'bottom',
            }}
          />
        </Source>
      </>
    );
  return (
    <>
      <Drawer
        id="building-search-drawer"
        size="sm"
        closeOnEsc
        isOpen={isOpen}
        onClose={() => {
          dispatch(setRecentlyUpdatedBuildingModalOpen(false));
        }}
        isFullHeight={false}
        trapFocus={false}
      >
        <DrawerContent bg="blackAlpha.700" color={'white'}>
          <DrawerCloseButton bg={'blackAlpha.500'} _hover={{ bg: 'blackAlpha.900' }} />
          <DrawerHeader>
            <Heading as={'h4'}>Recently Updated Buildings</Heading>
          </DrawerHeader>
          <DrawerBody>
            Select a date:(dd/mm/yyyy)
            <Box id={'date-picker'} data-test-id={'date-picker'} color={'black'}>
              <DatePicker
                id="building-search-datepicker-input"
                data-test-id="building-search-datepicker-input"
                className="datepicker-input-recently-updated"
                dateFormat="dd/MM/yyyy"
                selected={startDate}
                onChange={(date: Date) => {
                  setStartDate(date);
                }}
                maxDate={new Date()}
                timeIntervals={5}
                popperContainer={({ children }) => <Box zIndex="5">{children}</Box>}
              />
            </Box>
            {/* Key value pairs */}
            {options && options.length > 0 ? (
              <Grid templateColumns="repeat(1, 1fr)">
                <GridItem>
                  Key
                  <Box color={'black'} zIndex={100}>
                    <ReactSelect
                      id="building-search-select-a-key"
                      data-test-id="building-search-select-a-key"
                      isClearable
                      value={selectedKey}
                      options={options}
                      onChange={(e) => {
                        setSelectedKey(e);
                      }}
                    />
                  </Box>
                </GridItem>
                <GridItem>
                  Comparison type (Equals/Like)
                  <Select
                    id="building-search-select-a-type"
                    data-test-id="building-search-select-a-type"
                    bg={'white'}
                    color={'black'}
                    value={comparisonType}
                    onChange={(e) => setComparisonType(e.target.value)}
                  >
                    data-test-id="select-a-type"
                    <option data-test-id="select-a-type-option-equals" value="EQUALS">
                      Equals
                    </option>
                    <option data-test-id="select-a-type-option-like" value="LIKE">
                      Like
                    </option>
                  </Select>
                </GridItem>
                <GridItem>
                  Value
                  <Input
                    id="building-search-comparison-value"
                    data-test-id="building-search-comparison-value"
                    bg={'white'}
                    color={'black'}
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                  ></Input>
                </GridItem>
                <GridItem display={'block'} mt={'10px'} right={'10px'}>
                  <Button
                    id={'add-building-parameter-button'}
                    data-test-id={'add-building-parameter-button'}
                    onClick={handleAdd}
                  >
                    Add
                  </Button>
                </GridItem>
                <DisplayTags
                  listName="Parameters"
                  deleteAll={() => setAllUserInputs([])}
                  selectedTags={allUserInputs}
                  deleteTagFunc={handleDeleteTag}
                />
                <GridItem display={'block'} mt={'10px'} right={'10px'}>
                  <Button
                    id={'search-building-results-button'}
                    data-test-id={'search-building-results-button'}
                    isLoading={getRecentlyModifiedBuildings.isFetching}
                    onClick={() => callTheApi()}
                  >
                    Update
                  </Button>
                </GridItem>
              </Grid>
            ) : null}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      {marker}
    </>
  );
};

export default RecentlyModifiedBuildingsModal;
