import { useQuery } from '@tanstack/react-query';
import { FC, ReactNode } from 'react';
import daAxiosClient from '../services/daAxiosClient';

type IsUserPermissionedProps = {
  children: ReactNode;
  app: string;
  action: string;
  resourceType: string;
  resource: string;
};

const IsUserPermissioned: FC<IsUserPermissionedProps> = (props) => {
  const { children, action, app, resourceType, resource } = props;

  const env = import.meta.env.VITE_APP_DENSEPASS_ENV;

  const isAllowedResponse = useQuery({
    queryKey: ['permission', app, action, resourceType, resource],
    queryFn: () =>
      daAxiosClient.densepass.analyzePermission({
        body: {
          enquiry: [
            {
              actions: [`${app}:${action}`],
              resources: [`drn:${env}:${app}:${resourceType}:${resource}`],
            },
          ],
        },
      }),
    retry: false,
    refetchInterval: 1000 * 60 * 60, // 1 hour
    refetchOnWindowFocus: false,
    staleTime: 0,
  });

  const isAllowed =
    isAllowedResponse.isSuccess && isAllowedResponse.data.data.every((r) => r.effect === 'Allow')
      ? true
      : false;

  if (isAllowed) {
    return <>{children}</>;
  }

  return null;
};

export default IsUserPermissioned;
