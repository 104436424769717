import { Box, Heading } from '@chakra-ui/react';
import { FC } from 'react';
import { NeutralHostSettingsConfig } from '../../helpers/layerConfig.types';
import { useLayerSettingsSelector } from '../../hooks/useLayersSettings';
import { LayerKey } from '../../reducers/layersSlice.types';
import LayerControlSettingsSlider from './LayerControlSettingsSlider';

type LayerControlSettingsProps = {
  layerKey: LayerKey;
  neutralHostSettings: NeutralHostSettingsConfig[];
};

const LayerControlSettings: FC<LayerControlSettingsProps> = (props) => {
  const { layerKey, neutralHostSettings } = props;

  const selectedMetric = useLayerSettingsSelector(layerKey, 'selectedMetric', null);

  const selectedSettings = neutralHostSettings.filter(
    (setting) => setting.metricCode === selectedMetric
  );

  if (selectedSettings.length === 0) {
    return null;
  }

  return (
    <>
      <Heading size="sm" color="white" mt="3" mb="2">
        Settings
      </Heading>
      {selectedSettings.map((setting) => {
        return (
          <Box key={`nh-setting-${setting.name}`} mb="8">
            <Box
              as="span"
              mb="0"
              color="white"
              textShadow="0.4px 0.4px 0.6px hsl(0deg 0% 0% / 0.2),
                    1px 1.1px 1.5px  hsl(0deg 0% 0% / 0.17),
                    3.1px 3.4px 4.5px  hsl(0deg 0% 0% / 0.14),
                    8.2px 8.9px 11.9px  hsl(0deg 0% 0% / 0.11)"
            >
              {setting.name}
            </Box>
            <LayerControlSettingsSlider layerKey={layerKey} setting={setting} />
          </Box>
        );
      })}
    </>
  );
};

export default LayerControlSettings;
