import { Box, CloseButton, Flex, Heading, Spinner } from '@chakra-ui/react';
import { Status, Wrapper } from '@googlemaps/react-wrapper';
import { booleanPointInPolygon, point, polygon } from '@turf/turf';
import { isEqual } from 'lodash';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import {
  StreetViewFacingDirection,
  StreetViewInitialState,
  StreetViewPosition,
  StreetViewState,
} from '../../reducers/streetViewSlice';

const render = (status: Status) => {
  return <Spinner />;
};

const MyMapComponent: FC = () => {
  const isStreetViewOn = useAppSelector((s) => s.streetViewStateSettings.streetViewLayerOn);

  const mapRef = useRef<HTMLDivElement>(null);
  const panoRef = useRef<HTMLDivElement>(null);
  const map = useRef<google.maps.Map>();
  const panorama = useRef<google.maps.StreetViewPanorama>();
  const streetViewService = useRef<google.maps.StreetViewService>();

  const [showPanorama, setShowPanorama] = useState<boolean>(true);

  const markerPosition = useAppSelector(
    (s) => s.streetViewStateSettings.streetViewCoordinates,
    isEqual
  );
  const dispatch = useAppDispatch();
  const isStreetViewOpen = useAppSelector((s) => s.streetViewStateSettings.streetViewLayerOn);
  const viewport = useAppSelector((state) => state.mapSettings.viewState.viewport);
  const south = viewport[0][0];
  const west = viewport[0][1];
  const north = viewport[1][0];
  const east = viewport[1][1];
  const viewportFeature = useMemo(
    () =>
      polygon([
        [
          [south, west],
          [north, west],
          [north, east],
          [south, east],
          [south, west],
        ],
      ]),
    [north, south, east, west]
  );

  useEffect(() => {
    if (markerPosition) {
      const isFeatureInViewport = booleanPointInPolygon(
        point([markerPosition.lng, markerPosition.lat]),
        viewportFeature
      );

      if (!isFeatureInViewport) {
        dispatch(StreetViewState(false));
      }

      if (isFeatureInViewport && isStreetViewOpen) {
        dispatch(StreetViewPosition({ lat: markerPosition.lat, lng: markerPosition.lng }));
      }
    }
  }, [dispatch, markerPosition, viewport, viewportFeature, isStreetViewOpen]);

  useEffect(() => {
    dispatch(StreetViewInitialState(true));
  }, [dispatch]);

  useEffect(() => {
    if (mapRef.current && panoRef.current) {
      map.current = new google.maps.Map(mapRef.current, {
        center: markerPosition,
        zoom: 14,
      });

      panorama.current = new google.maps.StreetViewPanorama(panoRef.current, {
        position: markerPosition,
        pov: {
          heading: 34,
          pitch: 10,
        },
      });

      panorama.current.addListener('position_changed', () => {
        if (panorama.current) {
          const newPosition = panorama.current.getPosition();

          if (newPosition) {
            const lat = newPosition.lat();
            const lng = newPosition.lng();
            dispatch(StreetViewPosition({ lng, lat }));
          }
        }
      });

      panorama.current.addListener('pov_changed', () => {
        if (panorama.current) {
          dispatch(StreetViewFacingDirection(panorama.current.getPov().heading));
        }
      });

      if (map.current && panorama.current && streetViewService.current) {
        map.current.setStreetView(panorama.current);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const isMapDraggableMarkerVisible = useAppSelector(
    (state) => state.streetViewStateSettings.streetViewLayerOn
  );

  useEffect(() => {
    streetViewService.current = new google.maps.StreetViewService();

    if (isMapDraggableMarkerVisible) {
      streetViewService.current
        .getPanorama({
          location: markerPosition,
          radius: 50,
          preference: google.maps.StreetViewPreference.NEAREST,
        })
        .then((availablePanoData) => {
          setShowPanorama(true);

          if (availablePanoData.data.location?.pano && panorama.current) {
            panorama.current.setPano(availablePanoData.data.location.pano);
          } else if (availablePanoData.data.location!?.pano && panorama.current) {
            panorama.current.setPano(availablePanoData.data.location.pano);
          }
        })
        .catch((e) => {
          setShowPanorama(false);
        });
    }

    if (panorama.current && isMapDraggableMarkerVisible) {
      panorama.current.setPosition(markerPosition);
    }
  }, [markerPosition, isMapDraggableMarkerVisible]);

  return (
    <Box
      id={'street-view-panel'}
      display={isStreetViewOn ? 'block' : 'none'}
      width={'500px'}
      height={'550px'}
      p={'5px'}
      background={'white'}
      borderRadius={'5px'}
      position={'absolute'}
      right={2}
      bottom={100}
      pointerEvents={'all'}
    >
      <Flex justifyContent={'space-between'}>
        <Heading color={'primary'} size={'sm'}>
          Street View
        </Heading>
        <CloseButton
          background={'primary'}
          mb={'10px'}
          alignSelf={'end'}
          onClick={() => {
            dispatch(StreetViewState(false));
          }}
        />
      </Flex>

      <Box display={!showPanorama ? 'none' : 'block'} ref={mapRef} id="map" />
      <Box
        display={!showPanorama ? 'none' : 'block'}
        borderRadius={'5px'}
        ref={panoRef}
        id="pano"
        style={{ width: '100%', height: '90%' }}
      />
      <Box display={showPanorama ? 'none' : 'block'}>
        No panorama available on the selected location
      </Box>
    </Box>
  );
};

const StreetView: FC = () => {
  return (
    <Wrapper apiKey={`${import.meta.env.VITE_APP_GOOGLE_MAPS_TOKEN}`} render={render}>
      <MyMapComponent />
    </Wrapper>
  );
};

export default StreetView;
