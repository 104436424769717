import { SymbolLayer } from 'mapbox-gl';
import { DetailConfig, FilterConfig, LayerStyles } from './layerConfig.types';

export const defaultLayerStyles: LayerConfig = {
  chunkSize: 14,
  layerStyles: {
    main: [
      {
        type: 'fill',
        paint: {
          'fill-color': ['rgba', 155, 0, 1, 0.5],
          'fill-outline-color': ['rgb', 155, 155, 155],
        },
      },
      {
        type: 'circle',
        paint: {
          'circle-color': ['rgba', 155, 155, 155, 0.5],
          'circle-stroke-color': ['rgb', 155, 155, 155],
        },
      },
    ],
  },
};

type LayerConfig = {
  layerStyles: LayerStyles;
  details?: DetailConfig[];
  filters?: FilterConfig[];
  chunkSize: number;
};

const awesomeIconStyle: Omit<SymbolLayer, 'id' | 'source'> = {
  type: 'symbol',
  layout: {
    'icon-allow-overlap': true,
    'icon-anchor': 'bottom',
    'icon-image': [
      'concat',
      ['get', 'awesomeIcon', ['get', 'style']],
      '_',
      ['get', 'awesomeColor', ['get', 'style']],
    ],
    'icon-size': ['interpolate', ['linear'], ['zoom'], 14, 1, 18, 0.9, 20, 0.8, 22, 0.7],
  },
};

const cellMapperConfig: LayerConfig = {
  chunkSize: 12,
  filters: [
    {
      metricCode: 'jsonContent.verified',
      type: 'dropdown',
      name: 'Verified',
    },
    { metricCode: 'jsonContent.provider_name', type: 'dropdown', name: 'Provider Name' },
    { metricCode: 'jsonContent.system', type: 'dropdown', name: 'System' },
    { metricCode: 'jsonContent.subsystem', type: 'dropdown', name: 'Subsystem' },
    { metricCode: 'jsonContent.generation', type: 'dropdown', name: 'Generation' },
    { metricCode: 'jsonContent.pci', type: 'dropdown', name: 'PCI' },
    { metricCode: 'jsonContent.band', type: 'dropdown', name: 'Band' },
    { metricCode: 'jsonContent.sid', type: 'dropdown', name: 'SID' },
  ],
  details: [
    {
      sectionKey: 'cellMapperUK',
      sectionTitle: 'Cell Locations',
      items: [
        {
          type: 'grid',
          itemKey: 'details',
          rows: [
            {
              metricName: 'country_code',
              showColor: false,
              rowKey: 'country_code',
              rowText: 'Country Code',
              accessor: 'jsonContent.country_code',
            },
            {
              metricName: 'provider_name',
              showColor: false,
              rowKey: 'provider',
              rowText: 'Provider Name',
              accessor: 'jsonContent.provider_name',
            },
            {
              metricName: 'mnc',
              showColor: false,
              rowKey: 'mnc',
              rowText: 'MNC',
              accessor: 'jsonContent.mnc',
            },
            {
              metricName: 'jsonContent.verified',
              showColor: false,
              rowKey: '1',
              rowText: 'Verified',
              accessor: 'jsonContent.verified',
            },
            {
              metricName: 'cellid',
              showColor: false,
              rowKey: 'cellid',
              rowText: 'Cell ID',
              accessor: 'jsonContent.cellid',
              format: 'text',
            },
            {
              metricName: 'pci',
              showColor: false,
              rowKey: 'pci',
              rowText: 'PCI',
              accessor: 'jsonContent.pci',
              format: 'text',
            },
            {
              metricName: 'lac',
              showColor: false,
              rowKey: 'lac',
              rowText: 'LAC',
              accessor: 'jsonContent.lac',
              format: 'text',
            },
            {
              metricName: 'tac',
              showColor: false,
              rowKey: 'tac',
              rowText: 'TAC',
              accessor: 'jsonContent.tac',
              format: 'text',
            },
            {
              metricName: 'sid',
              showColor: false,
              rowKey: 'sid',
              rowText: 'SID',
              accessor: 'jsonContent.sid',
            },
            {
              metricName: 'band',
              showColor: false,
              rowKey: 'band',
              rowText: 'Band',
              accessor: 'jsonContent.band',
            },
            {
              metricName: 'bearing',
              showColor: false,
              rowKey: 'bearing',
              rowText: 'Bearing',
              accessor: 'jsonContent.bearing',
              decimalPlaces: 4,
            },
            {
              metricName: 'generation',
              showColor: false,
              rowKey: 'generation',
              rowText: 'Generation',
              accessor: 'jsonContent.generation',
            },
            {
              metricName: 'system',
              showColor: false,
              rowKey: 'system',
              rowText: 'System',
              accessor: 'jsonContent.system',
            },
            {
              metricName: 'subsystem',
              showColor: false,
              rowKey: 'subsystem',
              rowText: 'Subsystem',
              accessor: 'jsonContent.subsystem',
            },
            {
              metricName: 'bandwidth',
              showColor: false,
              rowKey: 'bandwidth',
              rowText: 'Bandwidth',
              accessor: 'jsonContent.bandwidth',
              format: 'text',
            },
            {
              metricName: 'max_signal',
              showColor: false,
              rowKey: 'max_signal',
              rowText: 'Max Signal',
              accessor: 'jsonContent.max_signal',
              decimalPlaces: 4,
            },
            {
              metricName: 'latitude',
              showColor: false,
              rowKey: 'latitude',
              rowText: 'Latitude',
              accessor: 'jsonContent.latitude',
              decimalPlaces: 8,
            },
            {
              metricName: 'longitude',
              showColor: false,
              rowKey: 'longitude',
              rowText: 'Longitude',
              accessor: 'jsonContent.longitude',
              decimalPlaces: 8,
            },
          ],
        },
      ],
    },
  ],
  layerStyles: {
    main: [
      {
        type: 'symbol',
        layout: {
          'icon-allow-overlap': true,
          'icon-ignore-placement': true,
          'icon-anchor': 'bottom',
          'icon-size': 0.8,
          'icon-image': [
            'concat',
            'cell_',
            ['case', ['get', 'verified', ['get', 'jsonContent']], 'green', 'red'],
          ],
          'text-field': ['get', 'provider_name', ['get', 'jsonContent']],
          // 'text-field': [
          //   'case',
          //   ['get', 'cluster'],
          //   ['concat', 'cluster_', ['get', 'provider_name', ['get', 'jsonContent']]],
          //   ['get', 'provider_name', ['get', 'jsonContent']],
          // ],
          'text-size': 14,
          'text-offset': [0, 0.4],
          'text-allow-overlap': true,
          'text-ignore-placement': true,
        },
      },
    ],
    grey: [
      {
        type: 'symbol',
        layout: {
          'icon-allow-overlap': true,
          'icon-ignore-placement': true,
          'icon-anchor': 'bottom',
          'icon-size': 0.8,
          'icon-image': 'cell_grey',
          'text-field': ['get', 'provider_name', ['get', 'jsonContent']],
          'text-size': 14,
          'text-offset': [0, 0.4],
          'text-allow-overlap': true,
          'text-ignore-placement': true,
        },
      },
    ],
  },
};

const layerStyles: { [layerId: string]: LayerConfig } = {
  // Building Outline
  '-1': {
    chunkSize: 15,
    layerStyles: {
      main: [
        { type: 'fill', paint: { 'fill-color': ['rgb', 155, 155, 155], 'fill-opacity': 0.5 } },
      ],
    },
    details: [
      {
        sectionKey: 'building-outline',
        sectionTitle: `Building Outline`,
        items: [
          {
            type: 'html',
            itemKey: 'building-outline-content',
            rows: [
              {
                metricName: 'popupContent',
              },
            ],
          },
        ],
      },
    ],
  },
  // UFB Coverage
  '12': {
    chunkSize: 13,
    layerStyles: {
      main: [
        {
          type: 'fill',
          paint: {
            'fill-color': ['rgb', 250, 205, 124],
            'fill-opacity': 0.2,
            'fill-outline-color': ['rgb', 0, 0, 0],
          },
        },
      ],
    },
    details: [
      {
        sectionKey: 'building-outline',
        sectionTitle: `Building Outline`,
        items: [
          {
            type: 'html',
            itemKey: 'building-outline-content',
            rows: [
              {
                metricName: 'popupContent',
              },
            ],
          },
        ],
      },
    ],
  },
  // Dublin lighting
  '17': {
    chunkSize: 16,
    layerStyles: {
      main: [
        {
          type: 'circle',
          layout: {},
          paint: {
            'circle-color': ['get', 'fillColor', ['get', 'style']],
            'circle-stroke-color': ['get', 'color', ['get', 'style']],
          },
        },
      ],
    },
    details: [
      {
        sectionKey: 'dublin-lighting',
        sectionTitle: `Dublin Lighting`,
        items: [
          {
            type: 'html',
            itemKey: 'lighting-content',
            rows: [
              {
                metricName: 'popupContent',
              },
            ],
          },
        ],
      },
    ],
  },
  // NZ Vodafone Macro
  '82': {
    chunkSize: 12,
    layerStyles: {
      main: [awesomeIconStyle],
    },
    details: [
      {
        sectionKey: 'vodafone-macro-cells',
        sectionTitle: `Vodafone Macro Cells`,
        items: [
          {
            type: 'html',
            itemKey: 'vodafone-macro-cells-content',
            rows: [
              {
                metricName: 'popupContent',
              },
            ],
          },
        ],
      },
    ],
  },
  // NZ Spark Macro
  '83': {
    chunkSize: 12,
    layerStyles: {
      main: [awesomeIconStyle],
    },
    details: [
      {
        sectionKey: 'spark-macro-cells',
        sectionTitle: `Spark Macro Cells`,
        items: [
          {
            type: 'html',
            itemKey: 'spark-macro-cells-content',
            rows: [
              {
                metricName: 'popupContent',
              },
            ],
          },
        ],
      },
    ],
  },
  // NZ 2Degrees Macro
  '84': {
    chunkSize: 12,
    layerStyles: {
      main: [awesomeIconStyle],
    },
    details: [
      {
        sectionKey: '2degrees-macro-cells',
        sectionTitle: `2degrees Macro Cells`,
        items: [
          {
            type: 'html',
            itemKey: '2degrees-macro-cells-content',
            rows: [
              {
                metricName: 'popupContent',
              },
            ],
          },
        ],
      },
    ],
  },
  // NZ RCG Macro
  '85': {
    chunkSize: 8,
    layerStyles: {
      main: [awesomeIconStyle],
    },
    details: [
      {
        sectionKey: 'rcg-macro',
        sectionTitle: `RCG Macro Cells`,
        items: [
          {
            type: 'html',
            itemKey: 'rcg-cells-content',
            rows: [
              {
                metricName: 'popupContent',
              },
            ],
          },
        ],
      },
    ],
  },
  // IE Traffic Signals
  '89': {
    chunkSize: 15,
    layerStyles: {
      main: [awesomeIconStyle],
    },
    details: [
      {
        sectionKey: 'traffic-signals',
        sectionTitle: `Traffic Signals`,
        items: [
          {
            type: 'html',
            itemKey: 'signals-content',
            rows: [
              {
                metricName: 'popupContent',
              },
            ],
          },
        ],
      },
    ],
  },
  // IE Traffic CCTV
  '90': {
    chunkSize: 13,
    layerStyles: {
      main: [awesomeIconStyle],
    },
    details: [
      {
        sectionKey: 'traffic-cctv',
        sectionTitle: `Traffic CCTV`,
        items: [
          {
            type: 'html',
            itemKey: 'cctv-content',
            rows: [
              {
                metricName: 'popupContent',
              },
            ],
          },
        ],
      },
    ],
  },
  // IE Variable message
  '91': {
    chunkSize: 13,
    layerStyles: {
      main: [awesomeIconStyle],
    },
    details: [
      {
        sectionKey: 'variable-message',
        sectionTitle: `Variable Message`,
        items: [
          {
            type: 'html',
            itemKey: 'vMessage-content',
            rows: [
              {
                metricName: 'popupContent',
              },
            ],
          },
        ],
      },
    ],
  },
  // IE Telecoms ducting
  '92': {
    chunkSize: 12,
    layerStyles: {
      main: [
        {
          type: 'line',
          layout: {},
          paint: {
            'line-color': ['get', 'color', ['get', 'style']],
            'line-width': ['get', 'weight', ['get', 'style']],
          },
        },
      ],
    },
    details: [
      {
        sectionKey: 'telecoms-ducting',
        sectionTitle: `Telecoms Ducting`,
        items: [
          {
            type: 'html',
            itemKey: 'telecoms-ducting-content',
            rows: [
              {
                metricName: 'popupContent',
              },
            ],
          },
        ],
      },
    ],
  },
  // IE DCC Ducting
  '93': {
    chunkSize: 16,
    layerStyles: {
      main: [
        {
          type: 'line',
          layout: {},
          paint: {
            'line-color': ['get', 'color', ['get', 'style']],
            'line-width': ['get', 'weight', ['get', 'style']],
          },
        },
        {
          type: 'circle',
          layout: {},
          paint: {
            'circle-color': 'red',
          },
        },
      ],
    },
    details: [
      {
        sectionKey: 'dcc-ducting',
        sectionTitle: `DCC Details`,
        items: [
          {
            type: 'html',
            itemKey: 'bins-content',
            rows: [
              {
                metricName: 'popupContent',
              },
            ],
          },
        ],
      },
    ],
  },
  // Dublin Lighting v1
  '94': {
    chunkSize: 16,
    layerStyles: {
      main: [
        {
          type: 'circle',
          layout: {},
          paint: {
            'circle-color': ['get', 'fillColor', ['get', 'style']],
            'circle-stroke-color': ['get', 'color', ['get', 'style']],
          },
        },
      ],
    },
    details: [
      {
        sectionKey: 'dublin-lighting-v1',
        sectionTitle: `Dublin Lighting V1`,
        items: [
          {
            type: 'html',
            itemKey: 'dublin-lighting-v1-content',
            rows: [
              {
                metricName: 'popupContent',
              },
            ],
          },
        ],
      },
    ],
  },
  // IE Bins
  '95': {
    chunkSize: 15,
    layerStyles: {
      main: [
        { ...awesomeIconStyle, layout: { ...awesomeIconStyle.layout, 'icon-anchor': 'center' } },
      ],
    },
    details: [
      {
        sectionKey: 'bin',
        sectionTitle: `Bin Data`,
        items: [
          {
            type: 'html',
            itemKey: 'bins-content',
            rows: [
              {
                metricName: 'popupContent',
              },
            ],
          },
        ],
      },
    ],
  },
  // TPG Sites
  '96': {
    chunkSize: 12,
    layerStyles: {
      main: [awesomeIconStyle],
    },
    details: [
      {
        sectionKey: 'tpg-sites',
        sectionTitle: `TPG Sites`,
        items: [
          {
            type: 'html',
            itemKey: 'tpg-sites-content',
            rows: [
              {
                metricName: 'popupContent',
              },
            ],
          },
        ],
      },
    ],
  },
  // UK cell locations
  '20': cellMapperConfig,
  // US cell locations
  '21': cellMapperConfig,
};

export default layerStyles;
