import { Heading } from '@chakra-ui/react';
import { FC } from 'react';
import { useAppSelector } from '../../hooks/useAppSelector';
import useRegionsQueryClient, { Region } from '../../services/regionsClient';
import RegionSelectionList from './RegionSelectionList';

type RegionSelectionProps = {};

const RegionSelection: FC<RegionSelectionProps> = (props) => {
  const selectedProviderName = useAppSelector((state) => {
    return state.contextSettings.selectedProviderName;
  });

  const selectedCountryCode = useAppSelector((state) => {
    return state.contextSettings.selectedCountryCode;
  });

  const { data, isSuccess } = useRegionsQueryClient({
    countryCode: selectedCountryCode,
    providerName: selectedProviderName,
  });

  let regionsList: Region[] = [];

  const dataReady = isSuccess && data && data.length > 0;

  if (dataReady) {
    if (data.length > 1) {
      // multiple regions in base
      regionsList = data;
    } else if (data.length === 1 && data[0].notedRegions.length === 0) {
      // one region in base but no noted
      regionsList = data;
    } else if (data.length === 1 && data[0].notedRegions.length > 0) {
      // one region in base and has noted
      regionsList = data[0].notedRegions;
    } else {
      // default
      regionsList = data;
    }
  }

  return (
    <>
      <Heading color="white" size="md">
        Regions
      </Heading>
      {dataReady ? <RegionSelectionList regionsList={regionsList} /> : null}
    </>
  );
};

export default RegionSelection;
