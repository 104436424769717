import {
  Box,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderMark,
  RangeSliderThumb,
  RangeSliderTrack,
} from '@chakra-ui/react';
import { FC } from 'react';
import { FilterConfigInterval } from '../../helpers/layerConfig.types';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { setFilterValue } from '../../reducers/layersSlice';
import { LayerKey } from '../../reducers/layersSlice.types';

type LayerControlFilterSliderProps = {
  layerKey: LayerKey;
  intervals: FilterConfigInterval[];
  filterMetricCode: string;
};

const LayerControlFilterSlider: FC<LayerControlFilterSliderProps> = (props) => {
  const { layerKey, intervals, filterMetricCode } = props;

  const dispatch = useAppDispatch();

  const filterValues = useAppSelector((state) => {
    const layer = state.layersSettings[layerKey];
    if (layer) {
      return layer.filterValues[filterMetricCode];
    }
  });

  const positions =
    Array.isArray(filterValues) && filterValues
      ? filterValues.map((value, i) => {
          if (value === null) {
            if (i === 0) {
              return 0;
            } else {
              return intervals.length - 1;
            }
          }

          const position = intervals.findIndex((interval) => interval.value === value);

          return position;
        })
      : [0, intervals.length - 1];

  return (
    <Box width="100%" padding="3">
      <RangeSlider
        id={`filter-slider-${layerKey}-${filterMetricCode}`}
        size="lg"
        min={0}
        max={intervals.length - 1}
        value={positions}
        colorScheme="pink"
        step={1}
        onChange={(newPositions) => {
          const [minPosition, maxPosition] = newPositions;
          const whichThumbChanged = minPosition === positions[0] ? 1 : 0;
          const isThumbsInSamePosition = minPosition === maxPosition;

          let positionsToUse = newPositions;

          if (isThumbsInSamePosition) {
            if (whichThumbChanged === 0) {
              positionsToUse = [minPosition - 1, maxPosition];
            } else {
              positionsToUse = [minPosition, maxPosition + 1];
            }
          }

          const valueFromIntervals: [number | null, number | null] = [
            intervals[positionsToUse[0]].value,
            intervals[positionsToUse[1]].value,
          ];

          dispatch(
            setFilterValue({
              layerKey,
              filterMetricCode,
              filterValue: valueFromIntervals,
            })
          );
        }}
      >
        <RangeSliderTrack>
          <RangeSliderFilledTrack />
        </RangeSliderTrack>
        <RangeSliderThumb index={0} />
        <RangeSliderThumb index={1} />
        {intervals.map((interval, i) => {
          let val = 2;
          if (intervals.length > 15) {
            val = 5;
          }

          const isLastInterval = i === intervals.length - 1;
          const isEveryOtherInterval = i % val === 0;
          const showText = isLastInterval || isEveryOtherInterval;

          return (
            <RangeSliderMark
              key={`slider-mark-${i}`}
              value={i}
              color="white"
              textShadow="0.4px 0.4px 0.6px hsl(0deg 0% 0% / 0.2),
            1px 1.1px 1.5px  hsl(0deg 0% 0% / 0.17),
            3.1px 3.4px 4.5px  hsl(0deg 0% 0% / 0.14),
            8.2px 8.9px 11.9px  hsl(0deg 0% 0% / 0.11)"
              fontSize="small"
              transform="translateX(-50%)"
            >
              <Box
                borderRight="1px solid white"
                height={showText ? '6px' : '4px'}
                transform="translateX(-50%)"
                mt="1px"
              />
              {showText ? <Box mt="2">{interval.label ?? interval.value}</Box> : null}
            </RangeSliderMark>
          );
        })}
      </RangeSlider>
    </Box>
  );
};

export default LayerControlFilterSlider;
