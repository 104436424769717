import { Button, IconButton, Menu, MenuButton, MenuList } from '@chakra-ui/react';
import { MdOutlineApps } from 'react-icons/md';

import { Link } from 'react-router-dom';
import { checkApplicationAccess, isInternalUser } from '../../helpers/AuthProvider';

const portalLink = {
  buttonId: 'navigate-to-portal',
  title: 'Portal',
  url: import.meta.env.VITE_APP_PORTAL_URL,
};

const navList = [
  {
    buttonId: 'navigate-to-analytics',
    title: 'Analytics',
    url: import.meta.env.VITE_APP_ANALYTICS_URL,
    isInternalAccess: true,
    role: 'System-Analytics',
  },
  {
    buttonId: 'navigate-to-monitor',
    title: 'Monitor',
    url: import.meta.env.VITE_APP_MONITOR_URL,
    isInternalAccess: true,
    role: 'System-Monitor',
  },
  {
    buttonId: 'navigate-to-admin',
    title: 'Admin',
    url: import.meta.env.VITE_APP_ADMIN_URL,
    role: 'SystemAdmin',
  },
];

const NavToApps = () => {
  return (
    <Menu id={'nav-to-apps'}>
      <MenuButton bg={'brand.500'} as={IconButton} icon={<MdOutlineApps size="1.8em" />} />
      <MenuList>
        <Link to={portalLink.url} key={portalLink.buttonId} id={`${portalLink.buttonId}-link`}>
          <Button id={`${portalLink.buttonId}-button`} variant={'unstyledLink'}>
            {portalLink.title}
          </Button>
        </Link>
        {navList
          .filter((f) => {
            if (f.isInternalAccess) {
              if (isInternalUser()) {
                return true;
              }
            }

            return checkApplicationAccess(f.role);
          })
          .map((app) => (
            <Link to={app.url} key={app.buttonId} id={`${app.buttonId}-link`}>
              <Button id={`${app.buttonId}-button`} variant={'unstyledLink'}>
                {app.title}
              </Button>
            </Link>
          ))}
      </MenuList>
    </Menu>
  );
};
export default NavToApps;
