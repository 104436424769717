import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
} from '@chakra-ui/react';
import { polygon } from '@turf/turf';
import booleanContains from '../../helpers/booleanContains';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { setNodesModalOpen } from '../../reducers/modalSlice';
import { usePLMNQueryClient } from '../../services/contextClient';
import { RawOSSResultsProperties } from '../../services/ossResultsClient.types';
import { useOSSSingleLayerQueryClient } from '../../services/ossResultsClients';
import NodesData from './NodesData';

const NodesModal = () => {
  const dispatch = useAppDispatch();
  const isNodesModalOpen = useAppSelector((state) => state.modalSlice.isNodesModalOpen);
  const selectedCountryCode = useAppSelector((state) => state.contextSettings.selectedCountryCode);
  const selectedProviderName = useAppSelector(
    (state) => state.contextSettings.selectedProviderName
  );
  const viewport = useAppSelector((state) => state.mapSettings.viewState.viewport);
  const countryCode = useAppSelector((state) => state.contextSettings.selectedCountryCode);
  const providerName = useAppSelector((state) => state.contextSettings.selectedProviderName);
  const plmnQuery = usePLMNQueryClient({ countryCode, providerName });
  const nodesQueries = useOSSSingleLayerQueryClient({
    layerKey: 'oss',
    countryCode,
    providerName,
    plmns: plmnQuery.isSuccess ? plmnQuery.data : [],
  });

  const multiNodeData = nodesQueries.flatMap((nodeData) => {
    return nodeData.data;
  });

  const west = viewport[0][1];
  const south = viewport[0][0];
  const north = viewport[1][0];
  const east = viewport[1][1];

  const viewBox = polygon([
    [
      [south, west],
      [north, west],
      [north, east],
      [south, east],
      [south, west],
    ],
  ]);

  const inViewNodeData = multiNodeData.reduce((acc: RawOSSResultsProperties[], node) => {
    if (node && booleanContains(viewBox, node)) {
      acc.push(node.properties);
    }
    return acc;
  }, []);

  return (
    <Modal
      isOpen={isNodesModalOpen}
      onClose={() => {
        dispatch(setNodesModalOpen(false));
      }}
      size={'3xl'}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Nodes in view</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {inViewNodeData.length > 0 ? (
            <NodesData
              selectedNodes={inViewNodeData}
              countryCode={selectedCountryCode}
              providerName={selectedProviderName}
            />
          ) : inViewNodeData.length === 0 ? (
            <Text>No nodes found in the area. </Text>
          ) : (
            <Spinner />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default NodesModal;
