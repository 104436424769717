import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  InitializeLayerPayload,
  LayersSettingsState,
  SetFeaturesCountPayload,
  SetFilterValuePayload,
  SetFilterValuesPayload,
  SetIsLayerFiltersExpandedPayload,
  SetIsLayerMetricsExpandedPayload,
  SetIsLayerOnPayload,
  SetNeutralHostSettingValuePayload,
  SetSelectMetricPayload,
  SetShowAllFeaturesPayload,
  SetTileMetricPayload,
  SetVisibleChunksPayload,
  ToggleLayerFiltersExpandedPayload,
  ToggleLayerMetricsExpandedPayload,
} from './layersSlice.types';

const initialState: LayersSettingsState = {};

const layersSettings = createSlice({
  name: 'layersSettings',
  initialState,
  reducers: {
    initializeLayerSettings: (state, action: PayloadAction<InitializeLayerPayload>) => {
      state[action.payload.layerKey] = {
        isLayerOn: action.payload.isLayerOn,
        isMetricsExpanded: false,
        isFiltersExpanded: false,
        selectedTileMetric: action.payload.selectedTileMetric,
        selectedMetric: action.payload.selectedMetric,
        featuresCount: 0,
        showAllFeatures: true,
        filterValues: {},
        visibleChunks: [],
        neutralHostSettings: {},
      };
    },
    setIsLayerOn: (state, action: PayloadAction<SetIsLayerOnPayload>) => {
      const layer = state[action.payload.layerKey];

      if (layer) {
        layer.isLayerOn = action.payload.isLayerOn;
      }
    },
    setSelectedTileMetric: (state, action: PayloadAction<SetTileMetricPayload>) => {
      const layer = state[action.payload.layerKey];

      if (layer) {
        layer.selectedTileMetric = action.payload.selectedTileMetric;
      }
    },
    setSelectedMetric: (state, action: PayloadAction<SetSelectMetricPayload>) => {
      const layer = state[action.payload.layerKey];

      if (layer) {
        layer.selectedMetric = action.payload.selectedMetric;
      }
    },
    setShowAllFeatures: (state, action: PayloadAction<SetShowAllFeaturesPayload>) => {
      const layer = state[action.payload.layerKey];

      if (layer) {
        layer.showAllFeatures = action.payload.showAllFeatures;
      }
    },
    setFeaturesCount: (state, action: PayloadAction<SetFeaturesCountPayload>) => {
      const layer = state[action.payload.layerKey];

      if (layer) {
        layer.featuresCount = action.payload.featuresCount;
      }
    },
    initializeFilterValues: (state, action: PayloadAction<SetFilterValuesPayload>) => {
      const layer = state[action.payload.layerKey];

      if (layer) {
        action.payload.filterValues.forEach(({ filterMetricCode, filterValue }) => {
          layer.filterValues[filterMetricCode] = filterValue;
        });
      }
    },
    setFilterValues: (state, action: PayloadAction<SetFilterValuesPayload>) => {
      const layer = state[action.payload.layerKey];

      if (layer) {
        action.payload.filterValues.forEach(({ filterMetricCode, filterValue }) => {
          layer.filterValues[filterMetricCode] = filterValue;
        });
      }
    },
    setFilterValue: (state, action: PayloadAction<SetFilterValuePayload>) => {
      const layer = state[action.payload.layerKey];

      if (layer) {
        layer.filterValues[action.payload.filterMetricCode] = action.payload.filterValue;
      }
    },
    setNeutralHostSettingValue: (
      state,
      action: PayloadAction<SetNeutralHostSettingValuePayload>
    ) => {
      const layer = state[action.payload.layerKey];

      if (layer) {
        layer.neutralHostSettings[action.payload.metricCode] = action.payload.value;
      }
    },
    setVisibleChunks: (state, action: PayloadAction<SetVisibleChunksPayload>) => {
      const layer = state[action.payload.layerKey];

      if (layer) {
        layer.visibleChunks = action.payload.visibleChunks;
      }
    },
    setIsLayerMetricsExpanded: (state, action: PayloadAction<SetIsLayerMetricsExpandedPayload>) => {
      const layer = state[action.payload.layerKey];

      if (layer) {
        layer.isMetricsExpanded = action.payload.isMetricsExpanded;
      }
    },
    setIsLayerFiltersExpanded: (state, action: PayloadAction<SetIsLayerFiltersExpandedPayload>) => {
      const layer = state[action.payload.layerKey];

      if (layer) {
        layer.isFiltersExpanded = action.payload.isFiltersExpanded;
      }
    },
    toggleLayerMetricsExpanded: (
      state,
      action: PayloadAction<ToggleLayerMetricsExpandedPayload>
    ) => {
      const layer = state[action.payload.layerKey];

      if (layer) {
        layer.isMetricsExpanded = !layer.isMetricsExpanded;
      }
    },
    toggleLayerFiltersExpanded: (
      state,
      action: PayloadAction<ToggleLayerFiltersExpandedPayload>
    ) => {
      const layer = state[action.payload.layerKey];

      if (layer) {
        layer.isFiltersExpanded = !layer.isFiltersExpanded;
      }
    },
  },
});

const { actions, reducer } = layersSettings;

export const {
  initializeLayerSettings,
  setIsLayerOn,
  setSelectedTileMetric,
  setSelectedMetric,
  setShowAllFeatures,
  setFeaturesCount,
  initializeFilterValues,
  setFilterValues,
  setFilterValue,
  setNeutralHostSettingValue,
  setVisibleChunks,
  setIsLayerMetricsExpanded,
  setIsLayerFiltersExpanded,
  toggleLayerMetricsExpanded,
  toggleLayerFiltersExpanded,
} = actions;

export default reducer;
