import { bbox, BBox, Position } from '@turf/turf';
import { FitBoundsOptions } from 'mapbox-gl';
import { MapboxMap, MapRef } from 'react-map-gl';
import { BBox2d, BBox3d } from '../types/map';
import { Logger } from './Logger';
import Result, { Either } from './Result';

/**
 * Check if a BBox is 3D, by checking the number of items in the array.
 */
export const isBBox3d = (bBox: BBox): bBox is BBox3d => {
  return bBox.length === 6;
};

/**
 * Return a 2D BBox, by removing the z coordinates of a 3D BBox
 * Returns [west, south, east, north]
 */
export const getBBox2d = (bBox: BBox) => {
  if (isBBox3d(bBox)) {
    const bBox2d: [number, number, number, number] = [bBox[0], bBox[1], bBox[3], bBox[4]];
    return bBox2d;
  }

  return bBox;
};

export const assertBBox = (possibleBbox: unknown): Either<BBox> => {
  if (Array.isArray(possibleBbox)) {
    if (
      possibleBbox.every((n) => {
        return Number.isFinite(n);
      })
    ) {
      if (possibleBbox.length === 4) {
        return Result.success(possibleBbox as BBox2d);
      }
      if (possibleBbox.length === 6) {
        return Result.success(possibleBbox as BBox3d);
      }

      return Result.failure(
        `BBox must be array of 4 or 6 numbers. Received ${possibleBbox.length} numbers`
      );
    } else {
      return Result.failure(
        'Non number values in array. BBox must be array of numbers, of length 4 or 6 '
      );
    }
  }

  return Result.failure('BBox must be array of numbers, of length 4 or 6');
};

/**
 * Take a GeoJSON geometry and navigate the map to it
 */
export const goToGeometry = (geometry: GeoJSON.Geometry, map?: MapRef) => {
  const fitBoundsOptions: FitBoundsOptions = {
    duration: 1500,
  };

  if (map) {
    const bboxOfGeometry = bbox(geometry);
    const bBox2d = getBBox2d(bboxOfGeometry);

    map.fitBounds(bBox2d, fitBoundsOptions);
  }
};

/**
 * Take a BBox and navigate the map to it.
 */
export const goToBoundsFromBBox = (bBox: BBox, map?: MapRef) => {
  const fitBoundsOptions: FitBoundsOptions = {
    duration: 1500,
  };

  if (map) {
    const bBox2d = getBBox2d(bBox);

    map.fitBounds(bBox2d, fitBoundsOptions);
  }
};

/** Navigate the map to a latitude and longitude */
export const goToLocation = (
  coordinates: Position,
  map?: MapRef,
  zoomLevel?: number | undefined,
  pitchLevel?: number | undefined
) => {
  if (coordinates.length === 2) {
    map?.flyTo({
      center: [coordinates[0], coordinates[1]],
      zoom: zoomLevel ? zoomLevel : 15,
      pitch: pitchLevel ? pitchLevel : 0,
    });
  }
};

/** Check if a layer ID exists on the map */
export const isLayerAddedToMap = (layerId: string | number, map: MapRef) => {
  return map.getLayer(layerId.toString()) !== undefined;
};

/**
 * To use an image in a Mapbox layer it must be loaded and added to the style.
 * This will check if the image name is already added, and if not, add it.
 */
export const addImageToMapStyle = (
  map: MapboxMap,
  imageName: string,
  imageUrl: string,
  options?: { pixelRatio?: number | undefined; sdf?: boolean | undefined }
) => {
  if (!map.hasImage(imageName)) {
    map.loadImage(imageUrl, (error, image) => {
      if (error) {
        Logger.error('Error loading image:', error);
      }

      if (image) {
        map.addImage(imageName, image, options);
      }
    });
  }
};
