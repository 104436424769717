import {
  Box,
  Button,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react';
import { FC, Fragment, useRef, useState } from 'react';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { setAddressAnalysisModalOpen } from '../../reducers/modalSlice';

const allowedExtensions = ['csv'];

const AddressAnalysisModal: FC = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector((state) => state.modalSlice.isAddressAnalysisModalOpen);

  const toast = useToast();

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [fileName, setFileName] = useState<string | null>(null);
  const [file, setFile] = useState<string | null>(null);

  const [submissionPending, setSubmissionPending] = useState(false);

  return (
    <Modal
      onCloseComplete={() => {
        setFile(null);
        setFileName(null);
      }}
      isOpen={isOpen}
      onClose={() => {
        dispatch(setAddressAnalysisModalOpen(false));
      }}
      size="6xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Address List Analysis</ModalHeader>
        <ModalCloseButton color="black" />
        <ModalBody>
          <Button
            onClick={() => {
              if (fileInputRef.current) {
                fileInputRef.current.value = '';
              }
              fileInputRef.current?.click();
            }}
          >
            {fileName ? 'Change file' : 'Choose CSV file'}
          </Button>
          <Input
            type="file"
            accept=".csv"
            style={{ display: 'none' }}
            ref={fileInputRef}
            onChange={(e) => {
              const file = e.target.files ? e.target.files[0] : null;
              if (file) {
                const fileName = file.name;
                const splitName = fileName.split('.');
                const finalPart = splitName[splitName.length - 1];

                if (allowedExtensions.includes(finalPart)) {
                  const fileReader = new FileReader();
                  fileReader.onload = () => {
                    const result = fileReader.result;

                    if (typeof result === 'string') {
                      if (result.length > 0) {
                        setFileName(fileName);
                        setFile(result);
                      } else {
                        toast({
                          position: 'top',
                          status: 'error',
                          description: 'You have chosen an empty file. Please try again.',
                        });
                      }
                    } else {
                      toast({
                        position: 'top',
                        status: 'error',
                        description: 'Unexpected content. Please try again.',
                      });
                    }
                  };
                  fileReader.readAsText(file);
                } else {
                  toast({
                    position: 'top',
                    status: 'error',
                    description: 'Please choose a CSV file.',
                  });
                }
              }
            }}
          />
          <Box mt="4">
            <Heading size="sm">Filename:</Heading>
            {fileName}
          </Box>
          <Box mt="4">
            <Heading size="sm">Content:</Heading>
            <Box height="40vh" overflowY="auto">
              {file
                ? file.split('\n').map((line, i) => {
                    return (
                      <Fragment key={i}>
                        <Box>{line}</Box>
                        <hr />
                      </Fragment>
                    );
                  })
                : null}
            </Box>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button
            isDisabled={file === null}
            isLoading={submissionPending}
            onClick={() => {
              setSubmissionPending(true);
              toast({
                title: 'Address list submitted',
                duration: 3000,
              });

              setTimeout(() => {
                setSubmissionPending(false);
              }, 2000);
            }}
          >
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddressAnalysisModal;
