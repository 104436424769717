import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { FC } from 'react';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { setBuildingEditOpen } from '../../reducers/modalSlice';
import BuildingEditContent from './BuildingEditContent';

const BuildingEditModal: FC = () => {
  const dispatch = useAppDispatch();

  const isOpen = useAppSelector((state) => state.modalSlice.isBuildingEditOpen);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        dispatch(setBuildingEditOpen(false));
      }}
      size="full"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <ModalCloseButton size="lg" colorScheme="black" />
        </ModalHeader>
        <ModalBody display="flex" flexDirection="column">
          <BuildingEditContent />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default BuildingEditModal;
