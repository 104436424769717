import { Flex } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import { FC } from 'react';
import { LayerConfig, MetricConfig } from '../../helpers/layerConfig.types';
import { useAppSelector } from '../../hooks/useAppSelector';
import useLayersClient from '../../services/LayersClient';
import ScaleLegend from './ScaleLegend';

type ScaleLegendsProps = {};

const ScaleLegends: FC<ScaleLegendsProps> = () => {
  const allLayersSettings = useAppSelector((state) => {
    return state.layersSettings;
  });

  const isALayerOn = Object.entries(allLayersSettings).some((layer) => {
    const state = layer[1];

    return state && state.isLayerOn === true;
  });

  const layersClient = useLayersClient();

  const { data: layerConfig } = layersClient;

  if (isALayerOn && layerConfig) {
    const activeConfigsWithMetrics = layerConfig.filter(
      (config): config is LayerConfig & { metrics: MetricConfig[] } => {
        const layer = allLayersSettings[config.layerId];
        const isLayerOn = layer ? layer.isLayerOn : false;

        const hasMetrics = config.metrics && config.metrics.length > 0 ? true : false;

        return hasMetrics && isLayerOn;
      }
    );

    if (activeConfigsWithMetrics.length !== 0) {
      return (
        <Flex flexDirection="column" justify="start" align="start">
          <AnimatePresence>
            {activeConfigsWithMetrics.map((config, i, acc) => {
              const { layerId, name, metrics } = config;
              const first = i === 0;
              const last = i === acc.length - 1;

              return (
                <motion.div
                  key={`scale-legend-${layerId}`}
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: 'auto', opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  style={{ overflow: 'hidden' }}
                  transition={{ type: 'tween', duration: 0.25 }}
                >
                  <ScaleLegend
                    layerId={layerId}
                    layerName={name}
                    metrics={metrics}
                    first={first}
                    last={last}
                  />
                </motion.div>
              );
            })}
          </AnimatePresence>
        </Flex>
      );
    }
  }

  return null;
};

export default ScaleLegends;
