import { QueryObserverOptions, useQueries } from '@tanstack/react-query';
import { Feature, MultiPolygon, Polygon, centroid, feature, polygon } from '@turf/turf';
import isEqual from 'lodash/isEqual';
import { parse } from 'wellknown';
import { getBBox2d } from '../helpers/MapHelpers';
import booleanContains from '../helpers/booleanContains';
import { useLayerSettingsSelector } from '../hooks/useLayersSettings';
import { LayerKey } from '../reducers/layersSlice.types';
import dwAxiosClient from './dwAxiosClient';

type GetNeutralHostParams = {
  west: number;
  south: number;
  east: number;
  north: number;
  countryCode: string | null;
  providerName: string | null;
  plotType: 'BNH' | 'GNH';
};
type GetNeutralHostOptions = { signal: AbortSignal };

type NeutralHostProviders = (null | {
  brandName: string | null;
  isMvno: boolean;
  name: string;
  providerId: number;
})[];

type RawNeutralHostProperties = {
  boundaryWkt: string;
  buildingId: number | null;
  gridCellId: number | null;
  gridId: number | null;
  originalRegionId: number;
  periodCode: string;
  regionId: number;
  rsrp25Percentile: (number | null)[];
  rssnr25Percentile: (number | null)[];
  rssnrMetricRunId: (number | null)[];
} & RawNeutralHostMetrics;

type RawNeutralHostMetrics = {
  rsrp25Percentile: (number | null)[];
  rssnr25Percentile: (number | null)[];
  rssnrMetricRunId: (number | null)[];
};

type NeutralHostProperties = {
  boundaryWkt: string;
  buildingId: number | null;
  gridCellId: number | null;
  gridId: number | null;
  originalRegionId: number;
  periodCode: string;
  regionId: number;
  providers: string[];
} & NeutralHostMetrics;

type NeutralHostMetrics = {
  rsrp25Percentile: (number | null)[];
  rssnr25Percentile: (number | null)[];
  rssnrMetricRunId: (number | null)[];
};

type RawNeutralHostResponse = {
  neutralHostProviders: NeutralHostProviders;
  rows: RawNeutralHostProperties[];
};

export type NeutralHostResponse = Feature<Polygon | MultiPolygon, NeutralHostProperties>[];

const removeNullProvider = (
  row: RawNeutralHostProperties,
  propertyNames: (keyof RawNeutralHostMetrics)[],
  providers: (string | null)[]
) => {
  const map = propertyNames.map((propertyName) => {
    const propertyValue = row[propertyName];

    return propertyValue.reduce<(number | null)[]>((acc, value, index) => {
      const provider = providers[index];

      if (provider !== null) {
        acc.push(value);
      }

      return acc;
    }, []);
  });

  return map;
};

export const getNeutralHostByBox = async (
  params: GetNeutralHostParams,
  options: GetNeutralHostOptions
): Promise<NeutralHostResponse> => {
  const { west, south, east, north, countryCode, providerName, plotType } = params;

  const { signal } = options;

  const { data, request } = await dwAxiosClient.get<RawNeutralHostResponse>(
    `/ResultNeutralHost/Box/${west}/${south}/${east}/${north}`,
    {
      params: { plotType, countryCode, providerName },
      signal,
      headers: {
        Accept: 'application/json',
      },
    }
  );

  if (data && request.status === 200) {
    const boxGeometry = polygon([
      [
        [west, south],
        [west, north],
        [east, north],
        [east, south],
        [west, south],
      ],
    ]);

    const providers = data.neutralHostProviders.map((p) => {
      if (p === null) {
        return null;
      }

      return p.brandName ?? p.name;
    });

    const transformedData = data.rows.reduce((acc: NeutralHostResponse, row) => {
      if (row === null) {
        return acc;
      }

      const buildingGeometry = parse(row.boundaryWkt);

      if (
        buildingGeometry &&
        (buildingGeometry.type === 'Polygon' || buildingGeometry.type === 'MultiPolygon')
      ) {
        const buildingCentroid = centroid(buildingGeometry);
        const doesContain = booleanContains(boxGeometry, buildingCentroid);
        if (!doesContain) {
          return acc;
        }

        const [trimmedMetricRunId, trimmedRsrp25Percentile, trimmedRssnr25Percentile] =
          removeNullProvider(
            row,
            ['rssnrMetricRunId', 'rsrp25Percentile', 'rssnr25Percentile'],
            providers
          );

        const trimmedProviders = providers.filter((p): p is string => p !== null);

        const newProperties: NeutralHostProperties = {
          ...row,
          rssnr25Percentile: trimmedRssnr25Percentile,
          rsrp25Percentile: trimmedRsrp25Percentile,
          rssnrMetricRunId: trimmedMetricRunId,
          providers: trimmedProviders,
        };

        const newFeature = feature(buildingGeometry, newProperties);
        acc.push(newFeature);
      }

      return acc;
    }, []);

    return transformedData;
  }

  return [];
};

const useBuildingsNeutralHostQueryClient = ({
  layerKey,
  providerName,
  countryCode,
  enabled,
}: {
  layerKey: LayerKey;
  providerName: string | null;
  countryCode: string | null;
  enabled: boolean;
}) => {
  const chunks = useLayerSettingsSelector(layerKey, 'visibleChunks', [], isEqual);

  const queries = chunks
    ? chunks.map((chunk) => {
        const chunk2d = chunk ? getBBox2d(chunk) : [];
        const [west, south, east, north] = chunk2d;

        const query: QueryObserverOptions<NeutralHostResponse | null> = {
          queryKey: [layerKey, countryCode, providerName, chunk2d.join('|')],
          queryFn: ({ signal }: any) => {
            return getNeutralHostByBox(
              {
                west,
                south,
                east,
                north,
                countryCode,
                providerName,
                plotType: 'BNH',
              },
              { signal }
            );
          },
          staleTime: 1000 * 60 * 60 * 5,
          enabled,
        };

        return query;
      })
    : [];

  return useQueries({ queries });
};

export default useBuildingsNeutralHostQueryClient;
