import { bbox, centroid, Geometry } from '@turf/turf';
import 'mapbox-gl/dist/mapbox-gl.css';
import { CSSProperties, FC, useCallback, useEffect, useMemo } from 'react';
import { Layer, Map as ReactMapGl, Source } from 'react-map-gl';
import { MapboxProps } from 'react-map-gl/dist/esm/mapbox/mapbox';
import { parse } from 'wellknown';
import images from '../../config/images';
import { getToken, tokenStatus } from '../../helpers/AuthProvider';
import { Logger } from '../../helpers/Logger';
import { addImageToMapStyle } from '../../helpers/MapHelpers';
import Result from '../../helpers/Result';
import { calculateZoomLevel } from '../../helpers/utils';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import useLogOut from '../../hooks/useLogOut';
import { setIsDrawerOpen } from '../../reducers/drawersSlice';
import { setMapViewState, setSelectedFeatureId } from '../../reducers/mapSettings';
import { RootState } from '../../reducers/store';
import useUpdateURL from '../../services/UpdateURL';
import useClusterClient from '../../services/clusterClient';
import dwAxiosClient from '../../services/dwAxiosClient';
import MapClickedMarker from '../MapClickedMarker/MapClickedMarker';
import MapLayers from '../MapLayers';
import MapDraggableMarker from '../MapStreetView/MapDraggableMarker';
import RecentlyModifiedBuildingsModal from '../RecentlyModifiedBuildingsModal/RecentlyModifiedBuildingsModal';

type MapProps = {};

const accessToken = import.meta.env.VITE_APP_MAPBOX_TOKEN;
const apiUrl = import.meta.env.VITE_APP_API_URL;

let buildingId: string | null = null;

const getInitialMapView = await (async () => {
  const hash = new URLSearchParams(window.location.hash.slice(1));
  const hashBbox = hash.get('bbox');
  const viewState = hash.get('viewState');
  const hashBuildingId = hash.get('buildingId');

  if (hashBbox) {
    const boundsArray = hashBbox.split(',').map((n) => Number(n)) as [
      number,
      number,
      number,
      number
    ];

    hash.delete('bbox');
    hash.set('initView', '1');
    const hashString = hash.toString();
    window.location.hash = `#${hashString}`;
    return {
      bounds: boundsArray,
    };
  } else if (hashBuildingId) {
    buildingId = hashBuildingId;
    const buildingBounds = await dwAxiosClient
      .get(`/Building/${hashBuildingId}`)
      .then((response) => {
        const coordinates = response && bbox(response.data?.geometry);
        const centercoordinates =
          response && centroid(response.data?.geometry as Geometry).geometry.coordinates;
        const zoomLevel = calculateZoomLevel(
          coordinates,
          Math.floor(window.screen.width),
          Math.floor(window.screen.height)
        );
        hash.delete('buildingId');
        hash.set('initView', '1');
        const hashString = hash.toString();
        window.location.hash = `#${hashString}`;
        return { longitude: centercoordinates[0], latitude: centercoordinates[1], zoom: zoomLevel };
      });

    return buildingBounds;
  } else if (viewState) {
    const viewStateObj = viewState ? JSON.parse(viewState) : undefined;
    hash.set('initView', '1');
    const hashString = hash.toString();
    window.location.hash = `#${hashString}`;
    return viewStateObj;
  } else {
    return undefined;
  }
})();

const Map: FC<MapProps> = () => {
  const dispatch = useAppDispatch();
  const logout = useLogOut();
  const updateURL = useUpdateURL();

  useEffect(() => {
    if (buildingId) {
      dispatch(setSelectedFeatureId(parseInt(buildingId)));
      dispatch(setIsDrawerOpen({ drawerKey: 'buildings' }));
    }
  }, [dispatch]);

  // if (viewStateObj.latitude !== undefined && viewStateObj.longitude !== undefined) {
  //   goToLocation([viewStateObj.longitude, viewStateObj.latitude], map, viewStateObj.zoom);
  // }

  const mapStyleUrl = useAppSelector((state) => {
    return state.mapSettings.mapStyleUrl;
  });

  const setMapState = useCallback(
    (viewState: RootState['mapSettings']['viewState']) => {
      dispatch(setMapViewState(viewState));
      updateURL('viewState', JSON.stringify(viewState));
    },
    [dispatch, updateURL]
  );

  const onMoveEnd = useCallback<NonNullable<MapboxProps['onMoveEnd']>>(
    (e) => {
      const viewport = e.target.getBounds().toArray();
      setMapState({ ...e.viewState, viewport });
    },
    [setMapState]
  );

  const transformRequest = useCallback<NonNullable<MapboxProps['transformRequest']>>(
    (url, resourceType) => {
      if (resourceType === 'Tile' && url.includes(apiUrl ?? '')) {
        const currentTokenStatus = tokenStatus();

        if (currentTokenStatus === 'expired' || currentTokenStatus === 'no-token') {
          logout();
        }

        const token = getToken();

        if (Result.isSuccess(token)) {
          return {
            url,
            headers: { Authorization: `Bearer ${token.value}` },
          };
        } else {
          Logger.error(token.error);
          return { url };
        }
      } else {
        return { url };
      }
    },
    [logout]
  );

  const style = useMemo<CSSProperties>(() => {
    return { height: '100vh', width: '100%' };
  }, []);

  const onLoad = useCallback<NonNullable<MapboxProps['onLoad']>>(
    (e) => {
      if (import.meta.env.VITE_APP_ENV === 'qa') {
        (window as any).DWMap = e.target;
      }

      Object.entries(images).forEach(([imageName, imageUrl]) => {
        if (imageUrl) {
          addImageToMapStyle(e.target, imageName, imageUrl, { sdf: false });
        }
      });

      e.target.on('styleimagemissing', (imageMissingEvent: any) => {
        const imageName = imageMissingEvent.id;

        const imageUrl = images[imageName];

        if (imageUrl) {
          addImageToMapStyle(e.target, imageName, imageUrl, { sdf: false });
        }

        Logger.error('Image missing from style and config', imageName);
      });

      // const coordinates = buildingResponse && bbox(buildingResponse.data?.geometry);
      // const centercoordinates =
      //   buildingResponse &&
      //   centroid(buildingResponse.data?.geometry as Geometry).geometry.coordinates;
      // const zoomLevel = calculateZoomLevel(
      //   coordinates,
      //   Math.floor(window.screen.width),
      //   Math.floor(window.screen.height)
      // );
      // goToLocation([centercoordinates[0], centercoordinates[1]], map, zoomLevel);

      const viewport = e.target.getBounds().toArray();
      const longitude = e.target.getCenter().lng;
      const latitude = e.target.getCenter().lat;
      const zoom = e.target.getZoom();
      const bearing = e.target.getBearing();
      const pitch = e.target.getPitch();
      const padding = e.target.getPadding();
      setMapState({ longitude, latitude, zoom, bearing, pitch, padding, viewport });
    },
    [setMapState]
  );

  const isStreetViewOn = useAppSelector((state) => state.streetViewStateSettings.streetViewLayerOn);

  const responseCollection = useClusterClient();

  const wktString =
    responseCollection && responseCollection.data && responseCollection.data.boundary
      ? parse(responseCollection.data.boundary)
      : null;

  const toggleCluster = useAppSelector((state) => state.clusterSlice.toggleCluster);

  if (accessToken === undefined) {
    Logger.error('Mapbox access token is not set');
    return null;
  }

  return (
    <ReactMapGl
      id="dw-main-map"
      reuseMaps
      transformRequest={transformRequest}
      onMoveEnd={onMoveEnd}
      customAttribution={
        import.meta.env.VITE_APP_ENVIRONMENT_LABEL
          ? `DenseWare '${import.meta.env.VITE_APP_ENVIRONMENT_LABEL}' v${
              import.meta.env.VITE_APP_VERSION
            } | &copy; Dense Air`
          : 'DenseWare | &copy; Dense Air'
      }
      logoPosition="bottom-right"
      mapboxAccessToken={accessToken}
      style={style}
      mapStyle={mapStyleUrl}
      onLoad={onLoad}
      initialViewState={getInitialMapView}
    >
      <MapLayers />
      {/* This is the building search */}
      <RecentlyModifiedBuildingsModal />
      {/* clustering layer from CM */}
      {wktString && toggleCluster ? (
        <Source id={'cluster'} type="geojson" data={wktString}>
          <Layer
            key={`cluster-text-key`}
            id={'cluster-text'}
            source={'cluster'}
            type="fill"
            paint={{
              'fill-color': 'yellow',
              'fill-opacity': 0.3,
            }}
            maxzoom={22}
            minzoom={4}
          />
        </Source>
      ) : null}
      <MapClickedMarker />
      {isStreetViewOn ? <MapDraggableMarker /> : null}
    </ReactMapGl>
  );
};

export default Map;
