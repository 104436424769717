import { FC } from 'react';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { setFilterValue } from '../../reducers/layersSlice';
import { LayerKey } from '../../reducers/layersSlice.types';
import ReactMultiSelect from '../ReactMultiSelect/ReactMultiSelect';

type LayerControlFilterMultiTextProps = {
  layerKey: LayerKey;
  filterMetricCode: string;
  options: { label: string; value: string }[];
};

const LayerControlFilterMultiText: FC<LayerControlFilterMultiTextProps> = (props) => {
  const { layerKey, filterMetricCode, options } = props;

  const dispatch = useAppDispatch();

  const filterValue = useAppSelector((state) => {
    const layer = state.layersSettings[layerKey];
    if (layer) {
      return layer.filterValues[filterMetricCode];
    }
  });

  const value = Array.isArray(filterValue) ? filterValue : [];

  return (
    <ReactMultiSelect
      options={options}
      value={value.map((v) => ({ label: v, value: v }))}
      onChange={(selectedValue) => {
        dispatch(
          setFilterValue({
            layerKey,
            filterMetricCode,
            filterValue: selectedValue.map((s) => s.value),
          })
        );
      }}
    />
  );
};

export default LayerControlFilterMultiText;
