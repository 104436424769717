const colors = {
  brand: {
    '50': '#EEF4F6',
    '100': '#D0DFE7',
    '200': '#B2CBD7',
    '300': '#94B6C7',
    '400': '#75A1B8',
    '500': '#578DA8',
    '500.50': '#578DA832',
    '600': '#467186',
    '700': '#345565',
    '800': '#233843',
    '900': '#111C22',
  },
  primary: '#37596A',
  primaryDarkFifteenPercent: '#2E4B5A',
  primaryLightFifteenPercent: '#4E7F97',
  // missing secondary colour, placeholder
  secondary: '#99AEBB',

  // colours for popups, alerts etc
  errorRed: '#700202',
  errorRedBg: '#FECECE',
  warningOrange: '#663000',
  warningOrangeBg: '#FFDFC2',
  warningYellow: '#794500',
  warningYellowBg: '#FFF4E5',
  informationBlue: '#E5F6FD',
  informationBlueBg: '#08516F',
  successGreen: '#255325',
  successGreenBg: '#EDF7ED',
};

export default colors;
