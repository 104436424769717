import { pointToTile, tileToBBOX } from '@mapbox/tilebelt';
import { BBox } from '@turf/turf';
import { useCallback } from 'react';
import { getBBox2d } from '../helpers/MapHelpers';

const calculateChunks = ({
  viewport,
  zoomLevel,
  enabled,
}: {
  viewport: number[][] | null;
  zoomLevel: number | null;
  enabled: boolean;
}) => {
  if (!enabled || viewport === null || zoomLevel === null) {
    return { extents: null };
  }

  const viewportWest = viewport[0][0];
  const viewportSouth = viewport[0][1];
  const viewportEast = viewport[1][0];
  const viewportNorth = viewport[1][1];
  // viewport is
  // west,south
  // east,north

  // bbox should before
  // minX, minY, maxX, maxY

  const topLeftTile = pointToTile(viewportWest, viewportNorth, zoomLevel);
  const topRightTile = pointToTile(viewportEast, viewportNorth, zoomLevel);
  const bottomLeftTile = pointToTile(viewportWest, viewportSouth, zoomLevel);

  // I'm sure there's a smarter way to work these out, but this gets it done
  // tiles are [x, y, zoom]
  const topLeftTileX = topLeftTile[0];
  const topRightTileX = topRightTile[0];

  let missingXs = [];

  if (topRightTileX - topLeftTileX < 0) {
    // If the difference is negative we must be crossing the antimeridian
    // From slippy calcs. the largest x value is 2^zoom - 1
    const maxX = Math.pow(2, zoomLevel) - 1;

    // Get the x's from left x up to the max
    const xTweensToMax = [...Array(maxX - topLeftTileX + 1).keys()].map((i) => i + topLeftTileX);

    // Get the other x's from 0 to the right
    const xTweensFrom0 = [...Array(topRightTileX + 1).keys()].map((i) => i);

    // set the missing x's to these new lists
    missingXs = [...xTweensToMax, ...xTweensFrom0];
  } else {
    // else we just have smaller to larger, left to right
    const xTimes = topRightTileX - topLeftTileX;

    // Get all the numbers from the left x upto the right x
    const xTweens = [...Array(xTimes).keys()].map((i) => i + topLeftTileX);

    // Put all the missing number + top right x into an array
    missingXs = [...xTweens, topRightTileX];
  }

  const topLeftTileY = topLeftTile[1];
  const bottomLeftTileY = bottomLeftTile[1];
  const yTimes = bottomLeftTileY - topLeftTileY;
  const yTweens = Array.from(new Array(yTimes), (x, i) => i).map((i) => i + topLeftTileY);
  const missingYs = [...yTweens, bottomLeftTileY];

  // Go through the missing xs and ys and make the combinations of tiles in view
  const allTiles = missingXs.flatMap((x) => {
    return missingYs.map((y) => {
      return [x, y, zoomLevel];
    });
  });

  const extents: BBox[] = allTiles.map((t) => {
    const bbox = tileToBBOX(t);
    const tile2d = getBBox2d(bbox as [number, number, number, number]);

    return tile2d;
  });

  return { extents };
};

const useChunkArea = (
  viewport: number[][] | null,
  zoomLevel: number | null,
  enabled: boolean
): {
  extents: BBox[] | null;
} => {
  const joinedViewport = viewport?.join('|');

  const calc = useCallback(calculateChunks, [joinedViewport, zoomLevel, enabled]);

  const { extents } = calc({ viewport, zoomLevel, enabled });

  return { extents };
};

export default useChunkArea;
