import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type RegionsState = {
  selectedRegionId: number | null;
  selectedRegion: object | null;
};

type SetRegionIdPayload = RegionsState['selectedRegionId'];

type SetRegionPayload = RegionsState['selectedRegion'];

const initialState: RegionsState = {
  selectedRegionId: null,
  selectedRegion: null
};

const regionsSlice = createSlice({
  name: 'regions',
  initialState,
  reducers: {
    setSelectedRegionId: (state, action: PayloadAction<SetRegionIdPayload>) => {
      state.selectedRegionId = action.payload;
    },
    setSelectedRegion: (state, action: PayloadAction<SetRegionPayload>) => {
      state.selectedRegion = action.payload;
    },
  },
});

const { actions, reducer } = regionsSlice;

export const { setSelectedRegionId, setSelectedRegion } = actions;

export default reducer;
