import { isEqual } from 'lodash';
import { FC, useEffect } from 'react';
import { Marker, useMap } from 'react-map-gl';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../hooks/useAppSelector';
import { StreetViewPosition } from '../../reducers/streetViewSlice';

import directionIcon from './../../images/icons/direction.svg';

const MapDraggableMarker: FC = () => {
  const { 'dw-main-map': map } = useMap();
  const center = map?.getCenter();

  const dispatch = useDispatch();
  const viewport = useAppSelector((state) => state.mapSettings.viewState.viewport);
  const markerPosition = useAppSelector(
    (s) => s.streetViewStateSettings.streetViewCoordinates,
    isEqual
  );

  const clickedBuildingLatLong = useAppSelector(
    (s) => s.streetViewStateSettings.streetViewBuildingLatLong
  );

  const facingDirection = useAppSelector(
    (state) => state.streetViewStateSettings.streetViewHeading
  );

  useEffect(() => {
    if (map && center && clickedBuildingLatLong === null) {
      dispatch(StreetViewPosition({ lat: center.lat, lng: center.lng }));
    }
  }, [map, dispatch, center, clickedBuildingLatLong, viewport]);

  if (markerPosition !== null) {
    return (
      <Marker
        longitude={markerPosition.lng}
        latitude={markerPosition.lat}
        draggable={true}
        onDragEnd={(e) => {
          dispatch(
            StreetViewPosition({
              lat: e.lngLat.lat,
              lng: e.lngLat.lng,
            })
          );
        }}
        rotation={facingDirection}
        rotationAlignment="map"
      >
        <img src={`${directionIcon}`} alt="marker" />
      </Marker>
    );
  }
  return null;
};

export default MapDraggableMarker;
