import { LayerConfig } from './layerConfig.types';

const ossConfig: LayerConfig = {
  name: 'Dense Air Small Cells',
  boundingBoxWkt: null,
  description: 'Dense Air Small Cells',
  layerId: 'oss',
  visibleFromUtc: '2000-01-01T00:00:00Z',
  visibleToUtc: '2099-01-01T00:00:00Z',
  zoomFrom: 2,
  zoomTo: 24,
  defaultLayerOn: false,
  layerParts: [
    {
      from: 2,
      to: 24,
      type: 'geojson',
      layerType: 'symbol',
      layerChunkLevel: 3,
      isInteractive: true,
    },
  ],
  details: [
    {
      sectionTitle: 'Node Summary',
      sectionKey: 'Node Summery',
      items: [
        {
          itemKey: 'connection-counts-table',
          type: 'grid',
          rows: [
            {
              rowText: 'Node Name',
              rowKey: 'node-name',
              metricName: 'nodeName',
              showColor: true,
            },
            {
              rowText: 'Technology Type',
              rowKey: 'technology',
              metricName: 'technologyType',
              showColor: false,
            },
            {
              rowText: 'Hardware',
              rowKey: 'hardware',
              metricName: 'hardware',
              accessor: 'nodeConfig.hardware',
              showColor: false,
            },
            {
              rowText: 'Description',
              rowKey: 'description',
              metricName: 'description',
              showColor: false,
            },
            {
              rowText: 'Altitude',
              rowKey: 'altitude',
              metricName: 'altitude',
              accessor: 'location.altitude',
              showColor: false,
              decimalPlaces: 4,
            },
            {
              rowText: 'Latitude',
              rowKey: 'latitude',
              metricName: 'latitude',
              accessor: 'location.latitude',
              showColor: false,
              decimalPlaces: 6,
            },
            {
              rowText: 'Longitude',
              rowKey: 'longitude',
              metricName: 'longitude',
              accessor: 'location.longitude',
              showColor: false,
              decimalPlaces: 6,
            },
          ],
        },
      ],
    },
  ],
  filters: [
    {
      name: 'Technology Type',
      type: 'dropdown',
      metricCode: 'technologyType',
    },
  ],
};

export default ossConfig;
