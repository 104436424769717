import { DrawerKey } from '../reducers/drawersSlice';

/**
 * The IDs of different features are under different property names.
 * This function pulls out the right ID.
 */
export const getFeatureId = (feature: GeoJSON.Feature<GeoJSON.Geometry>, layerKey: DrawerKey) => {
  if (feature.properties) {
    if (layerKey === 'buildings') {
      return feature.properties.buildingId as string | number;
    }

    if (layerKey === 'buildingSales') {
      return feature.properties.buildingId as string | number;
    }

    if (layerKey === 'buildingSales-grey') {
      return feature.properties.buildingId as string | number;
    }

    if (layerKey === 'buildingCommercial') {
      return feature.properties.buildingId as string | number;
    }

    if (layerKey === 'buildingCommercial-grey') {
      return feature.properties.buildingId as string | number;
    }

    if (layerKey === 'buildings5gSa') {
      return feature.properties.buildingId as string | number;
    }

    if (layerKey === 'buildings5gNsa') {
      return feature.properties.buildingId as string | number;
    }

    if (layerKey === 'buildings-grey') {
      return feature.properties.buildingId as string | number;
    }

    if (layerKey === 'buildingsNh') {
      return feature.properties.buildingId as string | number;
    }

    if (layerKey === 'buildingsNh-grey') {
      return feature.properties.buildingId as string | number;
    }

    if (layerKey === 'grids') {
      return feature.properties.gridCellId as string | number;
    }

    if (layerKey === 'gridSales') {
      return feature.properties.gridCellId as string | number;
    }

    if (layerKey === 'gridSales-grey') {
      return feature.properties.gridCellId as string | number;
    }

    if (layerKey === 'gridCommercial') {
      return feature.properties.gridCellId as string | number;
    }

    if (layerKey === 'gridCommercial-grey') {
      return feature.properties.gridCellId as string | number;
    }

    if (layerKey === 'grids5gSa') {
      return feature.properties.gridCellId as string | number;
    }

    if (layerKey === 'grids5gNsa') {
      return feature.properties.gridCellId as string | number;
    }

    if (layerKey === 'grids-grey') {
      return feature.properties.gridCellId as string | number;
    }

    if (layerKey === 'gridsNh') {
      return feature.properties.gridCellId as string | number;
    }

    if (layerKey === 'gridsNh-grey') {
      return feature.properties.gridCellId as string | number;
    }

    if (layerKey === 'oss') {
      return feature.properties.nodeName as string | number;
    }

    if (layerKey === 'nqt') {
      return feature.properties.testId as string | number;
    }

    if (layerKey === 'nqt-grey') {
      return feature.properties.testId as string | number;
    }

    if (feature.properties.originalId) {
      return feature.properties.originalId as string | number;
    }
  }

  if (feature.id) {
    return feature.id as string | number;
  }

  return null;
};
