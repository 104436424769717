/**
 * Mapbox requires unique IDs from each layer.
 * Functions to make the layer names so they are in one place.
 * @module
 */

export const makeSourceId = (key: string | number) => `${key}-source`;
export const makeLayerId = (key: string | number) => key.toString();
export const makeLayerIdGrey = (key: string | number) => `${key.toString()}-grey`;
export const makeLayerIdTiles = (key: string | number) => `${key}-tiles-layer`;
