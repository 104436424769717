import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type NavigationSettingsState = {
  navigationIsOpen: boolean;
};

const initialState: NavigationSettingsState = {
  navigationIsOpen: false,
};

const navigationSettings = createSlice({
  name: 'navigationSettings',
  initialState,
  reducers: {
    setNavigationIsOpen: (
      state,
      action: PayloadAction<NavigationSettingsState['navigationIsOpen']>
    ) => {
      state.navigationIsOpen = action.payload;
    },
  },
});

const { actions, reducer } = navigationSettings;

export const { setNavigationIsOpen } = actions;

export default reducer;
