import { Box, Button, VStack } from '@chakra-ui/react';
import bbox from '@turf/bbox';
import { FC, useEffect } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useMap } from 'react-map-gl';
import { parse } from 'wellknown';
import { goToBoundsFromBBox } from '../../helpers/MapHelpers';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { setSelectedRegion, setSelectedRegionId } from '../../reducers/regionsSlice';
import { Region } from '../../services/regionsClient';

type RegionSelectionListProps = {
  regionsList: Region[];
};

const RegionSelectionList: FC<RegionSelectionListProps> = (props) => {
  const { regionsList } = props;
  const { 'dw-main-map': map } = useMap();
  const dispatch = useAppDispatch();

  const selectedRegionId = useAppSelector((state) => {
    return state.regionsSlice.selectedRegionId;
  });

  useEffect(() => {
    const hash = new URLSearchParams(window.location.hash.slice(1));
    const initedView = hash.get('initView');
    const selectedRegionFromRegionList = regionsList.filter(
      (region) => region.regionId === selectedRegionId
    )[0];

    if (initedView === null && !selectedRegionFromRegionList) {
      const newRegion = regionsList[0];
      const regionGeoJson = parse(newRegion.boundaryWkt);
      const boundsBBox = bbox(regionGeoJson);

      if (map) {
        goToBoundsFromBBox(boundsBBox, map);
        dispatch(setSelectedRegionId(newRegion.regionId));
        dispatch(setSelectedRegion(newRegion));
      }
    } else {
      dispatch(setSelectedRegion(selectedRegionFromRegionList));
    }
  }, [dispatch, map, regionsList, selectedRegionId]);

  return (
    <Box flexBasis={'40vh'}>
      <Scrollbars>
        <VStack align="start" spacing="1" mb="20px">
          {regionsList.map((region) => {
            return (
              <Button
                display={'flex'}
                key={`regionButton-${region.regionId}`}
                aria-label={`Go to region ${region.name}`}
                title={`Go to region ${region.name}`}
                size="xs"
                isActive={selectedRegionId === region.regionId}
                onClick={() => {
                  const regionGeoJson = parse(region.boundaryWkt);
                  const boundsBBox = bbox(regionGeoJson);
                  goToBoundsFromBBox(boundsBBox, map);
                  dispatch(setSelectedRegionId(region.regionId));
                  dispatch(setSelectedRegion(region));
                }}
              >
                {region.name}
              </Button>
            );
          })}
        </VStack>
      </Scrollbars>
    </Box>
  );
};

export default RegionSelectionList;
