import { Box, Heading, List, ListItem } from '@chakra-ui/react';
import React from 'react';
import { useAppSelector } from '../../hooks/useAppSelector';
import { usePersistedState } from '../../hooks/usePersistedState';
import useBuildingIdClient from '../../services/buildingIdClient';
import useMapboxGeocodingClient from '../../services/mapboxGeocodingClient';
import MapListItem, { SearchItem } from './MapListItem';

type MapSearchListContainerProps = {};

const MapSearchListContainer: React.FC<MapSearchListContainerProps> = (props) => {
  const searchValue = useAppSelector((state) => {
    return state.searchTextSettings.searchText;
  });

  const country = useAppSelector((state) => {
    return state.contextSettings.selectedCountryCode;
  });

  const [recentSearch, setRecentSearch] = usePersistedState<SearchItem[]>(`SEARCH-V1-${country}`);

  const { data: mapboxData, status: mapboxStatus } = useMapboxGeocodingClient({
    searchParam: searchValue,
    country,
  });

  const { data: dwData, status: dwStatus } = useBuildingIdClient({
    buildingId: searchValue,
  });

  const dwDataAsSearchItem: SearchItem | null = dwData
    ? {
        id: dwData.id,
        geometry: dwData.geometry,
        place_name: dwData.properties.name,
        place_type: ['building'],
      }
    : null;

  return (
    <>
      <Box maxH="" maxWidth="300px" p="0">
        <Box px={1}>
          <List spacing={1}>
            {dwStatus === 'success' &&
            mapboxStatus === 'success' &&
            mapboxData?.length === 0 &&
            dwData === null ? (
              <ListItem as="p" px="10px" color="white">
                No matching results
              </ListItem>
            ) : null}
            {dwDataAsSearchItem ? (
              <MapListItem
                key={dwDataAsSearchItem.id}
                item={dwDataAsSearchItem}
                addToRecentSearch={true}
                showRemoveButton={false}
                recentSearch={recentSearch}
                setRecentSearch={setRecentSearch}
              />
            ) : null}
            {mapboxData
              ? mapboxData.map((places) => {
                  const item: SearchItem = {
                    id: places.id,
                    geometry: places.geometry,
                    place_name: places.place_name,
                    place_type: places.place_type,
                  };

                  return (
                    <MapListItem
                      key={places.id}
                      showRemoveButton={false}
                      item={item}
                      recentSearch={recentSearch}
                      setRecentSearch={setRecentSearch}
                      addToRecentSearch={true}
                    />
                  );
                })
              : null}
          </List>
        </Box>
      </Box>

      <Box width="100%" p="0">
        <Box px={1}>
          {recentSearch && recentSearch.length >= 1 ? (
            <Heading
              as="h6"
              color={'white'}
              size="xs"
              px={'10px'}
              py={'10px'}
              mb={'10px'}
              background="#a6a0a0"
            >
              Previous Results
            </Heading>
          ) : null}
          <List spacing={1}>
            {recentSearch
              ? recentSearch?.map((result) => {
                  const item: SearchItem = {
                    id: result.id,
                    geometry: result.geometry,
                    place_name: result.place_name,
                    place_type: result.place_type,
                  };

                  return (
                    <MapListItem
                      key={`recentsearchresult-${result.id}`}
                      showRemoveButton={true}
                      item={item}
                      recentSearch={recentSearch}
                      setRecentSearch={setRecentSearch}
                      addToRecentSearch={false}
                    />
                  );
                })
              : null}
          </List>
        </Box>
      </Box>
    </>
  );
};

export default MapSearchListContainer;
