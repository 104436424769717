import { Box, Popover, PopoverContent, PopoverTrigger } from '@chakra-ui/react';
import { FC } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { IoNavigateCircleOutline } from 'react-icons/io5';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { setNavigationIsOpen } from '../../reducers/navigationSlice';
import MapOverlayButton from '../MapOverlayButton';
import MapSearch from '../MapSearch';
import RegionSelection from '../RegionSelection';

type NavigationProps = {};

const Navigation: FC<NavigationProps> = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector((state) => {
    return state.navigationSettings.navigationIsOpen;
  });

  const onToggle = () => {
    dispatch(setNavigationIsOpen(!isOpen));
  };

  return (
    <Popover
      id={'search-model'}
      trigger="click"
      placement="right-start"
      onClose={onToggle}
      onOpen={onToggle}
      isOpen={isOpen}
    >
      <PopoverTrigger>
        <Box>
          <MapOverlayButton id="search-button-open">
            {isOpen ? <AiOutlineClose size="1.6em" /> : <IoNavigateCircleOutline size="1.8em" />}
          </MapOverlayButton>
        </Box>
      </PopoverTrigger>
      <PopoverContent
        display={'flex'}
        flexDirection={'column'}
        height={'100%'}
        border={'none'}
        bg="blackAlpha.600"
        width={'500px'}
        maxH={'90vh'}
        maxW={'300px'}
        p={'20px'}
      >
        <MapSearch />
        <RegionSelection />
      </PopoverContent>
    </Popover>
  );
};

export default Navigation;
