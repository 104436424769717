import {
  Fade,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { FC } from 'react';
import { BiErrorCircle } from 'react-icons/bi';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { setFilterValue } from '../../reducers/layersSlice';
import { LayerKey } from '../../reducers/layersSlice.types';

type LayerControlFilterDateRangeProps = {
  layerKey: LayerKey;
  filterMetricCode: string;
};

const userDateRegExp = /^[0-9]{2}[/][0-9]{2}[/][0-9]{4}?$/;
const userDateTimeRegExp = /^[0-9]{2}[/][0-9]{2}[/][0-9]{4} [0-9]{2}[:][0-9]{2}?$/;

const LayerControlFilterDateRange: FC<LayerControlFilterDateRangeProps> = (props) => {
  const { layerKey, filterMetricCode } = props;

  const dispatch = useAppDispatch();

  const filterValue = useAppSelector((state) => {
    const layer = state.layersSettings[layerKey];

    if (layer) {
      return layer.filterValues[filterMetricCode];
    }
  });

  const filterValueFrom: string = Array.isArray(filterValue) ? filterValue[0] : '';
  const filterValueTo: string = Array.isArray(filterValue) ? filterValue[1] : '';

  const fromDateValid = filterValueFrom ? userDateRegExp.test(filterValueFrom) : true;
  const fromDateTimeValid = filterValueFrom ? userDateTimeRegExp.test(filterValueFrom) : true;
  const fromInvalid = !fromDateValid && !fromDateTimeValid;

  const toDateValid = filterValueTo ? userDateRegExp.test(filterValueTo) : true;
  const toDateTimeValid = filterValueTo ? userDateTimeRegExp.test(filterValueTo) : true;
  const toInvalid = !toDateValid && !toDateTimeValid;

  return (
    <Flex gridGap={10}>
      <FormControl id={`${layerKey}-date-range-filter`} isInvalid={fromInvalid}>
        <FormLabel as="div" fontSize="sm" color="white">
          From: (DD/MM/YYYY HH:MM)
        </FormLabel>
        <InputGroup>
          <Input
            errorBorderColor="#da6c6c"
            id={`${layerKey}-date-range-from-date-input`}
            color="white"
            value={filterValueFrom}
            onChange={(e) => {
              const newValue = e.target.value;
              const newFromValue = newValue === '' ? null : newValue;
              dispatch(
                setFilterValue({
                  layerKey,
                  filterMetricCode,
                  filterValue: [newFromValue, filterValueTo],
                })
              );
            }}
          />
          <Fade in={fromInvalid}>
            <InputRightElement>
              <BiErrorCircle size="1.4em" color="#da6c6c" />
            </InputRightElement>
          </Fade>
        </InputGroup>
        {toInvalid ? (
          <Fade in={toInvalid}>
            <FormErrorMessage color="#da6c6c" fontWeight="bold">
              Invalid date
            </FormErrorMessage>
          </Fade>
        ) : null}
      </FormControl>
      <FormControl isInvalid={toInvalid}>
        <FormLabel as="div" fontSize="sm" color="white">
          To: (DD/MM/YYYY HH:MM)
        </FormLabel>
        <InputGroup>
          <Input
            errorBorderColor="#da6c6c"
            id={`${layerKey}-date-range-to-date-input`}
            color="white"
            value={filterValueTo}
            onChange={(e) => {
              const newValue = e.target.value;
              const newToValue = newValue === '' ? null : newValue;

              dispatch(
                setFilterValue({
                  layerKey,
                  filterMetricCode,
                  filterValue: [filterValueFrom, newToValue],
                })
              );
            }}
          />
          <Fade in={toInvalid}>
            <InputRightElement>
              <BiErrorCircle size="1.4em" color="#da6c6c" />
            </InputRightElement>
          </Fade>
        </InputGroup>
        {toInvalid ? (
          <Fade in={toInvalid}>
            <FormErrorMessage color="#da6c6c" fontWeight="bold">
              Invalid date
            </FormErrorMessage>
          </Fade>
        ) : null}
      </FormControl>
    </Flex>
  );
};

export default LayerControlFilterDateRange;
