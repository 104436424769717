import { Button, ButtonProps } from '@chakra-ui/react';
import { FC } from 'react';

type MapOverlayButtonProps = ButtonProps;

const MapOverlayButton: FC<MapOverlayButtonProps> = (props) => {
  const { children, ...restOfProps } = props;

  return (
    <Button padding={1} {...restOfProps}>
      {children}
    </Button>
  );
};

export default MapOverlayButton;
